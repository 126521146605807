export const WIRE_STATUS_DB = {
  CREATED: 'created',
  RECIPIENT_ASSIGNED: 'recipient_assigned',
  RECIPIENT_PROXY_ASSIGNED: 'recipient_proxy_assigned',
  PROVIDE_PAYMENT_DETAILS: 'provide_payment_details',
  PAYMENT_DETAILS_PROVIDED: 'payment_details_provided',
  CANCELLED: 'cancelled',
  EXECUTED: 'executed',
  RECIPIENT_INVITED: 'recipient_invited',
  RECIPIENT_PROXY_INVITED: 'recipient_proxy_invited',
  EXECUTOR_INVITED: 'executor_invited',
  EXECUTOR_ASSIGNED: 'executor_assigned',
  READY_FOR_EXECUTION: 'ready_for_execution',
  PENDING: 'pending',
  PENDING_DEPOSIT: 'pending_deposit',
  DEPOSIT_SENT: 'deposit_sent',
  RETRY: 'retry_provide_payment_details',
  FAILED: 'failed',
  DEPOSITOR_UNASSIGNED: 'depositor_unassigned',
  DEPOSITOR_INVITED: 'depositor_invited',
} as const;

export const DEPOSIT_ACCOUNT_STATUS_DB = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING: 'pending',
  FAILED: 'failed',
} as const;

export const WIRE_TYPE_DB = {
  inbound: 'inbound',
  outbound: 'outbound',
} as const;

export const WIRE_TYPE_LABEL = {
  inbound: 'Inbound',
  outbound: 'Outbound',
};
