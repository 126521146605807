import { ReactComponent as MasterCardIcon } from './icons/cards/MasterCardIcon.svg';
import { ReactComponent as VisaIcon } from './icons/cards/VisaIcon.svg';
import { ReactComponent as AmexIcon } from './icons/cards/AmexIcon.svg';
import { ReactComponent as DiscoverIcon } from './icons/cards/DiscoverIcon.svg';
import { ReactComponent as JcbIcon } from './icons/cards/JcbIcon.svg';
import { ReactComponent as DinersIcon } from './icons/cards/DinersIcon.svg';
import { PAYMENT_CARD_SERVICE_TYPE } from '@/const/payment';

export const PAYMENT_CARDS_ICON_MAP = {
  [PAYMENT_CARD_SERVICE_TYPE.MASTER_CARD]: <MasterCardIcon />,
  [PAYMENT_CARD_SERVICE_TYPE.VISA]: <VisaIcon />,
  [PAYMENT_CARD_SERVICE_TYPE.AMEX]: <AmexIcon />,
  [PAYMENT_CARD_SERVICE_TYPE.DISCOVER]: <DiscoverIcon />,
  [PAYMENT_CARD_SERVICE_TYPE.JCB]: <JcbIcon />,
  [PAYMENT_CARD_SERVICE_TYPE.DINERS]: <DinersIcon />,
};
