import { jwtDecode } from 'jwt-decode';

export const decodeToken = <T>(token: string): T => {
  try {
    const decodedToken = jwtDecode(token);

    return decodedToken as T;
  } catch (error) {
    throw new Error(error.message);
  }
};
