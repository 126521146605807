import { USER_ROLE_MAP } from '@/const/user';
import { OrganizationRoutes } from '@/bundle/_Opco/ui/OrganizationUsers/routes/OrganizationUserRoutes';
import { OrganizationsRoutes } from '../_Opco/routes/OrganizationsRoutes';
import { OpcoUserRoutes } from '../_Opco/_OpcoUsersPage/routes/OpcoUserRoutes';
import { TransactionRoutes } from '../_OrgAdmin/pages/Transactions/routes/TransactionRoutes';
import { OutboundWireRoutes } from '../_OrgAdmin/pages/OutboundWire/routes/OutboundWireRoutes';
import { InboundWireRoutes } from '../_OrgAdmin/pages/InboundWire/routes/InboundWireRoutes';
import { DepositAccountsRoutes } from '../_OrgAdmin/pages/DepositAccounts/routes/DepositAccountsRoutes';
import { DepositorRoutes } from '../_Depositor/routes/DepositorRoutes';
import { ExecutorRoutes } from '../_Executor/routes/ExecutorRoutes';
import { RecipientWireRoutes } from '../_Recipient/routes/RecipientWireRoutes';

export const PRIVATE_ROUTES = {
  [USER_ROLE_MAP.OPCO]: [...OrganizationsRoutes, ...OrganizationRoutes, ...OpcoUserRoutes],
  [USER_ROLE_MAP.ORGANIZATION]: [
    ...TransactionRoutes,
    ...OutboundWireRoutes,
    ...InboundWireRoutes,
    ...DepositAccountsRoutes,
  ],
  [USER_ROLE_MAP.DEPOSITOR]: [...DepositorRoutes],
  [USER_ROLE_MAP.EXECUTOR]: [...ExecutorRoutes],
  [USER_ROLE_MAP.RECIPIENT]: [...RecipientWireRoutes],
};
