import styled from 'styled-components';
import {
  TypographyVariantType,
  TypographyFontWeightType,
  TYPOGRAPHY_VARIANT_MAP,
  TYPOGRAPHY_FONT_WEIGHT_MAP,
} from '@/styles/typography';
import { BoxWrapper } from '../Box/styles';

export const TypographyContainer = styled(BoxWrapper)<{
  variant: TypographyVariantType;
  fontWeight?: TypographyFontWeightType;
  color?: string;
}>`
  ${({ variant }) =>
    TYPOGRAPHY_VARIANT_MAP[variant] &&
    `
      ${TYPOGRAPHY_VARIANT_MAP[variant]};
  `};

  ${({ fontWeight }) =>
    TYPOGRAPHY_FONT_WEIGHT_MAP[fontWeight] &&
    `
      ${TYPOGRAPHY_FONT_WEIGHT_MAP[fontWeight]};
  `};
  ${({ color }) =>
    color &&
    `
      color: ${color};
  `};
`;
