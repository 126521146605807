import { flex } from '@/styles/layout';
import styled from 'styled-components';

export const FormWrapper = styled.div``;

export const ButtonsWrapper = styled.div`
  ${flex()};
  gap: 20px;
  margin-top: 12px;
`;
