import styled from 'styled-components';
import { resetButton } from '@/styles/common';
import { flex } from '@/styles/layout';
import { COLORS } from '@/styles/colors';

const ANIMATION = 'cubic-bezier(0, 0, 0, 1)';

export const Line = styled.div`
  background: ${COLORS.white};
  height: 2px;
  left: 4px;
  position: absolute;
  width: 32px;
  transition: transform 0.4s ${ANIMATION};
  transform: none;
`;

export const Line1 = styled(Line)`
  top: 8px;
`;

export const Line2 = styled(Line)`
  transition: opacity 0.2s ${ANIMATION};
  top: 19px;
`;

export const Line3 = styled(Line)`
  bottom: 8px;
`;

export const MenuButton = styled.button<{ isOpen: boolean }>`
  ${flex({ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' })};
  ${resetButton};
  cursor: pointer;
  position: relative;
  height: 40px;
  width: 40px;
  outline: none;

  ${({ isOpen }) =>
    isOpen &&
    `
    ${Line1} {
      transform: rotate(45deg) translate(7.77px, 7.77px);
    }

    ${Line2} {
      opacity: 0;
    }

    ${Line3} {
      transform: rotate(-45deg) translate(7.77px, -7.77px);
    }
  `};
`;
