import { useEffect, useRef } from 'react';

export const useEffectOnce = (callBack: () => void, condition = true) => {
  const isCalledRef = useRef(false);

  useEffect(() => {
    if (condition && !isCalledRef.current) {
      isCalledRef.current = true;
      callBack();
    }
  }, [callBack, condition]);
};
