import { BilledEventType } from '@/api/v1/opco/events/getBilledEventsApi';
import { Maybe } from '@/types/sharedTypes';

export type BilledEventsListType = {
  id: string;
  org_name: Maybe<string>;
  date_viewed: Maybe<string>;
  wire_name: Maybe<string>;
  wire_ref_id: Maybe<string>;
  user_viewed: Maybe<string>;
  transaction_name: Maybe<string>;
  id_verification: boolean;
  payment_details_collection_type: BilledEventType['wire']['payment_details_collection_type'];
};

export const getBilledEventsList = (billedEvents: BilledEventType[] = []): BilledEventsListType[] => {
  return billedEvents.map(({ id, date_created, wire, user, organization, id_verification }) => {
    return {
      id,
      org_name: organization?.name,
      date_viewed: date_created,
      wire_name: wire?.name,
      id_verification,
      wire_ref_id: wire?.ref_id,
      user_viewed: user?.email,
      transaction_name: wire?.contract?.name,
      payment_details_collection_type: wire?.payment_details_collection_type,
    };
  });
};
