import { Box } from '@/components/Box/Box';
import { ReelAnimateValue } from './ReelAnimateValue';

type ReelAnimateTimeType = {
  time: string;
};

export const ReelAnimateTime = ({ time }: ReelAnimateTimeType) => {
  const [minutes, seconds] = time.split(':');

  const [firstDigitMinutes, lastDigitMinutes] = minutes.split('');
  const [firstDigitSeconds, lastDigitSeconds] = seconds.split('');

  return (
    <Box display='flex'>
      <ReelAnimateValue value={firstDigitMinutes} />
      <ReelAnimateValue value={lastDigitMinutes} />
      :
      <ReelAnimateValue value={firstDigitSeconds} />
      <ReelAnimateValue value={lastDigitSeconds} />
    </Box>
  );
};
