import { Box } from '@/components/Box/Box';
import { Message } from '../../../styles';

interface CreatePinCodeMessageBoxType {
  error: string;
  currentHint: string;
}

export const CreatePinCodeHintBox = ({ error, currentHint }: CreatePinCodeMessageBoxType) => {
  return (
    <Box mt='16px'>
      {!error && <Message>{currentHint}</Message>}
      {error && <Message hasError={!!error}>{error}</Message>}
    </Box>
  );
};
