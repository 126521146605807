import { ReactComponent as ClearIndicatorSvg } from '../images/ClearIndicator.svg';
import { ClearIndicatorProps } from 'react-select';
import { ClearIndicarorWrapper } from './styles';

export const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <ClearIndicarorWrapper {...props.innerProps}>
      <ClearIndicatorSvg width={10} height={10} />
    </ClearIndicarorWrapper>
  );
};
