import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { bodySmall, poppinsMediumFont, poppinsRegularFont, poppinsSemiBoldFont } from '@/styles/typography';
import { flex } from '@/styles/layout';
import { ValueOf } from '@/types/sharedTypes';
import { Box } from '../Box/Box';
import { SpacingPropsType } from '../Box/styles';

export const VARIANT_MAP = {
  INFO: 'info',
  INFO_SECONDARY: 'info-secondary',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
} as const;

export type NotificationWrapperPropsType = SpacingPropsType & {
  verticalAlign?: React.CSSProperties['alignItems'];
  variant?: ValueOf<typeof VARIANT_MAP>;
  fontVariant?: 'medium' | 'semibold' | 'bold';
  inline?: boolean;
};

export const NotificationWrapper = styled(Box)<NotificationWrapperPropsType>`
  ${flex({ justifyContent: 'flex-start' })};
  border-radius: 10px;
  padding: 15px 16px;
  background-color: ${COLORS.blue[200]};
  ${poppinsRegularFont};
  ${bodySmall};

  ${({ inline }) =>
    inline &&
    `
    display: inline-flex;
  `};

  ${({ verticalAlign }) =>
    verticalAlign &&
    `
    align-items: ${verticalAlign};
  `};

  ${({ variant }) =>
    variant === 'success' &&
    `
    background-color: ${COLORS.green[200]};
  `};

  ${({ variant }) =>
    variant === 'info-secondary' &&
    `
    background-color: ${COLORS.grey[300]};
  `};

  ${({ variant }) =>
    variant === 'warning' &&
    `
    background-color: ${COLORS.yellow[200]};
  `};

  ${({ variant }) =>
    variant === 'error' &&
    `
    background-color: ${COLORS.red[100]};
  `};

  ${({ fontVariant }) =>
    fontVariant === 'medium' &&
    `
    ${poppinsMediumFont};
  `};

  ${({ fontVariant }) =>
    fontVariant === 'semibold' &&
    `
    ${poppinsSemiBoldFont};
  `};

  ${({ fontVariant }) =>
    fontVariant === 'bold' &&
    `
    ${poppinsSemiBoldFont};
  `};
`;

export const IconWrapper = styled.div`
  margin-right: 12px;
`;

export const NotificationContent = styled.div`
  word-break: break-word;
  color: ${COLORS.grey[950]};
`;

export const NotificationTitle = styled.div`
  ${poppinsSemiBoldFont};
  ${bodySmall};
  color: ${COLORS.black};
  margin-bottom: 12px;
`;

export const NotificationMessage = styled.div`
  color: ${COLORS.black};
`;
