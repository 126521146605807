import { Route } from 'react-router-dom';
import { getOutboundWireDetailsPath } from '../_Details/urls/getOutboundWireDetailsUrl';
import { OutboundWireDetailsPage } from '../_Details/OutboundWireDetailsPage';

export const OutboundWireRoutes = [
  <Route
    key={getOutboundWireDetailsPath()}
    path={getOutboundWireDetailsPath()}
    element={<OutboundWireDetailsPage />}
  />,
];
