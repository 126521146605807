import styled from 'styled-components';
import { BaseIcon } from '@/components/form/FormikDatePicker/ui/styles';
import { flex } from '@/styles/layout';
import { Input } from '../form/fields/FormikInput/styles';
import { caption, poppinsSemiBoldFont } from '@/styles/typography';
import { COLORS } from '@/styles/colors';

export const CrossIconWrapper = styled(BaseIcon)`
  display: none;
  right: 16px;
`;

export const AutocompleteWrapper = styled.div`
  position: relative;
`;

export const LabelBlock = styled.div`
  ${flex({ justifyContent: 'space-between' })};
`;

export const InputWrapper = styled.div`
  position: relative;
  ${Input}:focus ~ ${CrossIconWrapper} {
    ${flex()};
  }

  &:hover {
    ${CrossIconWrapper} {
      ${flex()};
    }
  }
`;

export const LoadingIndicator = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 46px;
`;

export const LabelSlotWrapper = styled.span`
  ${poppinsSemiBoldFont};
  ${caption};
  color: ${COLORS.grey[700]};
`;
