import { Loader } from '@/components/Loader/Loader';
import { LoaderWrapper } from './styles';

export const PaymentDetailsDataWidgetLoader = () => {
  return (
    <LoaderWrapper>
      <Loader background='transparent' inline />
      <span>Checking with the WireVault blockchain.</span>
    </LoaderWrapper>
  );
};
