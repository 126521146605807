import { Tooltip } from '@/components/Tooltip/Tooltip';
import { ReactComponent as EditIcon } from '../../../../images/EditIcon.svg';
import { EditLinkWrapper } from './styles';

type EditLinkType = {
  label: string;
  to?: string;
  preventDefault?: boolean;
  onClick?: () => void;
};

export const EditLink = ({ to = null, label, preventDefault, onClick }: EditLinkType) => {
  const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation();

    if (preventDefault) {
      event.preventDefault();
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <Tooltip id='edit-tooltip'>{label}</Tooltip>
      <EditLinkWrapper to={to} data-tooltip-id='edit-tooltip' onClick={handleClick}>
        <EditIcon />
      </EditLinkWrapper>
    </>
  );
};
