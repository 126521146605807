import { COLORS } from '@/styles/colors';
import { ellipsis } from '@/styles/common';
import { flexCenter } from '@/styles/layout';
import { bodySmall, poppinsMediumFont } from '@/styles/typography';
import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
`;

export const TableBody = styled.tbody``;

export const TableCell = styled.td<{ width: string }>`
  padding: 14px 16px;
  ${ellipsis};
  width: ${({ width }) => (width ? width : '100%')};
  color: ${COLORS.grey[950]};
  border-bottom: 1px solid ${COLORS.grey[300]};
  ${poppinsMediumFont};
  ${bodySmall};

  &:first-child {
    border-left: 1px solid ${COLORS.grey[300]};
  }

  &:last-child {
    border-right: 1px solid ${COLORS.grey[300]};
  }
`;

export const TableRow = styled.tr<{ isClickableRow?: boolean }>`
  border-bottom: 1px solid ${COLORS.grey[300]};
  cursor: ${({ isClickableRow }) => isClickableRow && 'pointer'};

  &:last-child {
    border-bottom: none;

    ${TableCell} {
      &:first-child {
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }

  &:hover {
    background-color: ${({ isClickableRow }) => isClickableRow && COLORS.grey[200]};
  }
`;

export const EmptyTableResults = styled.div`
  ${flexCenter};
  height: 100%;
`;
