import { MultiValueProps } from 'react-select';
import { MoreSelectedBadge } from './styles';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { Box } from '@/components/Box/Box';

export const MultiSelectValue = (props: MultiValueProps) => {
  const MAX_COUNT_TO_SHOW = 1;
  const itemsCount = props.getValue().length;

  if (props?.index < MAX_COUNT_TO_SHOW) {
    return (
      <Box display='flex' alignItems='center' columnGap='8px'>
        <Typography variant='bodySmall' color={COLORS.black} fontWeight='regular'>
          Organization Name
        </Typography>
        <MoreSelectedBadge>{itemsCount}</MoreSelectedBadge>
      </Box>
    );
  }

  return null;
};
