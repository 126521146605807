import { COLORS } from '@/styles/colors';
import { media } from '@/styles/responsive';
import { headline2, poppinsSemiBoldFont } from '@/styles/typography';
import styled from 'styled-components';

export const TwoFAInput = styled.input<{ hasError: boolean }>`
  border: ${({ hasError }) => (hasError ? `1px solid ${COLORS.red[500]}` : `1px solid ${COLORS.grey[200]}`)};
  padding: 10px 8px;
  width: 64px;
  height: 64px;
  ${poppinsSemiBoldFont};
  ${headline2}
  letter-spacing: -0.72px;
  background: ${COLORS.grey[200]};
  color: ${COLORS.grey[950]};
  outline: none;
  border-radius: 10px;
  text-align: center;
  transition: 0.2s all ease-in-out;

  &:focus {
    border: 1px solid ${COLORS.green[500]};
    box-shadow: 0 0 2px rgb(0 134 111 / 60%);
  }

  ${media.mobile`
    width: 50px;
    height: 50px;  
  `}
`;
