import { checkAuthPinCodeApi } from '@/api/v1/users/checkAuthPinCodeApi';
import { getAuthPinCodeAttemptsApi } from '@/api/v1/users/getAuthPinCodeAttemptsApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const checkAuthPinCode = async (payload: { pin: string }) => {
  try {
    const response = await checkAuthPinCodeApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const getAuthPinCodeAttempts = async () => {
  try {
    const response = await getAuthPinCodeAttemptsApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
