import { useCallback } from 'react';

export const useRadioGroupDetailsConfig = () => {
  const radioGroupConfig = useCallback((isCurrentPaymentType, setIsCurrentPaymentType, clearPaymentDetailsState) => {
    const isCheckHandler = (type: string) => {
      setIsCurrentPaymentType(type);
      clearPaymentDetailsState();
    };

    return {
      first_radio: {
        radio_title: 'Domestic Payment',
        isChecked: isCurrentPaymentType,
        value: 'domestic',
        handler: () => isCheckHandler('domestic'),
      },
      second_radio: {
        radio_title: 'International Payment',
        isChecked: isCurrentPaymentType,
        value: 'international',
        handler: () => isCheckHandler('international'),
      },
    };
  }, []);

  return { radioGroupConfig };
};
