import { getDepositorWireApi } from '@/api/v1/depositor/getDepositorWireApi';
import { WIRE_STATUS_DB } from '@/const/wire';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { depositorChangeWireStatusApi } from '@/api/v1/depositor/depositorChangeWireStatusApi';
import { getDepositorPaymentDetailsApi } from '@/api/v1/depositor/getDepositorPaymentDetailsApi';

export const loadDepositorWire = async (id: string) => {
  try {
    const response = await getDepositorWireApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadDepositorPaymentDetails = async (id: string) => {
  try {
    const response = await getDepositorPaymentDetailsApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const changeDepositorWireStatus = async (id: string, status: typeof WIRE_STATUS_DB.DEPOSIT_SENT) => {
  const payload = { status };

  try {
    const response = await depositorChangeWireStatusApi(id, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
