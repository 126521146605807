import { ChangeEvent, ClipboardEvent, DragEvent, KeyboardEvent, useEffect, useRef } from 'react';
import { TwoFAInput } from './styles';
import { Box } from '@/components/Box/Box';

type TwoFaCodeInputType = {
  code: string[];
  onChange: (code: string[]) => void;
  hasError: boolean;
};

type ChangeEventType = ChangeEvent<HTMLInputElement>;
type PasteEventType = ClipboardEvent<HTMLInputElement>;
type DropEventType = DragEvent<HTMLInputElement>;
type KeyDownEventType = KeyboardEvent<HTMLInputElement>;

export const CODE_LENGTH = 6;

const EVENT_KEY_MAP = {
  BACKSPACE: 'Backspace',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
};

const NUMBER_REGEX = /^\d*$/;
const NUMBER_PASTE_REGEX = /[^0-9]/g;

export const TwoFaCodeInput = ({ code, onChange, hasError }: TwoFaCodeInputType) => {
  const inputRefs = useRef([]);

  const getNextRef = (index: number) => {
    return inputRefs.current[index + 1];
  };

  const getPreviousRef = (index: number) => {
    return inputRefs.current[index - 1];
  };

  const changeCode = (index: number, value: string) => {
    const newCode = [...code];

    newCode[index] = value;
    onChange(newCode);
  };

  const setTextFromClipboard = (index: number, event) => {
    event.preventDefault?.();

    const clipboardText =
      event.type === 'drop' ? event.dataTransfer.getData('text') : event.clipboardData.getData('text');
    const pastedNumbers = clipboardText?.replace(NUMBER_PASTE_REGEX, '').slice(0, code.length).split('');
    const newCode = [...code];
    let startIndex = 0;

    inputRefs.current.forEach((_, refIndex) => {
      if (refIndex < index) return;

      newCode[refIndex] = pastedNumbers[startIndex++];
    });

    onChange(newCode);
  };

  const handleKeyDown = (index: number, event: KeyDownEventType) => {
    if (event.key === EVENT_KEY_MAP.ARROW_LEFT) {
      const previousRef = getPreviousRef(index);

      previousRef?.focus();
    }

    if (event.key === EVENT_KEY_MAP.ARROW_RIGHT) {
      const nextRef = getNextRef(index);

      nextRef?.focus();
    }

    if (event.key === EVENT_KEY_MAP.BACKSPACE) {
      event.preventDefault();

      const hasCodeValue = code[index] !== '';

      if (hasCodeValue) {
        changeCode(index, '');
      } else {
        changeCode(index - 1, '');

        const previousRef = getPreviousRef(index);

        previousRef?.focus();
      }
    }
  };

  const handleDrop = (index: number, event: DropEventType) => {
    setTextFromClipboard(index, event);
  };

  const handlePaste = (index: number, event: PasteEventType) => {
    setTextFromClipboard(index, event);
  };

  // Mobile web browsers of iOS/android phones does not support <onPaste> event when the text/number is paste from keyboard clipboard.
  // Manually trigger the paste function and provide the value to clipboard
  const triggerPasteEventForMobile = (index: number, event: ChangeEventType, value: string) => {
    const pasteEvent: any = {
      ...event,
      clipboardData: {
        getData() {
          return value.trim();
        },
      },
    };

    handlePaste(index, pasteEvent);
  };

  const handleChange = (index: number, event: ChangeEventType) => {
    let { value, defaultValue } = event.target;

    if (!NUMBER_REGEX.test(value)) return;

    const newValue = value.replace(defaultValue, '');

    if (newValue.trim().length > 1) {
      triggerPasteEventForMobile(index, event, value);

      return;
    }

    changeCode(index, newValue);

    const nextRef = getNextRef(index);

    nextRef?.focus();
  };

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  return (
    <Box display='flex' justifyContent='space-between'>
      {code.map((value, index) => (
        <TwoFAInput
          type='tel'
          key={index}
          value={value}
          ref={(ref) => (inputRefs.current[index] = ref)}
          onPaste={(event: PasteEventType) => handlePaste(index, event)}
          onDrop={(event: DropEventType) => handleDrop(index, event)}
          onChange={(event: ChangeEventType) => handleChange(index, event)}
          onKeyDown={(event: KeyDownEventType) => handleKeyDown(index, event)}
          hasError={hasError}
        />
      ))}
    </Box>
  );
};
