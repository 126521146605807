import { ApiResponseType } from '@/types/sharedTypes';
import { TrustStampResponseStatusType } from '../../recipient/getRecipientTrustStampStatusApi';
import { authApiService } from '@/api/authApiService';

export const getRecipientTrustStampStatusByOrgAdminApi = (
  wireId: string
): Promise<ApiResponseType<TrustStampResponseStatusType>> => {
  const uri = `/organization/wires/${wireId}/trust-stamp/status/`;

  return authApiService.GET(uri);
};
