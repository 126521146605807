import { RequestOptions } from '@/types/sharedTypes';
import { authApiService } from '@/api/authApiService';

export const unblockOrganizationUserApi = (
  organizationId: string,
  userId: string,
  payload: {},
  options?: RequestOptions
) => {
  const uri = `/opco/organizations/${organizationId}/users/${userId}/status:unblock/`;

  return authApiService.POST(uri, payload, null, options);
};
