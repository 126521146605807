import { FormValues } from '@/helpers/formHelpers/formHelpers';
import { FormBlockGenerator } from '../../../../../../../components/form/FormBlockGenerator/FormBlockGenerator';
import { FormWrapper, Row, HighlightRow, HeaderAction, HighlightRowTitle } from './styles';

type AddIntermediaryBankFormType = {
  isOpen: boolean;
  onToggle: () => void;
  error?: {};
  intermediaryBankConfig: FormValues;
};

export const AddIntermediaryBankForm = ({
  isOpen,
  onToggle,
  error,
  intermediaryBankConfig,
}: AddIntermediaryBankFormType) => {
  return (
    <FormWrapper>
      {isOpen ? (
        <>
          <HighlightRow>
            <HighlightRowTitle>Intermediary Bank</HighlightRowTitle>
            <HeaderAction onClick={onToggle}>Remove</HeaderAction>
          </HighlightRow>
          <FormBlockGenerator configuration={intermediaryBankConfig} errorsApi={error} columns={2} />
        </>
      ) : (
        <Row>
          <HeaderAction onClick={onToggle}>+ Add Intermediary Bank</HeaderAction>
        </Row>
      )}
    </FormWrapper>
  );
};
