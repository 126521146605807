import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { ellipsis } from '@/styles/common';
import { bodySmall } from '@/styles/typography';

export const LastResetTime = styled.time`
  color: ${COLORS.grey[950]};
  ${ellipsis};
  ${bodySmall}
`;

export const TimeSeparator = styled.div`
  margin: 0 16px;
  width: 1px;
  background-color: ${COLORS.grey[300]};
`;

export const ResetLabel = styled.div`
  ${bodySmall};
  color: ${COLORS.grey[600]};
`;
