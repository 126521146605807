import { useEffect, useState } from 'react';

/**
 * Returns the online status of the browser.
 *
 */
export function useOnline(): boolean {
  const [online, setOnline] = useState(navigator.onLine);

  const changeOffline = () => {
    setOnline(false);
  };

  const changeOnline = () => {
    setOnline(true);
  };

  useEffect(() => {
    setOnline(navigator.onLine);

    window.addEventListener('online', changeOnline);
    window.addEventListener('offline', changeOffline);

    return () => {
      window.removeEventListener('online', changeOnline);
      window.removeEventListener('offline', changeOffline);
    };
  }, []);

  return online;
}
