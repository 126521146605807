import { createDomesticDepositAccountApi } from '@/api/v1/organization/deposit-accounts/createDomesticDepositAccountApi';
import { createInternationalDepositAccountApi } from '@/api/v1/organization/deposit-accounts/createInternationalDepositAccountApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const createDepositAccount = async (payload, isDomesticPayment: boolean) => {
  try {
    const response = isDomesticPayment
      ? await createDomesticDepositAccountApi(payload)
      : await createInternationalDepositAccountApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
