import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { ReactNode, useEffect, useState } from 'react';
import { updatePaymentDetails } from '../../api';
import { AMPLITUDE_EVENTS, AmplitudeEventsType } from '@/services/amplitudeService/amplitudeEvents';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { PAYMENT_TYPE_DB } from '@/const/shared';
import { isDomesticPaymentType, isInternationalPaymentType } from '@/helpers/paymentDetailsHelpers';
import { useRadioGroupDetailsConfig } from '../../hooks/useRadioGroupDetailsConfig';
import { createPaymentDetailsValidationSchema } from '../../const/createPaymentDetailsValidationSchema';
import { useChangeInitialValues } from '../../hooks/useChangeInitialValues';
import { ConfirmModal, ConfirmModalHint } from '@/components/ConfirmModal/ConfirmModal';
import { PaymentType } from '@/types/paymentDetailsTypes';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { PaymentDetailsSubtitle } from './styles';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { FormBlockGenerator } from '@/components/form/FormBlockGenerator/FormBlockGenerator';
import { Button } from '@/components/Button/Button';
import { EmailLink } from '@/components/EmailLink/EmailLink';
import { FormValues, isValidForm } from '@/helpers/formHelpers/formHelpers';
import { AddIntermediaryBankForm } from '@/bundle/_OrgAdmin/pages/DepositAccounts/_Create/ui/AddIntermediaryBankForm/AddIntermediaryBankForm';
import { CREATE_PAYMENT_DETAILS_DOMESTIC_FORM_CONFIG } from '../../const/paymentDetailsDomesticFormConfig';
import { CREATE_PAYMENT_DETAILS_INTERNATIONAL_FORM_CONFIG } from '../../const/paymentDetailstInternationalFormConfig';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { AssignedWireType } from '@/types/wireTypes';
import { TEXT_MAX_LENGTH } from '@/helpers/formHelpers/validators';
import { isRecipientChainProxy, isRecipientProxy } from '@/helpers/wireHelpers';
import { getAmplitudePaymentDetailsProvider } from '@/services/amplitudeService/amplitudeHelpers';
import { Box } from '@/components/Box/Box';

type RecipientProvidePaymentDetailsFormType = {
  wire: AssignedWireType;
  onProvidePaymentDetails: () => void;
  autofillNames?: boolean;
  headlineSlot?: ReactNode;
  actionSlot?: ReactNode;
};

const {
  PAYMENT_DETAILS_DOMESTIC_FORM_CONFIG,
  PAYMENT_DETAILS_DOMESTIC_INTERMEDIARY_FORM_CONFIG,
  intermediaryBankDomesticInitialValues,
} = CREATE_PAYMENT_DETAILS_DOMESTIC_FORM_CONFIG;
const {
  PAYMENT_DETAILS_INTERNATIONAL_FORM_CONFIG,
  PAYMENT_DETAILS_INTERNATIONAL_INTERMEDIARY_FORM_CONFIG,
  intermediaryBankInternationalInitialValues,
} = CREATE_PAYMENT_DETAILS_INTERNATIONAL_FORM_CONFIG;

export const RecipientProvidePaymentDetailsForm = ({
  wire,
  onProvidePaymentDetails,
  headlineSlot,
  autofillNames = false,
  actionSlot,
}: RecipientProvidePaymentDetailsFormType) => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const { radioGroupConfig } = useRadioGroupDetailsConfig();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenIntermediaryBank, setIsOpenIntermediaryBank] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType>(PAYMENT_TYPE_DB.DOMESTIC);

  const isDomesticPayment = isDomesticPaymentType(selectedPaymentType);
  const isInternationalPayment = isInternationalPaymentType(selectedPaymentType);
  const { initialValues } = useChangeInitialValues(isDomesticPayment);
  const validationSchema = createPaymentDetailsValidationSchema(isDomesticPayment, isOpenIntermediaryBank);
  const isProxy = isRecipientProxy(wire);
  const isChainProxy = isRecipientChainProxy(wire);

  const logAmplitudeEventSuccess = (paymentDetails) => {
    let currentEvent: AmplitudeEventsType;

    if (isDomesticPayment) {
      currentEvent = paymentDetails?.intermediary_bank_aba_number
        ? AMPLITUDE_EVENTS.DomesticPaymentIntermediarySuccess
        : AMPLITUDE_EVENTS.DomesticPaymentSuccess;
    }

    if (isInternationalPayment) {
      currentEvent = paymentDetails?.intermediary_bank_swift_code
        ? AMPLITUDE_EVENTS.InternationalPaymentIntermediarySuccess
        : AMPLITUDE_EVENTS.InternationalPaymentSuccess;
    }

    const provider = getAmplitudePaymentDetailsProvider(isChainProxy, isProxy);

    return amplitudeService.logEvent(currentEvent, { provider });
  };

  const {
    mutate: mutatePaymentDetails,
    data: updatedPaymentDetailsData,
    isPending: isPendingPaymentDetails,
    reset: resetPaymentDetails,
  } = useMutation({
    mutationKey: ['provide_payment_details'],
    mutationFn: (values: FormValues) => {
      const payload = {
        ...values,
        account_name: values.account_name_field,
      };

      return updatePaymentDetails(id, payload, isDomesticPayment);
    },
    onSuccess(updatedPaymentDetails) {
      if (updatedPaymentDetails.error) return;

      setIsOpenModal(false);
      logAmplitudeEventSuccess(updatedPaymentDetails?.body);
      queryClient.invalidateQueries({ queryKey: ['load_recipient_wire', id] });
      onProvidePaymentDetails();
    },
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      await mutatePaymentDetails(values);
    },
  });

  useEffect(() => {
    if (!autofillNames) return;

    // Autofill recipient and account name and strip to max length
    const { first_name, middle_name, last_name } = wire?.assigned_user || {};
    const fullName = `${first_name || ''} ${middle_name || ''} ${last_name || ''}`.substring(0, TEXT_MAX_LENGTH);

    const trimmedFullName = fullName.trim();

    formik.setValues({
      ...formik.initialValues,
      recipient_name: trimmedFullName,
      account_name_field: trimmedFullName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomesticPayment, autofillNames, wire?.assigned_user]);

  const logAmplitudeEventRedirect = () => {
    const { values } = formik;

    let currentEvent: AmplitudeEventsType;

    if (isDomesticPayment) {
      currentEvent = values.intermediary_bank_aba_number
        ? AMPLITUDE_EVENTS.DomesticPaymentIntermediaryRedirect
        : AMPLITUDE_EVENTS.DomesticPaymentRedirect;
    }

    if (isInternationalPayment) {
      currentEvent = values.intermediary_bank_swift_code
        ? AMPLITUDE_EVENTS.InternationalPaymentIntermediaryRedirect
        : AMPLITUDE_EVENTS.InternationalPaymentRedirect;
    }

    const provider = getAmplitudePaymentDetailsProvider(isChainProxy, isProxy);

    return amplitudeService.logEvent(currentEvent, { provider });
  };

  const openConfirmPaymentDetailsModal = async () => {
    const isValid = await isValidForm(formik);

    if (!isValid) return;

    logAmplitudeEventRedirect();
    setIsOpenModal(true);
  };

  const toggleIntermediaryForm = () => {
    setIsOpenIntermediaryBank(!isOpenIntermediaryBank);

    const initialIntermediaryBankValues = isDomesticPayment
      ? intermediaryBankDomesticInitialValues
      : intermediaryBankInternationalInitialValues;

    formik.setValues({
      ...formik.values,
      ...initialIntermediaryBankValues,
    });
  };

  const paymentFormConfig = isDomesticPayment
    ? PAYMENT_DETAILS_DOMESTIC_FORM_CONFIG
    : PAYMENT_DETAILS_INTERNATIONAL_FORM_CONFIG;

  const intermediaryBankConfig = isDomesticPayment
    ? PAYMENT_DETAILS_DOMESTIC_INTERMEDIARY_FORM_CONFIG
    : PAYMENT_DETAILS_INTERNATIONAL_INTERMEDIARY_FORM_CONFIG;

  const getFormattedError = () => {
    const accountNameError = getResponseError(updatedPaymentDetailsData?.error, 'account_name');

    //TODO fix type error content
    if (accountNameError) {
      // @ts-ignore
      const { account_name, ...rest } = updatedPaymentDetailsData?.error?.error_content;

      return {
        ...updatedPaymentDetailsData?.error,
        error_content: {
          ...rest,
          account_name_field: [accountNameError],
        },
      };
    }

    return updatedPaymentDetailsData?.error;
  };

  const formattedError = getFormattedError();

  return (
    <>
      <DetailsWidget header='Wire Info' hasHighlight headlineSlot={headlineSlot} headerOffset={8} action={actionSlot}>
        <PaymentDetailsSubtitle>Please provide your wire info.</PaymentDetailsSubtitle>
        <FormikForm value={formik}>
          <FormBlockGenerator
            configuration={paymentFormConfig}
            radioGroupConfig={radioGroupConfig(selectedPaymentType, setSelectedPaymentType, resetPaymentDetails)}
            errorsApi={formattedError}
            columns={2}
          />
          <AddIntermediaryBankForm
            isOpen={isOpenIntermediaryBank}
            onToggle={toggleIntermediaryForm}
            error={formattedError}
            intermediaryBankConfig={intermediaryBankConfig}
          />
        </FormikForm>

        <Box display='flex' alignItems='center' justifyContent='end' columnGap='20px' mt='12px' mb='12px'>
          <Button width={160} size='medium' onClick={openConfirmPaymentDetailsModal} mobileStretch>
            Submit
          </Button>
        </Box>
      </DetailsWidget>

      <ConfirmModal
        isOpen={isOpenModal}
        header='Confirm Wire Info'
        isLoading={isPendingPaymentDetails}
        body={
          <>
            <ConfirmModalHint>You are certifying the validity of the wire info you have provided.</ConfirmModalHint>
            <ConfirmModalHint>
              This information will be committed to the WireVault blockchain and cannot be changed.
            </ConfirmModalHint>
            <ConfirmModalHint>
              If you need to make a change to your wire info, please contact{' '}
              <EmailLink email={wire?.created_by?.email} variant='big' /> to request a cancellation before the wire is
              sent.
            </ConfirmModalHint>
          </>
        }
        onClose={() => setIsOpenModal(false)}
        onConfirm={formik.handleSubmit}
      />
    </>
  );
};
