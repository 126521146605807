import { DEPOSIT_ACCOUNT_STATUS_DB } from '@/const/wire';
import { StatusType, VARIANT_HIGHLIGHT } from '@/components/Status/const';
import { ReactComponent as PendingStatusIcon } from '@/images/BlcStatusesIcons/PendingStatusIcon.svg';
import { ReactComponent as FailedStatusIcon } from '@/images/BlcStatusesIcons/FailedStatusIcon.svg';

export const STATUS_DEPOSIT_ACCOUNT_TEXT_DB = {
  [DEPOSIT_ACCOUNT_STATUS_DB.ACTIVE]: 'Active',
  [DEPOSIT_ACCOUNT_STATUS_DB.INACTIVE]: 'Inactive',
  [DEPOSIT_ACCOUNT_STATUS_DB.PENDING]: 'Pending',
  [DEPOSIT_ACCOUNT_STATUS_DB.FAILED]: 'Failed',
};

export const STATUS_DEPOSIT_ACCOUNT_MAP: StatusType = {
  [DEPOSIT_ACCOUNT_STATUS_DB.ACTIVE]: { variant: VARIANT_HIGHLIGHT.GREEN },
  [DEPOSIT_ACCOUNT_STATUS_DB.INACTIVE]: { variant: VARIANT_HIGHLIGHT.RED },
  [DEPOSIT_ACCOUNT_STATUS_DB.PENDING]: {
    variant: VARIANT_HIGHLIGHT.BLUE,
    statusIcon: <PendingStatusIcon />,
  },
  [DEPOSIT_ACCOUNT_STATUS_DB.FAILED]: {
    variant: VARIANT_HIGHLIGHT.RED,
    statusIcon: <FailedStatusIcon />,
  },
};
