import { flexCenterColumn } from '@/styles/layout';
import { body, poppinsMediumFont, subTitle } from '@/styles/typography';
import styled from 'styled-components';
import { COLORS } from '@/styles/colors';

export const AccessDeniedWrapper = styled.div`
  height: 100%;
  padding: 12px 16px;
  ${flexCenterColumn};
  ${poppinsMediumFont};
`;

export const AccessDeniedHeader = styled.h4`
  ${subTitle};
  font-weight: 500;
  margin: 32px 0 0;
`;

export const AccessDeniedSubheader = styled.p`
  ${body};
  font-weight: 500;
  color: ${COLORS.grey[600]};
  margin-bottom: 24px;
  text-align: center;
`;
