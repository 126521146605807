import { Box } from '@/components/Box/Box';
import { CardDot } from './styles';

type PaymentCardNumberType = {
  cardNumber: string;
};

const ENDING_CARD_NUMBER_LENGTH = 4;
const DOTS_LIST = Array.from(Array(ENDING_CARD_NUMBER_LENGTH).keys());

export const PaymentCardNumber = ({ cardNumber }: PaymentCardNumberType) => {
  const endingCardNumber = cardNumber?.slice(-ENDING_CARD_NUMBER_LENGTH);

  return (
    <Box display='inline-flex' alignItems='center'>
      {DOTS_LIST.map((_, index) => (
        <CardDot key={index} />
      ))}
      {endingCardNumber}
    </Box>
  );
};
