import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';
import { THEME } from '@/styles/theme';

export const ContentLayoutWrapper = styled.div`
  ${flex({ flexDirection: 'column' })};
  flex: 1;
  background: ${COLORS.white};
  padding: 20px 36px 36px;
  border-radius: 20px;

  ${media.tablet`
    border-radius: 0;
    padding: 24px 24px;
  `}

  ${media.mobile`
    padding: 24px 16px;
  `}
`;

export const ContentWrapper = styled.div`
  ${flex({ flexDirection: 'column' })};
  width: 100%;
  flex: 1;
`;

export const BannerWrapper = styled.div`
  top: 0;
  position: sticky;
  z-index: ${THEME.upsellBanner.zIndex};

  ${media.tablet`
    width: 100%;
  `}
`;
