import { css } from 'styled-components';
import { THEME } from './theme';

export const customVerticalScrollBase = css`
  ::-webkit-scrollbar {
    width: ${THEME.layout.scrollWidth};
  }

  ::-webkit-scrollbar-thumb {
    background: #d5dee3;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ellipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const resetList = css`
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
`;

export const resetButton = css`
  background: transparent;
  padding: 0;
  outline: none;
  border: none;
`;

export const overlayBackground = css`
  background: rgb(102 111 120 / 50%);
  backdrop-filter: blur(1.5px);
`;

export const customVerticalScroll = css`
  scroll-behavior: smooth;

  ${customVerticalScrollBase};
`;

// We can't create design dashed border via native border, so use custom one via background-image
export const dashedLine = css`
  width: 100%;
  position: relative;
  height: 1px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23BEC2CC' stroke-width='2' stroke-dasharray='3%2c 10' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
`;
