import { setPasswordApi } from '@/api/v1/users/public/setPasswordApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const setPassword = async (userId: string, token: string, password: string) => {
  const payload = {
    password,
  };

  try {
    const response = await setPasswordApi(userId, token, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
