import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

export type InternationalPaymentType = {
  id: string;
  date_created: string;
  date_modified: string;
  recipient_name: string;
  account_name: string;
  bank_swift_code: string;
  bank_name: string;
  bank_account_number: string;
  bank_address: string;
  bank_phone_number: string;
  intermediary_bank_swift_code?: string;
  intermediary_bank_name?: string;
  intermediary_bank_account_number?: string;
  intermediary_bank_address?: string;
  intermediary_bank_phone_number?: string;
};

export const updateWirePaymentDetailsInternationalApi = (
  wireId: string,
  data: any
): Promise<ApiResponseType<InternationalPaymentType>> => {
  const uri = `/recipient/wires/${wireId}/payment-details/international/`;

  return authApiService.POST(uri, data);
};
