import { checkPinCodeApi } from '@/api/v1/users/checkPinCodeApi';
import { getPinCodeAttemptsApi } from '@/api/v1/users/getPinCodeAttempts';
import { resetInternalRequestApi } from '@/api/v1/opco/internal/resetInternalRequestApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const loadPinCodeAttempts = async () => {
  try {
    const response = await getPinCodeAttemptsApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const checkPinCode = async (pinCode: string, eventScope: string) => {
  const payload = {
    pin: pinCode,
    event_scope: eventScope,
  };

  try {
    const response = await checkPinCodeApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const resetRequest = async () => {
  try {
    const response = await resetInternalRequestApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
