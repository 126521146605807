import { ReactNode } from 'react';
import { RoundedButtonContainer, RoundedButtonContent, RoundedButtonIcon } from './styles';
import { ReactComponent as PlusDarkIcon } from '../../../src/images/PlusDarkIcon.svg';

type RoundedButtonType = {
  children: ReactNode;
  onClick: () => void;
};

export const RoundedButton = ({ children, onClick }: RoundedButtonType) => {
  return (
    <RoundedButtonContainer onClick={onClick}>
      <RoundedButtonContent>{children}</RoundedButtonContent>
      <RoundedButtonIcon>
        <PlusDarkIcon />
      </RoundedButtonIcon>
    </RoundedButtonContainer>
  );
};
