import { THEME } from '@/styles/theme';
import { useEffect, useLayoutEffect, useState } from 'react';

type useLockedScrollType = [boolean, (locked: boolean) => void];

export function useLockedScroll(initialLocked = false, rootId = 'main'): useLockedScrollType {
  const [locked, setLocked] = useState(initialLocked);

  // Do the side effect before render
  useLayoutEffect(() => {
    if (!locked) {
      return;
    }

    const scrollElement = document.getElementById(rootId);
    const originalOverflow = scrollElement.style.overflow;
    const originalPaddingRight = scrollElement.style.paddingRight;
    const hasScroll = scrollElement.scrollHeight > scrollElement.clientHeight;
    const scrollBarWidth = THEME.utils.getRawValue(THEME.layout.scrollWidth);

    // Lock body scroll
    scrollElement.style.overflow = 'hidden';

    // Avoid width reflow
    if (hasScroll && scrollBarWidth) {
      scrollElement.style.paddingRight = `${scrollBarWidth}px`;
    }

    return () => {
      scrollElement.style.overflow = originalOverflow;

      if (scrollBarWidth) {
        scrollElement.style.paddingRight = originalPaddingRight;
      }
    };
  }, [locked, rootId]);

  // Update state if initialValue changes
  useEffect(() => {
    if (locked !== initialLocked) {
      setLocked(initialLocked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLocked]);

  return [locked, setLocked];
}
