import { DocumentVerificationPassedDetails } from '@/bundle/shared/components/DocumentVerificationPassedDetails/DocumentVerificationPassedDetails';
import { AssignedWireType } from '@/types/wireTypes';
import {
  getDocumentVerifiedRecipientFullName,
  shouldShowDocumentVerificationPassedDetails,
} from '../RecipientDocumentVerification/helpers';
import { Box } from '@/components/Box/Box';
import { WireDetails } from '@/bundle/shared/components/WireDetails';
import { isCancelledWireStatus, isExecutedWireStatus } from '@/helpers/wireHelpers';
import { WaterMark } from '@/bundle/shared/components/WaterMark/WaterMark';
import { ReplacementWireRow } from '../ReplacementWireRow';
import { RecipientChainProxyDetails } from '@/bundle/shared/components/RecipientChainProxyDetails';
import { TrustStampResponseStatusType } from '@/api/v1/recipient/getRecipientTrustStampStatusApi';

type RecipientGeneralDetailsType = {
  wire: AssignedWireType;
  trustStampDetails: TrustStampResponseStatusType;
  isEditRecipient: boolean;
  onProxyEditRecipientOpenEdit: () => void;
};

export const RecipientGeneralDetails = ({
  wire,
  trustStampDetails,
  isEditRecipient,
  onProxyEditRecipientOpenEdit,
}: RecipientGeneralDetailsType) => {
  const wireStatus = wire?.status;
  const isExecutedWire = isExecutedWireStatus(wireStatus);
  const isCanceledWire = isCancelledWireStatus(wireStatus);
  const showWaterMark = isExecutedWire || isCanceledWire;
  const hasReplacementWire = wire?.child_wire?.id || wire?.parent_wire?.id;

  const showDocumentVerifiedDetails = shouldShowDocumentVerificationPassedDetails(wire, trustStampDetails);
  const documentVerifiedRecipientFullName = getDocumentVerifiedRecipientFullName(trustStampDetails);

  return (
    <>
      {wire && (
        <>
          <Box position='relative'>
            <WireDetails wire={wire}>{hasReplacementWire && <ReplacementWireRow wire={wire} />}</WireDetails>
            {showWaterMark && <WaterMark wireStatus={wireStatus} />}
          </Box>

          <RecipientChainProxyDetails
            wire={wire}
            isProxyEditRecipient={isEditRecipient}
            onProxyEditRecipient={onProxyEditRecipientOpenEdit}
          />
        </>
      )}
      {showDocumentVerifiedDetails && (
        <DocumentVerificationPassedDetails fullName={documentVerifiedRecipientFullName} />
      )}
    </>
  );
};
