import styled from 'styled-components';
import { COLORS } from '../../styles/colors';
import { poppinsMediumFont, subTitle } from '../../styles/typography';
import { flex } from '../../styles/layout';

export const NoResultsWrapper = styled.div<{ marginTop: number }>`
  ${flex({ flexDirection: 'column', alignItems: 'center' })};
  margin-top: ${({ marginTop }) => `${marginTop}px`};
  margin-bottom: 20px;
  text-align: center;
`;

export const NoResultsIcon = styled.div`
  margin-bottom: 28px;
`;

export const NoResultsMessage = styled.div`
  max-width: 357px;
  color: ${COLORS.grey[850]};
  ${subTitle};
  ${poppinsMediumFont};
`;
