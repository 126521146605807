import { ReactNode } from 'react';
import { PageColumnWrapper } from './styles';

type PageColumnType = {
  children: ReactNode;
};

export const PageColumn = ({ children }: PageColumnType) => {
  return <PageColumnWrapper>{children}</PageColumnWrapper>;
};
