import { AssignedWireType } from '@/types/wireTypes';
import { ReactComponent as RecipientIcon } from '@/images/RecipientIcon.svg';
import { ReactComponent as RecipientProxyIcon } from '@/images/ProxyIcon.svg';
import {
  isProxyProvidePaymentDetailsState,
  isRecipientProvidePaymentDetailsState,
  ProvidePaymentDetailsStateType,
} from './helpers';
import { ChangeButton } from './styles';
import { PaymentDetailsHeaderSlot } from '@/bundle/shared/components/PaymentDetailsHeaderSlot/PaymentDetailsHeaderSlot';

type PaymentDetailsHeadlineType = {
  wire: AssignedWireType;
  providePaymentDetailsState: ProvidePaymentDetailsStateType;
  onSourceWirePaymentDetailsConfirm: () => void;
  onChangeProvidePaymentDetailsMode: () => void;
};

export const PaymentDetailsHeadline = ({
  wire,
  providePaymentDetailsState,
  onSourceWirePaymentDetailsConfirm,
  onChangeProvidePaymentDetailsMode,
}: PaymentDetailsHeadlineType) => {
  const isRecipientProvidePaymentDetails = isRecipientProvidePaymentDetailsState(providePaymentDetailsState);
  const isProxyProvidePaymentDetails = isProxyProvidePaymentDetailsState(providePaymentDetailsState);
  const isDocumentVerificationRequired = wire?.is_pd_provider_kyc_required;
  const hasSourceWirePaymentDetails = !!wire?.payment_details_source_wire?.id;

  const getMetaButton = () => {
    if (hasSourceWirePaymentDetails) {
      return <ChangeButton onClick={onSourceWirePaymentDetailsConfirm}>Change</ChangeButton>;
    }

    if (isProxyProvidePaymentDetails && !isDocumentVerificationRequired) {
      return <ChangeButton onClick={onChangeProvidePaymentDetailsMode}>Change</ChangeButton>;
    }
  };

  const icon = isRecipientProvidePaymentDetails ? <RecipientIcon /> : <RecipientProxyIcon />;
  const header = isRecipientProvidePaymentDetails ? 'I’m the Recipient' : 'Provide wire info for the Recipient';
  const meta = getMetaButton();

  return <PaymentDetailsHeaderSlot icon={icon} header={header} meta={meta} />;
};
