import { UpdateRecipientPayloadType } from '@/api/v1/organization/wires/updateRecipientApi';
import { updateRecipientByProxyApi } from '@/api/v1/recipient/updateRecipientByProxyApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const updateRecipientByProxy = async (wireId: string, userId: string, payload: UpdateRecipientPayloadType) => {
  try {
    const response = await updateRecipientByProxyApi(wireId, userId, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
