import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { useState } from 'react';
import { useUser } from '@/context/userContext';
import { Drawer } from '@/components/Drawer/Drawer';
import { Wrapper } from '@/bundle/pages/ProfilePage/styles';
import { UserGeneralDetails } from '../../shared/components/UserDetails/UserGeneralDetails';
import { UserPersonalInfo } from '../../shared/components/UserDetails/UserPersonalInfo';
import { EditPersonalDetailsForm } from '@/bundle/pages/ProfilePage/ui/EditPersonalDetailsForm';

export const ProfilePage = () => {
  const [openForm, setOpenForm] = useState(false);
  const { user, currentRole, loadUser, isOrganizationAdminRole } = useUser();

  const closeForm = () => {
    setOpenForm(false);
  };

  const personalDetails = {
    first_name: user?.first_name,
    middle_name: user?.middle_name,
    last_name: user?.last_name,
  };

  return (
    <ContentLayout title='Profile'>
      <PageBody>
        <Wrapper>
          <UserGeneralDetails user={user} currentRole={currentRole} />
          <UserPersonalInfo onOpen={setOpenForm} personalDetails={personalDetails} isEdit={true} />
        </Wrapper>
      </PageBody>
      <Drawer
        header='Edit Personal Info'
        subHeader='Please edit your information.'
        isOpen={openForm}
        onClose={closeForm}
      >
        <EditPersonalDetailsForm
          onClose={closeForm}
          isOrgAdmin={isOrganizationAdminRole}
          loadUser={loadUser}
          personalDetails={personalDetails}
        />
      </Drawer>
    </ContentLayout>
  );
};
