import { useEffect, useState } from 'react';
import { Notification } from '@/components/Notification/Notification';
import {
  CancelButton,
  CheckboxLabel,
  CheckboxLabelPhoneCode,
  CheckboxRow,
  CheckboxRowsWrapper,
  CheckboxWrapper,
  CompleteRegistrationButtonBlock,
  CompleteRegistrationMessage,
  CompleteRegistrationTitle,
  TermsLink,
} from './styles';
import { Checkbox } from '@/components/Checkbox/Checkbox';
import { Button } from '@/components/Button/Button';
import { AuthStep } from '@/bundle/Auth/ui/AuthStep/AuthStep';
import { authTokenService } from '@/bundle/Auth/LoginFlow/LoginPage/service/authTokenService';
import { decodeToken } from '@/helpers/tokenHelpers';
import { phoneFormatter } from '@/helpers/formatHelpers';
import { useNavigate } from 'react-router-dom';
import { getLoginUrl } from '@/bundle/Auth/LoginFlow/LoginPage/urls/getLoginUrl';
import { AuthTokenType, ResponseErrorType } from '@/types/sharedTypes';
import { envConfig } from '@/config/envConfig';

type CompleteRegistrationFormType = {
  onConfirm: () => void;
  isLoading: boolean;
  error?: ResponseErrorType;
};

export const CompleteRegistrationForm = ({ onConfirm, isLoading, error }: CompleteRegistrationFormType) => {
  const navigate = useNavigate();

  const [phone, setPhone] = useState('');
  const [completeRegistrationTerms, setCompleteRegistrationTerms] = useState({
    checkedTerms: false,
    checkedPhone: false,
    checkedResetPassword: false,
  });

  const setUserPhone = () => {
    const tokens = authTokenService.getTokens();

    if (!tokens) return;

    const { mobile_phone } = decodeToken<AuthTokenType>(tokens.access);

    setPhone(phoneFormatter(mobile_phone));
  };

  useEffect(() => {
    setUserPhone();
  }, []);

  const toggleTerms = () => {
    setCompleteRegistrationTerms((values) => ({ ...values, checkedTerms: !values.checkedTerms }));
  };

  const togglePhone = () => {
    setCompleteRegistrationTerms((values) => ({ ...values, checkedPhone: !values.checkedPhone }));
  };

  const toggleResetPassword = () => {
    setCompleteRegistrationTerms((values) => ({ ...values, checkedResetPassword: !values.checkedResetPassword }));
  };

  const navigateToLogin = () => {
    navigate(getLoginUrl());
  };

  const hasCheckedAllTerms = Object.values(completeRegistrationTerms).every(Boolean);
  const termsConditionsUrl = envConfig.TERMS_AND_CONDITIONS_URL;
  const apiError = error?.error_content as string;

  return (
    <AuthStep width='508px'>
      <CompleteRegistrationTitle>Complete Registration</CompleteRegistrationTitle>
      <CompleteRegistrationMessage>
        To finish your account registration with WireVault, please confirm the following:
      </CompleteRegistrationMessage>

      {apiError && (
        <Notification variant='error' mb='24px'>
          {apiError}
        </Notification>
      )}

      <CheckboxRowsWrapper>
        <CheckboxRow>
          <CheckboxWrapper>
            <Checkbox onChange={toggleTerms} />
          </CheckboxWrapper>
          <CheckboxLabel>
            I have read and agree to the WireVault
            <br />
            <TermsLink href={termsConditionsUrl} target='_blank'>
              Terms & Conditions
            </TermsLink>
          </CheckboxLabel>
        </CheckboxRow>
        <CheckboxRow>
          <CheckboxWrapper>
            <Checkbox onChange={togglePhone} />
          </CheckboxWrapper>
          <CheckboxLabel>
            I confirm that I am authorized to use the mobile phone number{' '}
            <CheckboxLabelPhoneCode>{phone}</CheckboxLabelPhoneCode> and agree to receive SMS messages to secure my
            WireVault account
          </CheckboxLabel>
        </CheckboxRow>
        <CheckboxRow>
          <CheckboxWrapper>
            <Checkbox onChange={toggleResetPassword} />
          </CheckboxWrapper>
          <CheckboxLabel>
            I agree to reset and use a strong password with 2-factor authentication for the email provided with
            WireVault.
          </CheckboxLabel>
        </CheckboxRow>
      </CheckboxRowsWrapper>
      <CompleteRegistrationButtonBlock>
        <Button isLoading={isLoading} onClick={onConfirm} disabled={!hasCheckedAllTerms}>
          Complete Registration
        </Button>
        <CancelButton onClick={navigateToLogin}>Cancel Registration</CancelButton>
      </CompleteRegistrationButtonBlock>
    </AuthStep>
  );
};
