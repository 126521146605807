export const useScrollToTop = (rootId = 'main') => {
  const element = document.getElementById(rootId);

  const scrollToTop = () => {
    element?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return {
    scrollToTop,
  };
};
