import { authApiService } from '@/api/authApiService';
import { ApiResponseType, ListResponseType } from '@/types/sharedTypes';
import { SearchUserType } from '../../organization/users/getSearchUsersApi';

export type OrgAdminSearchUsersPayload = {
  search: string;
};

export const getOrganizationSearchUserApi = (
  data: OrgAdminSearchUsersPayload
): Promise<ApiResponseType<ListResponseType<SearchUserType>>> => {
  const uri = '/opco/organizations/users/search/';

  return authApiService.GET(uri, data);
};
