export const sessionStorageService = {
  get(key: string) {
    try {
      const data = window.sessionStorage.getItem(key);

      const parsedData = JSON.parse(data);

      return parsedData;
    } catch (error) {
      console.error('Getting data from session storage failed with', error?.message);

      return null;
    }
  },
  set(key: string, data: unknown) {
    try {
      const stringifiedData = JSON.stringify(data);

      return window.sessionStorage.setItem(key, stringifiedData);
    } catch (error) {
      console.error('Setting data to session storage failed with', error?.message);

      return null;
    }
  },
  remove(key: string) {
    try {
      return window.sessionStorage.removeItem(key);
    } catch (error) {
      console.error('Removing data from session storage failed with', error?.message);

      return null;
    }
  },
};

export const localStorageService = {
  get(key: string) {
    try {
      const data = window.localStorage.getItem(key);

      const parsedData = JSON.parse(data);

      return parsedData;
    } catch (error) {
      console.error('Getting data from local storage failed with', error?.message);

      return null;
    }
  },
  set(key: string, data: unknown) {
    try {
      const stringifiedData = JSON.stringify(data);

      return window.localStorage.setItem(key, stringifiedData);
    } catch (error) {
      console.error('Setting data to local storage failed with', error?.message);

      return null;
    }
  },
  remove(key: string) {
    try {
      return window.localStorage.removeItem(key);
    } catch (error) {
      console.error('Removing data from local storage failed with', error?.message);

      return null;
    }
  },
};
