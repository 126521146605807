import { ChangeEvent } from 'react';
import { useField } from 'formik';
import { Box } from '@/components/Box/Box';
import { InputError } from '../InputError/InputError';
import { ResponseErrorType } from '@/types/sharedTypes';
import { Label, StyledPhoneInput } from './styles';
import { Input } from '../FormikInput/styles';
import 'react-phone-input-2/lib/high-res.css';
import { getFieldError } from '@/helpers/formHelpers/formHelpers';
import noop from 'lodash/noop';

interface ChangeCountryType {
  countryCode: string;
  dialCode: string;
  format: string;
  name: string;
}

interface FormikPhoneInputType {
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  apiError?: ResponseErrorType;
  onEnterKeyPress?: () => void;
}

const DEFAULT_COUNTRY_CODE = 'us';

export const FormikPhoneInput = ({ name, label, disabled, apiError, onEnterKeyPress = noop }: FormikPhoneInputType) => {
  const [field, meta, { setValue, setError, setTouched }] = useField(name);

  const { error, apiError: apiErrorMessage, hasAnyError } = getFieldError(name, meta, apiError);

  const changePhone = (newPhone: string, country: ChangeCountryType, event: ChangeEvent<HTMLInputElement>) => {
    setValue(newPhone);

    // Country was changed in dropdown -> reset phone number value and error
    if (event.type === 'click') {
      setValue(country.dialCode);

      if (error) {
        setTouched(false);
        setError('');
      }
    }
  };

  return (
    <Box flexDirection='column' rowGap='4px' width='100%'>
      <Label>{label}</Label>
      <Box flexDirection='column'>
        {disabled ? (
          <Input name={field.name} value={field.value} disabled placeholder='Enter Mobile Phone Number' />
        ) : (
          <StyledPhoneInput
            inputProps={{
              name: field.name,
            }}
            country={DEFAULT_COUNTRY_CODE}
            value={field.value}
            onEnterKeyPress={onEnterKeyPress}
            error={hasAnyError}
            onChange={changePhone}
            onBlur={field.onBlur}
          />
        )}

        <InputError error={error} apiError={apiErrorMessage} />
      </Box>
    </Box>
  );
};
