import { IBilledEventsParams } from '@/bundle/_Opco/_BillingPage/hooks/filterListConfig';
import { ContractFilterListParams } from '@/bundle/_OrgAdmin/pages/Transactions/hooks/useFiltersListConfig';

export interface IOrganizationsListFilterListParam {
  name?: string | null;
  date_picker?: string | null;
}

export interface FilterParamsType
  extends QueryParamsType,
    ContractFilterListParams,
    IOrganizationsListFilterListParam,
    IBilledEventsParams {}

export interface QueryParamsType {
  page?: number | null;
  limit?: number | null;
  ordering?: string | null;
  filters?: FilterParamsType | null;
  search?: string | null;
  email?: string | null;
}

export interface QueryParamsStringType extends Omit<QueryParamsType, 'filters'> {
  filters?: string | null;
}

export const DEFAULT_LIMIT = 8;

export const DEFAULT_QUERY_PARAMS: QueryParamsType = {
  page: 0,
  limit: DEFAULT_LIMIT,
  ordering: null,
  filters: null,
  search: null,
  email: null,
};

export const defaultFilterParams: FilterParamsType = {
  contract__name: null,
  name: null,
  wire__contract__name: null,
  organization__id: null,
  date_created_after: null,
  date_created_before: null,
};
