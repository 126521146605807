import { ForgotPasswordPayload, forgotPasswordApi } from '@/api/v1/users/public/forgotPasswordApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const sendForgotPassword = async (payload: ForgotPasswordPayload) => {
  try {
    const response = await forgotPasswordApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
