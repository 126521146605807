import { COLORS } from '@/styles/colors';
import { bodySmall, poppinsRegularFont } from '@/styles/typography';
import styled from 'styled-components';

export const InputErrorWrapper = styled.div`
  height: 22px;
`;

export const ErrorMessage = styled.div`
  margin-top: 2px;
  ${bodySmall};
  ${poppinsRegularFont};
  color: ${COLORS.red[600]};
`;

export const AnimateFadeIn = styled.div`
  &.fade-enter {
    opacity: 0;
    transform: translateY(-8px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition:
      opacity 0.2s ease-out,
      transform 0.2s ease-out;
  }
`;
