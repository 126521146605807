import { authApiService } from '@/api/authApiService';
import { ApiResponseType, ListResponseType } from '@/types/sharedTypes';

export type PricingTierType = {
  id: string;
  name: string;
};

export const getOrganizationPricingTierApi = async (): Promise<ApiResponseType<ListResponseType<PricingTierType>>> => {
  const uri = '/opco/subscriptions/organization-pricing-tiers/';

  return authApiService.GET(uri);
};
