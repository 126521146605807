import { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as OpcoUsersIcon } from '../images/OpcoUsersIcon.svg';
import { ReactComponent as BagIcon } from '../images/BagIcon.svg';
import { ReactComponent as BillingIcon } from '../images/BillingIcon.svg';
import { ReactComponent as DepositAccountsIcon } from '../images/DepositAccountsIcon.svg';
import { ReactComponent as ContactUsIcon } from '../images/ContactUsIcon.svg';
import { getOpcoOrganizationsUrl, getOpcoOrganizationsPath } from '../../_Opco/urls/getOpcoOrganizationsUrl';
import { getTransactionsUrl, getTransactionsPath } from '../../_OrgAdmin/pages/Transactions/urls/getTransactionsUrl';
import { getRecipientWiresUrl, getRecipientWiresPath } from '../../_Recipient/urls/getRecipientWiresUrl';
import { getContactUsUrl, getContactUsPath } from '../../pages/ContactUsPage/urls/getContactUsUrl';
import { getExecutorWiresUrl, getExecutorWiresPath } from '../../_Executor/urls/getExecutorWiresUrl';
import { getTransactionDetailsPath } from '../../_OrgAdmin/pages/Transactions/_Details/urls/getTransactionDetailsUrl';
import { getOutboundWireDetailsPath } from '../../_OrgAdmin/pages/OutboundWire/_Details/urls/getOutboundWireDetailsUrl';
import { getRecipientWireDetailsPath } from '../../_Recipient/_Details/urls/getRecipientWireDetailsUrl';
import { getExecutorWireDetailsPath } from '../../_Executor/_Details/urls/getRecipientWireDetailsUrl';
import {
  getDepositAccountsUrl,
  getDepositAccountsPath,
} from '../../_OrgAdmin/pages/DepositAccounts/urls/getDepositAccountsUrl';
import { getDepositorWiresUrl, getDepositorWiresPath } from '../../_Depositor/urls/getDepositorWiresUrl';
import { getBilledEventsUrl, getBilledEventsPath } from '../../_Opco/_BillingPage/urls/getBilledEventsUrl';
import { getCreateDepositAccountPath } from '../../_OrgAdmin/pages/DepositAccounts/_Create/urls/getCreateDepositAccountUrl';
import { getDepositAccountDetailsPath } from '../../_OrgAdmin/pages/DepositAccounts/_Details/urls/getDepositAccountDetailsUrl';
import { getInboundWireDetailsPath } from '../../_OrgAdmin/pages/InboundWire/urls/getInboundWireDetailsUrl';
import { USER_ROLE_MAP } from '@/const/user';
import { getOpcoUsersUrl, getOpcoUsersPath } from '@/bundle/_Opco/_OpcoUsersPage/urls/getOpcoUsersUrl';
import { getOpcoUserDetailsPath } from '@/bundle/_Opco/_OpcoUsersPage/Details/url/getOpcoUserDetailsUrl';
import { getOpcoOrganizationUsersPath } from '@/bundle/_Opco/ui/OrganizationUsers/urls/getOpcoOrganizationUsersUrl';
import { getOrganizationUserDetailsPath } from '@/bundle/_Opco/ui/OrganizationUsers/Details/urls/getOrganizationUserDetails';
import { useUser } from '@/context/userContext';
import { getDepositorWireDetailsPath } from '@/bundle/_Depositor/_Details/urls/getDepositorWireDetailsUrl';

type MenuType = {
  title: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  url: string;
  paths?: string[];
  disabled?: boolean;
};

type SideBarType = {
  [key: string]: {
    menu: MenuType[];
  };
};

const CONTACT_US = {
  title: 'Contact Us',
  icon: ContactUsIcon,
  url: getContactUsUrl(),
  paths: [getContactUsPath()],
};

const OPCO_MENU = {
  menu: [
    {
      title: 'OpCo Users',
      icon: OpcoUsersIcon,
      url: getOpcoUsersUrl(),
      paths: [getOpcoUsersPath(), getOpcoUserDetailsPath()],
    },
    {
      title: 'Org Management',
      icon: BagIcon,
      url: getOpcoOrganizationsUrl(),
      paths: [getOpcoOrganizationsPath(), getOpcoOrganizationUsersPath(), getOrganizationUserDetailsPath()],
    },
    {
      title: 'Billing',
      icon: BillingIcon,
      url: getBilledEventsUrl(),
      paths: [getBilledEventsPath()],
    },
  ],
};

const ORG_ADMIN_MENU = {
  menu: [
    {
      title: 'Transactions',
      icon: BagIcon,
      url: getTransactionsUrl(),
      paths: [
        getTransactionsPath(),
        getTransactionDetailsPath(),
        getOutboundWireDetailsPath(),
        getInboundWireDetailsPath(),
      ],
    },
    {
      title: 'Deposit Accounts',
      icon: DepositAccountsIcon,
      url: getDepositAccountsUrl(),
      paths: [getDepositAccountsPath(), getCreateDepositAccountPath(), getDepositAccountDetailsPath()],
    },
    {
      ...CONTACT_US,
    },
  ],
};

const RECIPIENT_MENU = {
  menu: [
    {
      title: 'Wires',
      icon: BagIcon,
      url: getRecipientWiresUrl(),
      paths: [getRecipientWiresPath(), getRecipientWireDetailsPath()],
    },
    {
      ...CONTACT_US,
    },
  ],
};

const EXECUTOR_MENU = {
  menu: [
    {
      title: 'Wires',
      icon: BagIcon,
      url: getExecutorWiresUrl(),
      paths: [getExecutorWiresPath(), getExecutorWireDetailsPath()],
    },
    {
      ...CONTACT_US,
    },
  ],
};

const DEPOSITOR_MENU = {
  menu: [
    {
      title: 'Wires',
      icon: BagIcon,
      url: getDepositorWiresUrl(),
      paths: [getDepositorWiresPath(), getDepositorWireDetailsPath()],
    },
    {
      ...CONTACT_US,
    },
  ],
};

const USER_SIDEBAR_MENU_MAP: SideBarType = {
  [USER_ROLE_MAP.OPCO]: OPCO_MENU,
  [USER_ROLE_MAP.ORGANIZATION]: ORG_ADMIN_MENU,
  [USER_ROLE_MAP.RECIPIENT]: RECIPIENT_MENU,
  [USER_ROLE_MAP.EXECUTOR]: EXECUTOR_MENU,
  [USER_ROLE_MAP.DEPOSITOR]: DEPOSITOR_MENU,
};

export const useSidebar = () => {
  const { currentRole } = useUser();

  if (!currentRole) return { menu: [] };

  return USER_SIDEBAR_MENU_MAP[currentRole] || { menu: [] };
};
