import { Notification } from '@/components/Notification/Notification';
import { NotificationMessage, NotificationTitle } from '@/components/Notification/styles';
import { USER_ASSIGNMENT_STATUS_CODE_MAP } from '@/const/shared';

const NOTIFICATION_OFFSET = { mt: '-16px', mb: '24px' };

export const NewUserNotification = () => {
  return (
    <Notification {...NOTIFICATION_OFFSET}>
      <NotificationTitle>The email you provided is not associated with an active WireVault account.</NotificationTitle>
      <NotificationMessage>
        Please fill out the remaining user information and confirm the mobile phone number is correct before you send
        the invitation.
      </NotificationMessage>
    </Notification>
  );
};

export const AssignableUserNotification = () => {
  return (
    <Notification {...NOTIFICATION_OFFSET}>
      <NotificationTitle>This email address is associated with an existing WireVault account.</NotificationTitle>
      <NotificationMessage>Please click “Assign” to assign them to this wire.</NotificationMessage>
    </Notification>
  );
};

export const SameUserNotification = () => {
  return (
    <Notification variant='error' fontVariant='medium' {...NOTIFICATION_OFFSET}>
      You cannot assign yourself to this wire.
    </Notification>
  );
};

export const InvitedUserFromSameOrganizationNotification = () => {
  return (
    <Notification {...NOTIFICATION_OFFSET}>
      <NotificationTitle>The email address you provided has already been invited to WireVault.</NotificationTitle>
      <NotificationMessage>Please reach out to them about accepting the pending invitation.</NotificationMessage>
    </Notification>
  );
};

export const InvitedUserFromAnotherOrganizationNotification = () => {
  return (
    <Notification variant='error' {...NOTIFICATION_OFFSET}>
      <NotificationTitle>This email address is associated with a pending WireVault account.</NotificationTitle>
      <NotificationMessage>
        You cannot assign them to this wire until the accept the initial invitation that was sent from another
        organization. Please reach out to them about accepting the pending invitation and check back later.
      </NotificationMessage>
    </Notification>
  );
};

export const InvitedUserNotification = () => {
  return (
    <Notification variant='error' {...NOTIFICATION_OFFSET}>
      <NotificationTitle>This email address is associated with a pending WireVault account.</NotificationTitle>
      <NotificationMessage>
        You cannot give them the rights of another role until they accept the initial invitation. Please reach out to
        them about accepting the pending invitation and check back later.
      </NotificationMessage>
    </Notification>
  );
};

export const USER_ASSIGNMENT_NOTIFICATIONS_MAP = {
  [USER_ASSIGNMENT_STATUS_CODE_MAP.IS_SAME_ROLE_AND_INVITED_BUT_CREATED_BY_SAME_ORGANIZATION]: (
    <InvitedUserFromSameOrganizationNotification />
  ),
  [USER_ASSIGNMENT_STATUS_CODE_MAP.CAN_BE_ASSIGNED]: <AssignableUserNotification />,
  [USER_ASSIGNMENT_STATUS_CODE_MAP.IS_NOT_SAME_ROLE_AND_INVITED]: <InvitedUserNotification />,
  [USER_ASSIGNMENT_STATUS_CODE_MAP.IS_SAME_ROLE_AND_INVITED_BUT_CREATED_BY_ANOTHER_ORGANIZATION]: (
    <InvitedUserFromAnotherOrganizationNotification />
  ),
  [USER_ASSIGNMENT_STATUS_CODE_MAP.IS_SAME_USER]: <SameUserNotification />,
};
