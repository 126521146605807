import { ReactNode } from 'react';
import {
  ContentHeaderButtonRow,
  ContentHeaderActionMessage,
  ContentHeaderContainer,
  ContentHeaderRightBlock,
  ContentHeaderTitle,
  ContentHeaderWrapper,
  ContentHeaderSubtitle,
  ContentHeaderTextBlock,
  BackButton,
  BackIcon,
} from './styles';
import { Tooltip } from '@/components/Tooltip/Tooltip';

export type ButtonOptionsType = {
  text: string;
  url?: string;
  width?: number;
};

type ContentHeaderType = {
  title: string;
  subtitle?: ReactNode;
  headerActionMessage?: ReactNode;
  headerAction?: ReactNode;
  backButton?: string;
  onBack?: () => void;
};

export const ContentHeader = ({
  title,
  subtitle,
  headerActionMessage,
  headerAction,
  backButton,
  onBack,
}: ContentHeaderType) => {
  return (
    <ContentHeaderWrapper>
      <ContentHeaderContainer>
        <ContentHeaderTextBlock>
          <ContentHeaderTitle>
            {backButton && (
              <BackButton data-tooltip-id='back-tooltip' onClick={onBack}>
                <BackIcon />
              </BackButton>
            )}
            {title}
          </ContentHeaderTitle>
          {subtitle && <ContentHeaderSubtitle>{subtitle}</ContentHeaderSubtitle>}
        </ContentHeaderTextBlock>
        <ContentHeaderRightBlock>
          {headerActionMessage && <ContentHeaderActionMessage>{headerActionMessage}</ContentHeaderActionMessage>}
          {headerAction && <ContentHeaderButtonRow>{headerAction}</ContentHeaderButtonRow>}
        </ContentHeaderRightBlock>
      </ContentHeaderContainer>
      <Tooltip id='back-tooltip'>{backButton}</Tooltip>
    </ContentHeaderWrapper>
  );
};
