import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createDepositAccount } from './api';
import { getDepositAccountsUrl } from '../urls/getDepositAccountsUrl';
import {
  getFormInitialValues,
  getInitialValues,
  getMergedValues,
  isValidForm,
} from '@/helpers/formHelpers/formHelpers';
import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { CreateDepositAccountForm, CreateDepositAccountFormButton, CreateDepositAccountWrapper } from './styles';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { Button } from '@/components/Button/Button';
import { ConfirmModal, ConfirmModalHint } from '@/components/ConfirmModal/ConfirmModal';
import { getDepositAccountDetailsUrl } from '../_Details/urls/getDepositAccountDetailsUrl';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import {
  CREATE_DEPOSIT_ACCOUNT_REDIRECT,
  CREATE_DEPOSIT_ACCOUNT_SUCCESS,
  getDepositAccountAmplitudeEvent,
} from '@/bundle/_OrgAdmin/pages/DepositAccounts/_Create/const/amplitudeEventsMap';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { useCreateDepositAccountForm } from './hooks/useCreateDepositAccountForm';
import { DepositIntermediaryBankForm } from './ui/DepositIntermediaryBankForm/DepositIntermediaryBankForm';
import { DEPOSIT_ACCOUNT_INITIAL_VALUES } from './const/const';

export const CreateDepositAccountPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const { config, intermediaryConfig, validationSchema, updateForm, isDomesticPayment, isInternationalPayment } =
    useCreateDepositAccountForm();

  const [isOpenIntermediaryBank, setIsOpenIntermediaryBank] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['create_deposit_account'],
    mutationFn: (payload) => {
      return createDepositAccount(payload, isDomesticPayment);
    },
    onSuccess(createdDepositAccount) {
      if (createdDepositAccount.error) return;

      const currentEvent = getDepositAccountAmplitudeEvent(
        isDomesticPayment,
        isInternationalPayment,
        isOpenIntermediaryBank
      );

      amplitudeService.logEvent(CREATE_DEPOSIT_ACCOUNT_SUCCESS[currentEvent]);

      const { state } = location;
      const newState = state?.transactionId ? { transactionId: state?.transactionId, wireId: state?.wireId } : state;
      const id = createdDepositAccount?.body?.id;

      queryClient.invalidateQueries({ queryKey: ['load_deposit_account', id] });
      navigate(getDepositAccountDetailsUrl(id), { state: newState });
    },
    onSettled() {
      setIsModalOpen(false);
    },
  });

  const formik = useFormik({
    initialValues: DEPOSIT_ACCOUNT_INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      const currentConfig = isOpenIntermediaryBank ? [...config, ...intermediaryConfig] : config;
      const payload = getMergedValues(currentConfig, values);

      await mutate(payload as any);
    },
  });

  const submitHandler = () => {
    formik.handleSubmit();
  };

  const navigateToDepositAccounts = () => {
    navigate(getDepositAccountsUrl());
  };

  const openConfirmationModal = async () => {
    const isValid = await isValidForm(formik);

    if (!isValid) return;

    const currentEvent = getDepositAccountAmplitudeEvent(
      isDomesticPayment,
      isInternationalPayment,
      isOpenIntermediaryBank
    );

    amplitudeService.logEvent(CREATE_DEPOSIT_ACCOUNT_REDIRECT[currentEvent]);

    setIsModalOpen(true);
  };

  const toggleIntermediaryBankFormVisibility = () => {
    setIsOpenIntermediaryBank(!isOpenIntermediaryBank);
  };

  useEffect(() => {
    formik.setTouched({
      ...formik.touched,
      ...getFormInitialValues(getInitialValues(intermediaryConfig), false),
    });
    formik.setValues({
      ...formik.values,
      ...getFormInitialValues(getInitialValues(intermediaryConfig)),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenIntermediaryBank, intermediaryConfig]);

  useEffect(() => {
    updateForm(formik.values, isOpenIntermediaryBank);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.payment_type, isOpenIntermediaryBank]);

  // Used to preserve <deposit account name> when switching <current payment type>
  useEffect(() => {
    formik.resetForm();
    formik.setFieldValue('payment_type', formik.values.payment_type);
    formik.setFieldValue('name', formik.values.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.payment_type]);

  return (
    <ContentLayout
      title='Create Deposit Account'
      backButton='Back to Deposit Accounts'
      onBack={navigateToDepositAccounts}
    >
      <CreateDepositAccountWrapper>
        <CreateDepositAccountForm>
          <FormikForm value={formik}>
            <FormGeneratorNew config={config} apiError={data?.error} />

            <DepositIntermediaryBankForm
              isOpen={isOpenIntermediaryBank}
              onToggle={toggleIntermediaryBankFormVisibility}
              error={data?.error}
              config={intermediaryConfig}
            />
          </FormikForm>

          <CreateDepositAccountFormButton>
            <Button width={160} size='medium' onClick={openConfirmationModal}>
              Submit
            </Button>
          </CreateDepositAccountFormButton>
        </CreateDepositAccountForm>
      </CreateDepositAccountWrapper>

      <ConfirmModal
        isOpen={isModalOpen}
        header='Confirm Deposit Account'
        isLoading={isPending}
        body={
          <>
            <ConfirmModalHint>You are certifying the validity of your deposit account information.</ConfirmModalHint>
            <ConfirmModalHint>
              This information will be committed to the WireVault blockchain and cannot be changed.
            </ConfirmModalHint>
            <ConfirmModalHint>
              You can deactivate this deposit account at anytime and create a new one.
            </ConfirmModalHint>
          </>
        }
        onClose={() => setIsModalOpen(false)}
        onConfirm={submitHandler}
      />
    </ContentLayout>
  );
};
