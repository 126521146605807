import { flex } from '@/styles/layout';
import styled from 'styled-components';

export const NextStepButtonWrapper = styled.div`
  margin: 18px 0 16px;
`;

export const PasswordRecoverMessagesTextWrapper = styled.div`
  ${flex({ flexDirection: 'column' })};
  margin-bottom: 36px;
  row-gap: 12px;
`;
