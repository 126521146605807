import styled from 'styled-components';
import { poppinsSemiBoldFont, subTitle } from '../../../styles/typography';
import { flex } from '../../../styles/layout';
import { media } from '@/styles/responsive';

export const ContactUsWrapper = styled.div`
  width: 55%;
  position: relative;
  ${flex({ flexDirection: 'column', justifyContent: 'center' })}
  margin: 0 auto;

  ${media.tablet`
    width: 70%;
  `}

  ${media.mobile`
    width: 100%;
  `}
`;

export const ContactUsHeader = styled.div`
  width: 100%;
  margin-bottom: 24px;
  text-align: start;
  ${poppinsSemiBoldFont};
  ${subTitle};
`;
