import { ReactComponent as TrustStampLogo } from './images/TrustStampLogoIcon.svg';
import { ReactNode } from 'react';
import { TrustStampDetailsWrapper } from '@/bundle/_Recipient/_Details/ui/RecipientDocumentVerification/TrustStampDetails/styles';

type TrustStampDetailsType = {
  children: ReactNode;
};

export const TrustStampDetails = ({ children }: TrustStampDetailsType) => {
  return (
    <TrustStampDetailsWrapper>
      <TrustStampLogo />
      {children}
    </TrustStampDetailsWrapper>
  );
};
