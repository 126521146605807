import { Description, Label, MetaWrapper, Title, Wrapper } from './styles';
import { Box } from '@/components/Box/Box';
import { ReactNode } from 'react';

export interface SelectableTabsOptionType {
  value: string | boolean;
  label: string;
  description: string;
  meta?: ReactNode;
  icon?: ReactNode;
}

export interface SelectableTabsType {
  label?: string;
  options?: SelectableTabsOptionType[];
  value?: string | boolean;
  disabled?: boolean;
  onSelect?: (option: string | boolean) => void;
}

interface SelectableTabType {
  isActive: boolean;
  onSelect: (value: string | boolean) => void;
  option: SelectableTabsOptionType;
  disabled?: boolean;
}

const SelectableTab = ({ isActive, onSelect, option, disabled }: SelectableTabType) => {
  const { value, icon, meta, label, description } = option || {};

  return (
    <Wrapper isActive={isActive} disabled={disabled} onClick={() => onSelect?.(value)}>
      {icon && <Box mr='20px'>{icon}</Box>}
      <Box display='flex' flexDirection='column' alignItems='center' rowGap='4px' width='100%'>
        <Box flexDirection='row' justifyContent='space-between' alignItems='flex-start' display='flex' width='100%'>
          <Title>{label}</Title>
          {meta && <MetaWrapper>{meta}</MetaWrapper>}
        </Box>
        <Description>{description}</Description>
      </Box>
    </Wrapper>
  );
};

export const SelectableTabs = ({ label, options, value, disabled, onSelect }: SelectableTabsType) => {
  return (
    <Box flexDirection='column' rowGap='4px'>
      {label && <Label>{label}</Label>}
      <Box width='100%' flexDirection='column' rowGap='12px' display='flex' alignItems='center'>
        {options.map((option) => {
          const isSelected = value === option.value && !disabled;

          return (
            <SelectableTab
              key={option?.label}
              option={option}
              isActive={isSelected}
              disabled={disabled}
              onSelect={onSelect}
            />
          );
        })}
      </Box>
    </Box>
  );
};
