import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { amplitudeService } from './services/amplitudeService/amplitudeService';
import { ErrorBoundary } from './bundle/ErrorBoundary/ErrorBoundary';
import { QueryClientApp } from './bundle/QueryClient';
import './index.css';
import 'react-tooltip/dist/react-tooltip.css';
import { sentryService } from './services/sentryService';

amplitudeService.init();
sentryService.init();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorBoundary>
        <QueryClientApp />
      </ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
