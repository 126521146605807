import { envConfig } from '../../config/envConfig';

export const urlHelper = (uri: string, params?: any) => {
  let finalUrl = `${envConfig.BASE_URL_PREFIX}${uri}`;

  if (params && Object.keys(params)?.length) {
    const searchParamsString = new URLSearchParams(params).toString();

    finalUrl = `${finalUrl}?${searchParamsString}`;
  }

  return finalUrl;
};
