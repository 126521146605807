import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { bodySmall, headline4, poppinsSemiBoldFont } from '@/styles/typography';
import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';

export const LoginTitle = styled.div`
  ${headline4};
  ${poppinsSemiBoldFont};
  color: ${COLORS.grey[900]};
  margin-bottom: 28px;
`;

export const ForgotPasswordLabel = styled.div`
  margin-left: auto;
`;

export const LoginHintWrapper = styled.div`
  ${flex({ alignItems: 'center' })};
  column-gap: 5px;

  ${media.mobile`
    flex-direction: column;
    gap: 6px;
  `}
`;

export const LoginHint = styled.span`
  ${bodySmall};
  color: ${COLORS.black};
`;
