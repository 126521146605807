import { getUserDetailsByProxyApi } from '@/api/v1/recipient/getUserDetailsByProxyApi';
import { CreateRecipientPayloadType } from '@/api/v1/organization/wires/createRecipientApi';
import {
  AssignRecipientByProxyPayloadType,
  assignRecipientByProxyApi,
} from '@/api/v1/recipient/assignRecipientByProxyApi';
import { createRecipientByProxyApi } from '@/api/v1/recipient/createRecipientByProxyApi';
import { getRecipientAssignmentStatusByProxyApi } from '@/api/v1/recipient/getRecipientAssignmentStatusByProxyApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const createRecipientByProxy = async (wireId: string, payload: CreateRecipientPayloadType) => {
  try {
    const response = await createRecipientByProxyApi(wireId, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const assignRecipientByProxy = async (wireId: string, payload: AssignRecipientByProxyPayloadType) => {
  try {
    const response = await assignRecipientByProxyApi(wireId, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const recipientAssignmentStatusByProxy = async (wireId: string, userId: string) => {
  try {
    const response = await getRecipientAssignmentStatusByProxyApi(wireId, userId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadUserDetailsByProxy = async (userId: string) => {
  try {
    const response = await getUserDetailsByProxyApi(userId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
