import { Status } from '@/components/Status/Status';
import { InactiveDepositAccountStatus, StatusListWrapper } from './styles';
import { DEPOSIT_ACCOUNT_STATUS_DB } from '@/const/wire';
import { AssignedWireType } from '@/types/wireTypes';
import { WIRE_TYPE_INBOUND } from '../../const/const';
import { isCreatedWireStatus } from '@/helpers/wireHelpers';
import { RecipientType } from '../RecipientType/RecipientType';

type StatusListType = {
  wire: AssignedWireType;
  isProxy: boolean;
};

export const StatusList = ({ wire, isProxy }: StatusListType) => {
  const { type, status, payment_details } = wire;
  const isInboundWire = type === WIRE_TYPE_INBOUND;
  const depositAccountStatus = payment_details?.deposit_account?.status;
  const isInactiveDepositAccount = depositAccountStatus === DEPOSIT_ACCOUNT_STATUS_DB.INACTIVE;
  const isCreatedStatus = isCreatedWireStatus(status);

  return (
    <StatusListWrapper>
      {!isInboundWire && !isCreatedStatus && <RecipientType isProxy={isProxy} />}
      {isInactiveDepositAccount && (
        <InactiveDepositAccountStatus>Inactive deposit account</InactiveDepositAccountStatus>
      )}
      <Status status={status} />
    </StatusListWrapper>
  );
};
