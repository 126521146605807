import { PaymentStatusBadge } from './ui/PaymentStatusBadge';
import { ReactComponent as DownloadReportIcon } from './images/DownloadReportIcon.svg';
import { convertCentsToDollars, dateTimeUTCFormatter } from '@/helpers/formatHelpers';
import { PaymentTransaction } from '@/types/paymentTypes';
import { TableConfigItemsListType } from '@/components/Table/types/types';
import { getSubscriptionFromTransaction } from './helpers';
import { IconButton } from '@/components/IconButton/IconButton';
import { MouseEvent } from 'react';

// TODO v13.0.0: fix sorting flags, when BE will add sorting
export const PAYMENT_HISTORY_TABLE_CONFIG: TableConfigItemsListType<PaymentTransaction> = [
  {
    key: 1,
    name: 'invoice,-date_created',
    header: 'TRANSACTION ID',
    width: '20%',
    hasSort: true,
    getValue(item) {
      return item.id;
    },
  },
  {
    key: 2,
    name: 'site_admin,-date_created',
    header: 'SITE ADMIN',
    width: '20%',
    getValue() {
      return 'No Data';
    },
  },
  {
    key: 3,
    name: 'date_created',
    header: 'BILLING DATE',
    width: '20%',
    hasSort: true,
    hasTooltip: true,
    getValue(item) {
      return dateTimeUTCFormatter(item.settled_at);
    },
  },
  {
    key: 4,
    name: 'plan',
    header: 'PLAN',
    width: '10%',
    hasSort: true,
    getValue(item) {
      const subscription = getSubscriptionFromTransaction(item);

      return subscription?.name;
    },
  },
  {
    key: 5,
    name: 'amount',
    header: 'AMOUNT',
    width: '10%',
    hasSort: true,
    getValue(item) {
      return convertCentsToDollars(item.amount);
    },
  },
  {
    key: 6,
    name: 'status',
    header: 'STATUS',
    width: '10%',
    hasSort: true,
    getValue(item) {
      const subscription = getSubscriptionFromTransaction(item);

      return <PaymentStatusBadge status={subscription?.status}></PaymentStatusBadge>;
    },
  },
  {
    key: 7,
    name: 'report',
    header: '',
    width: '10%',
    getValue(item, onDownloadReport) {
      const downloadReport = (event: MouseEvent<HTMLOrSVGElement>, transaction: PaymentTransaction) => {
        event.stopPropagation();
        onDownloadReport(transaction);
      };

      return (
        <IconButton svgPath='stroke' onClick={(event) => downloadReport(event, item)}>
          <DownloadReportIcon />
        </IconButton>
      );
    },
  },
];
