import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { dateFormatter, formatAmount } from '@/helpers/formatHelpers';
import { AssignedWireType } from '@/types/wireTypes';
import { ReactNode } from 'react';
import { DetailsRowNotes } from '@/components/DetailsWidget/DetailsRowNotes/DetailsRowNotes';
import { Status } from '@/components/Status/Status';

type WireDetailsType = {
  wire: AssignedWireType;
  children?: ReactNode;
};

export const WireDetails = ({ wire, children }: WireDetailsType) => {
  const {
    name,
    contract,
    status,
    ref_id,
    date_created,
    expected_payment_date,
    created_by,
    date_modified,
    amount,
    notes,
  } = wire;

  return (
    <DetailsWidget>
      <DetailsRow name='Transaction Name' title={contract?.name}>
        {contract?.name || '-'}
      </DetailsRow>
      <DetailsRow name='Wire Name' direction='column' title={name}>
        {name}
      </DetailsRow>
      <DetailsRow name='Status'>
        <Status status={status} />
      </DetailsRow>
      <DetailsRow name='Wire Amount' hasBorder>
        {formatAmount(amount)}
      </DetailsRow>
      <DetailsRow name='Wire ID'>{ref_id || '-'}</DetailsRow>
      <DetailsRow name='Wire Creation Date'>{dateFormatter(date_created)}</DetailsRow>
      <DetailsRow name='Expected Payment Date'>{dateFormatter(expected_payment_date)}</DetailsRow>
      <DetailsRow name='Created by' title={created_by?.email}>
        {created_by?.email || '-'}
      </DetailsRow>
      <DetailsRow name='Last Update'>{dateFormatter(date_modified)}</DetailsRow>
      {children}
      <DetailsRowNotes name='Notes'>{notes}</DetailsRowNotes>
    </DetailsWidget>
  );
};
