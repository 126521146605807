import { prepareListApiParams } from '@/api/helpers/urlApiHelpers';
import { getRecipientsWiresApi } from '@/api/v1/recipient/getRecipientsWiresApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const loadRecipientWires = async (queryParams: any) => {
  const apiParams = prepareListApiParams(queryParams);

  try {
    const response = await getRecipientsWiresApi(apiParams);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
