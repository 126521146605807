import { flex } from '@/styles/layout';
import styled from 'styled-components';

export const TableHeaderCellIcon = styled.div`
  ${flex({ alignItems: 'center', flexDirection: 'column' })};
  margin-left: 2px;
`;

export const SortIconEmpty = styled.div`
  width: 20px;
  height: 10px;
`;
