import { COLORS } from '@/styles/colors';
import { ellipsis } from '@/styles/common';
import { flex } from '@/styles/layout';
import { bodySmall, poppinsMediumFont, poppinsRegularFont } from '@/styles/typography';
import styled from 'styled-components';

export const DepositAccountLinkWrapper = styled.div`
  position: relative;
  z-index: 3;
  ${flex({ justifyContent: 'space-between', alignItems: 'start' })};
`;

export const StatusBlock = styled.div`
  ${flex({ alignItems: 'center' })};
  column-gap: 8px;
  flex-shrink: 0;
  margin-right: 25px;
`;

export const Title = styled.div`
  ${poppinsRegularFont};
  color: ${COLORS.grey[600]};
  ${bodySmall};
`;

export const DepositAccountTitle = styled.div`
  cursor: pointer;
  ${poppinsMediumFont};
  color: ${COLORS.blue[500]};
  ${bodySmall};
  ${ellipsis};
`;
