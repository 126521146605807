import { format } from 'date-fns';
import range from 'lodash/range';

export const MONTHS = {
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
};

const START_YEAR = 1990;
const END_YEAR = new Date().getFullYear() + 20;
const YEARS_LIST = range(START_YEAR, END_YEAR);
const MONTHS_LIST = Object.values(MONTHS);

export const POPPER_MODIFIERS = [
  {
    name: 'offset',
    options: {
      offset: ({ placement }) => {
        if (placement === 'top' || placement === 'top-start' || placement === 'top-end') {
          return [0, -20];
        }

        return [];
      },
    },
  },
];

export const getMonthIndex = (month: string) => {
  const index = MONTHS_LIST.findIndex((value) => value === month);

  return index;
};

export const getCurrentMonth = (date: Date) => {
  return format(date, 'LLLL');
};

export const getCurrentYear = (currentDate: Date | number) => {
  return String(new Date(currentDate).getFullYear());
};

export const monthsOptions = MONTHS_LIST.map((month) => ({ value: month, label: month }));
export const yearsOptions = YEARS_LIST.map((year) => ({ value: year, label: year?.toString() }));

export const getInitialMonthOption = (date: Date) => {
  const month = getCurrentMonth(date);

  return { value: month, label: month };
};

export const getInitialYearOption = (date: Date) => {
  const year = getCurrentYear(date);

  return { value: year, label: year };
};
