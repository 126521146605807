export const OPCO_USER_STATUSES = {
  ACTIVE: 'active',
  BLOCKED: 'blocked',
  INVITED: 'invited',
  REINVITED: 'reinvited',
} as const;

export const OPCO_USER_ROLES = {
  ADMIN: 'admin',
} as const;
