import styled from 'styled-components';
import { ReactComponent as ExpiredLinkIcon } from '../../pages/LinkExpiredPage/images/ExpiredLinkIcon.svg';

export const TextWrapper = styled.div`
  margin-bottom: 32px;
`;

export const Icon = styled(ExpiredLinkIcon)`
  margin-bottom: 28px;
`;
