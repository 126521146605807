import { USER_ROLE_MAP, UserRoleType } from '@/const/user';
import { ReactComponent as DepositorRoleIcon } from './images/DepositorRoleIcon.svg';
import { ReactComponent as ExecutorRoleIcon } from './images/ExecutorRoleIcon.svg';
import { ReactComponent as RecipientRoleIcon } from './images/RecipientRoleIcon.svg';
import { ReactComponent as OrgAdminRoleIcon } from './images/OrgAdminRoleIcon.svg';
import { ReactNode } from 'react';

export type SortedActiveRoleType = {
  title: string;
  description: string;
  value: UserRoleType;
  icon: ReactNode;
};

export const SWITCH_ROLE_ITEM_MAP = {
  [USER_ROLE_MAP.ORGANIZATION]: {
    title: 'Admin',
    description: 'Manage transactions and wires.',
    value: USER_ROLE_MAP.ORGANIZATION,
    icon: <OrgAdminRoleIcon />,
  },
  [USER_ROLE_MAP.RECIPIENT]: {
    title: 'Recipient',
    description: 'Share wire info.',
    value: USER_ROLE_MAP.RECIPIENT,
    icon: <RecipientRoleIcon />,
  },
  [USER_ROLE_MAP.EXECUTOR]: {
    title: 'Executor',
    description: 'View shared payment wire info.',
    value: USER_ROLE_MAP.EXECUTOR,
    icon: <ExecutorRoleIcon />,
  },
  [USER_ROLE_MAP.DEPOSITOR]: {
    title: 'Depositor',
    description: 'View shared deposit wire info.',
    value: USER_ROLE_MAP.DEPOSITOR,
    icon: <DepositorRoleIcon />,
  },
};
