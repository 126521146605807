import { FormikRadioGroupNew } from '@/components/form/fields/FormikRadioGroupNew/FormikRadioGroupNew';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { useFormik } from 'formik';
import { USER_ROLE_OPTIONS } from '../../../ui/CreateOrgAdminForm/const';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { useState } from 'react';
import { CheckPinCodeModal } from '@/bundle/shared/components/CheckPinCodeModal/CheckPinCodeModal';
import { PIN_TOKEN_EVENT_SCOPE } from '@/const/shared';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { editOrganizationUserRole } from '../../api';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { Notification } from '@/components/Notification/Notification';
import { OrgAdminRoleType } from '@/const/user';
import { CannotModifyLastUserErrorModal } from './CannotModifyLastUserModalError';
import { CANNOT_EDIT_ROLE_LAST_USER_ERROR_MESSAGE } from './const';
import { ResponseErrorType } from '@/types/sharedTypes';
import { getChangedFormValues } from '@/helpers/formHelpers/formHelpers';

const getFormattedError = (apiError: ResponseErrorType) => {
  const formError = getResponseError(apiError);

  if (formError === CANNOT_EDIT_ROLE_LAST_USER_ERROR_MESSAGE) {
    return null;
  }

  return formError;
};

type EditUserRoleFormType = {
  onClose: () => void;
  userRole: OrgAdminRoleType;
  onSuccess: () => void;
};

export const EditUserRoleForm = ({ onClose, userRole, onSuccess }: EditUserRoleFormType) => {
  const [isPinCodeOpen, setIsPinCodeOpen] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const { organizationId, userId } = useParams<{ organizationId: string; userId: string }>();

  const closePinCode = () => {
    setIsPinCodeOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      role: userRole,
    },
    enableReinitialize: false,
    onSubmit: (values) => {
      const payload = getChangedFormValues(values, formik.initialValues);

      if (!payload) {
        onClose();

        return;
      }

      setIsPinCodeOpen(true);
    },
  });

  const { data, mutate, isPending } = useMutation({
    mutationKey: ['edit_role', organizationId, userId],
    mutationFn: (pinCode: string) => {
      return editOrganizationUserRole(organizationId, userId, formik.values?.role, pinCode);
    },

    onSuccess: (editUserRoleResponse) => {
      if (editUserRoleResponse.error) {
        if (getFormattedError(editUserRoleResponse.error) === null) {
          setIsOpenErrorModal(true);
        }

        return;
      }

      onSuccess();
    },
  });

  const checkPinCodeSuccess = (pinToken: string) => {
    mutate(pinToken);
    closePinCode();
  };

  const formattedError = getFormattedError(data?.error);

  return (
    <>
      {formattedError && (
        <Notification variant='error' mb='24px'>
          {formattedError}
        </Notification>
      )}
      <FormikForm value={formik}>
        <FormikRadioGroupNew name='role' label='Role*' options={USER_ROLE_OPTIONS} />
        <Box display='flex' alignItems='center' columnGap={20} mt='36px'>
          <Button variant='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button type='submit' isLoading={isPending}>
            Save
          </Button>
        </Box>
      </FormikForm>
      {isPinCodeOpen && (
        <CheckPinCodeModal
          isOpen={isPinCodeOpen}
          onClose={closePinCode}
          onSuccess={checkPinCodeSuccess}
          eventScope={PIN_TOKEN_EVENT_SCOPE.EDIT_ORG_ADMIN}
        />
      )}

      <CannotModifyLastUserErrorModal
        isOpen={isOpenErrorModal}
        onClose={() => setIsOpenErrorModal(false)}
        header='Role cannot be changed'
        subHeader={
          <>
            Please assign another user of this organization as a <br /> Site Admin in order to change the role of this
            user.
          </>
        }
      />
    </>
  );
};
