import styled from 'styled-components';
import { poppinsMediumFont, subTitleSmall } from '../../../styles/typography';
import { COLORS } from '../../../styles/colors';

export const Wrapper = styled.div`
  width: 100%;
  padding: 10px 28px;
  ${poppinsMediumFont};
  ${subTitleSmall};
  color: ${COLORS.black};
  background: ${COLORS.grey[300]};
  margin-bottom: 18px;
`;
