import { COLORS } from '@/styles/colors';
import { dashedLine } from '@/styles/common';
import { flex } from '@/styles/layout';
import styled from 'styled-components';

export const PaymentInformationWrapper = styled.div`
  background-color: ${COLORS.grey[150]};
  padding: 16px;
  border-radius: 10px;
  margin-bottom: 24px;
`;

export const PaymentInformationDivider = styled.div`
  ${dashedLine};
  margin: 16px 0 12px;
`;

export const AccountData = styled.div`
  ${flex({ alignItems: 'center' })};
  column-gap: 8px;

  & > svg {
    width: 32px;
    height: 24px;
  }
`;

export const CardIconWrapper = styled.span`
  display: inline-block;
  border: 1px solid ${COLORS.grey[300]};
  border-radius: 4px;
  width: 34px;
  height: 24px;
  overflow: hidden;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;
