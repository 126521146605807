import { ApiResponseType, RequestOptions } from '@/types/sharedTypes';
import { authApiService } from '@/api/authApiService';

export type CreateOpcoUserType = {
  email: string;
  mobile_phone: string;
  first_name: string;
  middle_name?: string;
  last_name: string;
  status: string;
  role: string;
};

export const createOpcoInternalUserApi = async (
  data: CreateOpcoUserType,
  options?: RequestOptions
): Promise<ApiResponseType<CreateOpcoUserType>> => {
  const uri = `/opco/internal/users/`;

  return authApiService.POST(uri, data, null, options);
};
