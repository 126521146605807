import { getRecipientTrustStampStatusByOrgAdminApi } from '@/api/v1/organization/wires/getRecipientTrustStampStatusByOrgAdminApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const getRecipientTrustStampStatusByOrgAdmin = async (wireId: string) => {
  try {
    const response = await getRecipientTrustStampStatusByOrgAdminApi(wireId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
