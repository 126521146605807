import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { FormikForm } from '../../../../../components/form/FormikForm/FormikForm';
import { Button } from '../../../../../components/Button/Button';
import { formatInboundWire } from '../helpers/formatInboundWire';
import { FormButtonBlock } from '../../../shared/ui/styles';
import { updateInboundWire } from '../api';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { getChangedFormValues, getMergedValues, getValidationSchema } from '@/helpers/formHelpers/formHelpers';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { getEditWirePayload } from '@/bundle/_OrgAdmin/shared/helpers/helpers';
import { getInboundWireEditConfig } from '../const/editFormConfig';

type InboundWireEditFormType = {
  inboundWire: any;
  currencyOptions: { value: string; label: string }[];
  onClose: () => void;
  onUpdate: () => void;
};

export const InboundWireEditForm = ({ inboundWire, onClose, onUpdate, currencyOptions }: InboundWireEditFormType) => {
  const queryClient = useQueryClient();
  const inboundWireEditConfig = getInboundWireEditConfig(currencyOptions);
  const inboundWireFormatted = formatInboundWire(inboundWire);

  const { data, mutate, isPending } = useMutation({
    mutationKey: ['update_inbound_wire'],
    mutationFn: (payload: any) => updateInboundWire(inboundWire?.id, payload),
    onSuccess: (updatedInboundWire) => {
      if (updatedInboundWire?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.EditGeneralInfoInboundWireSuccess);

      onUpdate();

      queryClient.setQueryData(['inbound_wire', inboundWire?.id], updatedInboundWire);
    },
  });

  const formik = useFormik({
    initialValues: getMergedValues(inboundWireEditConfig, inboundWireFormatted),
    enableReinitialize: true,
    validationSchema: getValidationSchema(inboundWireEditConfig),
    onSubmit: async (values) => {
      const payload = getChangedFormValues(values, formik.initialValues);

      if (!payload) {
        onClose();

        return;
      }

      const inboundWirePayload = getEditWirePayload(payload);

      await mutate(inboundWirePayload);
    },
  });

  return (
    <>
      <FormikForm value={formik}>
        <FormGeneratorNew config={inboundWireEditConfig} apiError={data?.error} />
      </FormikForm>
      <FormButtonBlock>
        <Button variant='secondary' mobileStretch onClick={onClose} width={160} size='medium'>
          Cancel
        </Button>
        <Button onClick={formik.handleSubmit} mobileStretch isLoading={isPending} width={160} size='medium'>
          Save
        </Button>
      </FormButtonBlock>
    </>
  );
};
