import { AdditionalMessage, StepOneMessage, StepTitle } from './styles';
import { Box } from '@/components/Box/Box';

export const ScanBarcodeStep = () => {
  return (
    <Box flexDirection='column' rowGap='4px' mb='24px'>
      <StepTitle>
        Step 4: Scan the Barcode <AdditionalMessage>(if applicable)</AdditionalMessage>
      </StepTitle>
      <StepOneMessage>
        Place your ID flat and hold your phone horizontally above it. Ensure the barcode is fully visible, then take the
        photo.
      </StepOneMessage>
    </Box>
  );
};
