import { useState } from 'react';
import {
  UserDetailsCard,
  UserDetailsRow,
  UserDetailsRowTitle,
  UserDetailsRowValue,
} from '@/bundle/_Opco/shared/styles';
import { OrganizationUserType } from '@/bundle/_Opco/types/types';
import { ReactComponent as UserIcon } from '../../../../../_OpcoUsersPage/Details/images/UserIcon.svg';
import { UserDetailsHeader } from '@/bundle/shared/components/UserDetails/UserDetailsHeader/UserDetailsHeader';
import { Drawer } from '@/components/Drawer/Drawer';
import { ReactComponent as EditIcon } from '@/images/EditIconNew.svg';
import {
  BlockWrapper,
  UserDetailsEditableRow,
} from '@/bundle/_Opco/ui/OrganizationUsers/Details/ui/OrganizationUserAccountDetails/styles';
import { Box } from '@/components/Box/Box';
import { EditPersonalDetailsForm } from '@/bundle/_Opco/ui/OrganizationUsers/Details/ui/OrganizationUserPersonalDetails/EditPersonalDetailsForm';
import { ORGANIZATION_USER_STATUS } from '../../../const/const';
import { IconButton } from '@/components/IconButton/IconButton';

type OrganizationUserPersonalDetailsType = {
  user: OrganizationUserType;
  onSave: () => void;
};

export const OrganizationUserPersonalDetails = ({ user, onSave }: OrganizationUserPersonalDetailsType) => {
  const [isEditOpen, setIsEditOpen] = useState(false);

  const { first_name, middle_name, last_name, status } = user;

  const openEdit = () => setIsEditOpen(true);

  const closeEdit = () => setIsEditOpen(false);

  const onSuccess = () => {
    onSave();
    closeEdit();
  };

  const isInvited = status === ORGANIZATION_USER_STATUS.INVITED;

  return (
    <BlockWrapper>
      <UserDetailsHeader title='Personal Information' icon={<UserIcon />}></UserDetailsHeader>
      <UserDetailsCard>
        <UserDetailsRow>
          <Box flexDirection='row' justifyContent='space-between'>
            <UserDetailsEditableRow>
              <UserDetailsRowTitle>First Name</UserDetailsRowTitle>
              <UserDetailsRowValue>{first_name || '-'}</UserDetailsRowValue>
            </UserDetailsEditableRow>
            {isInvited && (
              <IconButton onClick={openEdit} ml='12px'>
                <EditIcon />
              </IconButton>
            )}
          </Box>
        </UserDetailsRow>
        <UserDetailsRow>
          <UserDetailsRowTitle>Middle Name</UserDetailsRowTitle>
          <UserDetailsRowValue>{middle_name || '-'}</UserDetailsRowValue>
        </UserDetailsRow>
        <UserDetailsRow>
          <UserDetailsRowTitle>Last Name</UserDetailsRowTitle>
          <UserDetailsRowValue>{last_name || '-'}</UserDetailsRowValue>
        </UserDetailsRow>
      </UserDetailsCard>

      <Drawer
        isOpen={isEditOpen}
        onClose={closeEdit}
        header='Edit Personal Information'
        subHeader='Please edit personal information.'
      >
        <EditPersonalDetailsForm user={user} onClose={closeEdit} onSuccess={onSuccess} />
      </Drawer>
    </BlockWrapper>
  );
};
