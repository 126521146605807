import { IconButton } from '@/components/IconButton/IconButton';
import { ReactComponent as EditIcon } from '@/images/EditIconNew.svg';

type DetailsEditButtonType = {
  onEdit: () => void;
};

export const DetailsEditButton = ({ onEdit }: DetailsEditButtonType) => {
  return (
    <IconButton onClick={onEdit}>
      <EditIcon />
    </IconButton>
  );
};
