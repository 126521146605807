import { ReactNode } from 'react';
import { AppError } from '../Error/AppError';
import { useAppErrorState } from '../Error/stores/useAppErrorState';
import { LayoutSpinner, LayoutWrapper } from './styles';

export type BlankLayoutType = {
  isLoading: boolean;
  children?: ReactNode;
};

export const BlankLayout = ({ isLoading, children }: BlankLayoutType) => {
  const { appError } = useAppErrorState();

  if (appError.hasAppError) {
    return <AppError />;
  }

  return isLoading ? (
    <LayoutWrapper>
      <LayoutSpinner />
    </LayoutWrapper>
  ) : (
    <>{children}</>
  );
};
