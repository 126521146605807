export const BANK_ABA_NUMBER = new RegExp(/^[0-9]{9}$/);
export const AMOUNT = new RegExp(/^(?:\d{1,12}(?:\.\d{1,2})?|\d{1,2})$/);
export const EMAIL = new RegExp(/^[a-zA-Z0-9_.+-]+[^.]@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/);
export const PHONE_NUMBER = new RegExp(/^[0-9]{10,15}$/);

export const UPPER_CASE_LETTER = new RegExp(/[A-Z]/);
export const LOVER_CASE_LETTER = new RegExp(/[a-z]/);
export const ONE_DIGIT = new RegExp(/[0-9]/);
export const NO_SPACES = new RegExp(/^\S+$/);
export const LENGTH = new RegExp(/^.{15,30}$/);
export const LATIN_LETTERS_ONLY = new RegExp(/[^a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/);
export const SPEC_CHARACTERS = new RegExp(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/);

export const SEQUENTIAL_DIGITS = new RegExp(
  /^(?!.*(012345|123456|234567|345678|456789|567890|098765|987654|876543|765432|654321|543210)).*$/
);
export const IDENTICAL_DIGITS = new RegExp(/^(?!.*(.)\1{5}).{6}$/);
export const REPLACE_MULTI_LINE_BREAKS = new RegExp(/\n{3,}/g);
