import { Box } from '@/components/Box/Box';
import { Loader } from '@/components/Loader/Loader';
import { AttemptsCount, Message } from '../styles';

interface CheckPinCodeMessageBoxType {
  attemptsCount: number;
  error: string;
  isLoading: boolean;
}

export const CheckPinCodeHintBox = ({ attemptsCount, error, isLoading }: CheckPinCodeMessageBoxType) => {
  if (isLoading) {
    return (
      <Box mt='16px'>
        <Loader size='small' background='transparent' />
      </Box>
    );
  }

  return (
    <>
      {attemptsCount && (
        <Box mt='16px' columnGap='5px' justifyContent='center' flexDirection='row'>
          {error && <Message hasError={!!error}>{error}</Message>}
          <AttemptsCount hasError={error}>{attemptsCount}</AttemptsCount>
          <Message hasError={!!error}>attempts left</Message>
        </Box>
      )}
    </>
  );
};
