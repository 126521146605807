import { decodeToken } from '@/helpers/tokenHelpers';
import { getCompleteRegistrationUrl } from '@/bundle/Auth/InviteUserFlow/CompleteRegistrationPage/urls/getCompleteRegistrationUrl';
import { getTwoFAUrl } from '@/bundle/Auth/LoginFlow/TwoFAPage/urls/getTwoFAUrl';
import { getPinCodeUrl } from '@/bundle/Auth/LoginFlow/CheckPinCodePage/urls/getPinCodeUrl';
import { AuthTokenType, ValueOf } from '@/types/sharedTypes';
import { getLoginUrl } from '@/bundle/Auth/LoginFlow/LoginPage/urls/getLoginUrl';
import { getCreatePinCodeUrl } from '@/bundle/Auth/InviteUserFlow/CreatePinCodePage/urls/getCreatePinCodeUrl';
import { getForgotPasswordTwoFAUrl } from '../ForgotPasswordFlow/ForgotPasswordTwoFAPage/urls/getForgotPasswordTwoFAUrl';
import { getForgotPasswordCompleteRegistrationUrl } from '../ForgotPasswordFlow/ForgotPasswordCompleteRegistrationPage/urls/getForgotPasswordCompleteRegistrationUrl';
import { getForgotPasswordCheckPinCodeUrl } from '../ForgotPasswordFlow/ForgotPasswordCheckPinCodePage/urls/getForgotPasswordCheckPinCodeUrl';
import { getForgotPasswordSetPasswordUrl } from '../ForgotPasswordFlow/ForgotPasswordSetPasswordPage/urls/getForgotPasswordSetPasswordUrl';
import { getPersonalDetailsVerificationUrl } from '@/bundle/Auth/LoginFlow/PersonalDetailsVerificationPage/urls/getPersonalDetailsVerificationUrl';

export const AUTH_STEP = {
  TWO_FACTOR_AUTHENTICATION: '2fa_authentication',
  PIN_AUTHENTICATION: 'pin_authentication',
  COMPLETE_REGISTRATION: 'complete_registration',
  SET_PIN: 'set_pin',
  SET_NAME_VERIFICATION: 'name_verification',
} as const;

export const FORGOT_PASSWORD_STEP = {
  COMPLETE_REGISTRATION: 'fp_complete_registration',
  TWO_FACTOR_AUTHENTICATION: 'fp_2fa_authentication',
  PIN_AUTHENTICATION: 'fp_pin_authentication',
  SET_PASSWORD: 'fp_set_password',
} as const;

export type AuthStepType = ValueOf<typeof AUTH_STEP>;
export type ForgotPasswordStepType = ValueOf<typeof FORGOT_PASSWORD_STEP>;

export const AUTH_STEP_URL_MAPPER = {
  [AUTH_STEP.TWO_FACTOR_AUTHENTICATION]: getTwoFAUrl(),
  [AUTH_STEP.PIN_AUTHENTICATION]: getPinCodeUrl(),
  [AUTH_STEP.COMPLETE_REGISTRATION]: getCompleteRegistrationUrl(),
  [AUTH_STEP.SET_PIN]: getCreatePinCodeUrl(),
  [AUTH_STEP.SET_NAME_VERIFICATION]: getPersonalDetailsVerificationUrl(),
};

export const FORGOT_PASSWORD_URL_MAPPER = {
  [FORGOT_PASSWORD_STEP.COMPLETE_REGISTRATION]: getForgotPasswordCompleteRegistrationUrl(),
  [FORGOT_PASSWORD_STEP.TWO_FACTOR_AUTHENTICATION]: getForgotPasswordTwoFAUrl(),
  [FORGOT_PASSWORD_STEP.PIN_AUTHENTICATION]: getForgotPasswordCheckPinCodeUrl(),
  [FORGOT_PASSWORD_STEP.SET_PASSWORD]: getForgotPasswordSetPasswordUrl(),
};

export const getAuthNextStepUrl = (authToken: string) => {
  const loginUrl = getLoginUrl();
  const { step } = decodeToken<AuthTokenType>(authToken);
  const nextStepUrl = AUTH_STEP_URL_MAPPER[step] || loginUrl;

  return nextStepUrl;
};

export const getForgotPasswordNextStepUrl = (authToken: string) => {
  const loginUrl = getLoginUrl();
  const { step } = decodeToken<AuthTokenType>(authToken);
  const nextStepUrl = FORGOT_PASSWORD_URL_MAPPER[step] || loginUrl;

  return nextStepUrl;
};
