import { Box } from '@/components/Box/Box';
import { ReactNode } from 'react';
import { StepDescription, RecordVideoStepTitle } from './styles';

interface RecordVideoStepRowType {
  icon: ReactNode;
  title: string;
  description: string;
}

export const RecordVideoStepRow = ({ icon, title, description }: RecordVideoStepRowType) => {
  return (
    <Box flexDirection='column' rowGap='8px' width={140}>
      {icon}
      <Box flexDirection='column'>
        <RecordVideoStepTitle>{title}</RecordVideoStepTitle>
        <StepDescription>{description}</StepDescription>
      </Box>
    </Box>
  );
};
