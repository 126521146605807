import { getFormInitialValues } from '@/helpers/formHelpers/formHelpers';

const PAYMENT_DETAILS_INTERNATIONAL_FORM_CONFIG = {
  payment_type: {
    title: 'Payment Type',
    type: 'radio_group',
    gridColumn: '1 / 3',
  },
  recipient_name: {
    title: 'Recipient*',
    placeholder: 'Enter Recipient Name',
    gridColumn: '1 / 3',
  },
  recipient_address: {
    title: 'Recipient Address*',
    placeholder: 'Enter Recipient Address',
    gridColumn: '1 / 3',
  },
  /**
   * !!Note!!
   * We're adding "_field" at the end to disable autofill in browser, since default autoComplete does not work!
   * In form itself we will remove _field postfix and send to BE original key name.
   */
  account_name_field: {
    title: 'Account Name',
    placeholder: 'Enter Account Name',
    gridColumn: '1 / 3',
    autoComplete: 'new-field',
  },
  bank_swift_code: {
    type: 'password_without_visible',
    title: 'Swift Code*',
    placeholder: 'Enter Code',
    gridColumn: '1 / 2',
    autoComplete: 'new-password',
  },
  confirm_bank_swift_code: {
    type: 'confirm',
    title: 'Confirm Swift Code*',
    placeholder: 'Enter Code',
    gridColumn: '2 / 3',
    isCopyPasteDisabled: true,
  },
  bank_name: {
    title: 'Bank Name',
    placeholder: 'Enter Bank Name',
    gridColumn: '1 / 3',
  },
  bank_account_number: {
    type: 'password_without_visible',
    title: 'Account Number*',
    placeholder: 'Enter Number',
  },
  confirm_bank_account_number: {
    type: 'confirm',
    title: 'Confirm Account Number*',
    placeholder: 'Enter Number',
    isCopyPasteDisabled: true,
  },
  bank_address: {
    title: 'Bank Address',
    placeholder: 'Enter Bank Address',
    gridColumn: '1 / 3',
  },
  bank_phone_number: {
    type: 'phone_input',
    title: 'Bank Phone Number',
    placeholder: 'Enter Bank Phone Number With Country Code',
    gridColumn: '1 / 3',
  },
};

const PAYMENT_DETAILS_INTERNATIONAL_INTERMEDIARY_FORM_CONFIG = {
  intermediary_bank_swift_code: {
    type: 'password_without_visible',
    title: 'Swift Code*',
    placeholder: 'Enter Number',
    gridColumn: '1 / 2',
  },
  confirm_intermediary_bank_swift_code: {
    type: 'confirm',
    title: 'Confirm Swift Code*',
    placeholder: 'Enter Number',
    gridColumn: '2 / 3',
    isCopyPasteDisabled: true,
  },
  intermediary_bank_name: {
    title: 'Bank Name',
    placeholder: 'Enter Bank Name',
    gridColumn: '1 / 3',
  },
  intermediary_bank_account_number: {
    type: 'password_without_visible',
    title: 'Account Number*',
    placeholder: 'Enter Number',
    gridColumn: '1 / 2',
  },
  confirm_intermediary_bank_account_number: {
    type: 'confirm',
    title: 'Confirm Account Number*',
    placeholder: 'Enter Number',
    gridColumn: '2 / 3',
    isCopyPasteDisabled: true,
  },
  intermediary_bank_address: {
    title: 'Bank Address',
    placeholder: 'Enter Bank Address',
    gridColumn: '1 / 3',
  },
  intermediary_bank_phone_number: {
    type: 'phone_input',
    title: 'Bank Phone Number',
    placeholder: 'Enter Bank Phone Number With Country Code',
    gridColumn: '1 / 3',
  },
};

const initialInternationalPaymentFormConfig = {
  ...PAYMENT_DETAILS_INTERNATIONAL_FORM_CONFIG,
};

const initialInternationalPaymentFormIntermediaryFormConfig = {
  ...PAYMENT_DETAILS_INTERNATIONAL_FORM_CONFIG,
  ...PAYMENT_DETAILS_INTERNATIONAL_INTERMEDIARY_FORM_CONFIG,
};

const intermediaryBankInternationalInitialValues = getFormInitialValues(
  PAYMENT_DETAILS_INTERNATIONAL_INTERMEDIARY_FORM_CONFIG
);

export const CREATE_PAYMENT_DETAILS_INTERNATIONAL_FORM_CONFIG = {
  PAYMENT_DETAILS_INTERNATIONAL_FORM_CONFIG,
  PAYMENT_DETAILS_INTERNATIONAL_INTERMEDIARY_FORM_CONFIG,
  initialInternationalPaymentFormConfig,
  initialInternationalPaymentFormIntermediaryFormConfig,
  intermediaryBankInternationalInitialValues,
};
