import { TableCell, TableRow } from '../styles';
import { TableConfigItemType, TableEmptyColumnsType } from '../types/types';

export const TableEmptyRow = ({ columns }: TableEmptyColumnsType) => {
  return (
    <TableRow>
      {columns.map((column: TableConfigItemType) => {
        return (
          <TableCell key={column.key} width={column.width}>
            -
          </TableCell>
        );
      })}
    </TableRow>
  );
};
