import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUser } from '@/context/userContext';
import { getAccessDeniedUrl } from '@/bundle/pages/AccessDeniedPage/urls/getAccessDeniedUrl';

export const SiteAdminProtectedRoute = () => {
  const location = useLocation();
  const { isSiteAdminRole } = useUser();
  const accessDeniedUrl = getAccessDeniedUrl();

  if (isSiteAdminRole) {
    return <Outlet />;
  } else {
    return <Navigate to={accessDeniedUrl} state={{ from: location }} replace />;
  }
};
