import { authApiService } from '@/api/authApiService';
import { ApiResponseType, RequestOptions } from '@/types/sharedTypes';
import { OrganizationUserType } from '@/bundle/_Opco/types/types';

export type EditOpcoOrganizationUserPhoneType = {
  mobile_phone: string;
};

export const editOrganizationUserMobilePhoneApi = async (
  organizationId: string,
  userId: string,
  payload: EditOpcoOrganizationUserPhoneType,
  options?: RequestOptions
): Promise<ApiResponseType<OrganizationUserType>> => {
  const uri = `/opco/organizations/${organizationId}/users/${userId}/mobile-phone/`;

  return authApiService.PATCH(uri, payload, null, options);
};
