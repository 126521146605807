import { flexCenterColumn } from '@/styles/layout';
import { media } from '@/styles/responsive';
import styled from 'styled-components';

export const Wrapper = styled.div`
  ${flexCenterColumn};
  width: 50%;
  row-gap: 64px;

  ${media.mobile`
    width: 100%;
    row-gap: 32px;
  `}
`;
