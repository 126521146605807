import { LoadPaymentDetailsLoading } from '../../../../OutboundWire/_Details/ui/LoadPaymentDetailsWidget/LoadPaymentDetailsLoading';
import { PaymentDetailsWrapper } from './styles';
import { ReactComponent as NoPaymentDetailsIcon } from '@/images/NoItemsIcon.svg';
import { Notification } from '@/components/Notification/Notification';
import { getContactUsPath } from '@/bundle/pages/ContactUsPage/urls/getContactUsUrl';
import { DEPOSIT_ACCOUNT_STATUS_DB } from '@/const/wire';
import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import { ResponseErrorType } from '@/types/sharedTypes';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { NavigationLink } from '@/components/NavigationLink/NavigationLink';
import { NoResults } from '@/components/NoResults/NoResults';
import { DepositAccountStatusType } from '@/types/wireTypes';
import { PaymentDetailsData } from '@/bundle/shared/components/PaymentDetailsData/PaymentDetailsData';

type DepositAccountPaymentDetailsType = {
  status: DepositAccountStatusType;
  paymentDetails: PaymentDetailsGeneralType;
  isLoading: boolean;
  error?: ResponseErrorType;
};

export const DepositAccountPaymentDetails = ({
  status,
  paymentDetails,
  isLoading,
  error,
}: DepositAccountPaymentDetailsType) => {
  const isFailedStatus = status === DEPOSIT_ACCOUNT_STATUS_DB.FAILED;
  const isPendingStatus = status === DEPOSIT_ACCOUNT_STATUS_DB.PENDING;
  const isActiveStatus = status === DEPOSIT_ACCOUNT_STATUS_DB.ACTIVE;
  const isInactiveStatus = status === DEPOSIT_ACCOUNT_STATUS_DB.INACTIVE;
  const isDataFromBlockchain = paymentDetails?.is_blockchain_payment_details_data_from_bc;

  const hasPaymentDetails = isActiveStatus || isInactiveStatus;
  const hasDetails = !isLoading && !error;
  const hasBlockchainDetails = hasDetails && isDataFromBlockchain;
  const hasDatabaseDetails = hasDetails && isDataFromBlockchain === false;

  if (isPendingStatus) {
    return (
      <NoResults
        icon={<NoPaymentDetailsIcon />}
        message='This deposit account has been submitted to the WireVault blockchain.'
        marginTop={84}
      />
    );
  }

  return (
    <PaymentDetailsWrapper>
      <DetailsWidget header='Wire Info'>
        {isLoading && <LoadPaymentDetailsLoading />}

        {isFailedStatus && (
          <Notification variant='error' fontVariant='medium' verticalAlign='center'>
            Something went wrong. Please contact&nbsp;
            <NavigationLink to={getContactUsPath()}>WireVault team</NavigationLink>
          </Notification>
        )}

        {hasPaymentDetails && hasBlockchainDetails && (
          <Notification variant='success' fontVariant='medium' verticalAlign='center' mb='20px'>
            Stored in the WireVault blockchain.
          </Notification>
        )}

        {hasPaymentDetails && hasDatabaseDetails && (
          <Notification variant='info-secondary' mb='20px'>
            The WireVault blockchain is unavailable. The wire info cannot be confirmed at this time. Please try again
            later.
          </Notification>
        )}

        {hasPaymentDetails && hasDetails && <PaymentDetailsData paymentDetails={paymentDetails} />}
      </DetailsWidget>
    </PaymentDetailsWrapper>
  );
};
