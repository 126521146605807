import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { resetInternalRequestAuthApi } from '@/api/v1/opco/internal/resetInternalRequestAuthApi';

export const resetRequestAuth = async () => {
  try {
    const response = await resetInternalRequestAuthApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
