import {
  AssignableUserNotification,
  InvitedUserFromAnotherOrganizationNotification,
  InvitedUserFromSameOrganizationNotification,
  InvitedUserNotification,
  SameUserNotification,
} from '@/bundle/shared/components/UserAssignmentNotifications';
import { Notification } from '@/components/Notification/Notification';
import { ValueOf } from '@/types/sharedTypes';

export const PROXY_ASSIGN_USER_STATUS_CODE_MAP = {
  IS_NOT_ALLOWED_ROLE: 'is_not_allowed_role',
  IS_SAME_USER: 'is_same_user',
  IS_NOT_SAME_ROLE_AND_INVITED: 'is_not_same_role_and_invited',
  IS_SAME_ROLE_AND_INVITED_BUT_CREATED_BY_SAME_RECIPIENT_PROXY:
    'is_same_role_and_invited_but_created_by_same_recipient_proxy',
  IS_SAME_ROLE_AND_INVITED_BUT_CREATED_BY_ANOTHER_RECIPIENT_PROXY:
    'is_same_role_and_invited_but_created_by_another_recipient_proxy',
  USER_ASSOCIATED_WITH_ORGANIZATION_THAT_ORIGINATE_WIRE: 'user_associated_with_organization_that_originate_wire',
  CAN_BE_ASSIGNED: 'can_be_assigned',
} as const;

export type ProxyAssignUserStatusCodeType = ValueOf<typeof PROXY_ASSIGN_USER_STATUS_CODE_MAP>;

export const UserFromOrganizationThatOriginateWireNotification = () => {
  return (
    <Notification variant='error' fontVariant='medium' mt='-16px' mb='24px'>
      You are not allowed to assign this user to the wire.
    </Notification>
  );
};

export const PROXY_ASSIGN_RECIPIENT_NOTIFICATIONS_MAP = {
  [PROXY_ASSIGN_USER_STATUS_CODE_MAP.IS_SAME_ROLE_AND_INVITED_BUT_CREATED_BY_SAME_RECIPIENT_PROXY]: (
    <InvitedUserFromSameOrganizationNotification />
  ),
  [PROXY_ASSIGN_USER_STATUS_CODE_MAP.IS_SAME_ROLE_AND_INVITED_BUT_CREATED_BY_ANOTHER_RECIPIENT_PROXY]: (
    <InvitedUserFromAnotherOrganizationNotification />
  ),
  [PROXY_ASSIGN_USER_STATUS_CODE_MAP.CAN_BE_ASSIGNED]: <AssignableUserNotification />,
  [PROXY_ASSIGN_USER_STATUS_CODE_MAP.IS_NOT_SAME_ROLE_AND_INVITED]: <InvitedUserNotification />,
  [PROXY_ASSIGN_USER_STATUS_CODE_MAP.IS_SAME_USER]: <SameUserNotification />,
  [PROXY_ASSIGN_USER_STATUS_CODE_MAP.USER_ASSOCIATED_WITH_ORGANIZATION_THAT_ORIGINATE_WIRE]: (
    <UserFromOrganizationThatOriginateWireNotification />
  ),
};
