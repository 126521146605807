import { useMutation } from '@tanstack/react-query';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { AuthLayout } from '../../ui/AuthLayout/AuthLayout';
import { CompleteRegistrationForm } from '@/bundle/Auth/InviteUserFlow/CompleteRegistrationPage/ui/CompleteRegistrationForm';
import { forgotPasswordCompleteRegistration } from './api';
import { useForgotPasswordNavigateToStep } from '../hooks/useForgotPasswordNavigateToStep';

export const ForgotPasswordCompleteRegistrationPage = () => {
  const navigateToStep = useForgotPasswordNavigateToStep();

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['forgot_password_complete_registration'],
    mutationFn: () => {
      return forgotPasswordCompleteRegistration();
    },
    onSuccess(completeRegistrationResponse) {
      if (completeRegistrationResponse?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.ForgotPasswordCompleteRegistration);

      return navigateToStep(completeRegistrationResponse?.body);
    },
  });

  return (
    <AuthLayout>
      <CompleteRegistrationForm onConfirm={mutate} isLoading={isPending} error={data?.error} />
    </AuthLayout>
  );
};
