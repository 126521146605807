import { sendTwoFAApi } from '@/api/v1/users/sendTwoFAApi';
import { sendTwoFACodeApi } from '@/api/v1/users/sendTwoFACodeApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const sendTwoFA = async (code: string) => {
  const payload = {
    code,
  };

  try {
    const response = await sendTwoFAApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const sendTwoFACode = async () => {
  try {
    const response = await sendTwoFACodeApi();

    return prepareResponse(response, ['retry-after']);
  } catch (e) {
    throwResponseError(e);
  }
};
