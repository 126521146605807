import { ApiParams } from '@/api/helpers/urlApiHelpers';
import { ApiResponseType, RequestOptions } from '@/types/sharedTypes';
import { authApiService } from '@/api/authApiService';
import { OrgAdminRoleType } from '@/const/user';

export type CreateOrganizationUserType = {
  user: {
    email: string;
    mobile_phone: string;
    first_name: string;
    middle_name?: string;
    last_name: string;
  };
  role: OrgAdminRoleType;
};

export const createOrganizationUserApi = async (
  organizationId: string,
  data: CreateOrganizationUserType,
  params?: ApiParams,
  options?: RequestOptions
): Promise<ApiResponseType<CreateOrganizationUserType>> => {
  const uri = `/opco/organizations/${organizationId}/users/`;

  return authApiService.POST(uri, data, params, options);
};
