import { useMutation } from '@tanstack/react-query';
import { AuthLayout } from '@/bundle/Auth/ui/AuthLayout/AuthLayout';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { completeRegistration } from './api';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { CompleteRegistrationForm } from './ui/CompleteRegistrationForm';
import { useAuthNavigateToStep } from '../../hooks/useAuthNavigateToStep';

export const CompleteRegistrationPage = () => {
  const navigateToStep = useAuthNavigateToStep();

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['complete_registration'],
    mutationFn: () => {
      return completeRegistration();
    },
    onSuccess(completeRegistrationResponse) {
      if (completeRegistrationResponse?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.CompleteRegistration);

      return navigateToStep(completeRegistrationResponse?.body);
    },
  });

  return (
    <AuthLayout>
      <CompleteRegistrationForm onConfirm={mutate} isLoading={isPending} error={data?.error} />
    </AuthLayout>
  );
};
