import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

type PinCodeAttemptsResponseType = {
  remaining_attempts: number;
  total_attempts: number;
};

export const getPinCodeAttemptsApi = (): Promise<ApiResponseType<PinCodeAttemptsResponseType>> => {
  const uri = '/users/pin/attempts/';

  return authApiService.GET(uri);
};
