import styled, { keyframes } from 'styled-components';
import { COLORS } from '@/styles/colors';
import { body, bodySmall, poppinsMediumFont } from '@/styles/typography';
import { flex, flexCenter } from '@/styles/layout';
import { media } from '@/styles/responsive';

const slideUpAnimation = keyframes`
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  bottom: 40px;
  left: 50%;
  z-index: 10;
  ${flexCenter};
  animation: ${slideUpAnimation} 0.5s ease;

  ${media.tablet`
    width: 100%;
    left: 0;
  `}
`;

export const ButtonWrapper = styled.div`
  ${flex({ alignItems: 'center' })};
`;

export const CustomButton = styled.button`
  ${flex({ alignItems: 'center' })}
  flex-shrink: 0;
  padding: 13px 12px;
  border-radius: 10px 0 0 10px;
  color: ${COLORS.white};
  ${poppinsMediumFont};
  ${body};
  background: ${COLORS.black};
  column-gap: 12px;
  border: none;
  cursor: pointer;

  ${media.mobile`
    ${bodySmall}
  `}

  &:hover {
    background: ${COLORS.grey[850]};
  }
`;

export const CloseButton = styled(CustomButton)`
  border-radius: 0 10px 10px 0;
  padding: 15px 12px;
  border-left: 1px solid ${COLORS.grey[700]};
`;
