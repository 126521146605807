import { FormConfigType } from '@/helpers/formHelpers/formHelpers';
import {
  PHONE_NUMBER_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';

export const DOMESTIC_FORM_CONFIG: FormConfigType = [
  {
    name: 'recipient_name',
    label: 'Recipient*',
    placeholder: 'Enter Recipient Name',
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'recipient_address',
    label: 'Recipient Address*',
    placeholder: 'Enter Recipient Address',
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'account_name',
    label: 'Account Name',
    placeholder: 'Enter Account Name',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'bank_aba_number',
    label: 'Routing / ABA Number*',
    placeholder: 'Enter Number',
    column: 6,
    disabled: true,
  },
  {
    name: 'bank_name',
    label: 'Bank Name',
    placeholder: 'Enter Bank Name',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'bank_account_number',
    label: 'Account Number*',
    placeholder: 'Enter Number',
    column: 6,
    disabled: true,
  },
  {
    name: 'bank_address',
    label: 'Bank Address',
    placeholder: 'Enter Bank Address',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'bank_phone_number',
    label: 'Bank Phone Number',
    placeholder: 'Enter Bank Phone Number With Country Code',
    validator: PHONE_NUMBER_VALIDATOR,
    type: 'phone_input',
  },
];

export const DOMESTIC_INTERMEDIARY_FORM_CONFIG: FormConfigType = [
  {
    name: '',
    type: 'divider',
    label: 'Intermediary Bank',
  },
  {
    name: 'intermediary_bank_aba_number',
    label: 'Routing / ABA Number*',
    placeholder: 'Enter Number',
    column: 6,
    disabled: true,
  },
  {
    name: 'intermediary_bank_name',
    label: 'Bank Name',
    placeholder: 'Enter Bank Name',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'intermediary_bank_account_number',
    label: 'Account Number*',
    placeholder: 'Enter Number',
    column: 6,
    disabled: true,
  },
  {
    name: 'intermediary_bank_address',
    label: 'Bank Address',
    placeholder: 'Enter Bank Address',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'intermediary_bank_phone_number',
    label: 'Bank Phone Number',
    placeholder: 'Enter Bank Phone Number With Country Code',
    validator: PHONE_NUMBER_VALIDATOR,
    type: 'phone_input',
  },
];

export const INTERNATIONAL_FORM_CONFIG: FormConfigType = [
  {
    name: 'recipient_name',
    label: 'Recipient*',
    placeholder: 'Enter Recipient Name',
  },
  {
    name: 'recipient_address',
    label: 'Recipient Address*',
    placeholder: 'Enter Recipient Address',
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'account_name',
    label: 'Account Name',
    placeholder: 'Enter Account Name',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'bank_swift_code',
    label: 'Swift Code*',
    placeholder: 'Enter Code',
    column: 6,
    disabled: true,
  },
  {
    name: 'bank_name',
    label: 'Bank Name',
    placeholder: 'Enter Bank Name',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'bank_account_number',
    label: 'Account Number*',
    placeholder: 'Enter Number',
    column: 6,
    disabled: true,
  },
  {
    name: 'bank_address',
    label: 'Bank Address',
    placeholder: 'Enter Bank Address',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'bank_phone_number',
    label: 'Bank Phone Number',
    placeholder: 'Enter Bank Phone Number With Country Code',
    validator: PHONE_NUMBER_VALIDATOR,
    type: 'phone_input',
  },
];

export const INTERNATIONAL_INTERMEDIARY_FORM_CONFIG: FormConfigType = [
  {
    name: '',
    type: 'divider',
    label: 'Intermediary Bank',
  },
  {
    name: 'intermediary_bank_swift_code',
    label: 'Swift Code*',
    placeholder: 'Enter Number',
    column: 6,
    disabled: true,
  },
  {
    name: 'intermediary_bank_name',
    label: 'Bank Name',
    placeholder: 'Enter Bank Name',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'intermediary_bank_account_number',
    label: 'Account Number*',
    placeholder: 'Enter Number',
    column: 6,
    disabled: true,
  },
  {
    name: 'intermediary_bank_address',
    label: 'Bank Address',
    placeholder: 'Enter Bank Address',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'intermediary_bank_phone_number',
    label: 'Bank Phone Number',
    placeholder: 'Enter Bank Phone Number With Country Code',
    validator: PHONE_NUMBER_VALIDATOR,
    type: 'phone_input',
  },
];
