import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { PaymentDetailsDataWidgetLoader } from './ui/PaymentDetailsDataWidgetLoader';
import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import { PaymentDetailsData } from '../PaymentDetailsData/PaymentDetailsData';
import { Notification } from '@/components/Notification/Notification';
import { ResponseErrorType } from '@/types/sharedTypes';
import { ReactNode } from 'react';

type PaymentDetailsDataWidgetType = {
  paymentDetails: PaymentDetailsGeneralType;
  isLoading: boolean;
  apiError?: ResponseErrorType;
  action?: ReactNode;
  headlineSlot?: ReactNode;
};

export const PaymentDetailsDataWidget = ({
  paymentDetails,
  isLoading,
  apiError,
  action,
  headlineSlot,
}: PaymentDetailsDataWidgetType) => {
  const hasPaymentDetails = !!paymentDetails && !isLoading && !apiError;
  const isBlockchainPaymentDetails = hasPaymentDetails && !!paymentDetails?.is_blockchain_payment_details_data_from_bc;
  const isDatabasePaymentDetails =
    hasPaymentDetails && paymentDetails?.is_blockchain_payment_details_data_from_bc === false;

  return (
    <DetailsWidget header='Wire Info' headerOffset={12} action={action} headlineSlot={headlineSlot}>
      {isLoading && <PaymentDetailsDataWidgetLoader />}

      {isBlockchainPaymentDetails && (
        <Notification variant='success' fontVariant='medium' verticalAlign='center' mb='20px'>
          Stored in the WireVault blockchain.
        </Notification>
      )}

      {isDatabasePaymentDetails && (
        <Notification variant='info-secondary' fontVariant='medium' mb='20px'>
          The WireVault blockchain is unavailable. The wire info cannot be confirmed at this time. Please try again
          later.
        </Notification>
      )}

      {hasPaymentDetails && <PaymentDetailsData paymentDetails={paymentDetails} />}
    </DetailsWidget>
  );
};
