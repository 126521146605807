import { useState } from 'react';
import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { PaymentCard } from '../PaymentCard/PaymentCard';
import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { useMutation } from '@tanstack/react-query';
import { addPaymentMethod, deletePaymentMethod, setDefaultPaymentMethod } from '../../api';
import { Drawer } from '@/components/Drawer/Drawer';
import { PaymentCardForm } from '../PaymentCardForm/PaymentCardForm';
import { PAYMENT_METHOD_TYPE } from '@/const/shared';
import { DeleteCardIcon } from '../PaymentCard/styles';
import { ReactComponent as SecureLockIcon } from '../../images/SecureLockIcon.svg';
import { DefaultPaymentMethodType, PaymentCardType } from '@/types/paymentTypes';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';

type PaymentCardsListType = {
  cards: PaymentCardType[];
  defaultPaymentCardId?: string;
  onRefetchCustomerVault: () => void;
};

export const PaymentCardsList = ({ cards, defaultPaymentCardId, onRefetchCustomerVault }: PaymentCardsListType) => {
  const [isOpenConfirmDefaultCard, setIsOpenConfirmDefaultCard] = useState(false);
  const [isOpenConfirmDeleteCard, setIsOpenConfirmDeleteCard] = useState(false);
  const [openConfirmCard, setOpenConfirmCard] = useState<PaymentCardType | null>(null);

  const [openAddNewCardDrawer, setOpenAddNewCardDrawer] = useState(false);

  const { mutate: setDefaultPaymentMethodMutate, isPending: isPendingSetDefaultPaymentMethod } = useMutation({
    mutationKey: ['set_default_payment_method'],
    mutationFn: (paymentMethodId: string) => {
      const payload: DefaultPaymentMethodType = {
        payment_method_type: PAYMENT_METHOD_TYPE,
        payment_method_id: paymentMethodId,
      };

      return setDefaultPaymentMethod(payload);
    },
    onSuccess(setDefaultPaymentMethodResponse) {
      if (setDefaultPaymentMethodResponse?.error) {
        return;
      }

      onRefetchCustomerVault();
    },
    onSettled() {
      setIsOpenConfirmDefaultCard(false);
    },
  });

  const { mutate: deletePaymentMethodMutate, isPending: isPendingDeletePaymentMethod } = useMutation({
    mutationKey: ['delete_payment_method'],
    mutationFn: (paymentMethodId: string) => {
      const payload: DefaultPaymentMethodType = {
        payment_method_type: PAYMENT_METHOD_TYPE,
        payment_method_id: paymentMethodId,
      };

      return deletePaymentMethod(payload);
    },
    onSuccess(deletePaymentMethodResponse) {
      if (deletePaymentMethodResponse?.error) {
        return;
      }

      onRefetchCustomerVault();
    },
    onSettled() {
      setIsOpenConfirmDeleteCard(null);
    },
  });

  const { mutate: addPaymentMethodMutate, isPending: isPendingAddPaymentMethod } = useMutation({
    mutationKey: ['add_payment_method'],
    mutationFn: (token: string) => {
      return addPaymentMethod(token);
    },
    onSuccess(addPaymentMethodResponse) {
      if (addPaymentMethodResponse?.error) {
        return;
      }

      setOpenAddNewCardDrawer(false);
      onRefetchCustomerVault();
    },
  });

  const confirmDefaultCard = () => {
    if (!openConfirmCard) {
      return;
    }

    setDefaultPaymentMethodMutate(openConfirmCard.id);
  };

  const confirmDeleteCard = () => {
    if (!openConfirmCard) {
      return;
    }

    deletePaymentMethodMutate(openConfirmCard.id);
  };

  const handleOpenConfirmDefaultCard = (card: PaymentCardType) => {
    setOpenConfirmCard(card);
    setIsOpenConfirmDefaultCard(true);
  };

  const handleOpenConfirmDeleteCard = (card: PaymentCardType) => {
    setOpenConfirmCard(card);
    setIsOpenConfirmDeleteCard(true);
  };

  return (
    <Box>
      <DetailsWidget
        variant='small'
        header={
          <Typography variant='subTitleSmall' color={COLORS.grey[950]} fontWeight='semibold'>
            Payment Method
          </Typography>
        }
        headerOffset={16}
      >
        <Box display='flex' rowGap={12} columnGap={12} flexDirection='column' mt='16px'>
          {cards.map((card) => (
            <PaymentCard
              key={card.id}
              card={card}
              defaultPaymentCardId={defaultPaymentCardId}
              onSetDefault={handleOpenConfirmDefaultCard}
              onDelete={handleOpenConfirmDeleteCard}
            />
          ))}
        </Box>
        <Typography
          focusable
          mt='16px'
          fontWeight='medium'
          variant='bodySmall'
          color={COLORS.blue[500]}
          onClick={() => setOpenAddNewCardDrawer(true)}
        >
          + Add card
        </Typography>
      </DetailsWidget>

      <ConfirmModal
        isOpen={isOpenConfirmDefaultCard}
        header='Update default card'
        isLoading={isPendingSetDefaultPaymentMethod}
        body={
          <>
            This credit card will be used by default for billing.
            <Box mt='20px'>{openConfirmCard && <PaymentCard card={openConfirmCard} readonly />}</Box>
          </>
        }
        onClose={() => setIsOpenConfirmDefaultCard(false)}
        onConfirm={confirmDefaultCard}
        confirmText='Update'
        closeText='Cancel'
        confirmation={null}
      />

      <ConfirmModal
        isOpen={isOpenConfirmDeleteCard}
        header='Are you sure you want to delete this card?'
        isLoading={isPendingDeletePaymentMethod}
        body={
          <>
            This credit card will be deleted.
            <Box mt='20px'>{openConfirmCard && <PaymentCard card={openConfirmCard} readonly />}</Box>
          </>
        }
        onClose={() => setIsOpenConfirmDeleteCard(false)}
        onConfirm={confirmDeleteCard}
        confirmText={
          <Box display='flex' justifyContent='center' columnGap='8px'>
            <DeleteCardIcon /> Delete
          </Box>
        }
        closeText='Cancel'
        confirmButtonVariant='danger'
        confirmation={null}
      />

      <Drawer
        isOpen={openAddNewCardDrawer}
        header='Add Payment Method'
        subHeader={
          <Box display='flex' columnGap={8}>
            <SecureLockIcon /> Secured by Pepper Pay
          </Box>
        }
        onClose={() => setOpenAddNewCardDrawer(false)}
      >
        <PaymentCardForm
          onClose={() => setOpenAddNewCardDrawer(false)}
          saveButtonText='Add Payment Method'
          onSave={addPaymentMethodMutate}
          isLoading={isPendingAddPaymentMethod}
        />
      </Drawer>
    </Box>
  );
};
