import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { useState } from 'react';
import { Drawer } from '@/components/Drawer/Drawer';
import { EditBillingAddressForm, BillingAddressFormType } from '../EditBillingAddressForm/EditBillingAddressForm';
import { editBillingAddress } from '../../api';
import { useMutation } from '@tanstack/react-query';
import { ResponseErrorType } from '@/types/sharedTypes';
import { getFormattedBillingAddress } from './helpers';
import { DefaultBillingAddressType } from '@/types/paymentTypes';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { DetailsEditButton } from '@/bundle/_OrgAdmin/shared/ui/DetailsEditButton/DetailsEditButton';

type BillingAddressCardType = {
  onRefetchCustomerVault?: () => void;
  billingAddress?: DefaultBillingAddressType;
  readonly?: boolean;
};

export const BillingAddressCard = ({
  billingAddress,
  onRefetchCustomerVault,
  readonly = false,
}: BillingAddressCardType) => {
  const [isOpenBillingAddressEdit, setIsOpenBillingAddressEdit] = useState(false);
  const [apiError, setApiError] = useState<ResponseErrorType>();

  const closeEditBillingAddress = () => {
    setApiError(undefined);
    setIsOpenBillingAddressEdit(false);
  };

  const { mutate: editBillingAddressMutate, isPending: isPendingEditBillingAddress } = useMutation({
    mutationKey: ['edit_billing_address'],
    mutationFn: (values: BillingAddressFormType) => {
      if (!billingAddress) {
        return;
      }

      const { country, city, state, zip, address } = values;
      const payload: DefaultBillingAddressType = {
        city,
        country: country.value as string,
        state: state.value as string,
        postal_code: zip,
        line_1: address,
      };

      return editBillingAddress(billingAddress.id, payload);
    },
    onSuccess(setDefaultPaymentMethodResponse) {
      if (setDefaultPaymentMethodResponse?.error) {
        setApiError(setDefaultPaymentMethodResponse?.error);

        return;
      }

      closeEditBillingAddress();
      onRefetchCustomerVault?.();
    },
  });

  const { country, state, city, address, zip } = getFormattedBillingAddress(billingAddress);

  return (
    <>
      <DetailsWidget
        header={
          <Box>
            <Typography variant='subTitleSmall' fontWeight='semibold'>
              Billing Address
            </Typography>
          </Box>
        }
        headerOffset={16}
        action={!readonly && <DetailsEditButton onEdit={() => setIsOpenBillingAddressEdit(true)} />}
        variant='small'
      >
        <DetailsRow name='Address' title={address} offset='small'>
          {address}
        </DetailsRow>
        <DetailsRow name='City' title={city} offset='small'>
          {city}
        </DetailsRow>
        <DetailsRow name='State/Region' title={state} offset='small'>
          {state}
        </DetailsRow>
        <DetailsRow name='Zip Code' offset='small'>
          {zip}
        </DetailsRow>
        <DetailsRow name='Country' offset='small'>
          {country}
        </DetailsRow>
      </DetailsWidget>

      <Drawer isOpen={isOpenBillingAddressEdit} header='Edit Billing Address' onClose={closeEditBillingAddress}>
        <EditBillingAddressForm
          apiError={apiError}
          isLoading={isPendingEditBillingAddress}
          billingAddress={billingAddress}
          onSave={editBillingAddressMutate}
          onClose={closeEditBillingAddress}
        />
      </Drawer>
    </>
  );
};
