import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

export type CheckPinCodePayload = {
  pin: string;
  event_scope: string;
};

export type CheckPinCodeResponse = {
  auth_confirmation_token: string;
};

export const checkPinCodeApi = (data: CheckPinCodePayload): Promise<ApiResponseType<CheckPinCodeResponse>> => {
  const uri = '/users/pin:check/';

  return authApiService.POST(uri, data);
};
