import { authApiService } from '@/api/authApiService';
import { ApiParams } from '@/api/helpers/urlApiHelpers';
import { ApiResponseType } from '@/types/sharedTypes';
import { OrganizationUserType } from '@/bundle/_Opco/types/types';

export type EditOrganizationUserDetailsPayloadType = {
  first_name: string;
  middle_name?: string;
  last_name: string;
};

export const editOrganizationUserApi = async (
  organizationId: string,
  userId: string,
  data: EditOrganizationUserDetailsPayloadType,
  params?: ApiParams
): Promise<ApiResponseType<OrganizationUserType>> => {
  const uri = `/opco/organizations/${organizationId}/users/${userId}/`;

  return authApiService.PATCH(uri, data, params);
};
