import { ButtonHTMLAttributes, MouseEvent, ReactNode } from 'react';
import { IconButtonContainer } from './styles';
import { BoxWrapperPropsType } from '../Box/styles';

type IconButtonType = {
  children: ReactNode;
  svgPath?: 'fill' | 'stroke';
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
} & BoxWrapperPropsType &
  ButtonHTMLAttributes<HTMLButtonElement>;

export const IconButton = ({ children, svgPath = 'fill', ...props }: IconButtonType) => {
  return (
    <IconButtonContainer svgPath={svgPath} as='button' {...props}>
      {children}
    </IconButtonContainer>
  );
};
