import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { AnimateFadeIn, ErrorMessage, InputErrorWrapper } from './styles';

const ANIMATION_TIMEOUT = 200;

type InputErrorType = {
  error?: string | null;
  apiError?: string | null;
};

export const InputError = ({ error, apiError }: InputErrorType) => {
  const nodeRef = useRef(null);
  const hasApiError = !error && !!apiError;
  const hasAnyError = !!error || !!apiError;

  return (
    <InputErrorWrapper>
      <CSSTransition in={hasAnyError} nodeRef={nodeRef} classNames='fade' timeout={ANIMATION_TIMEOUT} unmountOnExit>
        <AnimateFadeIn ref={nodeRef}>
          {!!error && <ErrorMessage>{error}</ErrorMessage>}
          {hasApiError && <ErrorMessage>{apiError}</ErrorMessage>}
        </AnimateFadeIn>
      </CSSTransition>
    </InputErrorWrapper>
  );
};
