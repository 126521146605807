import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';
import { bodySmall, poppinsMediumFont, poppinsRegularFont } from '@/styles/typography';
import styled from 'styled-components';

export const WireEditInfoWrapper = styled.div`
  ${flex({ alignItems: 'center', justifyContent: 'space-between' })}
  margin-bottom: 20px;

  ${media.mobile`
    ${flex({ flexDirection: 'column', alignItems: 'start' })};
    row-gap: 8px;
  `}
`;

export const WireEditInfoItem = styled.div`
  ${flex({ alignItems: 'center' })};
  column-gap: 12px;

  ${media.mobile`
    ${flex({ justifyContent: 'space-between' })};
    width: 100%;
  `}
`;

export const WireEditInfoLabel = styled.div`
  ${poppinsRegularFont};
  ${bodySmall};
  ${COLORS.grey[600]}
`;

export const WireEditInfoValue = styled(WireEditInfoLabel)`
  ${poppinsMediumFont};
  color: ${COLORS.black};
`;
