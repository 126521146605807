import { authApiService } from '@/api/authApiService';
import { ApiResponseType, ValueOf } from '@/types/sharedTypes';

export const ADDING_ORG_ADMIN_ROLE_STATUS_MAP = {
  IS_NOT_ALLOWED_ROLE: 'is_not_allowed_role',
  IS_INVITED: 'is_invited',
  CAN_BE_ADDED: 'can_be_added',
} as const;

export type AddingOrgAdminStatusCodeType = ValueOf<typeof ADDING_ORG_ADMIN_ROLE_STATUS_MAP>;

type ResponseType = {
  code: AddingOrgAdminStatusCodeType;
  is_available_to_add: boolean;
};

export const getUserAddingStatusApi = async (userId: string): Promise<ApiResponseType<ResponseType>> => {
  const uri = `/opco/organizations/users/${userId}/adding-status/`;

  return authApiService.GET(uri);
};
