import styled from 'styled-components';
import { bodySmall, poppinsRegularFont, poppinsSemiBoldFont } from '@/styles/typography';
import { COLORS } from '@/styles/colors';

export const HeaderMessage = styled.div`
  ${poppinsSemiBoldFont};
  ${bodySmall};
`;

export const SecureMetaWrapper = styled.div`
  ${poppinsRegularFont};
  ${bodySmall};
  padding: 2px 8px;
  background-color: ${COLORS.green[200]};
  color: ${COLORS.green[500]};
  border-radius: 8px;
`;
