import { FormConfigType } from '@/helpers/formHelpers/formHelpers';
import {
  AMOUNT_VALIDATOR,
  REQUIRED_EMAIL_VALIDATOR,
  REQUIRED_OBJECT_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  STRING_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';

export const getCreateReplacementWireFormConfig = (
  currencyOptions = [],
  isChainProxy: boolean = false,
  isDocumentVerificationRequired: boolean = false
): FormConfigType => {
  return [
    {
      name: 'name',
      label: 'Wire Name*',
      placeholder: 'Enter Name',
      validator: REQUIRED_TEXT_FIELD_VALIDATOR,
    },
    {
      name: 'amount',
      label: 'Wire Amount',
      placeholder: 'Enter Amount',
      column: 6,
      validator: AMOUNT_VALIDATOR,
    },
    {
      name: 'currency',
      label: 'Currency',
      type: 'select',
      options: currencyOptions,
      value: currencyOptions[0],
      validator: REQUIRED_OBJECT_VALIDATOR,
      column: 6,
    },
    {
      name: 'expected_payment_date',
      label: 'Expected Payment Date',
      type: 'datepicker',
      placeholder: 'Select Date',
      column: 6,
      validator: STRING_VALIDATOR,
      meta: {
        hasErrorComponent: true,
      },
    },
    // Chain proxy has additional email field
    ...(isChainProxy
      ? [
          {
            name: 'email_proxy',
            label: 'Recipient Proxy Email Address',
            disabled: true,
            validator: REQUIRED_EMAIL_VALIDATOR,
          },
          {
            name: 'email',
            label: 'Recipient Email Address',
            disabled: true,
            validator: REQUIRED_EMAIL_VALIDATOR,
            ...(isDocumentVerificationRequired && {
              meta: {
                labelSlot: 'ID VERIFICATION REQUIRED',
              },
            }),
          },
        ]
      : [
          {
            name: 'email',
            label: 'Email Address',
            disabled: true,
            validator: REQUIRED_EMAIL_VALIDATOR,
            ...(isDocumentVerificationRequired && {
              meta: {
                labelSlot: 'ID VERIFICATION REQUIRED',
              },
            }),
          },
        ]),
    {
      name: 'notes',
      label: 'Notes',
      type: 'textarea',
      placeholder: 'Enter Notes',
      validator: TEXT_FIELD_VALIDATOR,
    },
  ];
};
