import { REPLACE_MULTI_LINE_BREAKS } from '@/const/regExpPatterns';

export const trimString = (text: string, showLetter = 30) => {
  if (text?.length > showLetter) {
    let slicedText = text.slice(0, showLetter);

    slicedText += '...';

    return slicedText;
  }

  return text;
};

export const formatNotes = (text: string) => {
  return text.replace(REPLACE_MULTI_LINE_BREAKS, '\n\n');
};
