import { Box } from '@/components/Box/Box';
import { ReactNode } from 'react';
import { Data, Description, DocumentVerificationItem } from './styles';
import { ReactComponent as SuccessIcon } from '@/images/circleSuccessIcon.svg';

type DocumentVerificationRuleType = {
  rule: string;
  icon: ReactNode;
  requiredCheck?: boolean;
  value?: ReactNode;
};

export const DocumentVerificationRule = ({ icon, rule, requiredCheck, value }: DocumentVerificationRuleType) => {
  return (
    <DocumentVerificationItem>
      <Box display='flex' alignItems='center' columnGap='8px'>
        <div>{icon}</div>
        <Description>{rule}</Description>
      </Box>
      <Box display='flex' alignItems='center' columnGap='8px'>
        {requiredCheck ? (
          <>
            <Data>Checked</Data>
            <SuccessIcon />
          </>
        ) : (
          <Data>{value}</Data>
        )}
      </Box>
    </DocumentVerificationItem>
  );
};
