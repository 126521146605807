import { ChangeEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import {
  autoUpdate,
  offset,
  shift,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import throttle from 'lodash/throttle';
import { Input, Label, LabelWrapper } from '../form/fields/FormikInput/styles';
import {
  AutocompleteWrapper,
  CrossIconWrapper,
  InputWrapper,
  LabelBlock,
  LabelSlotWrapper,
  LoadingIndicator,
} from './styles';
import { InputError } from '../form/fields/InputError/InputError';
import { Loader } from '../Loader/Loader';
import { ResponseErrorType } from '@/types/sharedTypes';
import { useField } from 'formik';
import { getFieldError } from '@/helpers/formHelpers/formHelpers';
import { AutocompleteDropdown } from './ui/AutocompleteDropdown';
import { AutocompleteOptionType } from './types';
import { useEffectOnDependencyChange } from './hooks/useEffectOnDependencyChange';
import { useHasFocusWithin } from './hooks/useHasFocusWithin';
import { ReactComponent as CrossIcon } from '@/components/form/FormikDatePicker/images/CloseIcon.svg';
import { EMAIL } from '@/const/regExpPatterns';

const THROTTLE_DELAY = 800;
const ENTER_KEY = 'Enter';

export type AutocompleteType = {
  name: string;
  options: AutocompleteOptionType[];
  label?: string;
  placeholder?: string;
  isLoading?: boolean;
  disabled?: boolean;
  onSelect: (option: AutocompleteOptionType) => void;
  error?: string;
  apiError?: ResponseErrorType;
  hideError?: boolean;
  zIndex?: number;
  labelSlot?: ReactNode;
};

// Mostly build with Floating UI library using helpers: https://floating-ui.com/docs/useListNavigation
export const Autocomplete = ({
  name,
  options = [],
  onSelect,
  label,
  placeholder,
  isLoading,
  disabled,
  apiError,
  hideError = false,
  zIndex,
  labelSlot,
}: AutocompleteType) => {
  const [field, meta, { setValue, setError, setTouched }] = useField(name);

  const { apiError: apiErrorMessage, error, hasAnyError } = getFieldError(name, meta, apiError);
  const listRef = useRef<Array<HTMLElement | null>>([]);

  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [
      offset(5),
      shift(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
    ],
    open: isOpen,
    onOpenChange: (openState, event, reason) => {
      setIsOpen(openState);

      const validEmail = EMAIL.test(field.value);

      if (!openState && reason === 'outside-press' && validEmail) {
        const matchOption = options.find((option) => field.value === option.label);

        if (!matchOption) return;

        onSelect({
          ...matchOption,
          isSelectedOption: true,
        });
      }
    },
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useRole(context, { role: 'listbox' }),
    useDismiss(context),
    useListNavigation(context, {
      listRef,
      activeIndex,
      onNavigate: setActiveIndex,
      virtual: true,
      loop: true,
    }),
  ]);

  // Create this callback only once to make throttle work
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChange = useCallback(
    throttle((newValue: string) => {
      const option = {
        label: newValue,
        value: newValue,
        isSelectedOption: false,
      };

      onSelect(option);
    }, THROTTLE_DELAY),
    []
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value;

    debounceChange(eventValue);
    setValue(eventValue);

    if (eventValue) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const selectOption = (option: AutocompleteOptionType) => {
    setValue(option.label);
    onSelect({ ...option, isSelectedOption: true });
    setIsOpen(false);

    refs.domReference.current?.focus();
  };

  const setNode = (node, index) => {
    listRef.current[index] = node;
  };

  const keyDownHandle = (event) => {
    // User selects option from dropdown via Enter key
    if (event.key === ENTER_KEY && activeIndex !== null && options[activeIndex]) {
      event.preventDefault();

      const selectedOption = options[activeIndex];

      setValue(selectedOption.label);
      setActiveIndex(null);
      setIsOpen(false);

      onSelect({
        ...selectedOption,
        isSelectedOption: true,
      });
    }
  };

  const clearAutoComplete = () => {
    setValue('');
    onSelect({ label: '', value: '', isSelectedOption: false });
  };

  const labelHasFocusWithin = useHasFocusWithin(refs.domReference);

  useEffectOnDependencyChange(() => {
    if (labelHasFocusWithin) {
      setIsOpen(true);
    }
  }, [labelHasFocusWithin]);

  useEffect(() => {
    if (!!options.length && isOpen) {
      setActiveIndex(0);
    }
  }, [options, isOpen]);

  const hasDropdown = isOpen && !!options.length;
  const hasValue = !!field.value;

  useEffect(() => {
    if (isOpen && hideError) {
      setError(undefined);
      setTouched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, hideError]);

  return (
    <AutocompleteWrapper>
      <LabelBlock>
        {label && (
          <LabelWrapper>
            <Label>{label}</Label>
          </LabelWrapper>
        )}
        {labelSlot && <LabelSlotWrapper>{labelSlot}</LabelSlotWrapper>}
      </LabelBlock>
      <InputWrapper>
        <Input
          {...field}
          type='text'
          placeholder={placeholder}
          aria-autocomplete='list'
          autoComplete='new-field'
          disabled={disabled}
          hasError={hasAnyError}
          onChange={handleChange}
          onBlur={field.onBlur}
          ref={refs.setReference}
          {...getReferenceProps({
            onKeyDown: keyDownHandle,
          })}
          hasValue={hasValue}
        />
        {isLoading && (
          <LoadingIndicator>
            <Loader size='small' background='transparent' />
          </LoadingIndicator>
        )}
        {hasValue && (
          <CrossIconWrapper onClick={clearAutoComplete} variant='medium'>
            <CrossIcon />
          </CrossIconWrapper>
        )}
      </InputWrapper>
      <InputError error={error} apiError={apiErrorMessage} />

      <AutocompleteDropdown
        isOpen={hasDropdown}
        options={options}
        context={context}
        searchValue={field.value}
        activeIndex={activeIndex}
        optionProps={getItemProps()}
        dropdownProps={getFloatingProps({
          ref: refs.setFloating,
          style: {
            ...floatingStyles,
          },
        })}
        onSelect={selectOption}
        onNodeReference={setNode}
        zIndex={zIndex}
      />
    </AutocompleteWrapper>
  );
};
