import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '@/components/Card/Card';
import { dateFormatter } from '@/helpers/formatHelpers';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { getTransactionDetailsUrl } from '../_Details/urls/getTransactionDetailsUrl';
import { TransactionListItemType } from '@/api/v1/organization/contracts/getTransactionsApi';

type TransactionsListType = {
  list: TransactionListItemType[];
};

export const TransactionsList = ({ list }: TransactionsListType) => {
  const navigate = useNavigate();

  const navigateToDetails = (item) => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.TransactionViewDetails);

    navigate(getTransactionDetailsUrl(item.id));
  };

  return (
    <>
      {list.map((item) => {
        const { name, date_created, expected_close_date, created_by, number_of_wires, id } = item;
        const { email } = created_by;

        return (
          <Card key={id} title={name} header={name} onClick={() => navigateToDetails(item)}>
            <CardContent name='Creation Date'>{dateFormatter(date_created)}</CardContent>
            <CardContent name='Expected Closing Date'>{dateFormatter(expected_close_date)}</CardContent>
            <CardContent name='Created by' ratio={2}>
              {email}
            </CardContent>
            <CardContent name='Number of wires'>{number_of_wires}</CardContent>
          </Card>
        );
      })}
    </>
  );
};
