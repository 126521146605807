import { useLocation, useParams } from 'react-router-dom';
import {
  LogoutLink,
  LogoutLinkWrapper,
  LogoutWrapper,
  ProfileSectionTitle,
  SidebarFooter,
  SidebarFooterWrapper,
  UserEmail,
  UserInfo,
  UserType,
} from './styles';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { ReactComponent as LogoutIcon } from '../../images/LogoutIcon.svg';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { useUser } from '@/context/userContext';
import { Box } from '@/components/Box/Box';
import { SwitchRoleButton } from '@/bundle/Sidebar/ui/SwitchRoleButton/SwitchRoleButton';
import { getUserRoleLabel, hasMultipleRoles } from '@/helpers/userHelpers';
import { MenuSection, MenuSectionIcon, MenuSectionLink } from '@/bundle/Sidebar/ui/SidebarMenu/styles';
import { ReactComponent as ProfileIcon } from '@/images/ProfileIcon.svg';
import { ReactComponent as SettingsIcon } from '@/images/SettingsIcon.svg';
import { getProfilePath } from '@/bundle/pages/ProfilePage/urls/getProfileUrl';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import {
  getOpcoUserDetailsUrl,
  getOpcoUserDetailsPath,
} from '@/bundle/_Opco/_OpcoUsersPage/Details/url/getOpcoUserDetailsUrl';
import { getSettingsPath } from '@/bundle/pages/SettingsPage/urls/getSettingsUrl';
import { isActiveRoute } from '../../helpers/helpers';
import { useLogout } from '@/bundle/shared/hooks/useLogout';

interface SidebarUserDetailsType {
  onNavigate: () => void;
}

export const SidebarUserDetails = ({ onNavigate }: SidebarUserDetailsType) => {
  const { mutate: logoutMutate } = useLogout();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { user, currentRole, isOpcoRole, isSiteAdminRole } = useUser();

  const handleLogout = () => {
    logoutMutate();
  };

  const isActiveProfileRoute = () => {
    const currentPath = getProfilePath();
    const isActivePath = isActiveRoute(pathname, [currentPath]);

    // Since Opco users has different profile url - add additional conditions for them
    return isActivePath || (isOpcoRole && isActiveRoute(pathname, [getOpcoUserDetailsPath()]) && user.id === id);
  };

  const email = user?.email;
  const userRole = getUserRoleLabel(currentRole, user);
  const showSwitchRoles = hasMultipleRoles(user);

  const currentUrl = isOpcoRole ? getOpcoUserDetailsUrl(user?.id) : getProfilePath();
  const isActiveProfile = isActiveProfileRoute();
  const settingsPath = getSettingsPath();
  const isActiveSettings = isActiveRoute(pathname, [settingsPath]);

  const navigateToProfile = () => {
    if (currentUrl !== pathname) {
      amplitudeService.logEvent(AMPLITUDE_EVENTS.ProfileStartRedirect);
    }

    onNavigate();
  };

  return (
    <SidebarFooterWrapper>
      <SidebarFooter>
        <Box flexDirection='column' rowGap='20px' width='100%'>
          <UserInfo>
            <UserEmail data-tooltip-id='email-tooltip'>{email}</UserEmail>
            <Tooltip id='email-tooltip' place='top'>
              {email}
            </Tooltip>
            {userRole && <UserType>{userRole}</UserType>}
          </UserInfo>
          {showSwitchRoles && <SwitchRoleButton role={currentRole} />}
        </Box>
      </SidebarFooter>

      <LogoutWrapper>
        {isSiteAdminRole && (
          <MenuSection isActive={isActiveSettings}>
            <MenuSectionLink to={settingsPath} onClick={onNavigate}>
              <MenuSectionIcon>
                <SettingsIcon />
              </MenuSectionIcon>
              <ProfileSectionTitle isActive={isActiveSettings}>Settings</ProfileSectionTitle>
            </MenuSectionLink>
          </MenuSection>
        )}
        <MenuSection isActive={isActiveProfile}>
          <MenuSectionLink to={currentUrl} onClick={navigateToProfile}>
            <MenuSectionIcon>
              <ProfileIcon />
            </MenuSectionIcon>
            <ProfileSectionTitle isActive={isActiveProfile}>Profile</ProfileSectionTitle>
          </MenuSectionLink>
        </MenuSection>
        <LogoutLinkWrapper onClick={handleLogout}>
          <LogoutIcon />
          <LogoutLink>Log out</LogoutLink>
        </LogoutLinkWrapper>
      </LogoutWrapper>
    </SidebarFooterWrapper>
  );
};
