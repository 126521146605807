import { OptionType, Select } from '@/components/Select/Select';
import { Box } from '@/components/Box/Box';
import { ReactComponent as BackButtonIcon } from '../images/BackArrowIcon.svg';
import { ReactComponent as NextButtonIcon } from '../images/NextArrowIcon.svg';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { ButtonWrapper } from '@/components/form/FormikDatePicker/styles';
import {
  getMonthIndex,
  getInitialYearOption,
  getInitialMonthOption,
  monthsOptions,
  yearsOptions,
} from '@/components/form/FormikDatePicker/const';
import { SelectMonthWrapper, SelectYearWrapper } from './styles';

interface DatePickerHeaderType {
  onYearSelect: (value: number) => void;
  onMonthSelect: (value: number) => void;
  onDecreaseMonth: () => void;
  onIncreaseMonth: () => void;
  date: Date;
}

export const DatePickerHeader = ({
  onYearSelect,
  onMonthSelect,
  onDecreaseMonth,
  onIncreaseMonth,
  date,
}: DatePickerHeaderType) => {
  const [selectedMonth, setSelectedMonth] = useState<OptionType>(() => getInitialMonthOption(date));
  const [selectedYear, setSelectedYear] = useState<OptionType>(() => getInitialYearOption(date));

  const onChangeMount = (option: OptionType) => {
    const monthIndex = getMonthIndex(option.value as string);

    onMonthSelect(monthIndex);
  };

  const onChangeYear = (option: OptionType) => {
    onYearSelect(option.value as number);
  };

  useEffect(() => {
    const monthName = format(date, 'LLLL');
    const year = date.getFullYear();

    const selectedMonthOption = monthsOptions.find(({ value }) => value === monthName);
    const selectedYearOption = yearsOptions.find(({ value }) => value === year);

    setSelectedMonth(selectedMonthOption);
    setSelectedYear(selectedYearOption);
  }, [date]);

  return (
    <Box flexDirection='row' columnGap='10px'>
      <ButtonWrapper onClick={onDecreaseMonth}>
        <BackButtonIcon />
      </ButtonWrapper>

      <SelectMonthWrapper>
        <Select
          value={selectedMonth}
          options={monthsOptions}
          onChange={onChangeMount}
          variant='compact'
          hasPortal={false}
        />
      </SelectMonthWrapper>

      <SelectYearWrapper>
        <Select
          value={selectedYear}
          options={yearsOptions}
          onChange={onChangeYear}
          variant='compact'
          hasPortal={false}
        />
      </SelectYearWrapper>

      <ButtonWrapper onClick={onIncreaseMonth}>
        <NextButtonIcon />
      </ButtonWrapper>
    </Box>
  );
};
