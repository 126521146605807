import styled from 'styled-components';
import { flex } from '@/styles/layout';
import { COLORS } from '@/styles/colors';
import { bodySmall, poppinsRegularFont } from '@/styles/typography';
import { media } from '@/styles/responsive';

export const StatusListWrapper = styled.div`
  ${flex()};
  gap: 8px;

  ${media.mobile`
    align-items: flex-start;
    flex-direction: column;
  `}
`;

export const InactiveDepositAccountStatus = styled.div`
  border-radius: 10px;
  padding: 4px 8px;
  width: max-content;
  background-color: ${COLORS.grey[200]};
  color: ${COLORS.grey[600]};
  ${bodySmall};
  ${poppinsRegularFont};
`;
