import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/Button/Button';
import { getLoginUrl } from '@/bundle/Auth/LoginFlow/LoginPage/urls/getLoginUrl';
import { AuthTitle } from '../../ui/AuthTitle/AuthTitle';
import { PasswordRecoverMessagesTextWrapper } from '../ForgotPasswordCheckEmailPage/styles';
import { AuthMessage } from '../../ui/styles';
import { AuthLayout } from '../../ui/AuthLayout/AuthLayout';
import { AuthStep } from '../../ui/AuthStep/AuthStep';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';

export const ForgotPasswordEmailSentPage = () => {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.ForgotPasswordCheckEmailLoginRedirect);

    navigate(getLoginUrl());
  };

  return (
    <AuthLayout>
      <AuthStep>
        <AuthTitle>Please Check Your Email</AuthTitle>
        <PasswordRecoverMessagesTextWrapper>
          <AuthMessage>We've sent a message with the instructions to set your new password.</AuthMessage>
          <AuthMessage>If you do not receive it soon, please check your spam folder.</AuthMessage>
        </PasswordRecoverMessagesTextWrapper>
        <Button onClick={navigateToLogin}>Return to Login</Button>
      </AuthStep>
    </AuthLayout>
  );
};
