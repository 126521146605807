import { COLORS } from '@/styles/colors';
import { bodySmall, poppinsMediumFont, poppinsRegularFont } from '@/styles/typography';
import styled from 'styled-components';
import { flex } from '@/styles/layout';

export const DetailsBlockNotes = styled.div`
  ${flex({ flexDirection: 'column' })};
  border-top: 1px solid ${COLORS.grey[400]};
  padding: 12px 0 0;
  width: 100%;
`;

export const DetailsBlockNotesLabel = styled.div<{ emptyNotes?: boolean }>`
  ${poppinsRegularFont};
  ${bodySmall};
  color: ${COLORS.grey[600]};

  ${({ emptyNotes }) =>
    emptyNotes &&
    `
      ${flex({ justifyContent: 'space-between' })};
  `};
`;

export const DetailsBlockNotesMessage = styled.div`
  color: ${COLORS.black};
  ${poppinsMediumFont};
  ${bodySmall};
  margin-top: 6px;
  word-break: break-word;
  white-space: pre-wrap;
`;

export const EmptyNotes = styled.div`
  margin-left: auto;
  ${poppinsMediumFont};
  color: ${COLORS.black};
`;
