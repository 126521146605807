import { ApiResponseType } from '@/types/sharedTypes';
import { authApiService } from '../../authApiService';
import { ApiParams } from '../../helpers/urlApiHelpers';
import { WiresListResponseType } from '@/types/wireTypes';

type ResultsType = {
  id: string;
  date_created: string;
  contract: any;
  date_modified: string;
  name: string;
  amount: string;
  currency: string;
  status: string;
  expected_payment_date: string;
  ref_id: string;
  created_by: any;
};

export type ResponseType = {
  count: number;
  next: string;
  previous: string;
  results: ResultsType[];
};

export const getExecutorWiresApi = (params?: ApiParams): Promise<ApiResponseType<WiresListResponseType>> => {
  const uri = `/executor/wires/`;

  return authApiService.GET(uri, params);
};
