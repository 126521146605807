import { authApiService } from '@/api/authApiService';
import { CustomerVaultType, DefaultBillingAddressType } from '@/types/paymentTypes';
import { ApiResponseType } from '@/types/sharedTypes';

export type CreateCustomerVaultPayloadType = {
  default_payment?: {
    token?: string;
  };
  default_billing_address: DefaultBillingAddressType;
};

export const createCustomerVaultApi = (
  payload: CreateCustomerVaultPayloadType
): Promise<ApiResponseType<CustomerVaultType>> => {
  const uri = '/payment/customer-vault/';

  return authApiService.POST(uri, payload);
};
