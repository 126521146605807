import { FormConfigType } from '@/helpers/formHelpers/formHelpers';
import { REQUIRED_OBJECT_VALIDATOR, REQUIRED_TEXT_FIELD_VALIDATOR } from '@/helpers/formHelpers/validators';
import countryRegionData from 'country-region-data/dist/data-umd';

type RegionType = {
  name: string;
  shortCode: string;
};

type CountryType = {
  countryName: string;
  countryShortCode: string;
  regions: RegionType[];
};

const countriesData = countryRegionData as CountryType[];
const countriesOptions = countriesData.map((item) => ({
  value: item.countryShortCode,
  label: item.countryName,
}));

const getStatesOptions = (regions: RegionType[]) => {
  return regions.map((item) => ({
    value: item.shortCode,
    label: item.name,
  }));
};

export const getCountryByCode = (code: string) => {
  return countriesData.find((item) => item.countryShortCode === code);
};

export const BILLING_ADDRESS_CONFIG: FormConfigType = [
  {
    options: countriesOptions,
    type: 'select',
    name: 'country',
    label: 'Country*',
    placeholder: 'Select Country',
    validator: REQUIRED_OBJECT_VALIDATOR,
    meta: {
      isSearchable: true,
    },
  },
  {
    name: 'address',
    value: '',
    label: 'Address*',
    placeholder: 'Enter Address',
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
    meta: {
      hasErrorComponent: true,
    },
  },
  {
    options: [],
    type: 'select',
    name: 'state',
    label: 'State/Region',
    placeholder: 'Enter State/Region',
    meta: {
      isSearchable: true,
    },
  },
  {
    value: '',
    name: 'city',
    label: 'City*',
    placeholder: 'Enter City',
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
    column: 7,
  },
  {
    value: '',
    name: 'zip',
    label: 'Zip Code*',
    placeholder: 'Enter Zip Code',
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
    column: 5,
  },
];

export const getBillingAddressConfig = (regions: RegionType[]) => {
  const options = getStatesOptions(regions);

  return BILLING_ADDRESS_CONFIG.map((item) => {
    if (item.name === 'state') {
      return {
        ...item,
        options,
        value: undefined,
      };
    }

    return {
      ...item,
    };
  });
};
