import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { body, bodySmall, poppinsRegularFont } from '@/styles/typography';
import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';

export const RadioGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // Note: box-shadow cropped when radio group used in overflows divs, so we're moving right to avoid this
  margin-left: 1px;
`;

export const RadioGroupTitle = styled.div`
  width: 100%;
  text-align: left;
  margin: 0 0 8px;
  color: ${COLORS.black};
  ${bodySmall};
`;

export const RadioTitle = styled.div<{ isActive: boolean; disabled?: boolean }>`
  width: max-content;
  ${poppinsRegularFont};
  ${body};
  color: ${({ isActive }) => (isActive ? '#27292D' : '#9197A5')};
  margin: 0 0 0 14px;
`;

export const RadioGroupBlockWrapper = styled.div`
  ${flex({ alignItems: 'center' })};
  width: 100%;
  column-gap: 36px;

  ${media.tablet`
    flex-direction: column;
    row-gap: 16px;
  `};
`;

export const CustomRadioInput = styled.input``;

export const FakeRadio = styled.div<{ disabled: boolean }>`
  display: block;
  position: relative;
  width: 20px;
  height: 20px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    border-radius: 50%;
    background-color: ${COLORS.white};
    border: 3px solid ${COLORS.white};
    box-shadow: ${({ disabled }) => `0 0 0 1px ${disabled ? COLORS.grey[600] : COLORS.grey[850]}`};
  }
`;

export const Input = styled.input<{ disabled: boolean }>`
  display: none;

  &:checked + ${FakeRadio} {
    &::after {
      box-shadow: ${({ disabled }) => `0 0 0 1px ${disabled ? COLORS.grey[600] : COLORS.green[500]}`};
      background-color: ${({ disabled }) => (disabled ? COLORS.grey[600] : COLORS.green[500])};
      transition: border 0.15s ease;
    }
  }
`;

export const CustomLabel = styled.label<{ disabled?: boolean }>`
  ${flex({ alignItems: 'center' })};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

  ${media.tablet`
    width: 100%;  
  `}

  &:hover ${Input}:not([disabled], :checked) + ${FakeRadio} {
    &::after {
      transition: background-color 0.2s;
      border: 5px solid ${COLORS.white};
      background-color: ${COLORS.grey[600]};
    }
  }
`;
