import { ApiResponseType, AuthResponseType } from '@/types/sharedTypes';
import { authApiService } from '@/api/authApiService';

export type PersonalDetailsVerificationPayload = {
  first_name: string;
  middle_name?: string;
  last_name: string;
};

export const sendPersonalDetailsVerificationApi = async (
  payload: PersonalDetailsVerificationPayload
): Promise<ApiResponseType<AuthResponseType>> => {
  const uri = '/users/auth/name:verify/';

  return authApiService.POST(uri, payload);
};
