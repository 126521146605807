import { useState } from 'react';
import { Input, InputType } from '../Input/Input';
import { EyeSvgWrapper } from './styles';
import { ReactComponent as EyeInputActive } from './images/EyeInputActive.svg';
import { ReactComponent as EyeInput } from './images/EyeInput.svg';

type InputPasswordType = InputType;

export const InputPassword = ({ hasToggleEye, ...rest }: InputPasswordType) => {
  const [showPassword, setShowPassword] = useState(false);

  const changePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const type = showPassword ? 'text' : 'password';

  return (
    <Input type={type} hasToggleEye={hasToggleEye} {...rest}>
      {hasToggleEye && (
        <EyeSvgWrapper onClick={changePasswordVisibility}>
          {showPassword ? <EyeInputActive /> : <EyeInput />}
        </EyeSvgWrapper>
      )}
    </Input>
  );
};
