import { Line1, Line2, Line3, MenuButton } from './styles';

type HamburgerButtonType = {
  isOpen: boolean;
  onToggle: () => void;
};

export const HamburgerButton = ({ isOpen, onToggle }: HamburgerButtonType) => {
  return (
    <MenuButton isOpen={isOpen} onClick={onToggle}>
      <Line1 />
      <Line2 />
      <Line3 />
    </MenuButton>
  );
};
