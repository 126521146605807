import { COLORS } from '@/styles/colors';
import { poppinsMediumFont } from '@/styles/typography';
import styled from 'styled-components';

export const MoreSelectedBadge = styled.div`
  color: ${COLORS.black};
  background-color: ${COLORS.grey[400]};
  border-radius: 4px;
  padding: 2px 6px;
  ${poppinsMediumFont}
`;

export const ClearIndicarorWrapper = styled.div`
  padding: 10px;
`;
