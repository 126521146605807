import { completeRegistrationApi } from '@/api/v1/users/completeRegistrationApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const completeRegistration = async () => {
  const payload = {
    agree_with_condition: true,
  };

  try {
    const response = await completeRegistrationApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
