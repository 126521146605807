import { TransactionType } from '@/types/wireTypes';
import { ApiResponseType } from '@/types/sharedTypes';
import { authApiService } from '@/api/authApiService';

export const updateTransactionApi = async (
  id: string,
  payload: TransactionType
): Promise<ApiResponseType<TransactionType>> => {
  const uri = `/organization/contracts/${id}/`;

  return authApiService.PATCH(uri, payload);
};
