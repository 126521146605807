import { dateFormatter, formatBlockchainId, phoneFormatter } from '@/helpers/formatHelpers';
import { isDomesticPaymentType } from '@/helpers/paymentDetailsHelpers';
import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';

const NO_VALUE_SEPARATOR = '-';

export const getPaymentDetailsData = (paymentDetails: PaymentDetailsGeneralType) => {
  if (!paymentDetails) return {};

  const {
    blockchain_id,
    blockchain_payment_details_data,
    internal_payment_details_data,
    is_blockchain_payment_details_data_from_bc,
  } = paymentDetails;

  const {
    bank_aba_number,
    bank_swift_code,
    bank_account_number,
    intermediary_bank_aba_number,
    intermediary_bank_swift_code,
    intermediary_bank_account_number,
  } = blockchain_payment_details_data;

  const {
    recipient_name,
    recipient_address,
    account_name,
    payment_type,
    bank_name,
    bank_address,
    bank_phone_number,
    intermediary_bank_name,
    intermediary_bank_address,
    intermediary_bank_phone_number,
    date_created,
  } = internal_payment_details_data;

  const isDomesticPayment = isDomesticPaymentType(payment_type);
  const bankCodeLabel = isDomesticPayment ? 'Routing / ABA Number' : 'Swift Code';
  const bankCodeValue = isDomesticPayment ? bank_aba_number : bank_swift_code;
  const intermediaryBankCodeValue = isDomesticPayment ? intermediary_bank_aba_number : intermediary_bank_swift_code;

  return {
    isDataFromBlockchain: is_blockchain_payment_details_data_from_bc,
    blockchainId: blockchain_id,
    formattedBlockchainId: formatBlockchainId(blockchain_id),
    bankCodeLabel,
    bankCodeValue,
    recipientName: recipient_name || NO_VALUE_SEPARATOR,
    recipientAddress: recipient_address || NO_VALUE_SEPARATOR,
    accountName: account_name || NO_VALUE_SEPARATOR,
    bankName: bank_name || NO_VALUE_SEPARATOR,
    bankAccountName: bank_account_number || NO_VALUE_SEPARATOR,
    bankAddress: bank_address || NO_VALUE_SEPARATOR,
    bankPhoneNumber: phoneFormatter(bank_phone_number),
    dateCreated: dateFormatter(date_created),
    intermediaryBankCodeValue,
    intermediaryBankName: intermediary_bank_name || NO_VALUE_SEPARATOR,
    intermediaryAccountName: intermediary_bank_account_number || NO_VALUE_SEPARATOR,
    intermediaryBankAddress: intermediary_bank_address || NO_VALUE_SEPARATOR,
    intermediaryBankPhoneNumber: phoneFormatter(intermediary_bank_phone_number),
  };
};
