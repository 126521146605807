import { Box } from '@/components/Box/Box';
import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import { getPaymentDetailsData } from '../helpers';

type GeneralPaymentDetailsType = {
  paymentDetails: PaymentDetailsGeneralType;
};

export const GeneralPaymentDetails = ({ paymentDetails }: GeneralPaymentDetailsType) => {
  if (!paymentDetails) return;

  const {
    blockchainId,
    formattedBlockchainId,
    recipientName,
    recipientAddress,
    accountName,
    bankName,
    bankAccountName,
    bankAddress,
    bankPhoneNumber,
    bankCodeLabel,
    bankCodeValue,
    isDataFromBlockchain,
  } = getPaymentDetailsData(paymentDetails);

  return (
    <Box>
      <DetailsRow name='Blockchain ID' title={blockchainId}>
        {formattedBlockchainId}
      </DetailsRow>
      <DetailsRow name='Recipient'>{recipientName}</DetailsRow>
      <DetailsRow name='Recipient Address'>{recipientAddress}</DetailsRow>
      <DetailsRow name='Account Name'>{accountName}</DetailsRow>
      <DetailsRow name={bankCodeLabel} hasHighlight={isDataFromBlockchain}>
        {bankCodeValue}
      </DetailsRow>
      <DetailsRow name='Bank Name'>{bankName}</DetailsRow>
      <DetailsRow name='Account Number' hasHighlight={isDataFromBlockchain}>
        {bankAccountName}
      </DetailsRow>
      <DetailsRow name='Bank Address'>{bankAddress}</DetailsRow>
      <DetailsRow name='Bank Phone Number'>{bankPhoneNumber}</DetailsRow>
    </Box>
  );
};
