import { authApiService } from '@/api/authApiService';
import { ApiParams } from '@/api/helpers/urlApiHelpers';
import { ApiResponseType, ListResponseType } from '@/types/sharedTypes';

type CreatedByType = {
  id: string;
  email: string;
};

export type TransactionListItemType = {
  id: string;
  date_created: string;
  date_modified: string;
  name: string;
  status: string;
  ref_id: string;
  expected_close_date: string;
  created_by: CreatedByType;
  number_of_wires: 0;
};

export const getTransactionsApi = async (
  params?: ApiParams
): Promise<ApiResponseType<ListResponseType<TransactionListItemType>>> => {
  const uri = '/organization/contracts/';

  return authApiService.GET(uri, params);
};
