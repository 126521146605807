import { EditUserForm } from '@/bundle/shared/components/EditUserForm/EditUserForm';
import { AssignExecutorForm } from './AssignExecutorForm/AssignExecutorForm';
import { USER_ROLE_MAP } from '@/const/user';
import { AssignedWireType } from '@/types/wireTypes';

type ExecutorActionsWidgetType = {
  wire: AssignedWireType;
  onRefetchWire: () => void;
  isShowAssignForm: boolean;
  isShowEditForm: boolean;
  onCloseAssignForm: () => void;
  onCloseEditForm: () => void;
};

export const ExecutorActionsWidget = ({
  wire,
  isShowAssignForm,
  isShowEditForm,
  onCloseAssignForm,
  onCloseEditForm,
  onRefetchWire,
}: ExecutorActionsWidgetType) => {
  const isEditMode = wire?.assigned_executor?.user?.date_verified !== undefined;

  const createOrAssignExecutor = () => {
    onCloseAssignForm();
    onRefetchWire();
  };

  return (
    <>
      {isShowAssignForm && !isEditMode && (
        <AssignExecutorForm
          onCreateExecutor={createOrAssignExecutor}
          onAssignExecutor={createOrAssignExecutor}
          onClose={onCloseAssignForm}
        />
      )}

      {isShowEditForm && isEditMode && (
        <EditUserForm
          header='Edit Executor'
          role={USER_ROLE_MAP.EXECUTOR}
          user={wire?.assigned_executor?.user}
          wireId={wire?.id}
          onClose={onCloseEditForm}
          onSuccess={onRefetchWire}
        />
      )}
    </>
  );
};
