import { addPaymentMethodApi } from '@/api/v1/payments/addPaymentMethodApi';
import { deletePaymentMethodApi } from '@/api/v1/payments/deletePaymentMethodApi';
import { getPaymentPlansApi } from '@/api/v1/payments/getPaymentPlansApi';
import { editBillingAddressApi } from '@/api/v1/payments/editBillingAddressApi';
import { getCustomerVaultApi } from '@/api/v1/payments/getCustomerVaultApi';
import {
  setDefaultBillingAddressApi,
  setDefaultBillingAddressPayloadType,
} from '@/api/v1/payments/setDefaultBillingAddressApi';
import { setDefaultPaymentMethodApi } from '@/api/v1/payments/setDefaultPaymentMethodApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import {
  CreateSubscriptionPayloadType,
  DefaultBillingAddressType,
  DefaultPaymentMethodType,
} from '@/types/paymentTypes';
import { createSubscriptionApi } from '@/api/v1/payments/createSubscriptionApi';
import { getPaymentTransactionsApi } from '@/api/v1/payments/getPaymentTransactionsApi';
import { createCustomerVaultApi } from '@/api/v1/payments/createCustomerVaultApi';
import { CreateCustomerVaultPayloadType } from '../../../../api/v1/payments/createCustomerVaultApi';
import { getPaymentPlanByIdApi } from '@/api/v1/payments/getPaymentPlanByIdApi';
import { getSubscriptionsApi } from '@/api/v1/payments/getSubscribtionsApi';

export const loadCustomerVault = async () => {
  try {
    const response = await getCustomerVaultApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const createCustomerVault = async (
  billingAddress: CreateCustomerVaultPayloadType['default_billing_address'],
  token?: string
) => {
  const payload = {
    default_billing_address: billingAddress,
    ...(token && {
      default_payment: {
        token,
      },
    }),
  };

  try {
    const response = await createCustomerVaultApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const addPaymentMethod = async (token: string) => {
  const payload = {
    token,
  };

  try {
    const response = await addPaymentMethodApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const deletePaymentMethod = async (payload: DefaultPaymentMethodType) => {
  try {
    const response = await deletePaymentMethodApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const setDefaultPaymentMethod = async (payload: DefaultPaymentMethodType) => {
  try {
    const response = await setDefaultPaymentMethodApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const editBillingAddress = async (addressId: string, payload: DefaultBillingAddressType) => {
  try {
    const response = await editBillingAddressApi(addressId, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const setDefaultBillingAddress = async (payload: setDefaultBillingAddressPayloadType) => {
  try {
    const response = await setDefaultBillingAddressApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadPaymentPlans = async () => {
  try {
    const response = await getPaymentPlansApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const getPaymentPlanById = async (planId) => {
  try {
    const response = await getPaymentPlanByIdApi(planId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const createSubscription = async (payload: CreateSubscriptionPayloadType) => {
  try {
    const response = await createSubscriptionApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const getPaymentTransactions = async () => {
  try {
    const response = await getPaymentTransactionsApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const getSubscriptions = async () => {
  try {
    const response = await getSubscriptionsApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
