import { authApiService } from '../../../authApiService';
import { ApiResponseType } from '@/types/sharedTypes';
import { WireType } from '@/types/wireTypes';

export type WireInitialType = {
  name: string;
  amount?: string;
  currency: string;
  expected_payment_date?: string;
  type: string;
};

export const createWireApi = async (contractId: string, data: WireInitialType): Promise<ApiResponseType<WireType>> => {
  const uri = `/organization/contracts/${contractId}/wires/`;

  return authApiService.POST(uri, data);
};
