import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import { bodySmall, poppinsRegularFont } from '@/styles/typography';
import styled from 'styled-components';

export const PaymentDetailsSubtitle = styled.div`
  ${poppinsRegularFont};
  ${bodySmall};
  color: ${COLORS.grey[600]};
  margin-bottom: 28px;
`;

export const RecipientHeadlineWrapper = styled.div`
  ${flex({ alignItems: 'center' })}
  column-gap: 12px;
  ${bodySmall};
  font-weight: 600;
`;
