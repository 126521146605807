import { getSearchUsersApi, SearchRoleType } from '@/api/v1/organization/users/getSearchUsersApi';
import { getUserInfoApi } from '@/api/v1/organization/users/getUserInfoApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { sendUpsellRequestApi } from '@/api/v1/marketing/sendUpsellRequestApi';
import { logoutApi } from '@/api/v1/users/logoutApi';

export const searchUsers = async (search: string, role: SearchRoleType) => {
  const payload = {
    search,
    role,
  };

  try {
    const response = await getSearchUsersApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadUserInfo = async (userId: string) => {
  try {
    const response = await getUserInfoApi(userId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const sendUpsellRequest = async () => {
  try {
    const response = await sendUpsellRequestApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const logout = async () => {
  try {
    const response = await logoutApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
