import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import { GeneralPaymentDetails } from './ui/GeneralPaymentDetails';
import { IntermediaryPaymentDetails } from './ui/IntermediaryPaymentDetails';
import { Box } from '@/components/Box/Box';
import { hasIntermediaryPaymentDetails } from '@/helpers/paymentDetailsHelpers';

type PaymentDetailsDataType = {
  paymentDetails: PaymentDetailsGeneralType;
};

export const PaymentDetailsData = ({ paymentDetails }: PaymentDetailsDataType) => {
  if (!paymentDetails) return;

  const hasIntermediaryBank = hasIntermediaryPaymentDetails(paymentDetails);

  return (
    <Box>
      <GeneralPaymentDetails paymentDetails={paymentDetails} />
      {hasIntermediaryBank && <IntermediaryPaymentDetails paymentDetails={paymentDetails} />}
    </Box>
  );
};
