import { Loader } from '@/components/Loader/Loader';
import { LoadPaymentDetailsRow } from './styles';

export const LoadPaymentDetailsLoading = () => {
  return (
    <LoadPaymentDetailsRow>
      <Loader background='transparent' inline />
      <span>Checking with the WireVault blockchain.</span>
    </LoadPaymentDetailsRow>
  );
};
