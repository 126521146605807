import React, { useEffect, useState } from 'react';
import { Sidebar } from '../Sidebar/Sidebar';
import { BlankLayout } from './BlankLayout';
import { MainLayoutContainer, MainLayoutContent } from './styles';
import { MobileHeader } from '../Sidebar/ui/MobileHeader/MobileHeader';
import { useResponsiveDevice } from '@/hooks/useResponsiveDevice';
import { useLockedScroll } from '@/hooks/useLockedScroll';

type MainLayoutType = {
  children?: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutType) => {
  const [siderbarOpen, setSidebarOpen] = useState(false);
  const { isDesktop } = useResponsiveDevice();
  const [, setLocked] = useLockedScroll();

  useEffect(() => {
    setLocked(!!siderbarOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siderbarOpen]);

  const toggleSidebar = () => {
    setSidebarOpen((opened) => !opened);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const sidebarNavigate = () => {
    if (isDesktop) return;

    closeSidebar();
  };

  return (
    <BlankLayout isLoading={false}>
      <MainLayoutContainer>
        <MobileHeader isOpen={siderbarOpen} onSidebarToggle={toggleSidebar} />
        <Sidebar isOpen={siderbarOpen} onNavigate={sidebarNavigate} onClose={closeSidebar} />
        <MainLayoutContent id='main'>{children}</MainLayoutContent>
      </MainLayoutContainer>
    </BlankLayout>
  );
};
