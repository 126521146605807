import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { SelectedPriceCrossedOut } from '../styles';
import { convertCentsToDollars } from '@/helpers/formatHelpers';

type SelectedPriceType = {
  isYearlyFrequency: boolean;
  monthlyPrice: number;
  yearlyPrice: number;
};

export const SelectedPrice = ({ isYearlyFrequency, monthlyPrice, yearlyPrice }: SelectedPriceType) => {
  const convertedMonthlyPrice = convertCentsToDollars(monthlyPrice);
  const convertedYearlyPrice = convertCentsToDollars(yearlyPrice);
  const price = isYearlyFrequency ? convertedYearlyPrice : convertedMonthlyPrice;

  return (
    <Box display='flex' columnGap={4} alignItems='center'>
      {isYearlyFrequency && monthlyPrice !== 0 && (
        <SelectedPriceCrossedOut>{convertedMonthlyPrice}</SelectedPriceCrossedOut>
      )}
      <Typography variant='bodySmall' fontWeight='semibold'>
        {price}
      </Typography>
    </Box>
  );
};
