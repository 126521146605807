import { Box } from '@/components/Box/Box';
import { ReactNode } from 'react';
import { HeaderMessage } from './styles';

type PaymentDetailsHeaderSlotType = {
  header: ReactNode;
  icon?: ReactNode;
  meta?: ReactNode;
};

export const PaymentDetailsHeaderSlot = ({ icon, header, meta }: PaymentDetailsHeaderSlotType) => {
  return (
    <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
      <Box display='flex' columnGap='12px' alignItems='center'>
        {icon}
        {header && <HeaderMessage>{header}</HeaderMessage>}
      </Box>
      {meta}
    </Box>
  );
};
