import { authApiService } from '../../authApiService';
import { ApiResponseType, ForgotPasswordResponseType } from '@/types/sharedTypes';

export type ForgotPasswordTwoFAPayload = {
  code: string;
};

export const forgotPasswordTwoFAApi = async (
  data: ForgotPasswordTwoFAPayload
): Promise<ApiResponseType<ForgotPasswordResponseType>> => {
  const uri = `/users/forgot-password/2fa/`;

  return authApiService.POST(uri, data);
};
