import { AssignedWireType } from '@/types/wireTypes';
import { ApiResponseType } from '@/types/sharedTypes';
import { authApiService } from '@/api/authApiService';

export type AssignExecutorPayloadType = {
  user_id: string;
};

export const assignExecutorApi = (
  wireId: string,
  data: AssignExecutorPayloadType
): Promise<ApiResponseType<AssignedWireType>> => {
  const uri = `/organization/wires/${wireId}/executor/assign/`;

  return authApiService.POST(uri, data);
};
