import { COLORS } from '@/styles/colors';
import { body, bodySmall, poppinsRegularFont } from '@/styles/typography';
import styled from 'styled-components';
import { ReactComponent as ConfirmInputTicks } from '../../../../images/ConfirmInputTicks.svg';
import { flex } from '@/styles/layout';

export type InputSizeVariant = 'small' | 'medium';

export const CustomInput = styled.input<{
  hasError?: boolean;
  disabled?: boolean;
  hasConfirmTicks?: boolean;
  hasToggleEye?: boolean;
  variant?: InputSizeVariant;
}>`
  position: relative;
  display: block;
  width: 100%;
  color: ${COLORS.black};
  border: ${({ hasError }) => (hasError ? `1px solid ${COLORS.red[600]}` : `1px solid ${COLORS.grey[400]}`)};
  outline: none;
  ${body};
  padding: 13px 16px;
  border-radius: 10px;
  height: 50px;
  background: ${COLORS.grey[250]};
  ${poppinsRegularFont};

  ${({ hasConfirmTicks, hasToggleEye }) =>
    (hasConfirmTicks || hasToggleEye) &&
    `
    padding-right: 46px;
  `};

  ${({ hasConfirmTicks, hasToggleEye }) =>
    hasConfirmTicks &&
    hasToggleEye &&
    `
    padding-right: 76px;
  `};

  &[type='password'] {
    font-size: 22px;
    letter-spacing: 1px;
  }

  &:hover {
    border: 1px solid ${COLORS.grey[700]};
    background: ${COLORS.grey[250]};
  }

  &:focus {
    border: 1px solid ${COLORS.green[500]};
    box-shadow: 0 0 2px rgb(0 134 111 / 60%);
    background: ${COLORS.white};
    color: #031020;
  }

  &:disabled {
    border: 1px solid ${COLORS.grey[300]};
    background-color: ${COLORS.grey[200]};
  }

  &:placeholder-shown {
    ${body};
  }

  ::placeholder {
    ${body};
    color: ${COLORS.grey[600]};
    font-family: ${poppinsRegularFont};
  }

  :input-placeholder,
  ::input-placeholder {
    ${body};
    color: ${COLORS.grey[600]};
    font-family: ${poppinsRegularFont};
  }

  ${({ variant }) =>
    variant === 'small' &&
    `
    height: 40px;
    ${bodySmall};

    &:placeholder-shown {
    ${bodySmall};
    }

    ::placeholder {
    ${bodySmall};
    }

    :input-placeholder,
    ::input-placeholder {
    ${bodySmall};
    }
  `};
`;

export const ConfirmationTicks = styled(ConfirmInputTicks)`
  position: absolute;
  top: 15px;
  right: 16px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  bottom: 15px;
  ${flex({ alignItems: 'center' })};
  cursor: pointer;
  right: 18px;
`;
