import { ReactNode } from 'react';
import { AuthBody, AuthHeader, AuthPage, LinkWrapper } from './styles';
import { BlankLayout } from '@/bundle/Layouts/BlankLayout';
import { ReactComponent as LogoIcon } from '@/images/Logo.svg';
import { ExternalLink } from '@/components/ExternalLink/ExternalLink';
import { envConfig } from '@/config/envConfig';

export type AuthLayoutPosition = 'center' | 'top';

type AuthLayoutType = {
  banner?: ReactNode;
  isLoading?: boolean;
  children?: ReactNode;
  position?: AuthLayoutPosition;
};

export const AuthLayout = ({ isLoading, banner, children, position = 'center' }: AuthLayoutType) => {
  return (
    <BlankLayout isLoading={isLoading}>
      <AuthPage>
        <AuthHeader>
          <LinkWrapper>
            <ExternalLink href={envConfig.LANDING_URL} target={'_blank'}>
              <LogoIcon />
            </ExternalLink>
          </LinkWrapper>
        </AuthHeader>
        {banner}
        <AuthBody position={position}>{children}</AuthBody>
      </AuthPage>
    </BlankLayout>
  );
};
