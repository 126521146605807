import { ReactNode } from 'react';
import { ContentHeader } from './ContentHeader/ContentHeader';
import { ContentLayoutWrapper, ContentWrapper, BannerWrapper } from './styles';

type ContentLayoutType = {
  title: string;
  subtitle?: ReactNode;
  children?: ReactNode;
  headerActionMessage?: ReactNode;
  headerAction?: ReactNode;
  backButton?: string;
  onBack?: () => void;
  banner?: ReactNode;
};

export const ContentLayout = ({
  title,
  subtitle,
  children,
  headerActionMessage,
  headerAction,
  backButton,
  onBack,
  banner,
}: ContentLayoutType) => {
  return (
    <>
      {banner && <BannerWrapper>{banner}</BannerWrapper>}

      <ContentLayoutWrapper>
        <ContentHeader
          title={title}
          subtitle={subtitle}
          headerActionMessage={headerActionMessage}
          headerAction={headerAction}
          backButton={backButton}
          onBack={onBack}
        />
        <ContentWrapper>{children}</ContentWrapper>
      </ContentLayoutWrapper>
    </>
  );
};
