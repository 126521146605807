import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { WIRE_STATUS_DB } from '@/const/wire';
import { getDepositorAssignmentStatusApi } from '@/api/v1/organization/getDepositorAssignmentStatusApi';
import { getDepositAccountPaymentDetailsApi } from '@/api/v1/organization/deposit-accounts/getDepositAccountPaymentDetailsApi';
import { updateDepositorApi } from '@/api/v1/organization/wires/updateDepositorApi';
import { getDepositAccountsIdsApi } from '@/api/v1/organization/deposit-accounts/getDepositAccountsIdsApi';
import { assignDepositorApi } from '@/api/v1/organization/wires/assignDepositorApi';
import { createDepositorApi } from '@/api/v1/organization/wires/createDepositorApi';
import { updateWireApi } from '@/api/v1/organization/wires/updateWireApi';
import { getWireApi } from '@/api/v1/organization/wires/getWireApi';
import { changeWireStatusApi } from '@/api/v1/organization/wires/changeWireStatusApi';
import { updateWireDepositAccountPaymentDetailsApi } from '@/api/v1/organization/deposit-accounts/updateWireDepositAccountPaymentDetailsApi';
import { getPaymentDetailsApi } from '@/api/v1/organization/wires/getPaymentDetailsApi';
import { deletePaymentDetailsApi } from '@/api/v1/organization/wires/deletePaymentDetailsApi';

// Wire APIs
export const loadInboundWire = async (id: string) => {
  try {
    const response = await getWireApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const updateInboundWire = async (id: string, payload) => {
  try {
    const response = await updateWireApi(id, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const changeWireStatus = async (id: string, status: typeof WIRE_STATUS_DB.CANCELLED) => {
  const payload = {
    status,
  };

  try {
    const response = await changeWireStatusApi(id, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

// Deposit Account APIs
export const loadDepositAccountPaymentDetails = async (id: string) => {
  try {
    const response = await getDepositAccountPaymentDetailsApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadDepositAccountsIds = async () => {
  try {
    const response = await getDepositAccountsIdsApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const assignDepositor = async (id: string, userId: string) => {
  const payload = {
    user_id: userId,
  };

  try {
    const response = await assignDepositorApi(id, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const createDepositor = async (id: string, payload) => {
  try {
    const response = await createDepositorApi(id, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const updateDepositor = async (id: string, payload) => {
  try {
    const response = await updateDepositorApi(id, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

// Payment details APIs
export const loadPaymentDetails = async (id: string) => {
  try {
    const response = await getPaymentDetailsApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const updateWirePaymentDetails = async (id: string, depositId: string, payload) => {
  try {
    const response = await updateWireDepositAccountPaymentDetailsApi(id, depositId, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const resetPaymentDetails = async (id: string) => {
  try {
    const response = await deletePaymentDetailsApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const depositorAssignmentStatus = async (userId: string) => {
  try {
    const response = await getDepositorAssignmentStatusApi(userId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
