import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

export type ResponseType = {
  id: string;
  bank_phone_number: string;
  intermediary_bank_phone_number: string;
  date_created: string;
  date_modified: string;
  recipient_name: string;
  account_name: string;
  bank_aba_number: string;
  bank_swift_code: string;
  bank_name: string;
  bank_account_number: string;
  bank_address: string;
  intermediary_bank_aba_number: string;
  intermediary_bank_swift_code: string;
  intermediary_bank_name: string;
  intermediary_bank_account_number: string;
  intermediary_bank_address: string;
};

export type PayloadType = {
  bank_phone_number: string;
  recipient_name: string;
  account_name: string;
  bank_name: string;
  bank_address: string;
  intermediary_bank_name?: string;
  intermediary_bank_address?: string;
  intermediary_bank_phone_number?: string;
};

export const updateWireDepositAccountPaymentDetailsApi = (
  wireId: string,
  depositAccountId: string,
  data: PayloadType
): Promise<ApiResponseType<ResponseType>> => {
  const uri = `/organization/wires/${wireId}/deposit-accounts/${depositAccountId}/payment-details/`;

  return authApiService.POST(uri, data);
};
