import { authApiService } from '@/api/authApiService';
import { ApiResponseType, RequestOptions } from '@/types/sharedTypes';
import { OrganizationUserType } from '@/bundle/_Opco/types/types';
import { OrgAdminRoleType } from '@/const/user';

export type EditOpcoOrganizationUserRole = {
  role: OrgAdminRoleType;
};

export const editOrganizationUserRoleApi = async (
  organizationId: string,
  userId: string,
  payload: EditOpcoOrganizationUserRole,
  options?: RequestOptions
): Promise<ApiResponseType<OrganizationUserType>> => {
  const uri = `/opco/organizations/${organizationId}/users/${userId}/role/`;

  return authApiService.PATCH(uri, payload, null, options);
};
