import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import { bodySmall, poppinsRegularFont } from '@/styles/typography';
import styled from 'styled-components';

export const CustomValidationWrapper = styled.div`
  ${flex({ flexDirection: 'column' })};
  width: 100%;
  margin: 0 0 20px;
`;

export const CustomValidationRow = styled.div`
  ${flex({ alignItems: 'center' })};
  width: 100%;
`;

export const IconBlock = styled.div``;

export const MessageBlock = styled.div`
  color: ${COLORS.black};
  ${poppinsRegularFont};
  ${bodySmall};
  margin: 0 0 0 8px;
`;
