import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';

export const DEPOSIT_ACCOUNT_MAP = {
  DOMESTIC_WITHOUT_INTERMEDIARY: 'domestic_without_intermediary',
  DOMESTIC_WITH_INTERMEDIARY: 'domestic_with_intermediary',
  INTERNATIONAL_WITHOUT_INTERMEDIARY: 'international_without_intermediary',
  INTERNATIONAL_WITH_INTERMEDIARY: 'international_with_intermediary',
};

export const CREATE_DEPOSIT_ACCOUNT_REDIRECT = {
  [DEPOSIT_ACCOUNT_MAP.DOMESTIC_WITHOUT_INTERMEDIARY]:
    AMPLITUDE_EVENTS.CreateDomesticDepositAccountWithoutIntermediaryRedirect,
  [DEPOSIT_ACCOUNT_MAP.DOMESTIC_WITH_INTERMEDIARY]:
    AMPLITUDE_EVENTS.CreateDomesticDepositAccountWithIntermediaryRedirect,
  [DEPOSIT_ACCOUNT_MAP.INTERNATIONAL_WITHOUT_INTERMEDIARY]:
    AMPLITUDE_EVENTS.CreateInternationalDepositAccountWithoutIntermediaryRedirect,
  [DEPOSIT_ACCOUNT_MAP.INTERNATIONAL_WITH_INTERMEDIARY]:
    AMPLITUDE_EVENTS.CreateInternationalDepositAccountWithIntermediaryRedirect,
};

export const CREATE_DEPOSIT_ACCOUNT_SUCCESS = {
  [DEPOSIT_ACCOUNT_MAP.DOMESTIC_WITHOUT_INTERMEDIARY]: AMPLITUDE_EVENTS.CreateDomesticAccountWithoutIntermediarySuccess,
  [DEPOSIT_ACCOUNT_MAP.DOMESTIC_WITH_INTERMEDIARY]: AMPLITUDE_EVENTS.CreateDomesticAccountWithIntermediarySuccess,
  [DEPOSIT_ACCOUNT_MAP.INTERNATIONAL_WITHOUT_INTERMEDIARY]:
    AMPLITUDE_EVENTS.CreateInternationalAccountWithoutIntermediarySuccess,
  [DEPOSIT_ACCOUNT_MAP.INTERNATIONAL_WITH_INTERMEDIARY]:
    AMPLITUDE_EVENTS.CreateInternationalAccountWithIntermediarySuccess,
};

export const getDepositAccountAmplitudeEvent = (
  isDomesticPayment: boolean,
  isInternationalPayment: boolean,
  isOpenIntermediaryBank: boolean
) => {
  if (isDomesticPayment && !isOpenIntermediaryBank) return DEPOSIT_ACCOUNT_MAP.DOMESTIC_WITHOUT_INTERMEDIARY;

  if (isDomesticPayment && isOpenIntermediaryBank) return DEPOSIT_ACCOUNT_MAP.DOMESTIC_WITH_INTERMEDIARY;

  if (isInternationalPayment && !isOpenIntermediaryBank) return DEPOSIT_ACCOUNT_MAP.INTERNATIONAL_WITHOUT_INTERMEDIARY;

  if (isInternationalPayment && isOpenIntermediaryBank) return DEPOSIT_ACCOUNT_MAP.INTERNATIONAL_WITH_INTERMEDIARY;
};
