import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { forgotPasswordTwoFAApi } from '@/api/v1/users/forgotPasswordTwoFAApi';
import { sendForgotPasswordTwoFACodeApi } from '@/api/v1/users/sendForgotPasswordTwoFACodeApi';

export const sendForgotPasswordTwoFA = async (code: string) => {
  const payload = {
    code,
  };

  try {
    const response = await forgotPasswordTwoFAApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const resendForgotPasswordTwoFACode = async () => {
  try {
    const response = await sendForgotPasswordTwoFACodeApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
