import { useEffect, useState } from 'react';
import { USER_ROLE_MAP } from '@/const/user';
import { authTokenService } from '@/bundle/Auth/LoginFlow/LoginPage/service/authTokenService';
import { decodeToken } from '@/helpers/tokenHelpers';
import { AccessTokenType } from '@/types/sharedTypes';

export const useHasOrgAdminRole = () => {
  const [hasOrgAdminRole, setHasOrgAdminRole] = useState(null);

  useEffect(() => {
    const tokens = authTokenService.getTokens();

    if (!tokens || !tokens?.access) return;

    const { existing_roles } = decodeToken<AccessTokenType>(tokens?.access);

    setHasOrgAdminRole(existing_roles?.includes(USER_ROLE_MAP.ORGANIZATION));
  }, []);

  return { hasOrgAdminRole };
};
