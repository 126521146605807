import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { resetInternalRequestFromEmailApi } from '@/api/v1/opco/internal/resetInternalRequestFromEmailApi';

export const resetRequestFromEmail = async (userId: string, token: string) => {
  try {
    const response = await resetInternalRequestFromEmailApi(userId, token);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
