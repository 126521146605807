// Used by backend team to redirect endpoints to local environment
const BACKEND_LOCALHOST = 'localhost:3080';

export const isBackendLocalhost = document.location.host === BACKEND_LOCALHOST;

export const mainCfg = {
  BASE_URL_PREFIX: '',
  API_URL: process.env.REACT_APP_API_URL,
  RECAPCHA_SITE_KEY: process.env.REACT_APP_RECAPCHA_SITE_KEY,
  AMPLITUDE_API_KEY: process.env.REACT_APP_AMPLITUDE_API_KEY,
  LANDING_URL: process.env.REACT_APP_LANDING_URL,
  TERMS_AND_CONDITIONS_URL: process.env.REACT_APP_TERMS_AND_CONDITIONS_URL,
  RELEASE_VERSION: process.env.REACT_APP_RELEASE_VERSION,
  BUILD_VERSION: process.env.REACT_APP_BITBUCKET_BUILD_NUMBER,
  SENTRY_DSN_KEY: process.env.REACT_APP_SENTRY_DSN_KEY,
  PEPPER_PAY_API_KEY: process.env.REACT_APP_PEPPER_PAY_API_KEY,
  ENV_NAME: process.env.REACT_APP_ENV_NAME,
};

const getConfig = () => {
  let currentConfig = {};

  if (isBackendLocalhost) {
    currentConfig = {
      API_URL: `http://${BACKEND_LOCALHOST}/api`,
    };
  }

  return {
    ...mainCfg,
    ...currentConfig,
  };
};

export const envConfig = getConfig();
