import styled from 'styled-components';
import { flex, flexCenterColumn } from '@/styles/layout';
import { COLORS } from '@/styles/colors';
import { media } from '@/styles/responsive';
import { AuthLayoutPosition } from './AuthLayout';

export const AuthBody = styled.div<{ position: AuthLayoutPosition }>`
  ${flexCenterColumn};
  flex: 1;
  background-color: ${COLORS.grey[300]};

  ${({ position }) =>
    position === 'top' &&
    `
    ${flex({ justifyContent: 'flex-start' })};
    padding-top: 48px;
  `}

  ${media.mobile`
    background-color: ${COLORS.white};
  `}
`;

export const AuthHeader = styled.div`
  background: ${COLORS.black};
  padding: 20px 30px;
  box-shadow: 0 2px 10px rgb(19 62 112 / 20%);

  ${media.mobile`
    padding: 20px 16px;
  `}
`;

export const AuthPage = styled.div`
  ${flex({ flexDirection: 'column' })};
  height: 100%;
`;

export const LinkWrapper = styled.div`
  width: max-content;
`;
