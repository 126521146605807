import { updateSelfUserDetailsApi, UpdateUserDetailsType } from '@/api/v1/users/updateSelfUserDetailsApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const updateSelfUserDetails = async (data: UpdateUserDetailsType) => {
  try {
    const response = await updateSelfUserDetailsApi(data);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
