import { PaginatorContainer } from './PaginatorContainer/PaginatorContainer';

type PaginatorType = {
  page: number;
  limit: number;
  total: number;
};

export const Paginator = ({ page, limit, total }: PaginatorType) => {
  const hasPaginator = total > limit;

  if (!hasPaginator) return;

  return <PaginatorContainer page={page} limit={limit} total={total} />;
};
