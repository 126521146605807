import { authApiService } from '@/api/authApiService';
import { RequestOptions } from '@/types/sharedTypes';

export const blockOrganizationUserApi = (
  organizationId: string,
  userId: string,
  payload: {},
  options?: RequestOptions
) => {
  const uri = `/opco/organizations/${organizationId}/users/${userId}/status:block/`;

  return authApiService.POST(uri, payload, null, options);
};
