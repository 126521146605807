import { PAYMENT_TYPE_DB } from '@/const/shared';
import { FormConfigType, getInitialValues } from '@/helpers/formHelpers/formHelpers';
import {
  BANK_ABA_NUMBER_VALIDATOR,
  CONFIRM_BANK_ABA_NUMBER_VALIDATOR,
  CONFIRM_BANK_ACCOUNT_NUMBER,
  CONFIRM_INTERMEDIARY_BANK_ABA_NUMBER_VALIDATOR,
  CONFIRM_INTERMEDIARY_BANK_ACCOUNT_NUMBER,
  CONFIRM_INTERMEDIARY_SWIFT_CODE_VALIDATOR,
  CONFIRM_SWIFT_CODE_VALIDATOR,
  PHONE_NUMBER_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  SWIFT_CODE_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';

export const DOMESTIC_FORM_CONFIG: FormConfigType = [
  {
    name: 'name',
    label: 'Deposit Account Name*',
    placeholder: 'Enter Deposit Account Name',
    autoComplete: 'new-field',
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'payment_type',
    value: PAYMENT_TYPE_DB.DOMESTIC,
    options: [
      { label: 'Domestic Payment', value: PAYMENT_TYPE_DB.DOMESTIC },
      { label: 'International Payment', value: PAYMENT_TYPE_DB.INTERNATIONAL },
    ],
    label: 'Payment Type',
    type: 'radio',
  },
  {
    name: 'recipient_name',
    label: 'Recipient*',
    placeholder: 'Enter Recipient Name',
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'recipient_address',
    label: 'Recipient Address*',
    placeholder: 'Enter Recipient Address',
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'account_name',
    label: 'Account Name',
    placeholder: 'Enter Account Name',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'bank_aba_number',
    type: 'password',
    label: 'Routing / ABA Number*',
    placeholder: 'Enter Number',
    column: 6,
    validator: BANK_ABA_NUMBER_VALIDATOR,
  },
  {
    name: 'confirm_bank_aba_number',
    type: 'confirm',
    label: 'Confirm Routing / ABA Number*',
    placeholder: 'Enter Number',
    column: 6,
    validator: CONFIRM_BANK_ABA_NUMBER_VALIDATOR,
  },
  {
    name: 'bank_name',
    label: 'Bank Name',
    placeholder: 'Enter Bank Name',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'bank_account_number',
    type: 'password',
    label: 'Account Number*',
    placeholder: 'Enter Number',
    column: 6,
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'confirm_bank_account_number',
    type: 'confirm',
    label: 'Confirm Account Number*',
    placeholder: 'Enter Number',
    column: 6,
    validator: CONFIRM_BANK_ACCOUNT_NUMBER,
  },
  {
    name: 'bank_address',
    label: 'Bank Address',
    placeholder: 'Enter Bank Address',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'bank_phone_number',
    label: 'Bank Phone Number',
    placeholder: 'Enter Bank Phone Number',
    validator: PHONE_NUMBER_VALIDATOR,
    type: 'phone_input',
  },
];

export const INTERNATIONAL_FORM_CONFIG = DOMESTIC_FORM_CONFIG.map((field) => {
  if (field.name === 'bank_aba_number') {
    return {
      name: 'bank_swift_code',
      label: 'Swift Code*',
      placeholder: 'Enter Code',
      autoComplete: 'new-password',
      column: 6,
      validator: SWIFT_CODE_VALIDATOR,
    };
  }

  if (field.name === 'confirm_bank_aba_number') {
    return {
      name: 'confirm_bank_swift_code',
      label: 'Confirm Swift Code*',
      placeholder: 'Enter Code',
      column: 6,
      validator: CONFIRM_SWIFT_CODE_VALIDATOR,
    };
  }

  return field;
});

export const INTERMEDIARY_DOMESTIC_FORM_CONFIG: FormConfigType = [
  {
    name: 'intermediary_bank_aba_number',
    type: 'password',
    label: 'Routing / ABA Number*',
    placeholder: 'Enter Number',
    column: 6,
    validator: BANK_ABA_NUMBER_VALIDATOR,
  },
  {
    name: 'confirm_intermediary_bank_aba_number',
    type: 'confirm',
    label: 'Confirm Routing / ABA Number*',
    placeholder: 'Enter Number',
    column: 6,
    validator: CONFIRM_INTERMEDIARY_BANK_ABA_NUMBER_VALIDATOR,
  },
  {
    name: 'intermediary_bank_name',
    label: 'Bank Name',
    placeholder: 'Enter Bank Name',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'intermediary_bank_account_number',
    type: 'password',
    label: 'Account Number*',
    placeholder: 'Enter Number',
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
    column: 6,
  },
  {
    name: 'confirm_intermediary_bank_account_number',
    type: 'confirm',
    label: 'Confirm Account Number*',
    placeholder: 'Enter Number',
    validator: CONFIRM_INTERMEDIARY_BANK_ACCOUNT_NUMBER,
    column: 6,
  },
  {
    name: 'intermediary_bank_address',
    label: 'Bank Address',
    placeholder: 'Enter Bank Address',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'intermediary_bank_phone_number',
    label: 'Bank Phone Number',
    placeholder: 'Enter Bank Phone Number',
    validator: PHONE_NUMBER_VALIDATOR,
    type: 'phone_input',
  },
];

export const INTERMEDIARY_INTERNATIONAL_FORM_CONFIG: FormConfigType = INTERMEDIARY_DOMESTIC_FORM_CONFIG.map((field) => {
  if (field.name === 'intermediary_bank_aba_number') {
    return {
      name: 'intermediary_bank_swift_code',
      type: 'password',
      label: 'Swift Code*',
      isCopyPasteDisabled: true,
      placeholder: 'Enter Code',
      validator: SWIFT_CODE_VALIDATOR,
      column: 6,
    };
  }

  if (field.name === 'confirm_intermediary_bank_aba_number') {
    return {
      name: 'confirm_intermediary_bank_swift_code',
      label: 'Confirm Swift Code*',
      placeholder: 'Enter Code',
      validator: CONFIRM_INTERMEDIARY_SWIFT_CODE_VALIDATOR,
      column: 6,
    };
  }

  return field;
});

export const DOMESTIC_WITH_INTERMEDIARY_FORM_CONFIG = [...DOMESTIC_FORM_CONFIG, ...INTERMEDIARY_DOMESTIC_FORM_CONFIG];

export const INTERNATIONAL_WITH_INTERMEDIARY_FORM_CONFIG = [
  ...INTERNATIONAL_FORM_CONFIG,
  ...INTERMEDIARY_INTERNATIONAL_FORM_CONFIG,
];

export const DEPOSIT_ACCOUNT_INITIAL_VALUES = getInitialValues([
  ...DOMESTIC_WITH_INTERMEDIARY_FORM_CONFIG,
  ...INTERNATIONAL_WITH_INTERMEDIARY_FORM_CONFIG,
]);
