import { prepareListApiParams } from '@/api/helpers/urlApiHelpers';
import { getDepositAccountsApi } from '@/api/v1/organization/deposit-accounts/getDepositAccountsApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const loadDepositAccounts = async (params) => {
  const apiParams = prepareListApiParams(params);

  try {
    const response = await getDepositAccountsApi(apiParams);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
