import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';
import { ApiParams } from '@/api/helpers/urlApiHelpers';
import { DepositAccountStatusType } from '@/types/wireTypes';

export interface DepositAccountType {
  id: string;
  created_by: {
    id: string;
    email: string;
  };
  date_created: string;
  date_modified: string;
  name: string;
  status: DepositAccountStatusType;
  ref_id: string;
}

export interface DepositAccountPaymentDetailsType extends DepositAccountType {
  payment_details: {
    bank_account_number?: string;
    bank_name?: string;
  };
}

export type DepositAccountsListType = {
  count: number;
  next: string;
  previous?: string;
  results: DepositAccountPaymentDetailsType[];
};

export const getDepositAccountsApi = (params?: ApiParams): Promise<ApiResponseType<DepositAccountsListType>> => {
  const uri = '/organization/deposit-accounts/';

  return authApiService.GET(uri, params);
};
