import { ApiResponseType } from '@/types/sharedTypes';
import { authApiService } from '../../authApiService';
import { ApiParams } from '../../helpers/urlApiHelpers';
import { WiresListResponseType } from '@/types/wireTypes';

export const getRecipientsWiresApi = (params?: ApiParams): Promise<ApiResponseType<WiresListResponseType>> => {
  const uri = `/recipient/wires/`;

  return authApiService.GET(uri, params);
};
