import { ADDING_ORG_ADMIN_ROLE_STATUS_MAP } from '@/api/v1/opco/organizations/getUserAddingStatusApi';
import { Notification } from '@/components/Notification/Notification';
import { NotificationMessage, NotificationTitle } from '@/components/Notification/styles';

const NOTIFICATION_OFFSET = { mt: '-16px', mb: '24px' };

export const NewOrgAdminNotification = () => {
  return (
    <Notification {...NOTIFICATION_OFFSET}>
      <NotificationTitle>
        The email address you provided is not associated with an active WireVault account.
      </NotificationTitle>
      <NotificationMessage>
        Please fill out the remaining user information and confirm the mobile phone number is correct before you send
        the invitation.
      </NotificationMessage>
    </Notification>
  );
};

export const UserNotAssociatedWithOrgAdminRoleNotification = () => {
  return (
    <Notification {...NOTIFICATION_OFFSET}>
      <NotificationTitle>
        This user exists in the WireVault but is not associated with the OrgAdmin role.
      </NotificationTitle>
      <NotificationMessage>
        Please click «Save» to allow user to work with the transactions and wires of the organization.
      </NotificationMessage>
    </Notification>
  );
};

export const InvitedUserNotification = () => {
  return (
    <Notification {...NOTIFICATION_OFFSET} variant='error'>
      <NotificationTitle>This email address is associated with a pending WireVault account.</NotificationTitle>
      <NotificationMessage>
        You cannot give them rights of another role until they accept the initial invitation. Please reach out to them
        about accepting the pending invitation and check back later.
      </NotificationMessage>
    </Notification>
  );
};

export const ADDING_ORG_ADMIN_NOTIFICATIONS_MAP = {
  [ADDING_ORG_ADMIN_ROLE_STATUS_MAP.CAN_BE_ADDED]: <UserNotAssociatedWithOrgAdminRoleNotification />,
  [ADDING_ORG_ADMIN_ROLE_STATUS_MAP.IS_INVITED]: <InvitedUserNotification />,
};
