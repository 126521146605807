import { VARIANT_HIGHLIGHT } from '@/components/Status/const';
import { OPCO_USER_STATUSES } from '../../const/const';

export type OpcoUserStatusMapType = {
  [key: string]: {
    title: string;
    variant: string;
  };
};

export const OPCO_USER_STATUS_MAP: OpcoUserStatusMapType = {
  [OPCO_USER_STATUSES.ACTIVE]: { title: 'Active', variant: VARIANT_HIGHLIGHT.GREEN },
  [OPCO_USER_STATUSES.BLOCKED]: { title: 'Blocked', variant: VARIANT_HIGHLIGHT.GREY },
  [OPCO_USER_STATUSES.INVITED]: { title: 'Invited', variant: VARIANT_HIGHLIGHT.LIGHT_PURPLE },
  [OPCO_USER_STATUSES.REINVITED]: { title: 'Reinvited', variant: VARIANT_HIGHLIGHT.LIGHT_PURPLE },
};
