import styled from 'styled-components';
import { ellipsis } from '@/styles/common';
import { flex } from '@/styles/layout';

export const InviteNotification = styled.div`
  max-width: 600px;
  margin-top: 94px;
`;

export const UserDetailsEditableRow = styled.div`
  ${ellipsis};
`;

export const Wrapper = styled.div`
  width: 100%;
  ${flex()};
  column-gap: 28px;
`;

export const BlockWrapper = styled.div`
  width: 50%;
`;
