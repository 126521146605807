import { ForgotPinCodeMessage, RequestButton } from '@/bundle/shared/components/PinCode/styles';
import { Box } from '@/components/Box/Box';

type RequestLinkType = {
  onOpen?: () => void;
};

export const RequestLink = ({ onOpen }: RequestLinkType) => {
  return (
    <Box alignItems='center' justifyContent='center' flexDirection='row'>
      <ForgotPinCodeMessage>Forgot your PIN code? Please</ForgotPinCodeMessage>
      <RequestButton onClick={onOpen}>request a reset</RequestButton>
    </Box>
  );
};
