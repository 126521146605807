import { SwitchToggle } from '@/components/SwitchToggle/SwitchToggle';
import { useField } from 'formik';
import { ChangeEvent } from 'react';
import { InputError } from '../InputError/InputError';
import { getFieldError } from '@/helpers/formHelpers/formHelpers';
import { ResponseErrorType } from '@/types/sharedTypes';
import { SizeType } from '@/components/SwitchToggle/styles';

type FormikSwitchToggleType = {
  name: string;
  label?: string;
  disabled?: boolean;
  apiError?: ResponseErrorType;
  size?: SizeType;
};

export const FormikSwitchToggle = ({
  label = '',
  name,
  disabled,
  apiError,
  size = 'medium',
}: FormikSwitchToggleType) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, { setValue }] = useField(name);

  const { error, apiError: apiErrorMessage } = getFieldError(name, meta, apiError);

  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;

    setValue(checked);
  };

  return (
    <>
      <SwitchToggle
        name={name}
        label={label}
        checked={field.value}
        onChange={handleToggle}
        disabled={disabled}
        size={size}
      />
      <InputError error={error} apiError={apiErrorMessage} />
    </>
  );
};
