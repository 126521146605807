import { useSyncExternalStore, useCallback } from 'react';
import { createStore } from '../../../components/store/createStore';
import { RequiredAuthError } from '../classes/RequiredAuthError';
import { useNavigate } from 'react-router-dom';
import { getLoginUrl } from '@/bundle/Auth/LoginFlow/LoginPage/urls/getLoginUrl';
import { authTokenService } from '@/bundle/Auth/LoginFlow/LoginPage/service/authTokenService';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { CURRENT_ROLE_KEY } from '@/const/shared';
import { sessionStorageService } from '@/helpers/storageHelpers';

export type AppErrorStateType = {
  hasAppError?: boolean;
  status?: any;
  statusText?: any;
};

const initialState: AppErrorStateType = {
  hasAppError: false,
  status: null,
  statusText: null,
};

const store = createStore(initialState);

export const useAppErrorState = (setIsUpdating?: any) => {
  const navigate = useNavigate();
  const appError = useSyncExternalStore(store.subscribe, store.getState);

  const setAppErrorRes = useCallback(
    (e: any) => {
      if (setIsUpdating) {
        setIsUpdating(false);
      }

      if (e instanceof RequiredAuthError) {
        amplitudeService.resetUser();
        authTokenService.removeTokens();
        sessionStorageService.remove(CURRENT_ROLE_KEY);

        return navigate(getLoginUrl());
      }

      const newState: AppErrorStateType = {
        hasAppError: true,
        status: e.status,
        statusText: e?.message,
      };

      store.setState(newState);
    },
    [navigate, setIsUpdating]
  );

  const clearState = useCallback(() => {
    store.setState(initialState);
  }, []);

  return {
    appError,
    setAppErrorRes,
    clearState,
  };
};
