import { EmailLinkWrapper, EmailLinkWrapperBig } from './styles';

type EmailLinkType = {
  email: string;
  variant?: string;
};

const EMAIL_LINK_MAP = {
  default: EmailLinkWrapper,
  big: EmailLinkWrapperBig,
};

export const EmailLink = ({ email, variant = 'default' }: EmailLinkType) => {
  const EmailLinkComponent = EMAIL_LINK_MAP[variant];

  return <EmailLinkComponent href={`mailto:${email}`}>{email}</EmailLinkComponent>;
};
