import { resetList } from '@/styles/common';
import { flex } from '@/styles/layout';
import styled from 'styled-components';
import { bodySmall, poppinsMediumFont, poppinsRegularFont } from '@/styles/typography';

export const DocumentVerificationList = styled.ul`
  ${flex({ flexDirection: 'column' })};
  gap: 12px;
  ${resetList};
  margin-bottom: 18px;
`;

export const DocumentVerificationItem = styled.div`
  ${flex({ justifyContent: 'space-between', alignItems: 'center' })};
`;

export const Description = styled.span`
  ${poppinsRegularFont};
  ${bodySmall};
`;

export const Data = styled(Description)`
  ${poppinsMediumFont};
`;
