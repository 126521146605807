import { forgotPasswordCompleteRegistrationApi } from '@/api/v1/users/forgotPasswordCompleteRegistrationApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const forgotPasswordCompleteRegistration = async () => {
  const payload = {
    agree_with_condition: true,
  };

  try {
    const response = await forgotPasswordCompleteRegistrationApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
