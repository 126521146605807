import { Notification } from '@/components/Notification/Notification';

export const UserInvitedNotification = () => {
  return (
    <Notification mb='12px'>
      The invitation to WireVault has not been accepted. Please be sure the email address and mobile phone number are
      correct. You can change the information for this account until the invitation is accepted.
    </Notification>
  );
};
