import styled from 'styled-components';
import { bodySmall, poppinsMediumFont, poppinsRegularFont, subTitle } from '@/styles/typography';
import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';

export const FormTitle = styled.div`
  width: 100%;
  margin-bottom: 14px;
  ${poppinsMediumFont};
  ${subTitle};
  color: ${COLORS.black};
`;

export const FormMessage = styled.div`
  width: 100%;
  color: ${COLORS.grey[600]};
  ${bodySmall};
  ${poppinsRegularFont};
  margin-bottom: 28px;
`;

export const FormButtonBlock = styled.div`
  ${flex({ justifyContent: 'end' })}
  gap: 12px;
  margin-top: 12px;

  ${media.mobile`
    ${flex({ flexDirection: 'column-reverse' })}
  `}
`;

export const FormButtonBlockCentered = styled(FormButtonBlock)`
  ${flex({ justifyContent: 'space-between' })};
  margin-top: 12px;
  gap: 20px;

  ${media.mobile`
    flex-direction: column-reverse;
  `}
`;
