import { useEffect, useState } from 'react';
import { ProgressBarDone, ProgressBarWrapper } from './styles';
import { Box } from '../Box/Box';
import { Typography } from '../Typography/Typography';

type ProgressBarType = {
  label: string;
  count: number;
  totalCount: number;
};

export const ProgressBar = ({ label, count, totalCount }: ProgressBarType) => {
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    const progress = (count / totalCount) * 100;

    setProgressValue(progress);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return (
    <Box display='flex' flexDirection='column' rowGap='2px'>
      <Box display='flex' alignItems='center' justifyContent='space-between' rowGap={2}>
        <Typography variant='bodySmall'>{label}</Typography>
        <Typography variant='bodySmall' fontWeight='semibold'>
          {`${count}/${totalCount}`}
        </Typography>
      </Box>
      <ProgressBarWrapper>
        <ProgressBarDone progressValue={progressValue} />
      </ProgressBarWrapper>
    </Box>
  );
};
