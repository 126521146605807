import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

type ResultsType = {
  id: string;
  name: string;
};

export type ResponseType = {
  count: number;
  next: string | null;
  previous: string | null;
  results: ResultsType[];
};

export const getDepositAccountsIdsApi = (): Promise<ApiResponseType<ResponseType>> => {
  const uri = '/organization/deposit-accounts/ids/';

  return authApiService.GET(uri);
};
