import { Button } from '@/components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { getLoginUrl } from '@/bundle/Auth/LoginFlow/LoginPage/urls/getLoginUrl';
import { CreatePasswordButtonBlock, CreatePasswordMessage, ChangedPasswordIcon } from './styles';
import { AuthLayout } from '../../ui/AuthLayout/AuthLayout';
import { AuthStep } from '../../ui/AuthStep/AuthStep';

type PasswordChangedPageType = {
  onRedirect?: () => void;
};

export const PasswordChangedPage = ({ onRedirect }: PasswordChangedPageType) => {
  const navigate = useNavigate();

  const redirectToLogin = () => {
    onRedirect?.();

    return navigate(getLoginUrl());
  };

  return (
    <AuthLayout>
      <AuthStep center>
        <ChangedPasswordIcon />
        <CreatePasswordMessage>
          Your password has been <br /> successfully set
        </CreatePasswordMessage>
        <CreatePasswordButtonBlock>
          <Button onClick={redirectToLogin}>Return to Login</Button>
        </CreatePasswordButtonBlock>
      </AuthStep>
    </AuthLayout>
  );
};
