import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { WIRE_STATUS_DB } from '@/const/wire';
import { getWireApi } from '@/api/v1/organization/wires/getWireApi';
import { updateWireApi } from '@/api/v1/organization/wires/updateWireApi';
import { changeWireStatusApi } from '@/api/v1/organization/wires/changeWireStatusApi';
import {
  ReplacementWireDataType,
  createReplacementWireApi,
} from '@/api/v1/organization/wires/createReplacementWireApi';
import { sendPaymentDetailsRegisterViewEventApi } from '@/api/v1/organization/wires/getPaymentDetailsRegisterViewEventApi';
import { getPaymentDetailsApi } from '@/api/v1/organization/wires/getPaymentDetailsApi';

export type ChangeWireStatusType = typeof WIRE_STATUS_DB.CANCELLED | typeof WIRE_STATUS_DB.EXECUTED;

export const loadPaymentDetails = async (id: string) => {
  try {
    const response = await getPaymentDetailsApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadOutboundWireDetails = async (id: string) => {
  try {
    const response = await getWireApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const updateOutboundWire = async (id: string, payload) => {
  try {
    const response = await updateWireApi(id, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const registerBillingEvent = async (id: string) => {
  try {
    const response = await sendPaymentDetailsRegisterViewEventApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const changeWireStatus = async (id: string, status: ChangeWireStatusType) => {
  try {
    const payload = {
      status,
    };

    const response = await changeWireStatusApi(id, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const createReplacementWire = async (id: string, payload: ReplacementWireDataType) => {
  try {
    const response = await createReplacementWireApi(id, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
