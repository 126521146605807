import { ApiResponseType } from '@/types/sharedTypes';
import { apiService } from '../../../apiService';

type ForgotPasswordType = {
  auth_token: string;
  is_completed: boolean;
};

export type ForgotPasswordPayload = {
  email: string;
  recaptcha_response: string;
};

export const forgotPasswordApi = async (data: ForgotPasswordPayload): Promise<ApiResponseType<ForgotPasswordType>> => {
  const uri = '/users/forgot-password/email/';

  return apiService.POST(uri, data);
};
