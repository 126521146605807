import { FormConfigType } from '@/helpers/formHelpers/formHelpers';
import {
  REQUIRED_PHONE_NUMBER_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';

export const EDIT_USER_FORM_CONFIG: FormConfigType = [
  {
    name: 'email',
    label: 'Email Address*',
    disabled: true,
  },
  {
    name: 'mobile_phone',
    label: 'Mobile Phone Number*',
    type: 'phone_input',
    validator: REQUIRED_PHONE_NUMBER_VALIDATOR,
  },
  {
    name: 'first_name',
    label: 'First Name*',
    placeholder: 'Enter First Name',
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'middle_name',
    label: 'Middle Name',
    placeholder: 'Enter Middle Name',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'last_name',
    label: 'Last Name*',
    placeholder: 'Enter Last Name',
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
  },
];
