import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

import { AssignedWireType } from '@/types/wireTypes';

export type AssignRecipientPayloadType = {
  user_id: string;
  is_proxy: boolean;
  is_pd_provider_kyc_required?: boolean;
};

export const assignRecipientApi = (
  wireId: string,
  data: AssignRecipientPayloadType
): Promise<ApiResponseType<AssignedWireType>> => {
  const uri = `/organization/wires/${wireId}/recipient/assign/`;

  return authApiService.POST(uri, data);
};
