import { ApiResponseType, ListResponseType } from '@/types/sharedTypes';
import { authApiService } from '@/api/authApiService';
import { ApiParams } from '@/api/helpers/urlApiHelpers';
import { OpcoUserType } from '@/bundle/_Opco/types/types';

export const getOpcoInternalUsersApi = async (
  params?: ApiParams
): Promise<ApiResponseType<ListResponseType<OpcoUserType>>> => {
  const uri = `/opco/internal/users/`;

  return authApiService.GET(uri, params);
};
