import { ApiResponseType, BaseResponseType, RequestOptions } from '@/types/sharedTypes';
import { authApiService } from '@/api/authApiService';

export const resetOpcoInternalUserApi = async (
  userId: string,
  options?: RequestOptions
): Promise<ApiResponseType<BaseResponseType>> => {
  const uri = `/opco/internal/users/${userId}/status:reset/`;

  return authApiService.POST(uri, {}, null, options);
};
