import { AssignedWireType } from '@/types/wireTypes';
import { isRecipientChainProxy, isRecipientInvitedWireStatus, isRecipientProxy } from '@/helpers/wireHelpers';
import { DetailsEditButton } from '@/bundle/_OrgAdmin/shared/ui/DetailsEditButton/DetailsEditButton';
import { UserDetailsWidget } from './UserDetailsWidget/UserDetailsWidget';

type RecipientChainProxyDetailsType = {
  wire: AssignedWireType;
  isRecipientEdit?: boolean;
  isProxyEditRecipient?: boolean;
  onEditRecipient?: () => void;
  onProxyEditRecipient?: () => void;
};

export const RecipientChainProxyDetails = ({
  wire,
  isProxyEditRecipient,
  isRecipientEdit,
  onProxyEditRecipient,
  onEditRecipient,
}: RecipientChainProxyDetailsType) => {
  const isChainProxy = isRecipientChainProxy(wire);
  const proxyRecipientDetails = wire?.assigned_proxy_user;
  const recipientDetails = wire?.assigned_user;
  const isDocumentVerificationRequired = !!wire?.is_pd_provider_kyc_required;

  if (isChainProxy) {
    const isRecipientInvited = isRecipientInvitedWireStatus(wire?.status);

    return (
      <>
        <UserDetailsWidget header='Recipient Proxy' user={proxyRecipientDetails} />
        {!isProxyEditRecipient && (
          <UserDetailsWidget
            header='Recipient'
            user={recipientDetails}
            action={isRecipientInvited && <DetailsEditButton onEdit={onProxyEditRecipient} />}
            marker={isDocumentVerificationRequired && 'ID VERIFICATION'}
            hasInvitedNotification={isRecipientInvited}
          />
        )}
      </>
    );
  }

  const isProxy = isRecipientProxy(wire);
  const header = isProxy ? 'Recipient Proxy' : 'Recipient';
  const userDetails = isProxy ? proxyRecipientDetails : recipientDetails;

  return (
    <UserDetailsWidget
      header={header}
      user={userDetails}
      action={isRecipientEdit && <DetailsEditButton onEdit={onEditRecipient} />}
      marker={isDocumentVerificationRequired && 'ID VERIFICATION'}
      hasInvitedNotification={isRecipientEdit}
    />
  );
};
