import * as Yup from 'yup';
import { REQUIRED_TEXT_FIELD_VALIDATOR } from '../../../../../../../../helpers/formHelpers/validators';

interface EditTransactionValidationSchemaType {
  name: string;
}

export const editTransactionValidationSchema: Yup.AnySchema<EditTransactionValidationSchemaType> = Yup.object().shape({
  name: REQUIRED_TEXT_FIELD_VALIDATOR,
});
