import { prepareListApiParams } from '@/api/helpers/urlApiHelpers';
import { createTransactionApi } from '@/api/v1/organization/contracts/createTransactionApi';
import { getTransactionsApi } from '@/api/v1/organization/contracts/getTransactionsApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const createTransaction = async (payload) => {
  try {
    const response = await createTransactionApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadTransactions = async (params) => {
  const apiParams = prepareListApiParams(params);

  try {
    const response = await getTransactionsApi(apiParams);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
