import { useFormik } from 'formik';
import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { updateDepositAccount } from '../../api';
import { ReactComponent as EditIcon } from '@/images/EditIconNew.svg';
import { ReactComponent as SaveIcon } from '@/images/SaveIcon.svg';
import { ReactComponent as CloseIcon } from '@/images/CloseIconNew.svg';
import { DepositAccountStatus } from '../../../ui/DepositAccountStatus/DepositAccountStatus';
import { dateFormatter } from '@/helpers/formatHelpers';
import { editDepositAccountValidationSchema } from './const/editDepositAccountValidationSchema';
import { DEPOSIT_ACCOUNT_STATUS_DB } from '@/const/wire';
import {
  DepositAccountDetailsFirstRow,
  DepositAccountDetailsSecondRow,
  DepositAccountDetailsWrapper,
  DepositAccountName,
  DetailsBlockTitle,
  DetailsBlockData,
  DetailsBlockColumn,
} from './styles';
import { Loader } from '@/components/Loader/Loader';
import { DepositAccountType } from '@/api/v1/organization/deposit-accounts/getDepositAccountsApi';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { IconButton } from '@/components/IconButton/IconButton';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';
import { Box } from '@/components/Box/Box';

type DepositAccountDetailsType = {
  depositAccount: DepositAccountType;
};

type PayloadType = {
  name: string;
};

export const DepositAccountDetails = ({ depositAccount }: DepositAccountDetailsType) => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const [isEdit, setIsEdit] = useState(false);
  const isActiveDepositAccount = depositAccount?.status === DEPOSIT_ACCOUNT_STATUS_DB.ACTIVE;
  const formattedDate = dateFormatter(depositAccount?.date_created);
  const { name, status, ref_id, created_by } = depositAccount || {};

  const { mutate, data, isPending } = useMutation({
    mutationKey: ['update_deposit_account'],
    mutationFn: (payload: PayloadType) => {
      return updateDepositAccount(id, payload);
    },
    onSuccess(updatedDepositAccount) {
      if (updatedDepositAccount.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.EditDepositAccountSuccess);

      setIsEdit(false);
      queryClient.setQueryData(['load_deposit_account', id], updatedDepositAccount);
    },
  });

  const formik = useFormik({
    validationSchema: editDepositAccountValidationSchema,
    initialValues: {
      name: depositAccount?.name,
    },
    enableReinitialize: true,
    onSubmit: async (values, formActions) => {
      if (values.name.trim() === depositAccount?.name) {
        formActions.setFieldValue('name', values.name.trim());
        setIsEdit(false);

        return;
      }

      await mutate(values);
    },
  });

  const renameDepositAccount = () => {
    formik.handleSubmit();
  };

  const closeEdit = () => {
    formik.resetForm();
    setIsEdit(false);
  };

  const openEdit = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.EditDepositAccountStartRedirect);

    setIsEdit(true);
  };

  return (
    <FormikForm value={formik}>
      <DepositAccountDetailsWrapper>
        <DepositAccountDetailsFirstRow isEdit={isEdit}>
          {isEdit ? (
            <FormikInput autoFocus name='name' disabled={!isEdit} variant='small' apiError={data?.error} />
          ) : (
            <DepositAccountName>{name || '-'}</DepositAccountName>
          )}
          {isActiveDepositAccount && (
            <>
              {isEdit && !isPending && (
                <Box display='flex' columnGap={12} mt='5px'>
                  <IconButton onClick={renameDepositAccount}>
                    <SaveIcon />
                  </IconButton>
                  <IconButton onClick={closeEdit} svgPath='stroke'>
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
              {isPending && <Loader width={40} background='transparent' />}
              {!isEdit && (
                <IconButton onClick={openEdit}>
                  <EditIcon />
                </IconButton>
              )}
            </>
          )}
        </DepositAccountDetailsFirstRow>

        <DepositAccountDetailsSecondRow>
          <DetailsBlockColumn>
            <DetailsBlockTitle>Status</DetailsBlockTitle>
            <DepositAccountStatus status={status} />
          </DetailsBlockColumn>
          <DetailsBlockColumn>
            <DetailsBlockTitle>Deposit Account ID</DetailsBlockTitle>
            <DetailsBlockData>{ref_id || '-'}</DetailsBlockData>
          </DetailsBlockColumn>
          <DetailsBlockColumn>
            <DetailsBlockTitle>Created by</DetailsBlockTitle>
            <DetailsBlockData>{created_by?.email || '-'}</DetailsBlockData>
          </DetailsBlockColumn>
          <DetailsBlockColumn>
            <DetailsBlockTitle>Creation Date</DetailsBlockTitle>
            <DetailsBlockData>{formattedDate || '-'}</DetailsBlockData>
          </DetailsBlockColumn>
        </DepositAccountDetailsSecondRow>
      </DepositAccountDetailsWrapper>
    </FormikForm>
  );
};
