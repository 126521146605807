import styled, { css } from 'styled-components';
import { media } from '@/styles/responsive';
import { ReactComponent as ExecutedIcon } from './images/ExecutedIcon.svg';
import { ReactComponent as SentIcon } from './images/SentIcon.svg';
import { ReactComponent as CancelledIcon } from './images/CancelledIcon.svg';

const mobileIcon = css`
  ${media.mobile`
    width: 150px;
    height: 150px;
  `}
`;

export const WaterMarkWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: -100px;
  margin-left: -100px;
  z-index: 1;

  ${media.mobile`
    bottom: -75px;
    margin-left: -75px;
  `}
`;

export const ExecutedIconWrapper = styled(ExecutedIcon)`
  ${mobileIcon};
`;

export const SentIconWrapper = styled(SentIcon)`
  ${mobileIcon};
`;
export const CancelledIconWrapper = styled(CancelledIcon)`
  ${mobileIcon};
`;
