import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getSubscriptions } from '../SettingsPage/api';
import { getSettingsUrl } from '../SettingsPage/urls/getSettingsUrl';

export const PaymentCheckoutProtectedRoute = () => {
  const location = useLocation();
  const settingsUrl = getSettingsUrl();

  const { data: getSubscriptionsData, isPending: isPendingGetSubscriptions } = useQuery({
    queryKey: ['get_subscriptions'],
    queryFn: () => getSubscriptions(),
  });

  const hasSubscription = getSubscriptionsData?.body?.results?.length > 0;

  if (isPendingGetSubscriptions) {
    return null;
  }

  if (hasSubscription) {
    return <Navigate to={settingsUrl} state={{ from: location }} replace />;
  } else {
    return <Outlet />;
  }
};
