import React from 'react';
import { PageBodyWrapper } from './styles';

type PageBodyType = {
  children?: React.ReactNode;
};

export const PageBody = ({ children }: PageBodyType) => {
  return <PageBodyWrapper>{children}</PageBodyWrapper>;
};
