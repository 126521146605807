import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

export type ReplacementWireDataType = {
  name?: string;
  amount?: string;
  currency?: string;
  expected_payment_date?: string;
  notes: string;
};

type ContractType = {
  id: string;
  name: string;
};

type CreatedByType = {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  mobile_phone: string;
  type: string;
  email: string;
  language: string;
  date_verified: string;
};

type AssignedRecipientType = {
  id: string;
};

type AssignedUserType = {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  mobile_phone: string;
  type: string;
  email: string;
  language: string;
  date_verified: string;
};

type ParentWireType = {
  id: string;
  name: string;
};

type ChildWireType = {
  id: string;
  name: string;
};

export type ReplacementWireResponseType = {
  id: string;
  date_created: string;
  date_modified: string;
  name: string;
  amount: string;
  currency: string;
  status: string;
  ref_id: string;
  expected_payment_date: string;
  contract: ContractType;
  created_by: CreatedByType;
  assigned_recipient: AssignedRecipientType;
  assigned_user: AssignedUserType;
  is_payment_details_exist: boolean;
  parent_wire: ParentWireType;
  child_wire: ChildWireType;
};

export const createReplacementWireApi = (
  wireId: string,
  data: ReplacementWireDataType
): Promise<ApiResponseType<ReplacementWireResponseType>> => {
  const uri = `/organization/wires/${wireId}/child/`;

  return authApiService.POST(uri, data);
};
