import { SourceWirePaymentDetails } from '@/bundle/shared/components/SourceWirePaymentDetails/SourceWirePaymentDetails';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { confirmPaymentDetails, loadRecipientPaymentDetails } from '../../api';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { showSnackbar } from '@/components/Snackbar/Snackbar';
import { useParams } from 'react-router-dom';
import { Button } from '@/components/Button/Button';
import { AssignedWireType } from '@/types/wireTypes';
import { isRecipientProxy } from '@/helpers/wireHelpers';
import { ConfirmModal, ConfirmModalHint } from '@/components/ConfirmModal/ConfirmModal';
import { EmailLink } from '@/components/EmailLink/EmailLink';
import { useState } from 'react';
import { ProvidePaymentDetailsTimer } from '../ProvidePaymentDetailsTimer/ProvidePaymentDetailsTimer';
import { TrustStampResponseStatusType } from '@/api/v1/recipient/getRecipientTrustStampStatusApi';
import { isTrustStampSucceedStatus } from '../RecipientDocumentVerification/helpers';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { SourceWireButtonsContainer } from './styles';

type RecipientSourceWirePaymentDetailsType = {
  wire: AssignedWireType;
  onProvidePaymentDetails: () => void;
  trustStampDetails: TrustStampResponseStatusType;
  onResetTrustStampNextAttemptTimer: () => void;
};

export const RecipientSourceWirePaymentDetails = ({
  wire,
  onProvidePaymentDetails,
  trustStampDetails,
  onResetTrustStampNextAttemptTimer,
}: RecipientSourceWirePaymentDetailsType) => {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const isProxy = isRecipientProxy(wire);
  const paymentDetailsId = wire?.payment_details_source_wire?.id;
  const createdByEmail = wire?.created_by?.email;

  const { data: paymentDetailsData } = useQuery({
    queryKey: ['load_source_wire_payment_details', paymentDetailsId],
    queryFn: () => loadRecipientPaymentDetails(paymentDetailsId),
    enabled: !!paymentDetailsId && !isProxy,
  });

  const { mutate, isPending } = useMutation({
    mutationKey: ['confirm_payment_details', id],
    mutationFn: () => confirmPaymentDetails(id),
    onSuccess: (confirmPaymentDetailsResponse) => {
      if (confirmPaymentDetailsResponse?.error) {
        const error = getResponseError(confirmPaymentDetailsResponse?.error);

        showSnackbar(error, { variant: 'error' });

        return;
      }

      amplitudeService.logEvent(AMPLITUDE_EVENTS.OutboundWireSourceWireConfirmSuccess);
      setIsOpenConfirmModal(false);
      queryClient.invalidateQueries({ queryKey: ['load_recipient_wire', id] });
    },
  });

  const openConfirmModal = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.OutboundWireSourceWireConfirmRedirect);
    setIsOpenConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setIsOpenConfirmModal(false);
  };

  const paymentDetails = paymentDetailsData?.body;
  const isDocumentVerificationRequired = !!wire?.is_pd_provider_kyc_required;
  const expiredPaymentDetailsTime = trustStampDetails?.provide_pd_disable_after_sec;
  const isDisableButton = expiredPaymentDetailsTime === 0 && isTrustStampSucceedStatus(trustStampDetails?.status);

  if (!paymentDetails) return null;

  return (
    <>
      <SourceWirePaymentDetails
        wire={wire}
        paymentDetails={paymentDetails}
        notification='Please confirm your wire info.'
        actionSlot={
          isDocumentVerificationRequired && (
            <ProvidePaymentDetailsTimer
              expiredPaymentDetailsTime={expiredPaymentDetailsTime}
              onResetProvidePaymentDetailsTimer={onResetTrustStampNextAttemptTimer}
            />
          )
        }
      >
        <SourceWireButtonsContainer>
          <Button variant='secondary' onClick={onProvidePaymentDetails} disabled={isDisableButton}>
            Provide New
          </Button>
          <Button onClick={openConfirmModal} disabled={isDisableButton}>
            Confirm
          </Button>
        </SourceWireButtonsContainer>
      </SourceWirePaymentDetails>

      <ConfirmModal
        isOpen={isOpenConfirmModal}
        header='Confirm Wire Info'
        body={
          <>
            <ConfirmModalHint>You are certifying the validity of the wire info you have provided.</ConfirmModalHint>
            <ConfirmModalHint>
              This information will be committed to the WireVault blockchain and cannot be changed.
            </ConfirmModalHint>
            <ConfirmModalHint>
              If you need to make a change to your wire info, please contact{' '}
              <EmailLink email={createdByEmail} variant='big' /> to request a cancellation before the wire is sent.
            </ConfirmModalHint>
          </>
        }
        onClose={closeConfirmModal}
        onConfirm={mutate}
        isLoading={isPending}
      />
    </>
  );
};
