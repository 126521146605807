import { useQuery } from '@tanstack/react-query';
import { PAYMENT_HISTORY_TABLE_CONFIG } from './const';
import { TableHeader } from '@/components/Table/TableHeader/TableHeader';
import { Table, TableBody, TableCell, TableRow } from '@/components/Table/styles';
import { TableHeaderCell } from '@/components/Table/TableHeaderCell/TableHeaderCell';
import { TableEmptyRow } from '@/components/Table/TableEmptyRow/TableEmptyRow';
import { Drawer } from '@/components/Drawer/Drawer';
import { useState } from 'react';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { Box } from '@/components/Box/Box';
import { getPaymentTransactions } from '../../api';
import { PaymentTransaction } from '@/types/paymentTypes';
import { UtcTooltip } from '@/bundle/_Opco/ui/UtcTooltip/UtcTooltip';
import { PaymentTransactionDetails } from './ui/PaymentTransactionDetails';
import { DEFAULT_LIMIT } from '@/api/helpers/queryHelpers';
import { PageFooter } from '@/components/Page/PageFooter/PageFooter';
import { Paginator } from '@/components/Paginator/Paginator';
import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { Notification } from '@/components/Notification/Notification';
import { useGetSearchParams } from '@/hooks/useGetSearchParams';

export const PaymentTransactionHistoryTable = () => {
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<PaymentTransaction | null>();
  const searchParams = useGetSearchParams();

  const { data } = useQuery({
    queryKey: ['get_payment_transactions'],
    queryFn: () => getPaymentTransactions(),
  });

  const openTransactionDetails = (transaction: PaymentTransaction) => {
    setIsOpen(true);
    setSelectedTransaction(transaction);
  };

  const closeTransactionDetails = () => {
    setIsOpen(false);
    setSelectedTransaction(null);
  };

  const onDownloadReport = () => {
    setDownloadDialogOpen(true);
  };

  const historyList = data?.body?.results;
  const hasItems = !!historyList?.length;
  //  TODO v13.0.0: switch to BE pagination when it will be implemented
  const hasPaginator = hasItems && historyList.length > DEFAULT_LIMIT;
  const startIndex = (searchParams.page || 0) * DEFAULT_LIMIT;
  const croppedList = historyList?.slice(startIndex, startIndex + DEFAULT_LIMIT);

  return (
    <>
      <Box mb='20px'>
        <Typography variant='subTitleSmall' color={COLORS.grey[950]} fontWeight='semibold'>
          Transaction History
        </Typography>
        <Typography variant='bodySmall' color={COLORS.grey[650]}>
          See the transactions you made.
        </Typography>
      </Box>
      <Table>
        <TableHeader>
          {PAYMENT_HISTORY_TABLE_CONFIG.map(({ key, name, width, header, hasSort, hasTooltip }) => (
            <TableHeaderCell key={key} name={name} width={width} hasSort={hasSort && hasItems}>
              {hasTooltip && <UtcTooltip message='Billing Date is recorded in UTC.' />}
              {header}
            </TableHeaderCell>
          ))}
        </TableHeader>
        <TableBody>
          {hasItems ? (
            croppedList.map((item) => (
              <TableRow key={item.id} onClick={() => openTransactionDetails(item)} isClickableRow>
                {PAYMENT_HISTORY_TABLE_CONFIG.map(({ key, width, getValue }) => (
                  <TableCell key={key} width={width}>
                    {getValue(item, onDownloadReport)}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableEmptyRow columns={PAYMENT_HISTORY_TABLE_CONFIG} />
          )}
        </TableBody>
      </Table>

      {/* TODO v13.0.0: switch to BE pagination when it will be implemented */}
      {hasPaginator && (
        <PageFooter>
          <Paginator page={searchParams.page} limit={searchParams.limit} total={historyList.length} />
        </PageFooter>
      )}

      {/* TODO v13.0.0: remove this dialog when download report feature will be clarified */}
      <ConfirmModal
        isOpen={downloadDialogOpen}
        body={<Notification variant='error'>This feature is not available yet.</Notification>}
        header='IN DEVELOPMENT'
        confirmText='Ok'
        confirmation={null}
        onClose={() => setDownloadDialogOpen(false)}
        onConfirm={() => setDownloadDialogOpen(false)}
      ></ConfirmModal>

      <Drawer isOpen={isOpen} header='Payout Details' subHeader='Description text' onClose={closeTransactionDetails}>
        <PaymentTransactionDetails transaction={selectedTransaction} />
      </Drawer>
    </>
  );
};
