import * as amplitude from '@amplitude/analytics-browser';
import { EnrichmentPlugin } from '@amplitude/analytics-types';
import { envConfig } from '@/config/envConfig';
import { ADDITIONAL_PROPERTIES, DEFAULT_EVENTS_LIST, AmplitudeEventsType } from './amplitudeEvents';
import { USER_ROLE_MAP } from '@/const/user';
import { SelfUserDetailsType } from '@/api/v1/users/getSelfUserDetailsApi';
import { getOrganizationName } from '@/helpers/userHelpers';

export const AMPLITUDE_USER_ROLE_LABEL = {
  [USER_ROLE_MAP.OPCO]: 'op_co',
  [USER_ROLE_MAP.ORGANIZATION]: 'org_admin',
  [USER_ROLE_MAP.RECIPIENT]: 'recipient',
  [USER_ROLE_MAP.EXECUTOR]: 'executor',
  [USER_ROLE_MAP.DEPOSITOR]: 'depositor',
};

export const customEventsTrackingEnrichment = (): EnrichmentPlugin => {
  return {
    name: 'custom-events-tracking-enrichment',
    type: 'enrichment',
    setup: async () => undefined,
    execute: async (event) => {
      if (DEFAULT_EVENTS_LIST.includes(event.event_type)) {
        return event;
      }

      event.event_properties = {
        ...event.event_properties,
        [ADDITIONAL_PROPERTIES.PageDomain]: window.location.hostname || '',
        [ADDITIONAL_PROPERTIES.PageLocation]: window.location.href || '',
        [ADDITIONAL_PROPERTIES.PagePath]: window.location.pathname || '',
        [ADDITIONAL_PROPERTIES.PageTitle]: document.title || '',
        [ADDITIONAL_PROPERTIES.PageUrl]: window.location.href.split('?')[0] || '',
      };

      return event;
    },
  };
};

export const amplitudeService = {
  init() {
    /**
     * IMPORTANT: install plugin before calling init to make sure plugin is added by the time
     * init function sends out the "Page View" event
     */
    amplitude.add(customEventsTrackingEnrichment());
    amplitude.init(envConfig.AMPLITUDE_API_KEY, { defaultTracking: true });
  },

  logEvent(event: AmplitudeEventsType, data?: unknown) {
    amplitude.logEvent(event, data);
  },

  setUser(user: SelfUserDetailsType, currentRole) {
    if (user?.id && currentRole) {
      amplitude.setUserId(user.id);
      const userTypeLabel = AMPLITUDE_USER_ROLE_LABEL[currentRole];
      const identify = new amplitude.Identify();

      identify.set('type', userTypeLabel);
      identify.set('organizationName', getOrganizationName(user));
      amplitude.identify(identify);
    }
  },

  resetUser() {
    amplitude.reset();
  },
};
