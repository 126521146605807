import styled from 'styled-components';
import { body, bodySmall, poppinsMediumFont, poppinsRegularFont, poppinsSemiBoldFont } from '@/styles/typography';
import { COLORS } from '@/styles/colors';
import { resetButton } from '@/styles/common';
import { flex, flexCenter } from '@/styles/layout';

export const StepTitle = styled.div`
  ${poppinsSemiBoldFont};
  ${body};
`;

export const StepOneMessage = styled.div`
  ${poppinsRegularFont};
  ${bodySmall}
`;

export const StepDescription = styled.div`
  ${poppinsRegularFont};
  ${bodySmall};
  color: ${COLORS.grey[700]};
`;

export const RecordVideoStepTitle = styled.div`
  ${poppinsSemiBoldFont};
  ${bodySmall};
`;

export const TakePhotoStepWrapper = styled.div`
  ${flex()};
  flex-wrap: wrap;
  gap: 16px;
`;

export const ToggleButton = styled.button`
  width: max-content;
  ${flexCenter};
  ${resetButton};
  color: ${COLORS.blue[500]};
  ${poppinsMediumFont};
  cursor: pointer;
  column-gap: 8px;
`;

export const BoldText = styled.b`
  color: ${COLORS.black};
  ${poppinsSemiBoldFont};
`;

export const AnimatedBlock = styled.div<{ isShow: boolean }>`
  max-height: ${({ isShow }) => (isShow ? '800px' : '0')};
  overflow: hidden;
  transition: all 0.5s;
`;

export const AdditionalMessage = styled.span`
  color: ${COLORS.grey[600]};
`;
