import { ChipButton, ChipTitle, ChipWrapper } from './styles';

type ChipType = {
  title: string;
  onClick: () => void;
  isRemovable?: boolean;
};

export const Chip = ({ title, onClick, isRemovable }: ChipType) => {
  return (
    <ChipWrapper>
      <ChipTitle>{title}</ChipTitle>
      {isRemovable && <ChipButton onClick={onClick}>&times;</ChipButton>}
    </ChipWrapper>
  );
};
