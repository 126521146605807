import { USER_ASSIGNMENT_STATUS_CODE_MAP } from '@/const/shared';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';

export const ASSIGN_AMPLITUDE_EVENTS_REDIRECT_MAP = {
  [USER_ASSIGNMENT_STATUS_CODE_MAP.CAN_BE_ASSIGNED]: AMPLITUDE_EVENTS.ExistingDepositorRedirect,
  [USER_ASSIGNMENT_STATUS_CODE_MAP.IS_SAME_ROLE_AND_INVITED_BUT_CREATED_BY_SAME_ORGANIZATION]:
    AMPLITUDE_EVENTS.InvitedDepositorRedirect,
};

export const ERROR_ASSIGN_AMPLITUDE_EVENTS_REDIRECT_MAP = {
  [USER_ASSIGNMENT_STATUS_CODE_MAP.IS_NOT_SAME_ROLE_AND_INVITED]:
    AMPLITUDE_EVENTS.AssignUnacceptedInvitationDepositorFailed,
  [USER_ASSIGNMENT_STATUS_CODE_MAP.IS_SAME_ROLE_AND_INVITED_BUT_CREATED_BY_ANOTHER_ORGANIZATION]:
    AMPLITUDE_EVENTS.AssignUnacceptedInvitationFromOtherOrganizationFailed,
  [USER_ASSIGNMENT_STATUS_CODE_MAP.IS_SAME_USER]: AMPLITUDE_EVENTS.AssignYourSelfFailed,
};

export const ASSIGN_AMPLITUDE_EVENTS_SUCCESS_MAP = {
  [USER_ASSIGNMENT_STATUS_CODE_MAP.CAN_BE_ASSIGNED]: AMPLITUDE_EVENTS.ExistingDepositorSuccess,
  [USER_ASSIGNMENT_STATUS_CODE_MAP.IS_SAME_ROLE_AND_INVITED_BUT_CREATED_BY_SAME_ORGANIZATION]:
    AMPLITUDE_EVENTS.InvitedDepositorSuccess,
};
