import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { BILLING_ADDRESS_CONFIG, getBillingAddressConfig, getCountryByCode } from './const';
import { getInitialValues, getValidationSchema } from '@/helpers/formHelpers/formHelpers';
import { Notification } from '@/components/Notification/Notification';
import { ResponseErrorType } from '@/types/sharedTypes';
import { OptionType } from '@/components/Select/Select';
import { DefaultBillingAddressType } from '@/types/paymentTypes';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';

export type BillingAddressFormType = {
  address: string;
  city: string;
  country?: OptionType;
  state?: OptionType;
  zip: string;
};

type EditBillingAddressFormType = {
  billingAddress?: DefaultBillingAddressType;
  onSave: (billingAddress: BillingAddressFormType) => void;
  onClose?: () => void;
  isLoading: boolean;
  apiError?: ResponseErrorType;
};

export const EditBillingAddressForm = ({
  billingAddress,
  apiError,
  onSave,
  onClose,
  isLoading,
}: EditBillingAddressFormType) => {
  const [config, setConfig] = useState(BILLING_ADDRESS_CONFIG);
  const [isFirstLoaded, setIsFirstLoaded] = useState(false);

  const formik = useFormik<BillingAddressFormType>({
    initialValues: getInitialValues(BILLING_ADDRESS_CONFIG),
    validationSchema: getValidationSchema(BILLING_ADDRESS_CONFIG),
    onSubmit: onSave,
  });

  useEffect(() => {
    setIsFirstLoaded(true);

    if (!billingAddress) {
      return;
    }

    const country = getCountryByCode(billingAddress.country);
    const state = country?.regions.find((item) => item.shortCode === billingAddress.state);

    formik.setValues({
      city: billingAddress.city,
      zip: billingAddress.postal_code,
      address: billingAddress.line_1,
      state: {
        label: state?.name,
        value: state?.shortCode,
      },
      country: {
        label: country?.countryName,
        value: country?.countryShortCode,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingAddress]);

  useEffect(() => {
    if (isFirstLoaded) {
      formik.resetForm({
        values: {
          ...formik.initialValues,
          country: formik.values.country,
        },
      });
    }

    const countryValue = formik.values.country?.value;
    const country = getCountryByCode(countryValue as string);
    const regions = country?.regions || [];

    const newConfig = getBillingAddressConfig(regions);

    setConfig(newConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.country]);

  const error = getResponseError(apiError);

  return (
    <FormikForm value={formik}>
      {error && (
        <Notification mt='20px' variant='error' mb='24px'>
          {error}
        </Notification>
      )}
      <Box mt='20px'>
        <FormGeneratorNew config={config} />
      </Box>
      <Box mt='20px' display='flex' columnGap='20px'>
        {onClose && (
          <Button variant='secondary' onClick={() => onClose()}>
            Cancel
          </Button>
        )}
        <Button isLoading={isLoading} type='submit' onClick={formik.handleSubmit}>
          Save
        </Button>
      </Box>
    </FormikForm>
  );
};
