import { ReactNode } from 'react';
import { TableHeaderContainer } from './styles';
import { TableRow } from '../styles';

type TableHeaderType = {
  children: ReactNode;
};

export const TableHeader = ({ children }: TableHeaderType) => {
  return (
    <TableHeaderContainer>
      <TableRow>{children}</TableRow>
    </TableHeaderContainer>
  );
};
