import styled from 'styled-components';
import { flex } from '@/styles/layout';
import { COLORS } from '@/styles/colors';
import { headline4, poppinsSemiBoldFont } from '@/styles/typography';
import { media } from '@/styles/responsive';
import { THEME } from '@/styles/theme';
import { overlayBackground } from '@/styles/common';

export const ModalOverlay = styled.div`
  ${flex({ alignItems: 'center', justifyContent: 'center' })}
  z-index: ${THEME.modal.zIndex};
  position: fixed;
  ${overlayBackground};
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  min-height: 100%;
  max-height: max-content;
  overflow: auto;
`;

export const ModalBlock = styled.div<{ top?: number }>`
  ${flex({ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' })};
  padding: 42px;
  background: ${COLORS.white};
  box-shadow: 0 2px 20px rgb(63 77 90 / 25%);
  border-radius: 16px;
  will-change: transform;

  &.fade-enter {
    opacity: 0;
    transform: scale(0.9);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition:
      opacity 150ms,
      transform 150ms;
  }

  ${media.mobile`
    width: 100%;
    max-width: 420px;
    margin: 16px;
    padding: 20px 16px;
    left: 16px;
    right: 16px;
  `}
`;

export const ModalBlockHeader = styled.h4<{ isStartPosition?: boolean }>`
  ${headline4};
  ${poppinsSemiBoldFont};
  color: ${COLORS.grey[900]};
  margin: 0 0 24px;
  width: 100%;
  text-align: ${({ isStartPosition }) => (isStartPosition ? 'start' : 'center')};
`;
