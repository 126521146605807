import { ReactNode } from 'react';
import { Label, LabelWrapper } from './styles';

interface SelectLabelType {
  label: string;
  postfixLabel?: ReactNode;
}

export const SelectLabel = ({ label, postfixLabel }: SelectLabelType) => {
  return (
    <LabelWrapper>
      {label && <Label>{label}</Label>}
      {postfixLabel && postfixLabel}
    </LabelWrapper>
  );
};
