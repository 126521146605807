import styled from 'styled-components';
import { flex } from '@/styles/layout';
import { COLORS } from '@/styles/colors';
import { caption, poppinsMediumFont, poppinsSemiBoldFont, subTitle } from '@/styles/typography';
import { media } from '@/styles/responsive';
import { THEME } from '@/styles/theme';

export const DetailsWidgetWrapper = styled.div<{ hasHighlight: boolean }>`
  ${flex({ flexDirection: 'column' })};
  position: relative;
  border-radius: 20px;
  border: 1px solid ${COLORS.grey[400]};
  background-color: ${COLORS.white};

  ${({ hasHighlight }) =>
    hasHighlight &&
    `
    border: 1px solid ${COLORS.grey[850]};
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  `};
`;

export const DetailsWidgetHeading = styled.div<{ hasAction: boolean; headerOffset: number }>`
  ${flex({ justifyContent: 'space-between', alignItems: 'center' })};
  margin-bottom: ${({ hasAction }) => (hasAction ? 12 : 20)}px;

  ${({ headerOffset }) =>
    headerOffset &&
    `
    margin-bottom: ${headerOffset}px;
  `};
`;

export const DetailsWidgetHeader = styled.div`
  ${poppinsMediumFont};
  ${subTitle};
`;

export const DetailsWidgetContent = styled.div``;

export const ContentWrapper = styled.div<{ hasSlot: boolean; variant: 'default' | 'small' }>`
  padding: 28px;

  ${({ hasSlot }) =>
    hasSlot &&
    `
    padding: 20px 28px 28px 28px;
  `}

  ${({ variant }) =>
    variant === 'small' &&
    `
    padding: 20px;
  `}

  ${media.mobile`
    padding: ${THEME.offset.mobile};
  `}
`;

export const HeadlineSlot = styled.div`
  background-color: ${COLORS.blue[200]};
  border-radius: 20px 20px 0 0;
  padding: 16px 28px;

  ${media.mobile`
    padding: ${THEME.offset.mobile};
  `}
`;

export const DetailsWidgetMarker = styled.div`
  position: absolute;
  padding: 2px 16px;
  ${caption};
  ${poppinsSemiBoldFont};
  color: ${COLORS.white};
  border-radius: 20px 0;
  background-color: ${COLORS.grey[700]};
`;

export const LoaderWrapper = styled.div`
  ${flex({ justifyContent: 'center' })};
  padding-top: 50px;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10;
  background: ${COLORS.white};
  opacity: 0.5;
  border-radius: 20px;
`;
