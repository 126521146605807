import { ACCESS_DENIED_RESPONSE, HTTP_FORBIDDEN_STATUS } from '@/api/const';

export class AccessDeniedError extends Error {
  status: number;

  constructor(errorText = ACCESS_DENIED_RESPONSE) {
    super(errorText);

    this.name = 'AccessDeniedError';
    this.status = HTTP_FORBIDDEN_STATUS;
  }
}
