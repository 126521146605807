import { Route } from 'react-router-dom';
import { RecipientWiresPage } from '../RecipientWiresPage';
import { getRecipientWiresPath } from '../urls/getRecipientWiresUrl';
import { RecipientWireDetailsPage } from '../_Details/RecipientWireDetailsPage';
import { getRecipientWireDetailsPath } from '../_Details/urls/getRecipientWireDetailsUrl';

export const RecipientWireRoutes = [
  <Route key={getRecipientWiresPath()} path={getRecipientWiresPath()} element={<RecipientWiresPage />} />,
  <Route
    key={getRecipientWireDetailsPath()}
    path={getRecipientWireDetailsPath()}
    element={<RecipientWireDetailsPage />}
  />,
];
