import { PinCodeButtonsWrapper, PinCodeWrapper, Title } from './styles';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { ReactNode } from 'react';
import { PinCodeKeyboard } from './ui/PinCodeKeyboard/PinCodeKeyboard';
import { PIN_CODE_LENGTH } from './const';
import { PinCodeDots } from './ui/PinCodeDots/PinCodeDots';

interface PinCodeType {
  title: string;
  hasShadow?: boolean;
  onSubmit: () => void;
  onBack: () => void;
  link?: ReactNode;
  pinCode?: number[];
  onSet: (value: unknown) => void;
  onClear: () => void;
  pinCodeKeyBoard?: number[];
  isDisabled: boolean;
  apiError?: string;
  error?: string;
  messageBox?: ReactNode;
  backButtonLabel?: string;
  isLoading?: boolean;
}

export const PinCode = ({
  title,
  hasShadow,
  onSubmit,
  onBack,
  link,
  pinCode,
  onSet,
  onClear,
  pinCodeKeyBoard,
  isDisabled,
  apiError,
  error,
  messageBox,
  backButtonLabel = 'Return to Login',
  isLoading,
}: PinCodeType) => {
  const addPinCodeValue = (value) => {
    if (pinCode.length >= PIN_CODE_LENGTH) return;

    onSet((prev) => [...prev, value]);
  };

  const removeLastPinCodeValue = () => {
    onSet((values) => values.slice(0, -1));
  };

  const formError = !!(apiError || error);

  return (
    <PinCodeWrapper hasShadow={hasShadow}>
      <Box alignItems='center' justifyContent='center'>
        <Title>{title}</Title>
      </Box>
      <Box alignItems='center' justifyContent='center' flexDirection='column'>
        <PinCodeDots pinCode={pinCode} error={formError} />
        {messageBox}
      </Box>
      <PinCodeKeyboard
        onAdd={addPinCodeValue}
        pinCodeKeyboard={pinCodeKeyBoard}
        onClear={onClear}
        onDelete={removeLastPinCodeValue}
      />
      <PinCodeButtonsWrapper>
        <Button variant='secondary' onClick={onBack}>
          {backButtonLabel}
        </Button>
        <Button onClick={onSubmit} disabled={isDisabled} isLoading={isLoading}>
          Submit
        </Button>
      </PinCodeButtonsWrapper>
      {link}
    </PinCodeWrapper>
  );
};
