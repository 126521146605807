import { AccessDeniedWrapper, AccessDeniedHeader, AccessDeniedSubheader } from '@/bundle/pages/AccessDeniedPage/styles';
import { ReactComponent as LockIcon } from './image/Lock.svg';
import { Button } from '@/components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@/context/userContext';
import { hasMultipleRoles } from '@/helpers/userHelpers';
import { getDashboardRedirectUrl } from '../../Auth/LoginFlow/LoginPage/const/const';
import { getSwitchRoleUrl } from '../SwitchRolePage/url/getSwitchRoleUrl';

export const AccessDeniedPage = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const redirectToDashboard = () => {
    const hasMultipleUserRoles = hasMultipleRoles(user);

    if (hasMultipleUserRoles) {
      return navigate(getSwitchRoleUrl());
    }

    const activeRole = user.active_roles[0];
    const url = getDashboardRedirectUrl(activeRole);

    return navigate(url);
  };

  return (
    <AccessDeniedWrapper>
      <LockIcon />
      <AccessDeniedHeader>Access Forbidden</AccessDeniedHeader>
      <AccessDeniedSubheader>Sorry, you don't have access to view this page.</AccessDeniedSubheader>
      <Button width={200} onClick={redirectToDashboard}>
        Go To Dashboard
      </Button>
    </AccessDeniedWrapper>
  );
};
