import noop from 'lodash/noop';
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

const DEFAULT_POLLING_INTERVAL = 30000; // 30 seconds;

export const useInterval = (callback: (cancelCallback) => void = noop, delay: number = DEFAULT_POLLING_INTERVAL) => {
  const savedCallback = useRef(callback);
  const timeoutId = useRef(null);

  const cancelInterval = useCallback(() => {
    if (timeoutId.current) {
      clearInterval(timeoutId.current);
      timeoutId.current = null;
    }
  }, []);

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (!delay && delay !== 0) {
      return;
    }

    timeoutId.current = setInterval(() => savedCallback.current(cancelInterval), delay);

    return () => {
      clearInterval(timeoutId.current);
      timeoutId.current = null;
    };
  }, [delay, cancelInterval]);

  return {
    cancelInterval,
    isIntervalRunning: !!timeoutId.current,
  };
};
