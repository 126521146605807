import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '@/context/userContext';
import { hasRole } from '@/helpers/userHelpers';
import { UserRoleType } from '@/const/user';
import { getAccessDeniedUrl } from '../pages/AccessDeniedPage/urls/getAccessDeniedUrl';

type ProtectedRouteType = {
  allowedRole: UserRoleType;
};

export const ProtectedRoute = ({ allowedRole }: ProtectedRouteType) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [checking, setChecking] = useState(true);
  const { user, loading, currentRole, setRole } = useUser();
  const accessDeniedUrl = getAccessDeniedUrl();

  useEffect(() => {
    if (!user) return;

    const hasAccess = hasRole(user, allowedRole);

    if (!hasAccess) return navigate(accessDeniedUrl, { state: { from: location } });

    if (!currentRole || currentRole !== allowedRole) {
      setRole(allowedRole);
    }

    setChecking(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, location.pathname]);

  const checkingAccess = loading || !user || checking;

  if (checkingAccess) return;

  if (hasRole(user, allowedRole)) {
    return <Outlet />;
  } else {
    return <Navigate to={accessDeniedUrl} state={{ from: location }} replace />;
  }
};
