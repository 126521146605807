import { ReactNode } from 'react';
import { PaymentSettingsCardWrapper } from './styles';
import { COLORS } from '@/styles/colors';
import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';

type PaymentSettingsCardType = {
  header?: ReactNode;
  subHeader?: ReactNode;
  variant?: 'primary' | 'dark';
  children: ReactNode;
};

export const PaymentSettingsCard = ({ header, subHeader, variant = 'primary', children }: PaymentSettingsCardType) => {
  return (
    <PaymentSettingsCardWrapper variant={variant}>
      <Box display='flex' flexDirection='column'>
        {header && (
          <Typography variant='subTitleSmall' color={COLORS.grey[950]} fontWeight='semibold'>
            {header}
          </Typography>
        )}
        {subHeader && (
          <Typography variant='bodySmall' color={COLORS.grey[650]}>
            {subHeader}
          </Typography>
        )}
      </Box>
      {children}
    </PaymentSettingsCardWrapper>
  );
};
