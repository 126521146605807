import { ReactComponent as RecipientInvitedIcon } from '@/images/RecipientInviteIcon.svg';
import { ReactComponent as RecipientProxyIcon } from '@/images/ProxyOutlineIcon.svg';

export const INVITE_RECIPIENT_OPTION = 'invite';
export const PROVIDE_PAYMENT_DETAILS_FOR_RECIPIENT_OPTION = 'provide';

export type ProxyProvidePaymentDetailsOptionType =
  | typeof INVITE_RECIPIENT_OPTION
  | typeof PROVIDE_PAYMENT_DETAILS_FOR_RECIPIENT_OPTION;

export const PROXY_PROVIDE_WIRE_INFO_OPTIONS = [
  {
    icon: <RecipientInvitedIcon />,
    label: 'Invite the Recipient',
    description: 'to share their own wire info.',
    meta: 'Secure',
    value: INVITE_RECIPIENT_OPTION,
  },
  {
    icon: <RecipientProxyIcon />,
    label: 'Provide wire info for the Recipient',
    description: 'collected outside of WireVault.',
    value: PROVIDE_PAYMENT_DETAILS_FOR_RECIPIENT_OPTION,
  },
];
