import { useField } from 'formik';
import { OptionType, Select } from '../Select/Select';
import { MultiSelectValue } from './ui/MultiSelectValue';
import { ClearIndicator } from './ui/ClearIndicator';
import { FormatOptionLabelMeta } from 'react-select';
import { Checkbox, CheckboxClickEventType } from '../Checkbox/Checkbox';
import { MultiSelectOptionWrapper } from './styles';
import { OptionLabel } from '../Select/styles';
import { noop } from 'lodash';

type MultiSelectType = {
  name: string;
  options: OptionType[];
  placeholder?: string;
  label?: string;
  size?: 'medium' | 'small';
};

const formatOptionLabel = (option: OptionType, meta: FormatOptionLabelMeta<OptionType>) => {
  const isMenuItem = meta.context === 'value';
  const isSelected = !!meta?.selectValue.find((item) => option.value === item?.value);

  const toggleCheckbox = (event: CheckboxClickEventType) => {
    event.stopPropagation();
  };

  return (
    <MultiSelectOptionWrapper>
      {!isMenuItem && <Checkbox checked={isSelected} onChange={noop} onClick={toggleCheckbox}></Checkbox>}
      <OptionLabel>{option.label}</OptionLabel>
    </MultiSelectOptionWrapper>
  );
};

export const MultiSelect = ({ name, options, placeholder, label, size = 'medium' }: MultiSelectType) => {
  const [field, meta, { setValue }] = useField(name);

  const onChange = (value: OptionType) => {
    setValue(value);
  };

  return (
    <Select
      {...field}
      options={options}
      onChange={onChange}
      isMulti
      closeMenuOnSelect={false}
      value={meta?.value}
      placeholder={placeholder}
      label={label}
      components={{ MultiValue: MultiSelectValue, ClearIndicator }}
      formatOptionLabel={formatOptionLabel}
      size={size}
    />
  );
};
