import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import { caption, poppinsRegularFont } from '@/styles/typography';
import styled from 'styled-components';

export const ChipWrapper = styled.div`
  ${flex({ alignItems: 'center' })}
  width: max-content;
  height: 30px;
  background-color: ${COLORS.grey[200]};
  border-radius: 8px;
  padding: 2px 8px;
  margin: 0 8px 8px 0;
`;

export const ChipTitle = styled.div`
  ${caption}
  ${poppinsRegularFont}
  line-height: 20px;
  color: ${COLORS.grey[850]};
`;

export const ChipButton = styled.div`
  cursor: pointer;
  color: rgb(34 37 40 / 70%);
  margin-left: 4px;

  &:hover {
    color: ${COLORS.black};
  }
`;
