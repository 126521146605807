import {
  SetInitialPasswordValidateTotpPayload,
  setInitialPasswordValidateTotpApi,
} from '@/api/v1/users/setInitialPasswordValidateTotpApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const setInitialPasswordValidateTotp = async (payload: SetInitialPasswordValidateTotpPayload) => {
  try {
    const response = await setInitialPasswordValidateTotpApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
