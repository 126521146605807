import {
  ForgotPasswordValidateTotpPayload,
  forgotPasswordValidateTotpApi,
} from '@/api/v1/users/forgotPasswordValidateTotpApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const forgotPasswordValidateTotp = async (payload: ForgotPasswordValidateTotpPayload) => {
  try {
    const response = await forgotPasswordValidateTotpApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
