import { FilterParamsType } from '@/api/helpers/queryHelpers';
import { REVERSED_DEFAULT_DATE_FORMAT } from '@/helpers/dateHelpers/const';
import { format, lastDayOfMonth, parseISO, subMonths } from 'date-fns';
import { BilledEventsFiltersType } from './useBilledEventsFilters';
import { OrganizationListItemType } from '@/bundle/_Opco/types/types';
import { getOrganizationOptions } from '../../ui/FilterList/helpers';

export const getInitialRangeDate = () => {
  const currentDate = new Date();
  const previousMonth = subMonths(currentDate, 1);

  const startDateFormatted = format(previousMonth, 'yyyy-MM-01');
  const endDateFormatted = format(lastDayOfMonth(previousMonth), REVERSED_DEFAULT_DATE_FORMAT);

  return {
    startDateFormatted,
    endDateFormatted,
  };
};

export const getQueryFilters = (filters: FilterParamsType) => {
  if (filters?.date_created_after && filters?.date_created_before) return filters;

  const dateRange = getInitialRangeDate();

  const dateFilters = {
    date_created_after: dateRange.startDateFormatted,
    date_created_before: dateRange.endDateFormatted,
  };

  return { ...filters, ...dateFilters };
};

export const getInitialFilters = (
  filters: FilterParamsType,
  organizations: OrganizationListItemType[]
): BilledEventsFiltersType => {
  const filteredOrganizations = organizations.filter((item) => filters.organization__id?.find((id) => item.id === id));
  const organizationsOptions = getOrganizationOptions(filteredOrganizations);

  return {
    dateStart: parseISO(filters.date_created_after),
    dateEnd: parseISO(filters.date_created_before),
    transactionName: filters.wire__contract__name || '',
    organizations: organizationsOptions,
  };
};

export const getString = (str?: string) => {
  return str && str.trim() !== '' ? str : undefined;
};

export const getArray = (arr?: string[]) => {
  return arr && Array.isArray(arr) && arr.length > 0 ? arr : undefined;
};
