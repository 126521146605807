import { forwardRef } from 'react';
import { DropdownLabel, DropdownLabelHighlight, DropdownOption } from './styles';
import { AutocompleteOptionType } from '../types';
import escapeRegExp from 'lodash/escapeRegExp';

type AutocompleteDropdownOptionType = {
  isActive: boolean;
  option: AutocompleteOptionType;
  onSelect: (option: AutocompleteOptionType) => void;
  searchValue: string;
  optionProps: Record<string, unknown>;
};

const renderLabel = (label: string, searchString: string) => {
  const splitRegex = new RegExp(escapeRegExp(searchString), 'gi');
  const hasMatch = searchString?.length && label?.split(splitRegex)?.length > 0;

  return (
    <>
      {hasMatch
        ? label?.split(splitRegex).map((word, index) => {
            const highlightLabel = label.match(splitRegex)?.[0] || '';

            return index > 0 ? (
              <DropdownLabel key={index}>
                <DropdownLabelHighlight>{highlightLabel}</DropdownLabelHighlight>
                {word}
              </DropdownLabel>
            ) : (
              word
            );
          })
        : label}
    </>
  );
};

export const AutocompleteDropdownOption = forwardRef<HTMLButtonElement, AutocompleteDropdownOptionType>(
  ({ isActive, option, onSelect, optionProps, searchValue }, ref) => {
    return (
      <DropdownOption {...optionProps} ref={ref} active={isActive} onClick={() => onSelect(option)}>
        {renderLabel(option.label, searchValue)}
      </DropdownOption>
    );
  }
);
