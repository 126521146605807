import { authApiService } from '@/api/authApiService';
import { ApiParams } from '@/api/helpers/urlApiHelpers';
import { ApiResponseType } from '@/types/sharedTypes';
import { AssignedWireType } from '@/types/wireTypes';

export type ResponseType = {
  count: number;
  next: string;
  previous: string;
  results: AssignedWireType[];
};

export const getTransactionsWiresApi = (
  transactionsId: string,
  params?: ApiParams
): Promise<ApiResponseType<ResponseType>> => {
  const uri = `/organization/contracts/${transactionsId}/wires/`;

  return authApiService.GET(uri, params);
};
