import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { phoneFormatter } from '@/helpers/formatHelpers';
import { ReactNode } from 'react';
import { UserInvitedNotification } from '../../Notifications/UserInvitedNotification';
import { WireUserType } from '@/types/wireTypes';

type UserGeneralDetailsType = {
  header: string;
  user: WireUserType;
  action?: ReactNode;
  hasInvitedNotification?: boolean;
  marker?: ReactNode;
};

export const UserDetailsWidget = ({ header, user, action, hasInvitedNotification, marker }: UserGeneralDetailsType) => {
  const { first_name, middle_name, last_name, email, mobile_phone } = user || {};
  const formattedPhoneNumber = phoneFormatter(mobile_phone);

  return (
    <DetailsWidget header={header} action={action} marker={marker}>
      {hasInvitedNotification && <UserInvitedNotification />}
      <DetailsRow name='First Name'>{first_name || '-'}</DetailsRow>
      <DetailsRow name='Middle Name'>{middle_name || '-'}</DetailsRow>
      <DetailsRow name='Last Name'>{last_name || '-'}</DetailsRow>
      <DetailsRow name='Email Address'>{email || '-'}</DetailsRow>
      <DetailsRow name='Mobile Phone Number'>{formattedPhoneNumber}</DetailsRow>
    </DetailsWidget>
  );
};
