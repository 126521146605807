import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { bodySmall, poppinsRegularFont } from '@/styles/typography';

export const RecipientTypeWrapper = styled.div`
  padding: 4px 8px;
  background-color: ${COLORS.grey[300]};
  color: ${COLORS.black};
  ${poppinsRegularFont};
  ${bodySmall};
  border-radius: 10px;
  white-space: nowrap;
`;
