import { DEFAULT_QUERY_PARAMS, FilterParamsType, QueryParamsStringType } from '@/api/helpers/queryHelpers';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';

export const useGetSearchParams = () => {
  const [searchParams] = useSearchParams();

  try {
    const params: QueryParamsStringType = queryString.parse(searchParams.toString(), {
      parseNumbers: true,
    });

    const page = params.page === undefined ? DEFAULT_QUERY_PARAMS.page : params.page;
    const limit = params.limit === undefined ? DEFAULT_QUERY_PARAMS.limit : params.limit;
    const ordering = params.ordering === undefined ? DEFAULT_QUERY_PARAMS.ordering : params.ordering;
    const search = params.search === undefined ? DEFAULT_QUERY_PARAMS.search : params.search;
    const email = params.email === undefined ? DEFAULT_QUERY_PARAMS.email : params.email;
    const filters: FilterParamsType =
      params.filters === undefined ? DEFAULT_QUERY_PARAMS.filters : JSON.parse(params.filters);

    return {
      page,
      limit,
      ordering,
      filters,
      search,
      email,
    };
  } catch (error) {
    console.error('Parsing query params failed with', error?.message);

    return {};
  }
};
