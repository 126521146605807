import * as Sentry from '@sentry/react';
import { envConfig } from '@/config/envConfig';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

export const sentryService = {
  init() {
    if (!envConfig.SENTRY_DSN_KEY) {
      console.warn('Sentry not enabled');
    }

    // Disable Sentry for localhost
    if (window.location.hostname === 'localhost') {
      return;
    }

    try {
      Sentry.init({
        dsn: envConfig.SENTRY_DSN_KEY,
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [],
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes
            ),
          }),

          Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 0.05, //  Capture 5% of the transactions since we're on free plan
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        environment: envConfig.ENV_NAME,
      });
    } catch (error) {
      console.error('Sentry init failed with', error?.message);
    }
  },
};
