import * as Yup from 'yup';
import { REQUIRED_TEXT_FIELD_VALIDATOR } from '@/helpers/formHelpers/validators';

interface EditDepositAccountValidationSchemaType {
  name: string;
}

export const editDepositAccountValidationSchema: Yup.AnySchema<EditDepositAccountValidationSchemaType> =
  Yup.object().shape({
    name: REQUIRED_TEXT_FIELD_VALIDATOR,
  });
