import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ReactNode, useEffect } from 'react';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { AutocompleteOptionType } from '@/components/Autocomplete/types';
import { Box } from '@/components/Box/Box';
import { ResponseErrorType } from '@/types/sharedTypes';
import { getAutocompleteOptions } from '@/helpers/formatHelpers';
import { EMAIL } from '@/const/regExpPatterns';
import { searchUsersByProxy } from './api';
import { NewUserNotification } from '@/bundle/shared/components/UserAssignmentNotifications';
import { PROXY_ASSIGN_RECIPIENT_NOTIFICATIONS_MAP, ProxyAssignUserStatusCodeType } from './const';
import { EMAIL_ALLOW_SEARCH_LENGTH } from '@/bundle/shared/components/AssignUserAutocomplete';

type ProxyAssignRecipientAutocompleteType = {
  selectedOption: AutocompleteOptionType;
  wireId: string;
  assignmentStatus: ProxyAssignUserStatusCodeType;
  onSelect: (selectedUser: AutocompleteOptionType) => void;
  disabled?: boolean;
  error?: string;
  apiError?: ResponseErrorType;
  labelSlot?: ReactNode;
};

export const ProxyAssignRecipientAutocomplete = ({
  selectedOption,
  wireId,
  assignmentStatus,
  onSelect,
  disabled,
  error,
  apiError,
  labelSlot,
}: ProxyAssignRecipientAutocompleteType) => {
  const queryClient = useQueryClient();
  const searchEmail = selectedOption?.value;
  const shouldLoadUsers = searchEmail?.length >= EMAIL_ALLOW_SEARCH_LENGTH && !selectedOption.isSelectedOption;

  const { data, isFetching } = useQuery({
    queryKey: ['search_users_by_proxy', searchEmail],
    queryFn: () => {
      return searchUsersByProxy(wireId, searchEmail);
    },
    enabled: shouldLoadUsers,
  });

  useEffect(() => {
    if (searchEmail?.length < EMAIL_ALLOW_SEARCH_LENGTH) {
      queryClient.resetQueries({ queryKey: ['search_users_by_proxy'], exact: false });
    }
  }, [searchEmail, queryClient]);

  const getAssignNotification = (statusCode: ProxyAssignUserStatusCodeType) => {
    if (!selectedOption) return;

    const isNewUser = !selectedOption.isSelectedOption && EMAIL.test(searchEmail);

    if (isNewUser) {
      return <NewUserNotification />;
    }

    return PROXY_ASSIGN_RECIPIENT_NOTIFICATIONS_MAP[statusCode];
  };

  const options = getAutocompleteOptions(data?.body?.results);
  const showNotifications = !error && !apiError && !disabled;
  const assignNotification = getAssignNotification(assignmentStatus);

  return (
    <>
      <Autocomplete
        name='email'
        label='Email Address*'
        placeholder='Enter or Select Email Address'
        options={options}
        isLoading={isFetching}
        disabled={disabled}
        onSelect={onSelect}
        apiError={apiError}
        hideError
        labelSlot={labelSlot}
      />
      {showNotifications && <Box>{assignNotification}</Box>}
    </>
  );
};
