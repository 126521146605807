import { parseISO } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from '@/helpers/dateHelpers/const';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';
import { WireType } from '@/types/wireTypes';

export const formatOutboundWire = (outboundWire = {} as WireType) => {
  const { name, ref_id, type, date_created, expected_payment_date, amount, currency, notes } = outboundWire;

  return {
    name,
    ref_id,
    type,
    date_created: formatDate(date_created, DEFAULT_DATE_FORMAT),
    expected_payment_date: expected_payment_date ? parseISO(expected_payment_date) : expected_payment_date,
    amount,
    currency,
    notes,
  };
};
