import { ListResponseType } from '../../../types/sharedTypes';
import { authApiService } from '@/api/authApiService';
import { PaymentTransaction } from '@/types/paymentTypes';
import { ApiResponseType } from '@/types/sharedTypes';

export const getPaymentTransactionsApi = async (): Promise<ApiResponseType<ListResponseType<PaymentTransaction>>> => {
  const uri = '/payment/transactions/';

  return authApiService.GET(uri);
};
