import { ApiResponseType } from '@/types/sharedTypes';
import { authApiService } from '../../authApiService';
import { ApiParams } from '../../helpers/urlApiHelpers';
import { AssignedWireType } from '@/types/wireTypes';

export type DepositorWiresListType = {
  count: number;
  next: string;
  previous: string;
  results: AssignedWireType[];
};

export const getDepositorWiresApi = (params?: ApiParams): Promise<ApiResponseType<DepositorWiresListType>> => {
  const uri = `/depositor/wires/`;

  return authApiService.GET(uri, params);
};
