import { WireType } from '@/types/wireTypes';
import { ApiResponseType } from '@/types/sharedTypes';
import { authApiService } from '@/api/authApiService';
import { ApiParams } from '@/api/helpers/urlApiHelpers';

export const updateWireApi = async (
  wireId: string,
  data: WireType,
  params?: ApiParams
): Promise<ApiResponseType<WireType>> => {
  const uri = `/organization/wires/${wireId}/`;

  return authApiService.PATCH(uri, data, params);
};
