import { Box } from '@/components/Box/Box';
import {
  InfoIcon,
  TimerWrapper,
  TooltipContentWrapper,
} from '@/bundle/_Recipient/_Details/ui/ProvidePaymentDetailsTimer/styles';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Button } from '@/components/Button/Button';
import { useEffect, useState } from 'react';
import { useTimer } from '@/hooks/useTimer';
import { formatSecondsToMinutes } from '@/helpers/dateHelpers/dateHelpers';
import { localStorageService } from '@/helpers/storageHelpers';

const STORAGE_KEY = 'PROVIDE_PAYMENT_DETAILS_TIMER_TOOLTIP_HAS_SHOWN';

type ProvidePaymentDetailsTimerType = {
  expiredPaymentDetailsTime: number;
  onResetProvidePaymentDetailsTimer: () => void;
};

export const ProvidePaymentDetailsTimer = ({
  expiredPaymentDetailsTime,
  onResetProvidePaymentDetailsTimer,
}: ProvidePaymentDetailsTimerType) => {
  const [isOpen, setIsOpen] = useState<null | boolean>(null);
  const [hasButtonTooltip, setHasButtonTooltip] = useState<null | boolean>(null);
  const { seconds } = useTimer(expiredPaymentDetailsTime, onResetProvidePaymentDetailsTimer);

  useEffect(() => {
    if (localStorageService.get(STORAGE_KEY) === false) {
      setHasButtonTooltip(false);
      setIsOpen(false);

      return;
    }

    setHasButtonTooltip(true);
    setIsOpen(true);
  }, []);

  const openTooltip = () => {
    setIsOpen(true);
  };

  const closeTooltip = () => {
    setIsOpen(false);
    setHasButtonTooltip(false);
    localStorageService.set(STORAGE_KEY, false);
  };

  const hoverCloseTooltip = () => {
    if (hasButtonTooltip) return;

    setIsOpen(false);
  };

  return (
    <Box display='flex' alignItems='center' columnGap='8px'>
      <Tooltip id='header-tooltip' offset={4} isOpen={isOpen} clickable>
        <TooltipContentWrapper>
          <div>You have 1 hour to provide your wire info before you must verify yourself again with a new selfie.</div>
          {hasButtonTooltip && (
            <Button type='button' width={51} size='medium' onClick={closeTooltip}>
              Ok
            </Button>
          )}
        </TooltipContentWrapper>
      </Tooltip>
      <TimerWrapper>{formatSecondsToMinutes(seconds)}</TimerWrapper>
      <InfoIcon data-tooltip-id='header-tooltip' onMouseEnter={openTooltip} onMouseLeave={hoverCloseTooltip} />
    </Box>
  );
};
