import { authApiService } from '../../authApiService';
import { ApiParams } from '../../helpers/urlApiHelpers';
import { ApiResponseType } from '@/types/sharedTypes';
import { AssignedWireType } from '@/types/wireTypes';

export const recipientChangeWireStatusApi = async (
  id: string,
  data: any,
  params?: ApiParams
): Promise<ApiResponseType<AssignedWireType>> => {
  const uri = `/recipient/wires/${id}/status/`;

  return authApiService.PATCH(uri, data, params);
};
