import styled from 'styled-components';
import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';
import { body, bodySmall, poppinsMediumFont, subTitle } from '@/styles/typography';
import { COLORS } from '@/styles/colors';
import { ellipsis } from '@/styles/common';

export const TransactionDetailsWrapper = styled.div`
  ${flex({ flexDirection: 'column' })};
  margin-bottom: 63px;
  border-radius: 16px;
  background: linear-gradient(90.49deg, #eefffa 0%, #f1f5ff 52.67%, #fff4f9 100%);
  box-shadow: rgb(0 39 32 / 15%) 0 4px 12px 0;
`;

export const TransactionDetailsHeading = styled.div<{ isEdit: boolean }>`
  ${({ isEdit }) =>
    isEdit
      ? flex({ justifyContent: 'space-between' })
      : flex({ justifyContent: 'space-between', alignItems: 'center' })};
  column-gap: 5%;
  padding: ${({ isEdit }) => (isEdit ? '18px 24px 0 8px' : '24px 24px 24px')};
`;

export const TransactionName = styled.div`
  ${poppinsMediumFont};
  ${subTitle};
  ${ellipsis};
  color: ${COLORS.grey[950]};
  width: 90%;
`;

export const TransactionDetailsFooter = styled.div`
  ${flex({ justifyContent: 'space-around', alignItems: 'start' })};
  padding: 0 24px 20px;
  gap: 30px;

  ${media.mobile`
    ${flex({ justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column' })};
    gap: 16px;
    padding: 0 16px 20px 16px;
  `}
`;

export const TransactionDetailsColumn = styled.div<{ ratio?: string }>`
  ${flex({ flexDirection: 'column' })};
  flex: 1;

  ${({ ratio }) =>
    ratio &&
    `
    flex: ${ratio};
  `};

  ${media.mobile`
    width: 100%;
    ${flex({ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' })};
  `}
`;

export const TransactionDetailsDateColumn = styled.div`
  ${flex({ flexDirection: 'column' })};
  flex: 1;

  ${media.mobile`
    width: 100%;
    ${flex({ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' })};
  `}
`;

export const TransactionDetailsColumnTitle = styled.div`
  ${bodySmall};
  color: ${COLORS.grey[600]};
  margin-bottom: 8px;
  flex-shrink: 0;

  ${media.mobile`
    margin-bottom: 0;
  `}
`;

export const TransactionDetailsColumnValue = styled.div`
  ${poppinsMediumFont};
  ${body};
  color: ${COLORS.black};
  ${ellipsis};

  ${media.mobile`
    margin-left: 8px;
  `}
`;
