import { UserAssignmentStatusCodeType } from '@/const/shared';
import { authApiService } from '../../authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

type AssignmentStatusType = {
  is_available_to_assign: true;
  code: UserAssignmentStatusCodeType;
};

export const getDepositorAssignmentStatusApi = async (
  userId: string
): Promise<ApiResponseType<AssignmentStatusType>> => {
  const uri = `/organization/depositors/users/${userId}/assignment-status/`;

  return authApiService.GET(uri);
};
