import { RefObject, useEffect, useState } from 'react';

const containsRelatedTarget = ({ currentTarget, relatedTarget }: FocusEvent) => {
  if (currentTarget instanceof HTMLElement && relatedTarget instanceof HTMLElement) {
    return currentTarget.contains(relatedTarget);
  }

  return false;
};

export function useHasFocusWithin(ref: RefObject<HTMLElement>): boolean {
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    const element = ref.current;

    if (!element) return;

    const handleFocusOut = (event: FocusEvent) => {
      if (!containsRelatedTarget(event)) {
        setFocused(false);
      }
    };

    const handleFocusIn = () => {
      setFocused(true);
    };

    element.addEventListener('focusin', handleFocusIn);
    element.addEventListener('focusout', handleFocusOut);

    return () => {
      element.removeEventListener('focusin', handleFocusIn);
      element.removeEventListener('focusout', handleFocusOut);
    };
  }, [setFocused, ref]);

  return focused;
}
