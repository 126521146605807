import { DefaultBillingAddressType } from '@/types/paymentTypes';
import { getCountryByCode } from '../EditBillingAddressForm/const';

export const getFormattedBillingAddress = (billingAddress?: DefaultBillingAddressType) => {
  const country = getCountryByCode(billingAddress?.country);
  const state = country?.regions?.find((item) => item.shortCode === billingAddress?.state);

  const address = billingAddress?.line_1 || billingAddress?.address_line_1 || '-';
  const city = billingAddress?.city || '-';
  const zip = billingAddress?.postal_code || '-';

  return {
    country: country?.countryName || '-',
    state: state?.name || '-',
    address,
    city,
    zip,
  };
};
