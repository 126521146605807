import { authApiService } from '../../authApiService';
import { ApiParams } from '../../helpers/urlApiHelpers';
import { ApiResponseType } from '@/types/sharedTypes';

export type ChangeStatusDataType = {
  status: string;
};

export const executorChangeWireStatusApi = async (
  id: string,
  data: ChangeStatusDataType,
  params?: ApiParams
): Promise<ApiResponseType<any>> => {
  const uri = `/executor/wires/${id}/status/`;

  return authApiService.PATCH(uri, data, params);
};
