import { useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AuthLayout } from '@/bundle/Auth/ui/AuthLayout/AuthLayout';
import { CheckPinCodeForm } from './ui/CheckPinCodeForm/CheckPinCodeForm';
import { checkAuthPinCode } from './api';
import { useAuthNavigateToStep } from '@/bundle/Auth/hooks/useAuthNavigateToStep';

export const CheckPinCodePage = () => {
  const checkPinCodeRef = useRef(null);
  const queryClient = useQueryClient();
  const navigateToStep = useAuthNavigateToStep();

  const {
    mutate,
    data: checkPinCodeData,
    isPending: isCheckPinCodePending,
    reset,
  } = useMutation({
    mutationKey: ['check_pin_code'],
    mutationFn: (pinCode: string) => {
      const payload = {
        pin: pinCode,
      };

      return checkAuthPinCode(payload);
    },
    onSuccess(response) {
      if (response?.error) {
        queryClient.invalidateQueries({ queryKey: ['get_attempts_count'] });
        checkPinCodeRef?.current?.resetPinCode();

        return;
      }

      navigateToStep(response.body);
    },
  });

  return (
    <AuthLayout>
      <CheckPinCodeForm
        ref={checkPinCodeRef}
        onConfirm={mutate}
        onReset={reset}
        isLoading={isCheckPinCodePending}
        error={checkPinCodeData?.error}
      />
    </AuthLayout>
  );
};
