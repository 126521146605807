import { ReactNode } from 'react';
import { To } from 'react-router-dom';
import { NavigationLinkWrapper } from './styles';

type VariantType = 'primary' | 'secondary';

type NavigationLinkType = {
  children: ReactNode;
  to: To;
  variant?: VariantType;
  onClick?: () => void;
  state?: {};
};

export const NavigationLink = ({ to, children, variant = 'primary', onClick, state }: NavigationLinkType) => {
  return (
    <NavigationLinkWrapper to={to} variant={variant} onClick={onClick} state={state}>
      {children}
    </NavigationLinkWrapper>
  );
};
