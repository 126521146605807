import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

export type UpdateExecutorPayloadType = {
  mobile_phone: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
};

export const updateExecutorApi = (wireId: string, data: UpdateExecutorPayloadType): Promise<ApiResponseType<any>> => {
  const uri = `/organization/wires/${wireId}/executor/`;

  return authApiService.PATCH(uri, data);
};
