import { PAYMENT_TYPE_DB } from '@/const/shared';
import { PaymentDetailsGeneralType, PaymentType } from '@/types/paymentDetailsTypes';
import { AssignedWireType } from '@/types/wireTypes';

export const isDomesticPaymentType = (paymentType: PaymentType) => paymentType === PAYMENT_TYPE_DB.DOMESTIC;
export const isInternationalPaymentType = (paymentType: PaymentType) => paymentType === PAYMENT_TYPE_DB.INTERNATIONAL;

export const getShowPaymentDetails = (
  hasPaymentDetails: boolean,
  wire: AssignedWireType,
  hasBillingEvent?: boolean
): boolean => {
  if (!wire) return;

  const { is_payment_details_exist, view_payment_details_event_register } = wire;

  if (hasBillingEvent && wire && hasPaymentDetails) return true;

  if (is_payment_details_exist && view_payment_details_event_register) return true;

  return false;
};

export const hasIntermediaryPaymentDetails = (paymentDetails: PaymentDetailsGeneralType): boolean => {
  if (!paymentDetails) return;

  const { internal_payment_details_data, blockchain_payment_details_data } = paymentDetails;
  const isDomesticPayment = isDomesticPaymentType(internal_payment_details_data?.payment_type);
  const isInternationalPayment = isInternationalPaymentType(internal_payment_details_data?.payment_type);

  return (
    (isDomesticPayment && !!blockchain_payment_details_data?.intermediary_bank_aba_number?.length) ||
    (isInternationalPayment && !!blockchain_payment_details_data?.intermediary_bank_swift_code?.length)
  );
};
