import { envConfig } from '@/config/envConfig';
import { defaultFilterParams, FilterParamsType, QueryParamsType } from './queryHelpers';

type DefaultListParamsType = {
  limit: number;
  offset: number;
};

export interface ApiParams extends FilterParamsType {
  limit?: number;
  offset?: number;
  ordering?: string;
  search?: string;
}

type URLSearchParams = Record<string, string>;

export const prepareOffset = (queryParams: QueryParamsType, defaultListParams: DefaultListParamsType) => {
  if (queryParams.page) {
    return queryParams.limit ? queryParams.limit * queryParams.page : defaultListParams.limit * queryParams.page;
  }

  return defaultListParams.offset;
};

export const urlApiHelper = (uri: string, params?: ApiParams) => {
  let finalUrl = `${envConfig.API_URL}${uri}`;

  if (params) {
    const searchParamsString = new URLSearchParams(params as URLSearchParams).toString().replace('%2C', ',');

    finalUrl = `${finalUrl}?${searchParamsString}`;
  }

  return finalUrl;
};

export const defaultListParams: DefaultListParamsType = {
  limit: 15,
  offset: 0,
};

export const prepareListApiParams = (queryParams?: QueryParamsType, hasPaging = true): ApiParams => {
  const params: ApiParams = {};

  if (hasPaging) {
    params.limit = queryParams.limit ? queryParams.limit : defaultListParams.limit;
    params.offset = prepareOffset(queryParams, defaultListParams);
  }

  if (queryParams.ordering) {
    params.ordering = queryParams.ordering;
  }

  if (queryParams.filters) {
    const filterValues: FilterParamsType = queryParams.filters;
    const filterNames = Object.keys(defaultFilterParams) as (keyof FilterParamsType)[];

    filterNames.forEach((filterName) => {
      const value = filterValues[filterName];

      if (value) {
        // @ts-ignore
        params[filterName] = Array.isArray(value) ? value.map((item) => item?.value || item).join(',') : value;
      }
    });
  }

  if (queryParams.search) {
    params.search = queryParams.search;
  }

  return params;
};
