import { HTTP_UNAUTHORIZED_STATUS } from '@/api/const';

export class RequiredAuthError extends Error {
  status: number;

  constructor(errorText = 'Token is invalid or expired') {
    super(errorText);

    this.name = 'RequiredAuthError';
    this.status = HTTP_UNAUTHORIZED_STATUS;
  }
}
