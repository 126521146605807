import { CSSProperties, ReactNode } from 'react';
import { PageContentWrapper } from './styles';

type PageContentType = {
  children: ReactNode;
  flexDirection?: CSSProperties['flexDirection'];
};

export const PageContent = ({ children, flexDirection }: PageContentType) => {
  return <PageContentWrapper flexDirection={flexDirection}>{children}</PageContentWrapper>;
};
