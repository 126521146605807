import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import {
  PersonalDetailsVerificationPayload,
  sendPersonalDetailsVerificationApi,
} from '@/api/v1/users/sendPersonalDetailsVerificationApi';

export const personalDetailsVerification = async (payload: PersonalDetailsVerificationPayload) => {
  try {
    const response = await sendPersonalDetailsVerificationApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
