import {
  ForgotPasswordSetPasswordPayload,
  forgotPasswordSetPasswordApi,
} from '@/api/v1/users/forgotPasswordSetPasswordApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const forgotPasswordSetPassword = async (payload: ForgotPasswordSetPasswordPayload) => {
  try {
    const response = await forgotPasswordSetPasswordApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
