import { ReactComponent as NoOptionsIcon } from '../images/NoOptionsIcon.svg';
import { Message, NoOptionsComponentWrapper } from './styles';

type NoOptionsMessageType = {
  message: string;
};

export const NoOptionsMessage = ({ message }: NoOptionsMessageType) => {
  return (
    <NoOptionsComponentWrapper>
      <NoOptionsIcon />
      <Message>{message}</Message>
    </NoOptionsComponentWrapper>
  );
};
