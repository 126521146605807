import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { bodySmall, poppinsMediumFont, subTitleSmall } from '@/styles/typography';
import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';

export const FormWrapper = styled.div``;

export const Row = styled.div`
  padding: 0 0 36px 0;
  ${flex({ alignItems: 'center', justifyContent: 'left' })};
`;

export const HeaderAction = styled.div`
  cursor: pointer;
  color: ${COLORS.blue[500]};
  ${poppinsMediumFont};
  ${bodySmall};
`;

export const HighlightRow = styled.div`
  padding: 10px 28px;
  ${flex({ alignItems: 'center', justifyContent: 'space-between' })};
  background-color: ${COLORS.grey[300]};
  margin: 0 -28px 24px -28px;

  ${media.mobile`
    margin: 0 -16px 24px -16px;  
  `}
`;

export const HighlightRowTitle = styled.div`
  ${flex({ alignItems: 'center' })};
  ${poppinsMediumFont};
  ${subTitleSmall};
  color: ${COLORS.black};
`;
