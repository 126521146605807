import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import styled from 'styled-components';

export const CommonLayoutWrapper = styled.div`
  border-radius: 20px;
  border: 1px solid ${COLORS.grey[400]};
  padding: 24px;
`;

export const PaymentColumn = styled.div`
  flex: 1;
  ${flex({ flexDirection: 'column' })}
  row-gap: 24px;
  overflow-y: auto;
`;
