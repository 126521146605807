import { authApiService } from '../../authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

export type SetInitialPasswordValidateTotpPayload = {
  user_id: string;
  token: string;
  token_type: string;
};

type SetInitialPasswordValidateTotpResponseType = {};

export const setInitialPasswordValidateTotpApi = async (
  data: SetInitialPasswordValidateTotpPayload
): Promise<ApiResponseType<SetInitialPasswordValidateTotpResponseType>> => {
  const uri = '/users/totp/';

  return authApiService.POST(uri, data);
};
