import { authApiService } from '@/api/authApiService';
import { ApiResponseType, ListResponseType } from '@/types/sharedTypes';

export type SearchUserType = {
  id: string;
  email: string;
};

export type SearchUsersByProxyPayloadType = {
  search: string;
};

export const getSearchUsersByProxyApi = (
  wireId: string,
  data: SearchUsersByProxyPayloadType
): Promise<ApiResponseType<ListResponseType<SearchUserType>>> => {
  const uri = `/recipient/wires/${wireId}/users/search/`;

  return authApiService.GET(uri, data);
};
