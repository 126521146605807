import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AuthLayout } from '@/bundle/Auth/ui/AuthLayout/AuthLayout';
import { sendTwoFACode, sendTwoFA } from './api';
import { TwoFAForm } from './ui/TwoFAForm/TwoFAForm';
import { useAuthNavigateToStep } from '../../hooks/useAuthNavigateToStep';

export const TwoFAPage = () => {
  const navigateToStep = useAuthNavigateToStep();
  const twoFAFormRef = useRef(null);

  const {
    mutate: sendTwoFAMutate,
    data: sendTwoFAData,
    isPending: isSendTwoFAPending,
  } = useMutation({
    mutationKey: ['send_two_fa'],
    mutationFn: (twoFACode: string) => {
      return sendTwoFA(twoFACode);
    },
    onSuccess(sendTwoFAResponse) {
      if (sendTwoFAResponse?.error) return;

      return navigateToStep(sendTwoFAResponse?.body);
    },
    onSettled() {
      twoFAFormRef?.current?.resetCodeChanged();
    },
  });

  const {
    mutate: sendCode,
    data: sendCodeData,
    isPending: isSendCodePending,
  } = useMutation({
    mutationKey: ['send_code'],
    mutationFn: () => {
      return sendTwoFACode();
    },
    onSuccess(sendCodeResponse) {
      const retryAfter = sendCodeResponse?.headers?.retryAfter;

      twoFAFormRef?.current?.resetTimer(retryAfter);

      if (sendCodeResponse?.error) return;
    },
  });

  const confirmTwoFA = (twoFACode: string) => {
    sendTwoFAMutate(twoFACode);
  };

  return (
    <AuthLayout>
      <TwoFAForm
        ref={twoFAFormRef}
        isTwoFALoading={isSendTwoFAPending}
        isSendCodeLoading={isSendCodePending}
        twoFAError={sendTwoFAData?.error}
        sendCodeError={sendCodeData?.error}
        onConfirm={confirmTwoFA}
        onResend={sendCode}
      />
    </AuthLayout>
  );
};
