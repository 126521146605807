import { media } from '@/styles/responsive';
import styled from 'styled-components';

export const Wrapper = styled.div<{ gridColumn: string }>`
  display: flex;
  grid-column: ${({ gridColumn }) => gridColumn};
  min-width: 0;
  position: relative;
  z-index: 2;
`;

export const BlockWrapper = styled.div<{
  columns: number;
  columnsWidth: string;
  marginTop?: number;
}>`
  display: grid;
  grid-template-columns: ${({ columns = 0, columnsWidth }) =>
    columnsWidth ? `${columnsWidth}` : columns ? `repeat(${columns}, 1fr)` : `none`};
  column-gap: 1.67vw;
  margin-top: ${({ marginTop = 0 }) => `${marginTop}px`};

  ${media.tablet`
    column-gap: 0;

    & > ${Wrapper} {
      grid-column: 1 / 3;
    }
  `}
`;

export const DividerWrapper = styled(Wrapper)`
  margin: 0 -28px;

  ${media.mobile`
    margin: 0 -16px;
  `}
`;

export const RadioGroupWrapper = styled(Wrapper)`
  margin-bottom: 22px;
`;

export const PhoneInputWrapper = styled(Wrapper)`
  z-index: 4;
`;
