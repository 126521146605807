import * as Yup from 'yup';
import { isEmptyObject } from '../objectHelpers';
import { OptionType } from '@/components/Select/Select';
import { FieldMetaProps } from 'formik';
import { ResponseErrorType } from '@/types/sharedTypes';
import { ReactNode } from 'react';
import isEqual from 'lodash/isEqual';
import { SelectableTabsOptionType } from '@/components/SelectableTabs/SelectableTabs';

export type FormValues = {
  [key: string]: unknown;
};

type FieldComponentType =
  | 'radio'
  | 'select'
  | 'textarea'
  | 'phone_input'
  | 'datepicker'
  | 'divider'
  | 'external'
  | 'password'
  | 'confirm'
  | 'selectable_tabs'
  | 'switch_toggle'
  | 'custom';

export type FieldType = {
  name: string;
  label: string;
  value?: string | boolean | OptionType;
  placeholder?: string;
  autoComplete?: string;
  validator?: Yup.AnySchema;
  options?: Array<{ label: string; value: string }>;
  type?: FieldComponentType;
  column?: number;
  disabled?: boolean;
  isConfirmTicks?: boolean;
  isCopyPasteDisabled?: boolean;
  meta?: {
    textareaRows?: number;
    isSearchable?: boolean;
    hasErrorComponent?: boolean;
    iconRight?: ReactNode;
    selectableTabsOptions?: SelectableTabsOptionType[];
    labelSlot?: ReactNode;
    mobileStretch?: boolean;
  };
};

export type FormConfigType = FieldType[];

export const getFormInitialValues = (formValues: FormValues, initialValue: any = '') => {
  return Object.keys(formValues).reduce((result, key) => {
    result[key] = initialValue;

    return result;
  }, {});
};

export const isValidForm = async (formik: any) => {
  formik.setTouched(getFormInitialValues(formik.values, true));

  // Formik validating errors asynchronously, so wait until it's finished
  const errorState = await formik.validateForm();

  return isEmptyObject(errorState);
};

export const getValidationSchema = (config: FormConfigType): Yup.ObjectSchema<{}> => {
  const shape = {};

  config.forEach((field) => {
    if (!field.validator) return;
    shape[field.name] = field.validator;
  });

  const schema = Yup.object().shape(shape);

  return schema;
};

export const getInitialValues = <T extends FormValues>(config: FormConfigType): T => {
  return config.reduce((result, current) => {
    result[current.name] = current.value ?? '';

    return result;
  }, {}) as T;
};

export const getMergedValues = <T extends FormValues>(config: FormConfigType, values: unknown = {}): T => {
  return config.reduce((result, current) => {
    if (!current.name) return result;

    if (current.type === 'select') {
      const selectedOption = current.options?.find((option) => option.value === values[current.name]);

      result[current.name] = selectedOption;
    } else {
      result[current.name] = values[current.name] ?? '';
    }

    return result;
  }, {}) as T;
};

export const getFieldError = (name: string, meta: FieldMetaProps<unknown>, apiError: ResponseErrorType) => {
  const hasError = !!(meta.touched && meta.error);
  const apiErrorMessage = apiError?.error_content[name] as string;
  const error = hasError ? meta.error : null;

  return {
    error,
    apiError: apiErrorMessage,
    hasAnyError: !!error || !!apiErrorMessage,
  };
};

export const getChangedFormValues = <T>(formValues: FormValues, initialValues: FormValues): T | null => {
  const changedValues = Object.entries(formValues).reduce((result, [key, value]) => {
    const hasChanged = !isEqual(initialValues[key], value);

    if (hasChanged) {
      result[key] = value;
    }

    return result;
  }, {});

  return isEmptyObject(changedValues) ? null : (changedValues as T);
};
