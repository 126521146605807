import { useMutation } from '@tanstack/react-query';
import { forgotPasswordValidateTotp } from './api';
import { ForgotPasswordValidateTotpPayload } from '@/api/v1/users/forgotPasswordValidateTotpApi';
import { useEffect, useState } from 'react';
import { getLoginUrl } from '../../LoginFlow/LoginPage/urls/getLoginUrl';
import { AuthLayout } from '../../ui/AuthLayout/AuthLayout';
import { LayoutSpinner } from '@/bundle/Layouts/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForgotPasswordNavigateToStep } from '../hooks/useForgotPasswordNavigateToStep';
import { ACTIVATION_TOKEN_INVALID_RESPONSE } from '@/api/const';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { getForgotPasswordLinkExpiredUrl } from '../const';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const ForgotPasswordValidateTotpPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const navigateToStep = useForgotPasswordNavigateToStep();
  const [isValidatingTotp, setIsValidatingTotp] = useState(true);

  const { mutate } = useMutation({
    mutationKey: ['validate_totp'],
    mutationFn: (payload: ForgotPasswordValidateTotpPayload) => {
      return forgotPasswordValidateTotp(payload);
    },
    onSuccess(validateTotpResponse) {
      if (validateTotpResponse?.error) {
        const errorContent = getResponseError(validateTotpResponse?.error);

        if (errorContent === ACTIVATION_TOKEN_INVALID_RESPONSE) {
          return navigate(getForgotPasswordLinkExpiredUrl());
        }

        return;
      }

      navigateToStep(validateTotpResponse?.body);
    },
    onSettled: () => {
      setIsValidatingTotp(false);
    },
  });

  useEffect(() => {
    const token = searchParams.get('token');
    const userId = searchParams.get('user_id');

    if (!token || !userId) {
      return navigate(getLoginUrl());
    }

    const payload = {
      token,
      user_id: userId,
    };

    amplitudeService.logEvent(AMPLITUDE_EVENTS.ForgotPasswordValidateTotp);

    mutate(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isValidatingTotp) {
    return (
      <AuthLayout>
        <LayoutSpinner />
      </AuthLayout>
    );
  }

  return null;
};
