import styled from 'styled-components';
import { poppinsMediumFont, subTitle } from '@/styles/typography';
import { COLORS } from '@/styles/colors';

export const WidgetExecutorWrapper = styled.div``;

export const IntermediaryBankBlock = styled.div`
  width: 100%;
  padding: 24px 0 0;
  margin: 10px 0 16px;
  border-top: 1px solid #bec2cc;
  color: #27292d;
  font-family: Poppins-Medium, sans-serif;
  font-size: 16px;
  line-height: 30px;
`;

export const AssignRecipientFormWrapper = styled.div`
  width: 100%;
  padding: 28px 28px 36px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.grey[850]};
  box-shadow: 0 4px 20px rgb(0 0 0 / 20%);
  border-radius: 20px;
`;

export const AssignRecipientFormHeader = styled.div`
  width: 100%;
  ${poppinsMediumFont};
  ${subTitle};
  text-align: start;
  color: ${COLORS.black};
  margin-bottom: 8px;
`;

export const AssignExecutorFormWrapper = styled(AssignRecipientFormWrapper)``;
