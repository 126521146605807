import { css } from 'styled-components';

// [Style guide]:
// https://www.figma.com/file/KeVG72CQz6YCz7pWMRTyGB/WireVault-DEV?type=design&mode=design&t=J3rCTamuHv4xCrPR-0

export const createFontSize = ({ fontSize = '14px', lineHeight = '20px', letterSpacing = 'normal' } = {}): string => `
  font-size: ${fontSize};
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing};
`;

export const poppinsRegularFont = css`
  font-family: Poppins-Regular, sans-serif;
`;

export const poppinsMediumFont = css`
  font-family: Poppins-Medium, sans-serif;
`;

export const poppinsSemiBoldFont = css`
  font-family: Poppins-SemiBold, sans-serif;
`;

export const poppinsBoldFont = css`
  font-family: Poppins-Bold, sans-serif;
`;
export const headline = createFontSize({ fontSize: '42px', lineHeight: '58px' });

export const headline1 = createFontSize({ fontSize: '40px', lineHeight: '56px' });

export const headline2 = createFontSize({ fontSize: '32px', lineHeight: '44px' });

export const headline3 = createFontSize({ fontSize: '28px', lineHeight: '42px' });

export const headline4 = createFontSize({ fontSize: '24px', lineHeight: '32px' });

export const headline5 = createFontSize({ fontSize: '22px', lineHeight: '33px', letterSpacing: '1px' });

export const subTitle = createFontSize({ fontSize: '20px', lineHeight: '30px' });

export const subTitleSmall = createFontSize({ fontSize: '18px', lineHeight: '30px' });

export const body = createFontSize({ fontSize: '16px', lineHeight: '24px' });

export const bodySmall = createFontSize({ fontSize: '14px', lineHeight: '20px' });

export const bodySmall2 = createFontSize({ fontSize: '13px', lineHeight: '18px' });

export const caption = createFontSize({ fontSize: '12px', lineHeight: '16px' });

export const captionSmall = createFontSize({ fontSize: '10px', lineHeight: '16px' });

export const TYPOGRAPHY_VARIANT_MAP = {
  headline,
  headline1,
  headline2,
  headline3,
  headline4,
  headline5,
  subTitle,
  subTitleSmall,
  body,
  bodySmall,
  bodySmall2,
  caption,
  captionSmall,
} as const;

export const TYPOGRAPHY_FONT_WEIGHT_MAP = {
  regular: poppinsRegularFont,
  medium: poppinsMediumFont,
  semibold: poppinsSemiBoldFont,
  bold: poppinsBoldFont,
} as const;

export type TypographyVariantType = keyof typeof TYPOGRAPHY_VARIANT_MAP;
export type TypographyFontWeightType = keyof typeof TYPOGRAPHY_FONT_WEIGHT_MAP;
