import { FormConfigType } from '@/helpers/formHelpers/formHelpers';
import { FormWrapper, Row, HighlightRow, HeaderAction, HighlightRowTitle } from './styles';
import { ResponseErrorType } from '@/types/sharedTypes';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';

type DepositIntermediaryBankFormType = {
  isOpen: boolean;
  onToggle: () => void;
  error?: ResponseErrorType;
  config: FormConfigType;
};

export const DepositIntermediaryBankForm = ({ isOpen, onToggle, error, config }: DepositIntermediaryBankFormType) => {
  return (
    <FormWrapper>
      {isOpen ? (
        <>
          <HighlightRow>
            <HighlightRowTitle>Intermediary Bank</HighlightRowTitle>
            <HeaderAction onClick={onToggle}>Remove</HeaderAction>
          </HighlightRow>

          <FormGeneratorNew config={config} apiError={error} />
        </>
      ) : (
        <Row>
          <HeaderAction onClick={onToggle}>+ Add Intermediary Bank</HeaderAction>
        </Row>
      )}
    </FormWrapper>
  );
};
