import { Route } from 'react-router-dom';
import { getOpcoOrganizationUsersPath } from '../urls/getOpcoOrganizationUsersUrl';
import { OrganizationUsersPage } from '../OrganizationUsersPage';
import { OrganizationUserDetailsPage } from '../Details/OrganizationUserDetailsPage';
import { getOrganizationUserDetailsPath } from '../Details/urls/getOrganizationUserDetails';

export const OrganizationRoutes = [
  <Route
    key={getOpcoOrganizationUsersPath()}
    path={getOpcoOrganizationUsersPath()}
    element={<OrganizationUsersPage />}
  />,
  <Route
    key={getOrganizationUserDetailsPath()}
    path={getOrganizationUserDetailsPath()}
    element={<OrganizationUserDetailsPage />}
  />,
];
