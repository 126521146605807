import { DependencyList, useEffect, useRef } from 'react';

export const useEffectOnDependencyChange = (effect: () => void, dependencies: DependencyList) => {
  const previousDependencies = useRef(dependencies);

  useEffect(() => {
    const hasDependenciesChanges = !previousDependencies.current.every(
      (previousDependency, i) => previousDependency === dependencies[i]
    );

    if (hasDependenciesChanges) {
      effect();
      previousDependencies.current = dependencies;
    }
  }, [dependencies, effect]);
};
