import { COLORS } from '@/styles/colors';
import { poppinsMediumFont, poppinsSemiBoldFont } from '@/styles/typography';
import styled from 'styled-components';

export const PaymentDetailsContainer = styled.div`
  padding: 16px;
  border-radius: 10px;
  background-color: ${COLORS.grey[200]};
`;

export const SourceWireDivider = styled.div`
  margin: 24px 0 20px;
  border-top: 1px solid ${COLORS.grey[400]};
`;

export const PaymentDetailsDivider = styled(SourceWireDivider)`
  margin: 24px 0;
`;

export const Title = styled.span`
  color: ${COLORS.grey[700]};
`;

export const IntermediaryDetailsHeader = styled.div`
  ${poppinsSemiBoldFont};
  color: ${COLORS.grey[950]};
  margin-bottom: 16px;
`;

export const SourceWireHeader = styled.div`
  ${poppinsMediumFont};
  color: ${COLORS.black};
  margin-bottom: 12px;
`;
