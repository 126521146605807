import styled from 'styled-components';
import { flex } from '@/styles/layout';
import { COLORS } from '@/styles/colors';
import { Input, InputSmall } from '../../fields/FormikInput/styles';
import { InputSizeVariant } from '@/components/Input/styles';

export const BaseIcon = styled.div<{ variant: InputSizeVariant }>`
  ${flex({ alignItems: 'center' })};
  position: absolute;
  bottom: 15px;
  cursor: pointer;
  right: 50px;

  ${({ variant }) => variant === 'small' && `bottom: 11px`};
`;

export const CrossIconWrapper = styled(BaseIcon)`
  display: none;
`;

export const DatePickerIconWrapper = styled(BaseIcon)`
  right: 18px;
  pointer-events: none;
`;

export const SelectMonthWrapper = styled.div`
  width: 60%;
`;

export const SelectYearWrapper = styled.div`
  width: 40%;
`;

export const DatePickerInputWrapper = styled.div`
  position: relative;

  ${Input}:focus ~ ${CrossIconWrapper},
  ${InputSmall}:focus ~ ${CrossIconWrapper} {
    ${flex()};
  }

  &:hover {
    ${Input}:not(:focus),
    ${InputSmall}:not(:focus) {
      border: 1px solid ${COLORS.grey[700]};
      background: ${COLORS.grey[250]};
    }

    ${CrossIconWrapper} {
      ${flex()};
    }
  }
`;
