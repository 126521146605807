import { getSelfUserDetailsApi } from '@/api/v1/users/getSelfUserDetailsApi';
import { LoginPayload, loginApi } from '@/api/v1/users/public/loginApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const login = async (payload: LoginPayload) => {
  try {
    const response = await loginApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadSelfUserDetails = async () => {
  try {
    const response = await getSelfUserDetailsApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
