import { useState } from 'react';
import {
  DOMESTIC_FORM_CONFIG,
  DOMESTIC_WITH_INTERMEDIARY_FORM_CONFIG,
  INTERMEDIARY_DOMESTIC_FORM_CONFIG,
  INTERMEDIARY_INTERNATIONAL_FORM_CONFIG,
  INTERNATIONAL_FORM_CONFIG,
  INTERNATIONAL_WITH_INTERMEDIARY_FORM_CONFIG,
} from '../const/const';
import { FormConfigType, getValidationSchema } from '@/helpers/formHelpers/formHelpers';
import { PaymentType } from '@/types/paymentDetailsTypes';
import { PAYMENT_TYPE_DB } from '@/const/shared';
import { isDomesticPaymentType, isInternationalPaymentType } from '@/helpers/paymentDetailsHelpers';

export const useCreateDepositAccountForm = () => {
  const [config, setConfig] = useState(DOMESTIC_FORM_CONFIG);
  const [intermediaryConfig, setIntermediaryConfig] = useState([]);
  const [validationSchema, setValidationSchema] = useState(getValidationSchema(config));
  const [currentPaymentType, setCurrentPaymentType] = useState<PaymentType>(PAYMENT_TYPE_DB.DOMESTIC as PaymentType);

  const updateForm = (formValues, isOpenIntermediaryBank: boolean) => {
    let mergedConfig: FormConfigType = [];

    setCurrentPaymentType(formValues.payment_type);

    if (formValues.payment_type === PAYMENT_TYPE_DB.INTERNATIONAL && isOpenIntermediaryBank) {
      mergedConfig = INTERNATIONAL_WITH_INTERMEDIARY_FORM_CONFIG;
      setConfig(INTERNATIONAL_FORM_CONFIG);
      setIntermediaryConfig(INTERMEDIARY_INTERNATIONAL_FORM_CONFIG);
    }

    if (formValues.payment_type === PAYMENT_TYPE_DB.INTERNATIONAL && !isOpenIntermediaryBank) {
      mergedConfig = INTERNATIONAL_FORM_CONFIG;
      setConfig(INTERNATIONAL_FORM_CONFIG);
      setIntermediaryConfig([]);
    }

    if (formValues.payment_type === PAYMENT_TYPE_DB.DOMESTIC && isOpenIntermediaryBank) {
      mergedConfig = DOMESTIC_WITH_INTERMEDIARY_FORM_CONFIG;
      setConfig(DOMESTIC_FORM_CONFIG);
      setIntermediaryConfig(INTERMEDIARY_DOMESTIC_FORM_CONFIG);
    }

    if (formValues.payment_type === PAYMENT_TYPE_DB.DOMESTIC && !isOpenIntermediaryBank) {
      mergedConfig = DOMESTIC_FORM_CONFIG;
      setConfig(DOMESTIC_FORM_CONFIG);
      setIntermediaryConfig([]);
    }

    setValidationSchema(getValidationSchema(mergedConfig));
  };

  const isDomesticPayment = isDomesticPaymentType(currentPaymentType);
  const isInternationalPayment = isInternationalPaymentType(currentPaymentType);

  return {
    updateForm,
    config,
    intermediaryConfig,
    validationSchema,
    isDomesticPayment,
    isInternationalPayment,
  };
};
