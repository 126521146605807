import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

type ExecutorType = {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  mobile_phone: string;
  type: string;
  email: string;
  language: string;
  date_verified: string;
};

export type CreateExecutorResponseType = {
  id: string;
  user: ExecutorType;
};

export type CreateExecutorPayloadType = {
  email: string;
  mobile_phone: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
};

export const createExecutorApi = (
  wireId: string,
  data: CreateExecutorPayloadType
): Promise<ApiResponseType<CreateExecutorResponseType>> => {
  const uri = `/organization/wires/${wireId}/executor/`;

  return authApiService.POST(uri, data);
};
