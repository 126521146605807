import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { PROXY_ASSIGN_USER_STATUS_CODE_MAP } from '../ProxyAssignRecipientAutocomplete/const';

export const PROXY_ASSIGN_RECIPIENT_SUCCESS_AMPLITUDE_EVENTS_MAP = {
  [PROXY_ASSIGN_USER_STATUS_CODE_MAP.IS_SAME_ROLE_AND_INVITED_BUT_CREATED_BY_SAME_RECIPIENT_PROXY]:
    AMPLITUDE_EVENTS.ProxyAssignInvitedRecipient,
  [PROXY_ASSIGN_USER_STATUS_CODE_MAP.CAN_BE_ASSIGNED]: AMPLITUDE_EVENTS.ProxyAssignExistingRecipient,
};

export const PROXY_ASSIGN_RECIPIENT_ERROR_AMPLITUDE_EVENTS_MAP = {
  [PROXY_ASSIGN_USER_STATUS_CODE_MAP.IS_SAME_ROLE_AND_INVITED_BUT_CREATED_BY_ANOTHER_RECIPIENT_PROXY]:
    AMPLITUDE_EVENTS.ProxyAssignNotAnotherOrganizationRegisteredRecipient,
  [PROXY_ASSIGN_USER_STATUS_CODE_MAP.IS_NOT_SAME_ROLE_AND_INVITED]: AMPLITUDE_EVENTS.ProxyAssignNotRegisteredRecipient,
  [PROXY_ASSIGN_USER_STATUS_CODE_MAP.IS_SAME_USER]: AMPLITUDE_EVENTS.ProxyAssignYourself,
  [PROXY_ASSIGN_USER_STATUS_CODE_MAP.USER_ASSOCIATED_WITH_ORGANIZATION_THAT_ORIGINATE_WIRE]:
    AMPLITUDE_EVENTS.ProxyAssignOrgAdminThatOriginatesWire,
};
