import { useQuery } from '@tanstack/react-query';
import { AssignedWireType } from '@/types/wireTypes';
import {
  getIsEnabledTrustStampDetailsRequest,
  isTrustStampFailedStatus,
  isTrustStampNotInitializedStatus,
  isTrustStampSucceedStatus,
} from '../ui/RecipientDocumentVerification/helpers';
import { getRecipientTrustStampStatus } from '../api';

const POLLING_INTERVAL = 5000;

export const useLoadTrustStampStatus = (wireId: string, wire: AssignedWireType) => {
  return useQuery({
    queryKey: ['load_trust_stamp_status', wireId],
    enabled: getIsEnabledTrustStampDetailsRequest(wire),
    queryFn: () => getRecipientTrustStampStatus(wireId),
    refetchInterval: (query) => {
      const body = query?.state?.data?.body;
      const status = body?.status;

      if (isTrustStampNotInitializedStatus(status) || isTrustStampFailedStatus(status)) {
        return false;
      }

      if (isTrustStampSucceedStatus(status) && body?.provide_pd_disable_after_sec === 0) {
        return false;
      }

      if (wire?.is_payment_details_exist) {
        return false;
      }

      return POLLING_INTERVAL;
    },
  });
};
