import styled from 'styled-components';
import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';
import { CSSProperties } from 'react';

export const PageContentWrapper = styled.div<{ flexDirection: CSSProperties['flexDirection'] }>`
  ${flex()};
  position: relative;
  gap: 28px;

  ${({ flexDirection }) =>
    flexDirection &&
    `
    flex-direction: ${flexDirection};
  `};

  ${media.mobile`
    flex-direction: column;
  `}
`;
