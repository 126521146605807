import { forgotPasswordCheckPinCodeApi } from '@/api/v1/users/forgotPasswordCheckPinCodeApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const forgotPasswordCheckPinCode = async (payload: { pin: string }) => {
  try {
    const response = await forgotPasswordCheckPinCodeApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
