import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  assignRecipientByProxy,
  createRecipientByProxy,
  loadUserDetailsByProxy,
  recipientAssignmentStatusByProxy,
} from './api';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { AssignRecipientFormHeader } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/styles';
import { FormMessage } from '@/bundle/_OrgAdmin/shared/ui/styles';
import { ASSIGN_USER_FORM_CONFIG } from '@/bundle/_OrgAdmin/shared/const/const';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { isNullOrUndefined } from '@/helpers/objectHelpers';
import { getInitialValues, getValidationSchema } from '@/helpers/formHelpers/formHelpers';
import { useFormik } from 'formik';
import { Maybe } from '@/types/sharedTypes';
import { AutocompleteOptionType } from '@/components/Autocomplete/types';
import { useAssignUser } from '@/bundle/shared/hooks/useAssignUser';
import { ProxyAssignRecipientAutocomplete } from '../ProxyAssignRecipientAutocomplete/ProxyAssignRecipientAutocomplete';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { ChangeButton } from './styles';
import { ReactComponent as RecipientChainProxyIcon } from '@/images/RecipientChainProxyIcon.svg';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import {
  PROXY_ASSIGN_RECIPIENT_ERROR_AMPLITUDE_EVENTS_MAP,
  PROXY_ASSIGN_RECIPIENT_SUCCESS_AMPLITUDE_EVENTS_MAP,
} from './const';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { PaymentDetailsHeaderSlot } from '@/bundle/shared/components/PaymentDetailsHeaderSlot/PaymentDetailsHeaderSlot';

type ProxyAssignRecipientFormType = {
  onCreateRecipient: () => void;
  onAssignRecipient: () => void;
  onChangeProvidePaymentDetailsMode: () => void;
  isDocumentVerificationRequired: boolean;
};

type FormValues = {
  email: string;
  mobile_phone: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  is_proxy: boolean;
};

export const ProxyAssignRecipientForm = ({
  onCreateRecipient,
  onAssignRecipient,
  onChangeProvidePaymentDetailsMode,
  isDocumentVerificationRequired,
}: ProxyAssignRecipientFormType) => {
  const { id } = useParams<{ id: string }>();
  const [selectedRecipientOption, setSelectedRecipientOption] = useState<Maybe<AutocompleteOptionType>>(null);
  const recipientId = selectedRecipientOption?.value;

  const { data: assignmentStatusData } = useQuery({
    queryKey: ['load_assignment_status_by_proxy', recipientId],
    queryFn: () => recipientAssignmentStatusByProxy(id, recipientId),
    enabled: !!selectedRecipientOption?.isSelectedOption,
  });

  const { data: loadUserData } = useQuery({
    queryKey: ['load_user_by_proxy', recipientId],
    queryFn: () => loadUserDetailsByProxy(recipientId),
    enabled: !!selectedRecipientOption?.isSelectedOption && !!assignmentStatusData?.body?.is_available_to_assign,
  });

  const {
    mutate: createRecipientMutate,
    isPending: isPendingCreateRecipient,
    data: createRecipientData,
    reset: resetCreateRecipient,
  } = useMutation({
    mutationKey: ['create_recipient_by_proxy'],
    mutationFn: (values: FormValues) => {
      return createRecipientByProxy(id, values);
    },
    onSuccess: (createdRecipientResponse) => {
      if (createdRecipientResponse?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.ProxyAssignNewRecipient);
      onCreateRecipient();
    },
  });

  const {
    mutate: assignRecipientMutate,
    isPending: isPendingAssignRecipient,
    data: assignRecipientData,
    reset: resetAssignRecipient,
  } = useMutation({
    mutationKey: ['assign_recipient_by_proxy'],
    mutationFn: (values: FormValues) => {
      const userData = loadUserData?.body;

      const payload = {
        user_id: userData?.id,
        is_proxy: values.is_proxy,
      };

      return assignRecipientByProxy(id, payload);
    },
    onSuccess: (assignedRecipientResponse) => {
      if (assignedRecipientResponse?.error) return;

      const eventType = PROXY_ASSIGN_RECIPIENT_SUCCESS_AMPLITUDE_EVENTS_MAP[assignmentStatusData?.body?.code];

      if (eventType) {
        amplitudeService.logEvent(eventType);
      }

      onAssignRecipient();
    },
  });

  useEffect(() => {
    const assigmentStatusBody = assignmentStatusData?.body;

    if (assigmentStatusBody && !assigmentStatusBody?.is_available_to_assign) {
      const eventType = PROXY_ASSIGN_RECIPIENT_ERROR_AMPLITUDE_EVENTS_MAP[assignmentStatusData?.body?.code];

      if (eventType) {
        amplitudeService.logEvent(eventType);
      }
    }
  }, [assignmentStatusData?.body]);

  const getUser = () => {
    return loadUserData?.body;
  };

  const isAvailableToAssign = useMemo(() => {
    const availableToAssign = assignmentStatusData?.body?.is_available_to_assign;

    if (isNullOrUndefined(availableToAssign)) return true;

    return availableToAssign === true;
  }, [assignmentStatusData]);

  const user = getUser();
  const isDisabledForm = !!user || (selectedRecipientOption?.isSelectedOption && !isAvailableToAssign);
  const validationSchema = isDisabledForm ? null : getValidationSchema(ASSIGN_USER_FORM_CONFIG);

  const formik = useFormik<FormValues>({
    initialValues: getInitialValues(ASSIGN_USER_FORM_CONFIG),
    validationSchema,
    onSubmit: (values) => {
      if (selectedRecipientOption?.isSelectedOption) {
        assignRecipientMutate(values);

        return;
      }

      createRecipientMutate(values);
    },
  });

  const onResetData = () => {
    resetCreateRecipient();
    resetAssignRecipient();
  };

  useAssignUser(
    loadUserData?.body,
    formik,
    onResetData,
    selectedRecipientOption,
    assignmentStatusData?.body?.is_available_to_assign
  );

  const assignmentStatus = assignmentStatusData?.body?.code;
  const createRecipientError = createRecipientData?.error;
  const assignRecipientError = assignRecipientData?.error;
  const apiError = createRecipientError || assignRecipientError;
  const isLoading = isPendingCreateRecipient || isPendingAssignRecipient;

  return (
    <DetailsWidget
      hasHighlight
      headlineSlot={
        <PaymentDetailsHeaderSlot
          icon={<RecipientChainProxyIcon />}
          header='Invite the Recipient'
          meta={<ChangeButton onClick={onChangeProvidePaymentDetailsMode}>Change</ChangeButton>}
        />
      }
    >
      <AssignRecipientFormHeader>Assign Recipient</AssignRecipientFormHeader>
      <FormMessage>Please indicate who will be receiving this wire payment.</FormMessage>
      <FormikForm value={formik}>
        <ProxyAssignRecipientAutocomplete
          selectedOption={selectedRecipientOption}
          wireId={id}
          assignmentStatus={assignmentStatus}
          onSelect={setSelectedRecipientOption}
          error={formik.errors?.email}
          apiError={apiError}
          labelSlot={isDocumentVerificationRequired && 'ID VERIFICATION REQUIRED'}
        />

        <FormGeneratorNew config={ASSIGN_USER_FORM_CONFIG} apiError={apiError} disableForm={isDisabledForm} />

        <Box alignItems='center' justifyContent='flex-end' mt='14px'>
          <Button
            width={172}
            size='medium'
            type='submit'
            disabled={!isAvailableToAssign}
            mobileStretch
            isLoading={isLoading}
          >
            Assign
          </Button>
        </Box>
      </FormikForm>
    </DetailsWidget>
  );
};
