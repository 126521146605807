import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { bodySmall } from '@/styles/typography';
import { NavigationLinkWrapper } from '@/components/NavigationLink/styles';

export const TrustStampDetailsWrapper = styled.div`
  padding: 12px;
  background-color: ${COLORS.grey[150]};
  border-radius: 12px;
`;

export const TrustStampMessage = styled.div`
  margin-top: 4px;
  ${bodySmall};
  color: ${COLORS.grey[700]};
`;

export const TrustStampLink = styled(NavigationLinkWrapper)``;
