import { WIRE_STATUS_DB } from '@/const/wire';
import { WireStatusType } from '@/types/wireTypes';
import { FormConfigType } from '@/helpers/formHelpers/formHelpers';
import {
  REQUIRED_EMAIL_VALIDATOR,
  REQUIRED_PHONE_NUMBER_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';

const NOT_ALLOWED_WIRE_STATUSES_CANCEL_COMMON = [
  WIRE_STATUS_DB.CANCELLED,
  WIRE_STATUS_DB.PENDING,
  WIRE_STATUS_DB.RETRY,
  WIRE_STATUS_DB.FAILED,
];

export const NOT_ALLOWED_WIRE_STATUSES_CANCEL_OUTBOND: Partial<WireStatusType>[] = [
  ...NOT_ALLOWED_WIRE_STATUSES_CANCEL_COMMON,
  WIRE_STATUS_DB.EXECUTED,
];

export const NOT_ALLOWED_WIRE_STATUSES_CANCEL_INBOUND: Partial<WireStatusType>[] = [
  ...NOT_ALLOWED_WIRE_STATUSES_CANCEL_COMMON,
  WIRE_STATUS_DB.DEPOSIT_SENT,
];

export const NOT_ALLOWED_WIRE_STATUSES_EXECUTE: Partial<WireStatusType>[] = [
  ...NOT_ALLOWED_WIRE_STATUSES_CANCEL_COMMON,
  WIRE_STATUS_DB.RECIPIENT_ASSIGNED,
  WIRE_STATUS_DB.RECIPIENT_INVITED,
  WIRE_STATUS_DB.EXECUTOR_INVITED,
  WIRE_STATUS_DB.EXECUTOR_ASSIGNED,
  WIRE_STATUS_DB.EXECUTOR_INVITED,
  WIRE_STATUS_DB.CREATED,
  WIRE_STATUS_DB.EXECUTED,
];

export const ASSIGN_USER_FORM_CONFIG: FormConfigType = [
  {
    name: 'email',
    label: 'Email Address*',
    type: 'external',
    validator: REQUIRED_EMAIL_VALIDATOR,
  },
  {
    name: 'mobile_phone',
    label: 'Mobile Phone Number*',
    type: 'phone_input',
    validator: REQUIRED_PHONE_NUMBER_VALIDATOR,
  },
  {
    name: 'first_name',
    label: 'First Name*',
    placeholder: 'Enter First Name',
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'middle_name',
    label: 'Middle Name',
    placeholder: 'Enter Middle Name',
    validator: TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'last_name',
    label: 'Last Name*',
    placeholder: 'Enter Last Name',
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
  },
];
