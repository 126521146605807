import { COLORS } from '@/styles/colors';
import { flexCenterColumn } from '@/styles/layout';
import styled, { keyframes } from 'styled-components';

const printAnimation = keyframes`
 0% {
    transform: translateY(-510px)
  }
  35% {
    transform: translateY(-395px);
  }
  70% {
    transform: translateY(-140px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const PaymentSuccessWrapper = styled.div`
  border-radius: 20px;
  background: ${COLORS.white};
  box-shadow:
    0 0 2px 0 rgb(0 0 0 / 12%),
    0 20px 20px 0 rgb(0 0 0 / 8%);
  max-width: 520px;
  width: 100%;
  ${flexCenterColumn};
  row-gap: 24px;
  padding: 42px;
`;

export const Printer = styled.div`
  width: 100%;
  position: relative;
  height: 16px;
  border-radius: 10px;
  border: 1px solid ${COLORS.grey[400]};
  background: linear-gradient(0deg, #eceef4 0%, #a0a4b1 108.33%);
`;

export const ReceiptWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: -30px;
  padding-bottom: 10px;
`;

export const Receipt = styled.div`
  width: 100%;
  ${flexCenterColumn};
  transform: translateY(-510px);
  animation-duration: 1.5s;
  animation-name: ${printAnimation};
  animation-fill-mode: forwards;
`;

export const ReceiptBody = styled.div`
  position: relative;
  background-color: ${COLORS.grey[150]};
  width: 90%;
  padding: 28px 24px 10px;
`;

export const Dot = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: ${COLORS.white};
  z-index: 1;
`;

export const LeftDot = styled(Dot)`
  left: -6px;
  bottom: 64px;
`;

export const RightDot = styled(Dot)`
  right: -6px;
  bottom: 64px;
`;

export const BottomDots = styled(Dot)<{ offset?: number }>`
  bottom: -6px;
  left: ${({ offset }) => (offset ? `${offset}px` : '')};
`;

export const Divider = styled.div`
  margin-top: 16px;
  border: 1px dotted ${COLORS.grey[400]};
  width: 100%;
`;
