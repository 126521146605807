import { RecipientTypeWrapper } from './styles';

interface RecipientTypePropsType {
  isProxy: boolean;
}

export const RecipientType = ({ isProxy }: RecipientTypePropsType) => {
  const currentMessage = isProxy ? 'Via Proxy' : 'Recipient';

  return <RecipientTypeWrapper>{currentMessage}</RecipientTypeWrapper>;
};
