import { ReactNode } from 'react';
import { useTableSorting } from '../hooks/useTableSorting';
import { TableHeaderCellContainer } from './styles';
import { TableSorting } from '../TableSorting/TableSorting';
import { Box } from '@/components/Box/Box';
import { useGetSearchParams } from '@/hooks/useGetSearchParams';

type TableHeaderCellType = {
  name: string;
  hasSort?: boolean;
  width?: string;
  children?: ReactNode;
};

export const TableHeaderCell = ({ width, name, hasSort, children }: TableHeaderCellType) => {
  const { tableSorting } = useTableSorting();
  const { ordering } = useGetSearchParams();

  const isSortAsc = hasSort && ordering === name;
  const isSortDesc = hasSort && ordering === `-${name}`;
  const isSort = hasSort && !(isSortAsc || isSortDesc);

  const sorting = () => {
    if (!hasSort) return;

    return tableSorting(name, isSort, isSortAsc, isSortDesc);
  };

  return (
    <TableHeaderCellContainer hasSort={hasSort} width={width} onClick={sorting}>
      <Box alignItems='flex-start' width='100%' textAlign='left'>
        {children}
        {hasSort && <TableSorting isSortAsc={isSortAsc} isSortDesc={isSortDesc} isSort={isSort} />}
      </Box>
    </TableHeaderCellContainer>
  );
};
