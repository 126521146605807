import { FloatingContext, FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { DropdownList, DropdownListItem, DropdownWrapper } from './styles';
import { AutocompleteOptionType } from '../types';
import { AutocompleteDropdownOption } from './AutocompleteDropdownOption';

type AutocompleteDropdownType = {
  isOpen: boolean;
  context: FloatingContext;
  activeIndex: number;
  searchValue: string;
  options: AutocompleteOptionType[];
  onSelect: (option: AutocompleteOptionType) => void;
  optionProps: Record<string, unknown>;
  dropdownProps: Record<string, unknown>;
  onNodeReference: (node: HTMLElement, index: number) => void;
  zIndex: number;
};

export const AutocompleteDropdown = ({
  isOpen,
  context,
  options,
  activeIndex,
  onSelect,
  searchValue,
  optionProps,
  dropdownProps,
  onNodeReference,
  zIndex,
}: AutocompleteDropdownType) => {
  return (
    <FloatingPortal>
      {isOpen && (
        <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
          <DropdownWrapper {...dropdownProps} zIndex={zIndex}>
            <DropdownList>
              {options.map((option, index) => {
                return (
                  <DropdownListItem key={option.value}>
                    <AutocompleteDropdownOption
                      optionProps={optionProps}
                      ref={(node) => {
                        onNodeReference(node, index);
                      }}
                      searchValue={searchValue}
                      onSelect={onSelect}
                      option={option}
                      isActive={activeIndex === index}
                    />
                  </DropdownListItem>
                );
              })}
            </DropdownList>
          </DropdownWrapper>
        </FloatingFocusManager>
      )}
    </FloatingPortal>
  );
};
