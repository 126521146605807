import { useNavigate, useParams } from 'react-router-dom';
import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { TransactionDetails } from './ui/TransactionDetails/TransactionDetails';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { WiresBlockHeaderTitle } from './styles';
import { Button } from '@/components/Button/Button';
import { ReactComponent as IconPlus } from '../../../../../images/PlusIcon.svg';
import { useEffect, useState } from 'react';
import { NoResults } from '@/components/NoResults/NoResults';
import { ReactComponent as NoWiresIcon } from '../../../../../images/NoWiresIcon.svg';
import { ModalWindow } from '@/components/ModalWindow/ModalWindow';
import { Paginator } from '@/components/Paginator/Paginator';
import { CreateWireForm } from '../../OutboundWire/_Edit/ui/CreateWireForm/CreateWireForm';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { useInterval } from '../../../../../hooks/useInterval';
import { WireTypeFilter } from './ui/WireTypeFilter/WireTypeFilter';
import {
  WIRE_TYPE_ALL,
  WIRE_TYPE_FILTER_AMPLITUDE_EVENTS_MAP,
  WIRE_TYPE_FILTER_CONFIG,
  WireTypesType,
} from './const/const';
import { PageFooter } from '../../../../../components/Page/PageFooter/PageFooter';
import { ApiParams, prepareListApiParams } from '@/api/helpers/urlApiHelpers';
import { WiresList } from './ui/WiresList/WiresList';
import { WIRE_TYPE_DB } from '@/const/wire';
import { useQuery, keepPreviousData, useQueryClient, useMutation } from '@tanstack/react-query';
import { createWire, loadCurrency, loadTransactionWires } from './api';
import { currencyFormatter } from '@/helpers/formatHelpers';
import { isPendingWireStatus } from '@/helpers/wireHelpers';
import { Box } from '@/components/Box/Box';
import { getTransactionsUrl } from '../urls/getTransactionsUrl';
import { useGetSearchParams } from '@/hooks/useGetSearchParams';
import { useReplaceSearchParams } from '@/hooks/useReplaceSearchParams';

const WIRES_POLLING_INTERVAL = 30000; // 30 seconds;

const getFilterParams = (wireType: WireTypesType, apiParams: ApiParams) => {
  const currentType = wireType !== WIRE_TYPE_ALL ? { type: wireType } : {};

  return {
    ...prepareListApiParams(apiParams),
    ...currentType,
  };
};

export const TransactionDetailsPage = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const searchParams = useGetSearchParams();
  const { replaceSearchParams } = useReplaceSearchParams();
  const { id } = useParams<{ id: string }>();

  const [selectedWireType, setSelectedWireType] = useState<WireTypesType>(WIRE_TYPE_ALL);
  const [isOpen, setIsOpen] = useState(false);
  const filterParams = getFilterParams(selectedWireType, searchParams);

  const { data: currency } = useQuery({
    queryKey: ['load_currency'],
    queryFn: () => loadCurrency(),
  });

  const { data: transactionWires, isPending: isLoadingWires } = useQuery({
    queryKey: ['load_wires', id, filterParams],
    queryFn: () => loadTransactionWires(id, filterParams),
    placeholderData: keepPreviousData,
  });

  const {
    mutate,
    data: createWireData,
    isPending,
    reset,
  } = useMutation({
    mutationKey: ['create_wire'],
    mutationFn: (payload) => {
      return createWire(id, payload);
    },
    onSuccess: (createdWireData) => {
      if (createdWireData.error) return;

      setIsOpen(false);
      queryClient.resetQueries({ queryKey: ['load_wires'] });

      const eventData = {
        amount: createdWireData.body?.amount,
        currency: createdWireData.body?.currency,
      };
      const wireTypeEvent =
        createdWireData.body?.type === WIRE_TYPE_DB.inbound
          ? AMPLITUDE_EVENTS.CreateInboundWireSuccess
          : AMPLITUDE_EVENTS.CreateOutboundWireSuccess;

      amplitudeService.logEvent(wireTypeEvent, eventData);
    },
  });

  const wires = transactionWires?.body?.results;
  const hasWires = !!wires?.length;
  const totalCount = transactionWires?.body?.count;
  const hasPaginator = totalCount > searchParams?.limit;

  const noAllTypeWires = !hasWires && selectedWireType === WIRE_TYPE_ALL && !isLoadingWires;
  const noSelectedTypeWires = !hasWires && selectedWireType !== WIRE_TYPE_ALL && !isLoadingWires;
  const showFilters = hasWires || selectedWireType !== WIRE_TYPE_ALL;
  const hasWireInPendingStatus = wires?.some(({ status }) => isPendingWireStatus(status));
  const currencyOptions = currencyFormatter(currency?.body?.results);

  const createWireHandler = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateWireRedirect);

    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    reset();
  };

  const selectWireType = (wireType: WireTypesType) => {
    amplitudeService.logEvent(WIRE_TYPE_FILTER_AMPLITUDE_EVENTS_MAP[wireType]);

    replaceSearchParams({ page: null });
    setSelectedWireType(wireType);
  };

  const navigateToTransactions = () => {
    navigate(getTransactionsUrl());
  };

  // polling wire list logic
  const { cancelInterval, isIntervalRunning } = useInterval(() => {
    if (hasWires && hasWireInPendingStatus) {
      queryClient.invalidateQueries({ queryKey: ['load_wires'] });
    }
  }, WIRES_POLLING_INTERVAL);

  // Cancel polling logic
  useEffect(() => {
    const shouldCancelInterval = hasWires && !hasWireInPendingStatus && isIntervalRunning;

    if (shouldCancelInterval) {
      cancelInterval();
    }
  }, [hasWires, hasWireInPendingStatus, cancelInterval, isIntervalRunning]);

  return (
    <ContentLayout title='Transaction Details' backButton='Back to Transactions' onBack={navigateToTransactions}>
      <PageBody>
        <TransactionDetails />
        <Box>
          <Box flexDirection='column'>
            <Box justifyContent='space-between' alignItems='center' mb='24px'>
              <WiresBlockHeaderTitle>Wires</WiresBlockHeaderTitle>
              <Button iconPosition='left' icon={<IconPlus />} width={160} onClick={createWireHandler}>
                Create Wire
              </Button>
            </Box>
            {showFilters && (
              <Box mb='16px'>
                <WireTypeFilter
                  selected={selectedWireType}
                  onSelect={selectWireType}
                  config={WIRE_TYPE_FILTER_CONFIG}
                />
              </Box>
            )}
          </Box>
          {hasWires && <WiresList list={wires} />}
          {noAllTypeWires && (
            <NoResults marginTop={68} icon={<NoWiresIcon />} message='Please create your first wire.' />
          )}
          {noSelectedTypeWires && (
            <NoResults
              marginTop={68}
              icon={<NoWiresIcon />}
              message='Seems, there are no such wires in the transaction.'
            />
          )}
        </Box>
      </PageBody>
      {hasPaginator && (
        <PageFooter>
          <Paginator page={searchParams.page} limit={searchParams.limit} total={totalCount} />
        </PageFooter>
      )}

      <ModalWindow isOpen={isOpen} headerText='Create Wire' isStartPositionHeader>
        <CreateWireForm
          onCreate={mutate}
          onClose={closeModal}
          currencyOptions={currencyOptions}
          selectedWireType={selectedWireType}
          isLoading={isPending}
          error={createWireData?.error}
        />
      </ModalWindow>
    </ContentLayout>
  );
};
