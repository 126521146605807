import { Outlet } from 'react-router-dom';
import { LayoutSpinner, LayoutWrapper } from '../Layouts/styles';
import { MainLayout } from '../Layouts/MainLayout';
import { useErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { AppError } from '../Error/AppError';
import { useUser } from '@/context/userContext';

type PrivateRouteType = {
  renderLayout?: boolean;
};

export const PrivateRoute = ({ renderLayout = true }: PrivateRouteType) => {
  const { error } = useErrorBoundary();
  const { user, loading } = useUser();

  if (!user || loading) {
    return (
      <LayoutWrapper>
        <LayoutSpinner />
      </LayoutWrapper>
    );
  }

  if (error) {
    return <AppError errorMessage={error.message} />;
  }

  if (renderLayout) {
    return (
      <MainLayout>
        <Outlet />
      </MainLayout>
    );
  }

  return <Outlet />;
};
