import { CancelledIconWrapper, ExecutedIconWrapper, SentIconWrapper, WaterMarkWrapper } from './styles';
import { WIRE_STATUS_DB } from '@/const/wire';
import { WireStatusType } from '@/types/wireTypes';

interface WaterMarkType {
  wireStatus: WireStatusType;
}

export const WaterMark = ({ wireStatus }: WaterMarkType) => {
  const WIRE_STATUS_ICON_MAP = {
    [WIRE_STATUS_DB.EXECUTED]: <ExecutedIconWrapper />,
    [WIRE_STATUS_DB.CANCELLED]: <CancelledIconWrapper />,
    [WIRE_STATUS_DB.DEPOSIT_SENT]: <SentIconWrapper />,
  };

  const wireStatusIcon = WIRE_STATUS_ICON_MAP[wireStatus];

  return <WaterMarkWrapper>{wireStatusIcon}</WaterMarkWrapper>;
};
