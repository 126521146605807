import { useMemo } from 'react';
import {
  LENGTH,
  LATIN_LETTERS_ONLY,
  LOVER_CASE_LETTER,
  NO_SPACES,
  ONE_DIGIT,
  SPEC_CHARACTERS,
  UPPER_CASE_LETTER,
} from '@/const/regExpPatterns';

export const useBulletValidation = (value?: string) => {
  const hasMinMaxLength = useMemo(() => {
    if (value) return LENGTH.test(value);

    return null;
  }, [value]);

  const hasOneLovercaseCharacter = useMemo(() => {
    if (value) {
      return LOVER_CASE_LETTER.test(value);
    }

    return null;
  }, [value]);

  const hasOneUppercaseCharacter = useMemo(() => {
    if (value) return UPPER_CASE_LETTER.test(value);

    return null;
  }, [value]);

  const hasOneDigit = useMemo(() => {
    if (value) return ONE_DIGIT.test(value);

    return null;
  }, [value]);

  const hasSpecCharecter = useMemo(() => {
    if (value) return SPEC_CHARACTERS.test(value);

    return null;
  }, [value]);

  const hasNoSpaces = useMemo(() => {
    if (value) return NO_SPACES.test(value);

    return null;
  }, [value]);

  const hasLatinLettersOnly = useMemo(() => {
    if (value) return !LATIN_LETTERS_ONLY.test(value);

    return null;
  }, [value]);

  const isFullValid = useMemo(() => {
    return (
      hasMinMaxLength &&
      hasOneLovercaseCharacter &&
      hasOneUppercaseCharacter &&
      hasOneDigit &&
      hasSpecCharecter &&
      hasNoSpaces &&
      hasLatinLettersOnly
    );
  }, [
    hasLatinLettersOnly,
    hasMinMaxLength,
    hasNoSpaces,
    hasOneDigit,
    hasOneLovercaseCharacter,
    hasOneUppercaseCharacter,
    hasSpecCharecter,
  ]);

  return {
    hasMinMaxLength,
    hasOneLovercaseCharacter,
    hasOneUppercaseCharacter,
    hasOneDigit,
    hasSpecCharecter,
    hasNoSpaces,
    hasLatinLettersOnly,
    isFullValid,
  };
};
