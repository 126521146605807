import { COLORS } from '@/styles/colors';
import { ellipsis } from '@/styles/common';
import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';
import { body, bodySmall, caption, captionSmall, poppinsMediumFont, poppinsRegularFont } from '@/styles/typography';
import styled from 'styled-components';
import { MenuSectionTitle } from '@/bundle/Sidebar/ui/SidebarMenu/styles';

export const SidebarFooterWrapper = styled.div`
  margin-top: auto;
`;

export const SidebarFooter = styled.div`
  ${flex({ flexDirection: 'row', alignItems: 'center' })};
  padding: 18px 20px;
  margin: 0 auto;

  ${media.mobile`
    padding: 18px 16px;
  `}
`;

export const UserInfo = styled.div`
  ${flex({ flexDirection: 'column', alignItems: 'flex-start' })};
  min-width: 0;
`;

export const UserEmail = styled.div`
  ${poppinsMediumFont};
  ${captionSmall};
  color: ${COLORS.grey[600]};
  cursor: pointer;
  width: 100%;
  ${ellipsis};

  ${media.mobile`
    ${bodySmall};
  `}
`;

export const UserType = styled.span`
  margin-top: 4px;
  padding: 2px 6px;
  background-color: ${COLORS.grey[850]};
  border-radius: 4px;
  color: ${COLORS.white};
  ${caption};
  ${poppinsMediumFont};

  ${media.mobile`
    ${bodySmall};
  `}
`;

export const LogoutWrapper = styled.ul`
  padding: 20px;
  border-top: 1px solid ${COLORS.grey[850]};

  ${media.mobile`
    padding: 20px 16px;
  `}
`;

export const LogoutLinkWrapper = styled.li`
  ${flex({ alignItems: 'center' })};
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.grey[900]};
  }
`;

export const LogoutLink = styled.div`
  ${poppinsRegularFont};
  ${bodySmall};
  color: ${COLORS.grey[600]};
  margin-left: 12px;

  ${media.tablet`
    ${body};
  `}
`;

export const ProfileSectionTitle = styled(MenuSectionTitle)<{ isActive }>`
  color: ${COLORS.grey[600]};

  ${({ isActive }) =>
    isActive &&
    `
      color: ${COLORS.white};
    `};
`;
