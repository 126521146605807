import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { bodySmall, poppinsMediumFont, poppinsRegularFont, subTitle } from '@/styles/typography';

export const Header = styled.div`
  ${poppinsMediumFont};
  color: ${COLORS.black};
  ${subTitle};
`;

export const SubHeader = styled.div`
  ${poppinsRegularFont};
  color: ${COLORS.grey[600]};
  ${bodySmall};
`;
