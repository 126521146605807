import { ApiResponseType } from '@/types/sharedTypes';
import { authApiService } from '@/api/authApiService';
import { OrganizationType } from '@/bundle/_Opco/types/types';

export const getOrganizationApi = async (
  organizationId = ':organizationId'
): Promise<ApiResponseType<OrganizationType>> => {
  const uri = `/opco/organizations/${organizationId}/`;

  return authApiService.GET(uri);
};
