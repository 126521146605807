import { ReactNode } from 'react';
import { Button } from '../Button/Button';
import { ConfirmModalButtons, ConfirmHeader, ConfirmMessage, ConfirmModalWrapper, ConfirmBody, Hint } from './styles';
import { ModalWindow } from '../ModalWindow/ModalWindow';
import { ButtonVariantType } from '../Button/styles';

type ConfirmModalType = {
  isOpen: boolean;
  header: ReactNode;
  body?: ReactNode;
  confirmation?: ReactNode;
  isLoading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  closeText?: ReactNode;
  confirmText?: ReactNode;
  confirmButtonVariant?: ButtonVariantType;
};

type HintInfoType = {
  children: ReactNode;
};

export const ConfirmModalHint = ({ children }: HintInfoType) => {
  return <Hint>{children}</Hint>;
};

export const ConfirmModal = ({
  isOpen,
  header,
  body,
  confirmation,
  onClose,
  onConfirm,
  isLoading,
  closeText,
  confirmText,
  confirmButtonVariant,
}: ConfirmModalType) => {
  const confirmationMessage = confirmation === null ? '' : confirmation || 'Do you want to continue?';

  return (
    <ModalWindow isOpen={isOpen}>
      <ConfirmModalWrapper>
        {header && <ConfirmHeader>{header}</ConfirmHeader>}
        {body && <ConfirmBody>{body}</ConfirmBody>}
        {confirmationMessage && <ConfirmMessage>{confirmationMessage}</ConfirmMessage>}
        <ConfirmModalButtons>
          <Button variant='secondary' width={200} onClick={onClose} mobileStretch>
            {closeText || 'No'}
          </Button>
          <Button variant={confirmButtonVariant} width={200} onClick={onConfirm} isLoading={isLoading} mobileStretch>
            {confirmText || 'Yes'}
          </Button>
        </ConfirmModalButtons>
      </ConfirmModalWrapper>
    </ModalWindow>
  );
};
