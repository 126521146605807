import { Box } from '@/components/Box/Box';
import { StepOneMessage, StepTitle } from './styles';
import { phoneFormatter } from '@/helpers/formatHelpers';

export const ReceiveSmsStep = ({ userPhone }) => {
  const formattedUserPhone = phoneFormatter(userPhone);

  return (
    <Box flexDirection='column' rowGap='4px'>
      <StepTitle>Step 1: Receive SMS link</StepTitle>
      <StepOneMessage>
        An SMS with a verification link will be sent to your phone number: <b>{formattedUserPhone}</b>. Please note that
        delivery may take a few minutes.
      </StepOneMessage>
    </Box>
  );
};
