import { createSearchParams } from 'react-router-dom';
import { ContentWrapper, MonthPriceWrapper, PaymentPlanSummaryWrapper, SavingPriceWrapper } from './styles';
import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { PaymentPlanCardRow } from '@/bundle/pages/SelectPaymentPlanPage/ui/PaymentPlanCard/ui/PaymentPlanCardRow';
import { PRICING_CARD_ITEMS } from '@/bundle/pages/SelectPaymentPlanPage/ui/PaymentPlanCard/const';
import { BillingFrequencyResponseType } from '@/types/paymentTypes';
import { convertCentsToDollars } from '@/helpers/formatHelpers';
import { NavigationLink } from '@/components/NavigationLink/NavigationLink';
import { getSelectPaymentPlanUrl } from '@/bundle/pages/SelectPaymentPlanPage/urls/getSelectPaymentPlanUrl';
import { PAYMENT_FREQUENCY_DB } from '@/const/payment';
import capitalize from 'lodash/capitalize';

type PaymentPlanSummaryType = {
  selectedPlan: {
    planPrice: number;
    billingFrequency: BillingFrequencyResponseType;
    addonPrice: number;
    planName: string;
  };
  hasAddon: boolean;
};

const MONTHS_COUNT = 12;

export const getPlanPrice = (
  hasAddon: boolean,
  billingFrequency: BillingFrequencyResponseType,
  planPrice: number,
  addonPrice: number
) => {
  const isYearlyFrequency = billingFrequency === PAYMENT_FREQUENCY_DB.ANNUAL;
  const pricePerMonths = hasAddon ? planPrice + addonPrice : planPrice;
  const totalPrice = isYearlyFrequency ? pricePerMonths * MONTHS_COUNT : pricePerMonths;

  return {
    isYearlyFrequency,
    pricePerMonths,
    totalPrice,
  };
};

export const PaymentPlanSummary = ({ selectedPlan, hasAddon }: PaymentPlanSummaryType) => {
  if (!selectedPlan) return;

  const pricingPlan = PRICING_CARD_ITEMS[selectedPlan.planName];

  const { isYearlyFrequency, pricePerMonths, totalPrice } = getPlanPrice(
    hasAddon,
    selectedPlan.billingFrequency,
    selectedPlan.planPrice,
    selectedPlan.addonPrice
  );

  const navigateToPaymentPlans = () => {
    return {
      pathname: getSelectPaymentPlanUrl(),
      search: createSearchParams({
        redirected_from: 'auth',
      }).toString(),
    };
  };

  return (
    <PaymentPlanSummaryWrapper>
      <ContentWrapper>
        <Box display='flex' alignItems='center' justifyContent='space-between' width='100%' mb='12px'>
          <Typography fontWeight='semibold' variant='subTitleSmall' color={COLORS.grey[950]}>
            {capitalize(selectedPlan?.planName)} Plan
          </Typography>
          <NavigationLink to={navigateToPaymentPlans()} variant='primary'>
            <Typography fontWeight='medium' variant='bodySmall'>
              View plans
            </Typography>
          </NavigationLink>
        </Box>
        <Typography fontWeight='semibold' variant='bodySmall' color={COLORS.black}>
          Great choice. Here’s what your plan includes:
        </Typography>

        <Box display='flex' flexDirection='column' rowGap={8} mt='12px' mb='16px'>
          <PaymentPlanCardRow>{pricingPlan?.depositInfoTitle}</PaymentPlanCardRow>
          <PaymentPlanCardRow>
            {pricingPlan?.paymentInfoTitle}
            {pricingPlan?.entitiesCount}
          </PaymentPlanCardRow>
          <PaymentPlanCardRow>{pricingPlan?.userCount}</PaymentPlanCardRow>
          <PaymentPlanCardRow isActive={hasAddon}>ID Verification (3FA)</PaymentPlanCardRow>
        </Box>

        <MonthPriceWrapper>
          <Typography variant='bodySmall'>Price / month</Typography>
          <Typography variant='bodySmall' fontWeight='semibold'>
            {convertCentsToDollars(pricePerMonths)}
          </Typography>
        </MonthPriceWrapper>
        <Box display='flex' justifyContent='space-between' alignItems='center' mt='12px'>
          <Typography variant='bodySmall' fontWeight='semibold' color={COLORS.black}>
            Total
          </Typography>
          <Typography variant='headline4' fontWeight='semibold' color={COLORS.black}>
            {convertCentsToDollars(totalPrice)}
          </Typography>
        </Box>
      </ContentWrapper>
      {isYearlyFrequency && (
        <SavingPriceWrapper>
          <Typography variant='bodySmall' color={COLORS.black}>
            Annual Savings
          </Typography>
          <Typography variant='bodySmall' fontWeight='semibold' color={COLORS.green[500]}>
            10%
          </Typography>
        </SavingPriceWrapper>
      )}
    </PaymentPlanSummaryWrapper>
  );
};
