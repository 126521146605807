import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { loadOrganizationUser } from './api';
import { OrganizationUserPersonalDetails } from './ui/OrganizationUserPersonalDetails/OrganizationUserPersonalDetails';
import { OrganizationUserAccountDetails } from './ui/OrganizationUserAccountDetails/OrganizationUserAccountDetails';
import { getOpcoOrganizationUsersUrl } from '../urls/getOpcoOrganizationUsersUrl';
import { Box } from '@/components/Box/Box';

export const OrganizationUserDetailsPage = () => {
  const navigate = useNavigate();
  const { organizationId, userId } = useParams<{ organizationId: string; userId: string }>();
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ['load_organization_user', organizationId, userId],
    queryFn: () => loadOrganizationUser(organizationId, userId),
  });

  const editUserSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['load_organization_user', organizationId, userId] });
  };

  const navigateToOrganizationUsers = () => {
    navigate(getOpcoOrganizationUsersUrl(organizationId));
  };

  const organizationUser = data?.body;

  return (
    <ContentLayout
      title='org user details'
      backButton='Back to Organization Users'
      onBack={navigateToOrganizationUsers}
    >
      <PageBody>
        {organizationUser && (
          <Box width='100%' flexDirection='column' rowGap='64px'>
            <OrganizationUserAccountDetails user={organizationUser} onSave={editUserSuccess} />
            <OrganizationUserPersonalDetails user={organizationUser} onSave={editUserSuccess} />
          </Box>
        )}
      </PageBody>
    </ContentLayout>
  );
};
