import { addSeconds, format } from 'date-fns';

export const formatDate = (date: string | Date, dateFormat: string = 'yyyy-MM-dd') => {
  if (!date) return null;

  return format(new Date(date), dateFormat);
};

export const formatSecondsToMinutes = (seconds: number) => {
  if (!seconds) return;

  let helperDate = addSeconds(new Date(0), seconds);

  return format(helperDate, 'mm:ss');
};
