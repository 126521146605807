import { Notification } from '@/components/Notification/Notification';
import { PaymentCardError, PaymentCardErrorList } from './styles';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { PEPPER_PAY_CONTAINER, useLoadTokenizer } from '../../hooks/useLoadTokenizer';
import { useEffectOnce } from '../../hooks/useEffectOnce';

type AddPaymentCardType = {
  onClose?: () => void;
  onSave: (token: string) => void;
  saveButtonText?: string;
  isLoading?: boolean;
};

export const PaymentCardForm = ({ onClose, saveButtonText, onSave, isLoading }: AddPaymentCardType) => {
  const { loadTokenizer, tokenizer, loading, apiError, validationError } = useLoadTokenizer((response) => {
    if (!response) return;

    onSave(response?.token);
  });

  useEffectOnce(() => loadTokenizer());

  return (
    <>
      {(validationError || apiError) && (
        <Notification mb='24px' variant='error'>
          <PaymentCardErrorList>
            {validationError?.map((item) => <PaymentCardError key={item}>{item}</PaymentCardError>)}
            {apiError && <PaymentCardError>{apiError}</PaymentCardError>}
          </PaymentCardErrorList>
        </Notification>
      )}
      <div id={PEPPER_PAY_CONTAINER} />

      <Box mt='20px' display='flex' columnGap='20px'>
        {onClose && (
          <Button variant='secondary' onClick={() => onClose()}>
            Cancel
          </Button>
        )}

        <Button onClick={() => tokenizer?.submit?.()} isLoading={isLoading || loading}>
          {saveButtonText || 'Save'}
        </Button>
      </Box>
    </>
  );
};
