import React from 'react';
import { PageFooterWrapper } from './styles';

export type PageFooterAlignType = 'flex-start' | 'center' | 'flex-end';

type PageFooterType = {
  children?: React.ReactNode;
  align?: PageFooterAlignType;
  columnGap?: number;
};

export const PageFooter = ({ children, align = 'center' }: PageFooterType) => {
  return <PageFooterWrapper align={align}>{children}</PageFooterWrapper>;
};
