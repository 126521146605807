import { OrganizationModifyType, OrganizationType } from '@/bundle/_Opco/types/types';
import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

export const updateOrganizationApi = (
  organizationId: string,
  data: OrganizationModifyType
): Promise<ApiResponseType<OrganizationType>> => {
  const uri = `/opco/organizations/${organizationId}/`;

  return authApiService.PATCH(uri, data);
};
