import * as Yup from 'yup';
import { EMAIL, PHONE_NUMBER, AMOUNT } from '@/const/regExpPatterns';

export const TEXT_MAX_LENGTH = 255;
const EMAIL_MAX_LENGTH = 254;
const MAX_LENGTH_VALIDATION_MESSAGE = `Value should be no more than ${TEXT_MAX_LENGTH} characters.`;
const MAX_LENGTH_VALIDATION_EMAIL_MESSAGE = `Value should be no more than ${EMAIL_MAX_LENGTH} characters.`;
const REQUIRED_VALIDATION_MESSAGE = 'Required field.';

export const STRING_VALIDATOR = Yup.string();

export const REQUIRED_OBJECT_VALIDATOR = Yup.object().required(REQUIRED_VALIDATION_MESSAGE);

export const TEXT_FIELD_VALIDATOR = STRING_VALIDATOR.max(TEXT_MAX_LENGTH, MAX_LENGTH_VALIDATION_MESSAGE);
export const REQUIRED_TEXT_FIELD_VALIDATOR = TEXT_FIELD_VALIDATOR.required(REQUIRED_VALIDATION_MESSAGE).trim();

export const PHONE_NUMBER_VALIDATOR = STRING_VALIDATOR.matches(PHONE_NUMBER, 'Mobile phone number is invalid.');
export const REQUIRED_PHONE_NUMBER_VALIDATOR = STRING_VALIDATOR.required(REQUIRED_VALIDATION_MESSAGE).matches(
  PHONE_NUMBER,
  'Mobile phone number is invalid.'
);

export const REQUIRED_EMAIL_VALIDATOR = STRING_VALIDATOR.required(REQUIRED_VALIDATION_MESSAGE)
  .matches(EMAIL, 'Email Address is invalid.')
  .max(EMAIL_MAX_LENGTH, MAX_LENGTH_VALIDATION_EMAIL_MESSAGE);

export const CONTACT_US_MESSAGE_VALIDATOR = STRING_VALIDATOR.required(REQUIRED_VALIDATION_MESSAGE)
  .trim()
  .min(10, 'Value should be more than 10 characters.')
  .max(10000, 'Value should be no more than 10 000 characters.');

export const AMOUNT_VALIDATOR = STRING_VALIDATOR.max(15, 'Amount is invalid.').matches(AMOUNT, 'Amount is invalid.');

export const EIN_SSN_CODE_VALIDATOR = STRING_VALIDATOR.trim()
  .max(100, 'Value should be no more than 100 characters.')
  .required(REQUIRED_VALIDATION_MESSAGE);

export const STATUS_VALIDATOR = STRING_VALIDATOR.nullable(false).required(REQUIRED_VALIDATION_MESSAGE);

export const PASSWORD_VALIDATOR = TEXT_FIELD_VALIDATOR.required(REQUIRED_VALIDATION_MESSAGE);
export const PASSWORD_CONFIRMATION_VALIDATOR = STRING_VALIDATOR.oneOf(
  [Yup.ref('password'), null],
  'The passwords don`t match. Please try again.'
).required(REQUIRED_VALIDATION_MESSAGE);

export const BANK_ABA_NUMBER_VALIDATOR = STRING_VALIDATOR.trim()
  .max(100, 'Value should be no more than 100 characters.')
  .required(REQUIRED_VALIDATION_MESSAGE);

export const CONFIRM_BANK_ABA_NUMBER_VALIDATOR = STRING_VALIDATOR.oneOf(
  [Yup.ref('bank_aba_number'), null],
  'ABA Number must match.'
).required(REQUIRED_VALIDATION_MESSAGE);

export const CONFIRM_BANK_ACCOUNT_NUMBER = STRING_VALIDATOR.oneOf(
  [Yup.ref('bank_account_number'), null],
  'Account numbers should match.'
).required(REQUIRED_VALIDATION_MESSAGE);

export const CONFIRM_INTERMEDIARY_BANK_ABA_NUMBER_VALIDATOR = STRING_VALIDATOR.oneOf(
  [Yup.ref('intermediary_bank_aba_number'), null],
  'ABA Number must match.'
).required(REQUIRED_VALIDATION_MESSAGE);

export const CONFIRM_INTERMEDIARY_BANK_ACCOUNT_NUMBER = STRING_VALIDATOR.oneOf(
  [Yup.ref('intermediary_bank_account_number'), null],
  'Account numbers should match.'
).required(REQUIRED_VALIDATION_MESSAGE);

export const SWIFT_CODE_VALIDATOR = STRING_VALIDATOR.trim()
  .min(8, 'Swift code is invalid.')
  .max(11, 'Swift code is invalid.')
  .required(REQUIRED_VALIDATION_MESSAGE);

export const CONFIRM_SWIFT_CODE_VALIDATOR = STRING_VALIDATOR.oneOf(
  [Yup.ref('bank_swift_code'), null],
  'Swift codes should match.'
).required(REQUIRED_VALIDATION_MESSAGE);

export const CONFIRM_INTERMEDIARY_SWIFT_CODE_VALIDATOR = STRING_VALIDATOR.oneOf(
  [Yup.ref('intermediary_bank_swift_code'), null],
  'Swift codes should match.'
).required(REQUIRED_VALIDATION_MESSAGE);
