import { ResponseErrorType } from '@/types/sharedTypes';
import { RequiredAuthError } from '@/bundle/Error/classes/RequiredAuthError';
import { BlockedUserError } from '@/bundle/Error/classes/BlockedUserError';
import camelCase from 'lodash/camelCase';
import { AccessDeniedError } from '@/bundle/Error/classes/AccessDeniedError';

export type FormattedResponseType<T> = {
  body?: T;
  headers?: {
    [key: string]: string;
  };
  error?: ResponseErrorType;
};

export type ResponseType<T> = {
  json?: T;
  response?: Response;
};

const getHeaders = (response: Response, headers: string[] = []) => {
  return headers.reduce((result, header) => {
    const headerValue = response?.headers.get(header);
    const headerName = camelCase(header);

    result[headerName] = headerValue;

    return result;
  }, {});
};

export const prepareResponse = <T>(response: ResponseType<T>, headers: string[] = []) => {
  let formattedResponse: FormattedResponseType<T> = {};
  const headersData = getHeaders(response?.response, headers);

  if (!response?.response.ok) {
    formattedResponse = {
      ...formattedResponse,
      error: {
        //TODO fix naming for camelCase
        error_content: response.json as ResponseErrorType,
        status_code: response.response.status,
      },
      headers: { ...headersData },
    };

    return formattedResponse;
  }

  return { ...formattedResponse, body: response?.json, headers: { ...headersData } };
};

export const throwResponseError = (error: Error) => {
  // Rethrow original errors, since we handle them separatly in <ErrorBoundary> component
  if (error instanceof RequiredAuthError) {
    throw error;
  }

  if (error instanceof BlockedUserError) {
    throw error;
  }

  if (error instanceof AccessDeniedError) {
    throw error;
  }

  throw new Error(error.message);
};

export const getResponseError = (error: ResponseErrorType, errorKey?: string): string => {
  if (!error) return;

  if (typeof error?.error_content === 'string') {
    return error?.error_content;
  }

  const errorByKey = error?.error_content?.[errorKey];

  if (Array.isArray(errorByKey)) {
    return errorByKey[0];
  }

  return errorByKey;
};
