import { FormConfigType } from '@/helpers/formHelpers/formHelpers';
import {
  REQUIRED_EMAIL_VALIDATOR,
  CONTACT_US_MESSAGE_VALIDATOR,
  PHONE_NUMBER_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';

export const CONTACT_US_FORM_CONFIG: FormConfigType = [
  {
    name: 'sender_email',
    label: 'Email Address*',
    placeholder: 'Enter Email Address',
    validator: REQUIRED_EMAIL_VALIDATOR,
  },
  {
    name: 'mobile_phone',
    label: 'Mobile Phone Number',
    validator: PHONE_NUMBER_VALIDATOR,
    type: 'phone_input',
  },
  {
    name: 'subject',
    label: 'Subject*',
    placeholder: 'Enter Subject',
    validator: REQUIRED_TEXT_FIELD_VALIDATOR,
  },
  {
    name: 'message',
    label: 'Message*',
    placeholder: 'Please include details of your request (steps to reproduce, device/environment, etc).',
    validator: CONTACT_US_MESSAGE_VALIDATOR,
    type: 'textarea',
    meta: {
      textareaRows: 10,
    },
  },
];
