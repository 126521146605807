import { TrustStampResponseStatusType } from '@/api/v1/recipient/getRecipientTrustStampStatusApi';
import { isProvidePaymentDetailsWireStatus, isRecipientChainProxy, isRecipientProxy } from '@/helpers/wireHelpers';
import { ValueOf } from '@/types/sharedTypes';
import { AssignedWireType } from '@/types/wireTypes';
import { isTrustStampNotInitializedStatus } from '../RecipientDocumentVerification/helpers';

export const PROVIDE_PAYMENT_DETAILS_STATE = {
  RECIPIENT_PROVIDE_PAYMENT_DETAILS: 'RECIPIENT_PROVIDE_PAYMENT_DETAILS',
  RECIPIENT_PROXY_SELECT_PROVIDER: 'RECIPIENT_PROXY_SELECT_PROVIDER',
  RECIPIENT_PROXY_ASSIGN_RECIPIENT: 'RECIPIENT_PROXY_ASSIGN_RECIPIENT',
  RECIPIENT_PROXY_PROVIDE_PAYMENT_DETAILS: 'RECIPIENT_PROXY_PROVIDE_PAYMENT_DETAILS',
  RECIPIENT_PROXY_PROVIDE_REPLACEMENT_PAYMENT_DETAILS: 'RECIPIENT_PROXY_PROVIDE_REPLACEMENT_PAYMENT_DETAILS',
  RECIPIENT_PAYMENT_DETAILS_PROVIDED: 'RECIPIENT_PAYMENT_DETAILS_PROVIDED',
  RECIPIENT_SOURCE_WIRE_PAYMENT_DETAILS_CONFIRM: 'RECIPIENT_SOURCE_WIRE_PAYMENT_DETAILS_CONFIRM',
} as const;

export type ProvidePaymentDetailsStateType = ValueOf<typeof PROVIDE_PAYMENT_DETAILS_STATE>;

export const isRecipientProvidePaymentDetailsState = (state: ProvidePaymentDetailsStateType) => {
  return state === PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_PROVIDE_PAYMENT_DETAILS;
};

export const isProxySelectProviderState = (state: ProvidePaymentDetailsStateType) => {
  return state === PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_PROXY_SELECT_PROVIDER;
};

export const isProxyProvidePaymentDetailsState = (state: ProvidePaymentDetailsStateType) => {
  return state === PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_PROXY_PROVIDE_PAYMENT_DETAILS;
};

export const isProxyAssignRecipientState = (state: ProvidePaymentDetailsStateType) => {
  return state === PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_PROXY_ASSIGN_RECIPIENT;
};

export const isProxyProvideReplacementPaymentDetailsState = (state: ProvidePaymentDetailsStateType) => {
  return state === PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_PROXY_PROVIDE_REPLACEMENT_PAYMENT_DETAILS;
};

export const isRecipientSourceWirePaymentDetailsConfirmState = (state: ProvidePaymentDetailsStateType) => {
  return state === PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_SOURCE_WIRE_PAYMENT_DETAILS_CONFIRM;
};

export const getInitialPaymentDetailsState = (
  wire: AssignedWireType,
  userId: string,
  trustStampDetails: TrustStampResponseStatusType,
  paymentDetailsState: ProvidePaymentDetailsStateType
) => {
  const wireStatus = wire?.status;

  if (!isProvidePaymentDetailsWireStatus(wireStatus)) {
    return null;
  }

  if (paymentDetailsState) {
    return paymentDetailsState;
  }

  const parentWireId = wire?.parent_wire?.id;
  const isProxy = isRecipientProxy(wire);
  const isChainProxy = isRecipientChainProxy(wire);
  const hasSourceWirePaymentDetails = !!wire?.payment_details_source_wire?.id;
  const isDocumentVerificationRequired = !!wire?.is_pd_provider_kyc_required;

  // Direct recipient confirming payment details or providing brand new payment details
  if (hasSourceWirePaymentDetails && !isProxy) {
    return PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_SOURCE_WIRE_PAYMENT_DETAILS_CONFIRM;
  }

  // Recipient(assigned by Proxy) providing payment details
  if (isChainProxy) {
    return PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_PROVIDE_PAYMENT_DETAILS;
  }

  if (isProxy && isDocumentVerificationRequired && trustStampDetails) {
    const isRecipientUser = userId === wire?.assigned_user?.id;

    if (isRecipientUser) {
      return PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_PROVIDE_PAYMENT_DETAILS;
    }

    if (isTrustStampNotInitializedStatus(trustStampDetails?.status)) {
      return PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_PROXY_SELECT_PROVIDER;
    } else {
      return PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_PROVIDE_PAYMENT_DETAILS;
    }
  }

  // Proxy providing payment details in replacement wire
  if (isProxy && parentWireId) {
    return PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_PROXY_PROVIDE_REPLACEMENT_PAYMENT_DETAILS;
  }

  // Proxy without document verification selecting to provide payment details or invite recipient
  if (isProxy && !isDocumentVerificationRequired) {
    return PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_PROXY_SELECT_PROVIDER;
  }

  // Direct recipient providing regular payment details
  if (!isProxy) {
    return PROVIDE_PAYMENT_DETAILS_STATE.RECIPIENT_PROVIDE_PAYMENT_DETAILS;
  }
};
