import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';
import styled from 'styled-components';

export const SourceWireButtonsContainer = styled.div`
  ${flex({ alignItems: 'center', justifyContent: 'space-between' })};
  margin-top: 24px;
  gap: 20px;

  ${media.mobile`
    ${flex({ flexDirection: 'column-reverse' })};
  `}
`;
