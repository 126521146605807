import { ApiResponseType, Maybe } from '@/types/sharedTypes';
import { authApiService } from '../../authApiService';
import { ActiveUserRolesType } from '@/const/user';
import { OrganizationUserStatusType } from '@/bundle/_Opco/types/types';

type OrganizationUserType = {
  id: string;
  role: 'org_admin' | 'site_admin';
  status: OrganizationUserStatusType;
  organization: Maybe<{ id: string; name: string; active: 'active' | 'blocked'; is_kyc_allowed: boolean }>;
};
type OpcoUserType = {
  id: string;
  operation_company: { id: string; name: string };
  role: 'admin';
};
type RecipientUserType = {
  id: string;
  status: 'active';
  recipient: {
    id: string;
    status: 'active';
  };
};
type DepositorUserType = { id: string };
type ExecutorUserType = { id: string };

export type SelfUserDetailsType = {
  id: string;
  created_by: {
    id: string;
    email: string;
  };
  active_roles: ActiveUserRolesType;
  date_created: string;
  date_modified: string;
  username: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  language: string;
  is_verified: boolean;
  email: string;
  mobile_phone: string;
  status: string;
  last_time_login: string;
  organization_user: Maybe<OrganizationUserType>;
  opco_user: Maybe<OpcoUserType>;
  recipient_user: Maybe<RecipientUserType>;
  depositor: Maybe<DepositorUserType>;
  executor: Maybe<ExecutorUserType>;
};

export const getSelfUserDetailsApi = async (): Promise<ApiResponseType<SelfUserDetailsType>> => {
  const uri = `/users/me/`;

  return authApiService.GET(uri);
};
