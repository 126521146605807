import { Route } from 'react-router-dom';
import { BilledEventsPage } from '../../_BillingPage/BilledEventsPage';
import { getBilledEventsPath } from '../../_BillingPage/urls/getBilledEventsUrl';
import { getOpcoUsersPath } from '../urls/getOpcoUsersUrl';
import { OpcoUsersPage } from '../OpcoUsersPage';
import { OpcoUserDetailsPage } from '../Details/OpcoUserDetailsPage';
import { getOpcoUserDetailsPath } from '../Details/url/getOpcoUserDetailsUrl';

export const OpcoUserRoutes = [
  <Route key={getOpcoUsersPath()} path={getOpcoUsersPath()} element={<OpcoUsersPage />} />,
  <Route key={getOpcoUserDetailsPath()} path={getOpcoUserDetailsPath()} element={<OpcoUserDetailsPage />} />,
  <Route key={getBilledEventsPath()} path={getBilledEventsPath()} element={<BilledEventsPage />} />,
];
