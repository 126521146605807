import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { AutocompleteOptionType } from '@/components/Autocomplete/types';
import { ResponseErrorType } from '@/types/sharedTypes';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { searchUsers } from '@/bundle/_Opco/ui/OrganizationUsers/api';
import { EMAIL_ALLOW_SEARCH_LENGTH } from '@/bundle/shared/components/AssignUserAutocomplete';
import {
  ADDING_ORG_ADMIN_NOTIFICATIONS_MAP,
  NewOrgAdminNotification,
} from '@/bundle/_Opco/ui/OrganizationUsers/component/AddingOrgAdminNotifications';
import { Box } from '@/components/Box/Box';
import { useEffect } from 'react';
import { getAutocompleteOptions } from '@/helpers/formatHelpers';
import { AddingOrgAdminStatusCodeType } from '@/api/v1/opco/organizations/getUserAddingStatusApi';
import { EMAIL } from '@/const/regExpPatterns';

interface CreateOrgAdminAutocompleteType {
  selectedOption: AutocompleteOptionType;
  selectedUserStatus: AddingOrgAdminStatusCodeType;
  onSelect: (selectedUser: AutocompleteOptionType) => void;
  disabled?: boolean;
  error?: string;
  apiError?: ResponseErrorType;
}

export const CreateOrgAdminAutocomplete = ({
  selectedOption,
  selectedUserStatus,
  onSelect,
  error,
  apiError,
  disabled,
}: CreateOrgAdminAutocompleteType) => {
  const queryClient = useQueryClient();
  const searchEmail = selectedOption?.value;
  const shouldLoadUsers = searchEmail?.length >= EMAIL_ALLOW_SEARCH_LENGTH && !selectedOption.isSelectedOption;

  const { data, isFetching } = useQuery({
    queryKey: ['search_users', searchEmail],
    queryFn: () => {
      return searchUsers(searchEmail);
    },
    enabled: !!shouldLoadUsers,
  });

  const options = getAutocompleteOptions(data?.body?.results);

  useEffect(() => {
    if (searchEmail?.length < EMAIL_ALLOW_SEARCH_LENGTH) {
      queryClient.resetQueries({ queryKey: ['search_users'], exact: false });
    }
  }, [searchEmail, queryClient]);

  const getOrgAdminAutocompleteNotification = (statusCode: AddingOrgAdminStatusCodeType) => {
    if (!selectedOption) return;

    const isNewUser = !selectedOption.isSelectedOption && EMAIL.test(searchEmail);

    if (isNewUser) {
      return <NewOrgAdminNotification />;
    }

    return ADDING_ORG_ADMIN_NOTIFICATIONS_MAP[statusCode];
  };

  const showNotifications = !error && !apiError && !disabled;
  const addingNotification = getOrgAdminAutocompleteNotification(selectedUserStatus);

  return (
    <>
      <Autocomplete
        name='email'
        label='Email Address*'
        placeholder='Enter or Select Email Address'
        options={options}
        isLoading={isFetching}
        disabled={disabled}
        onSelect={onSelect}
        apiError={apiError}
        hideError
        zIndex={99}
      />
      {showNotifications && <Box>{addingNotification}</Box>}
    </>
  );
};
