import styled from 'styled-components';
import { bodySmall, poppinsMediumFont } from '@/styles/typography';
import { COLORS } from '@/styles/colors';

export const LinkWrapper = styled.div`
  ${bodySmall};
  ${poppinsMediumFont};
  color: ${COLORS.blue[500]};
  cursor: pointer;
`;
