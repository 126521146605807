import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { ContactUsPayloadType, contactUsApi } from '@/api/v1/users/contactUsApi';

export const postContactMessage = async (payload: ContactUsPayloadType) => {
  try {
    const response = await contactUsApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
