import { FilterParamsType } from '@/api/helpers/queryHelpers';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';
import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { getInitialFilters, getQueryFilters, getInitialRangeDate, getString, getArray } from './helpers';
import { useLocation } from 'react-router-dom';
import { OrganizationListItemType } from '@/bundle/_Opco/types/types';
import { OptionType } from '@/components/Select/Select';
import { useReplaceSearchParams } from '@/hooks/useReplaceSearchParams';
import { useGetSearchParams } from '@/hooks/useGetSearchParams';

export type BilledEventsFiltersType = {
  organizations: OptionType[];
  transactionName: string;
  dateStart: Date;
  dateEnd: Date;
};

export const useBilledEventsFilters = (organizations: OrganizationListItemType[]) => {
  const { replaceSearchParams } = useReplaceSearchParams();

  const location = useLocation();

  const queryParams = useGetSearchParams();
  const queryFilters = getQueryFilters(queryParams.filters);

  const [filters, setFilters] = useState<BilledEventsFiltersType>(null);

  const applyFilters = (newFilters: BilledEventsFiltersType) => {
    const formattedValues: FilterParamsType = {
      wire__contract__name: getString(newFilters?.transactionName),
      organization__id: getArray(newFilters?.organizations?.map((item) => item.value as string)),
      date_created_after: formatDate(newFilters?.dateStart),
      date_created_before: formatDate(newFilters?.dateEnd),
    };

    const newQueryParams = {
      filters: JSON.stringify(formattedValues),
      page: null,
    };

    replaceSearchParams(newQueryParams);
    setFilters(newFilters);
  };

  const clearFilters = () => {
    const dateRange = getInitialRangeDate();

    applyFilters({
      transactionName: '',
      organizations: [],
      dateStart: parseISO(dateRange.startDateFormatted),
      dateEnd: parseISO(dateRange.endDateFormatted),
    });
  };

  useEffect(() => {
    if (!organizations) return;

    const initialFilters = getInitialFilters(queryFilters, organizations);

    if (!queryParams.filters) {
      applyFilters(initialFilters);
    }

    if (initialFilters) {
      setFilters(initialFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, organizations]);

  return { filters, queryFilters, applyFilters, clearFilters };
};
