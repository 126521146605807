import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

import { AssignedWireType } from '@/types/wireTypes';

export type AssignRecipientByProxyPayloadType = {
  user_id: string;
};

export const assignRecipientByProxyApi = (
  wireId: string,
  data: AssignRecipientByProxyPayloadType
): Promise<ApiResponseType<AssignedWireType>> => {
  const uri = `/recipient/wires/${wireId}/recipient/assign/`;

  return authApiService.POST(uri, data);
};
