import { Box } from '@/components/Box/Box';
import { ResponseErrorType } from '@/types/sharedTypes';
import { AssignedWireType } from '@/types/wireTypes';
import { ViewPaymentDetailsContainer, ViewPaymentDetailsHeader, ViewPaymentDetailsText } from './styles';
import { isCancelledWireStatus } from '@/helpers/wireHelpers';
import { Button } from '@/components/Button/Button';
import { Notification } from '@/components/Notification/Notification';
import { useResponsiveDevice } from '@/hooks/useResponsiveDevice';

type ViewPaymentDetailsType = {
  wire: AssignedWireType;
  billingEventApiError: ResponseErrorType;
  isShowPaymentDetails: boolean;
  isLoadingBillingEvent: boolean;
  onViewPaymentDetails: () => void;
};

export const ViewPaymentDetails = ({
  wire,
  isShowPaymentDetails,
  billingEventApiError,
  isLoadingBillingEvent,
  onViewPaymentDetails,
}: ViewPaymentDetailsType) => {
  const isCancelledWire = isCancelledWireStatus(wire?.status);
  const hasViewButton = !isShowPaymentDetails && !isCancelledWire;
  const headerMessage =
    isCancelledWire && !isShowPaymentDetails
      ? 'Wire Info were not viewed before wire cancellation'
      : 'Click here to view wire info.';
  const billingError = billingEventApiError?.error_content as string;

  const { isMobile } = useResponsiveDevice();

  return (
    <>
      <ViewPaymentDetailsContainer>
        <Box flexDirection='column' rowGap='4px' alignItems={isMobile && 'center'}>
          <ViewPaymentDetailsHeader>Wire Info</ViewPaymentDetailsHeader>
          <ViewPaymentDetailsText>{headerMessage}</ViewPaymentDetailsText>
        </Box>
        {hasViewButton && (
          <Button width={160} onClick={onViewPaymentDetails} mobileStretch isLoading={isLoadingBillingEvent}>
            View
          </Button>
        )}
      </ViewPaymentDetailsContainer>

      {billingEventApiError && (
        <Notification variant='error' fontVariant='medium' mt='12px'>
          {billingError}
        </Notification>
      )}
    </>
  );
};
