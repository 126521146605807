import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import { AssignRecipientFormHeader, AssignRecipientFormWrapper } from '../../../styles';
import { isNullOrUndefined } from '@/helpers/objectHelpers';
import { FormMessage } from '../../../../../../shared/ui/styles';
import { getMergedValues, getValidationSchema } from '@/helpers/formHelpers/formHelpers';
import {
  EVENTS_RECIPIENT_ACTION_TYPE,
  ERROR_ASSIGN_RECIPIENT_AMPLITUDE_EVENTS_MAP,
  getAssignRecipientFormConfig,
} from '../../../const/const';
import { assignRecipient, createRecipient, recipientAssignmentStatus } from '../../../api/recipientApi';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { Box } from '@/components/Box/Box';
import { Maybe } from '@/types/sharedTypes';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { AutocompleteOptionType } from '@/components/Autocomplete/types';
import { AssignUserAutocomplete } from '@/bundle/shared/components/AssignUserAutocomplete';
import { USER_ROLE_MAP } from '@/const/user';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { Button } from '@/components/Button/Button';
import { loadUserInfo } from '@/bundle/shared/api';
import { useAssignUser } from '@/bundle/shared/hooks/useAssignUser';

type AssignRecipientFormType = {
  allowedIdVerification?: boolean;
  onCreateRecipient: () => void;
  onAssignRecipient: () => void;
};

type FormValues = {
  email: string;
  mobile_phone: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  is_proxy: boolean;
  is_pd_provider_kyc_required?: boolean;
};

const getAmplitudeResponseType = (isProxy: boolean) => {
  return isProxy ? 'proxy' : 'recipient';
};

export const AssignRecipientForm = ({
  allowedIdVerification,
  onCreateRecipient,
  onAssignRecipient,
}: AssignRecipientFormType) => {
  const { id } = useParams<{ id: string }>();
  const [selectedRecipientOption, setSelectedRecipientOption] = useState<Maybe<AutocompleteOptionType>>(null);
  const recipientId = selectedRecipientOption?.value;

  const { data: assignmentStatusData } = useQuery({
    queryKey: ['load_assignment_status', recipientId],
    queryFn: () => recipientAssignmentStatus(recipientId),
    enabled: !!selectedRecipientOption?.isSelectedOption,
  });

  const { data: loadUserData } = useQuery({
    queryKey: ['load_user', recipientId],
    queryFn: () => loadUserInfo(recipientId),
    enabled: !!selectedRecipientOption?.isSelectedOption && !!assignmentStatusData?.body?.is_available_to_assign,
  });

  const {
    mutate: createRecipientMutate,
    isPending: isPendingCreateRecipient,
    data: createRecipientData,
    reset: resetCreateRecipient,
  } = useMutation({
    mutationKey: ['create_recipient'],
    mutationFn: (values: FormValues) => {
      const payload = {
        ...values,
        is_pd_provider_kyc_required: !!values.is_pd_provider_kyc_required,
      };

      return createRecipient(id, payload);
    },
    onSuccess: (createdRecipientResponse, variables) => {
      if (createdRecipientResponse?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.AssignNewRecipient, {
        responseType: getAmplitudeResponseType(variables.is_proxy),
      });
      onCreateRecipient();
    },
  });

  const {
    mutate: assignRecipientMutate,
    isPending: isPendingAssignRecipient,
    data: assignRecipientData,
    reset: resetAssignRecipient,
  } = useMutation({
    mutationKey: ['assign_recipient'],
    mutationFn: (values: FormValues) => {
      const userData = loadUserData?.body;

      const payload = {
        user_id: userData?.id,
        is_proxy: values.is_proxy,
        is_pd_provider_kyc_required: !!values.is_pd_provider_kyc_required,
      };

      return assignRecipient(id, payload);
    },
    onSuccess: (assignedRecipientResponse, variables) => {
      if (assignedRecipientResponse?.error) return;

      const eventType = EVENTS_RECIPIENT_ACTION_TYPE[assignmentStatusData?.body?.code];

      if (eventType) {
        amplitudeService.logEvent(eventType, { responseType: getAmplitudeResponseType(variables.is_proxy) });
      }

      onAssignRecipient();
    },
  });

  useEffect(() => {
    const assigmentStatusBody = assignmentStatusData?.body;

    if (assigmentStatusBody && !assigmentStatusBody?.is_available_to_assign) {
      const eventType = ERROR_ASSIGN_RECIPIENT_AMPLITUDE_EVENTS_MAP[assigmentStatusBody?.code];

      if (eventType) {
        amplitudeService.logEvent(ERROR_ASSIGN_RECIPIENT_AMPLITUDE_EVENTS_MAP[assigmentStatusBody?.code]);
      }
    }
  }, [assignmentStatusData?.body]);

  const getUser = () => {
    return loadUserData?.body;
  };

  const isAvailableToAssign = useMemo(() => {
    const availableToAssign = assignmentStatusData?.body?.is_available_to_assign;

    if (isNullOrUndefined(availableToAssign)) return true;

    return availableToAssign === true;
  }, [assignmentStatusData]);

  const user = getUser();

  // Note: do not remove useMemo in this case, cause then useEffect for disabling "is_proxy" and "is_pd_provider_kyc_required" does not work properly (see [Note_1])
  // TODO v11.0.0: find solution to remove useMemo!
  const assignRecipientFormConfig = useMemo(() => {
    return getAssignRecipientFormConfig(allowedIdVerification);
  }, [allowedIdVerification]);

  const initialValues = getMergedValues(assignRecipientFormConfig, {
    ...user,
    is_proxy: false,
    is_pd_provider_kyc_required: false,
  }) as FormValues;

  const isDisabledForm = !!user || (selectedRecipientOption?.isSelectedOption && !isAvailableToAssign);
  const validationSchema = isDisabledForm ? null : getValidationSchema(assignRecipientFormConfig);

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (selectedRecipientOption?.isSelectedOption) {
        assignRecipientMutate(values);

        return;
      }

      createRecipientMutate(values);
    },
  });

  const onResetData = () => {
    resetCreateRecipient();
    resetAssignRecipient();
  };

  useAssignUser(
    loadUserData?.body,
    formik,
    onResetData,
    selectedRecipientOption,
    assignmentStatusData?.body?.is_available_to_assign
  );

  const assignmentStatus = assignmentStatusData?.body?.code;
  const createRecipientError = createRecipientData?.error;
  const assignRecipientError = assignRecipientData?.error;
  const apiError = createRecipientError || assignRecipientError;
  const isLoading = isPendingCreateRecipient || isPendingAssignRecipient;
  const userIsNotAvailableToAssign = selectedRecipientOption?.isSelectedOption && !isAvailableToAssign;

  // [Note_1]: Disable payment details provider selectable tabs manually, since condition is different to rest fields
  useEffect(() => {
    const config = assignRecipientFormConfig.filter(
      ({ name }) => name === 'is_proxy' || name === 'is_pd_provider_kyc_required'
    );

    if (!config.length) return;

    config.forEach((configItem) => (configItem.disabled = userIsNotAvailableToAssign));
  }, [assignRecipientFormConfig, userIsNotAvailableToAssign]);

  return (
    <AssignRecipientFormWrapper>
      <AssignRecipientFormHeader>Assign Recipient</AssignRecipientFormHeader>
      <FormMessage>Please indicate who will be receiving this wire payment.</FormMessage>
      <FormikForm value={formik}>
        <AssignUserAutocomplete
          role={USER_ROLE_MAP.RECIPIENT}
          selectedOption={selectedRecipientOption}
          assignmentStatus={assignmentStatus}
          onSelect={setSelectedRecipientOption}
          error={formik.errors?.email}
          apiError={apiError}
        />

        <FormGeneratorNew config={assignRecipientFormConfig} apiError={apiError} disableForm={isDisabledForm} />

        <Box alignItems='center' justifyContent='flex-end' mt='14px'>
          <Button
            width={172}
            size='medium'
            type='submit'
            disabled={!isAvailableToAssign}
            mobileStretch
            isLoading={isLoading}
          >
            Assign
          </Button>
        </Box>
      </FormikForm>
    </AssignRecipientFormWrapper>
  );
};
