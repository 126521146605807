import * as Yup from 'yup';
import { REQUIRED_TEXT_FIELD_VALIDATOR } from '@/helpers/formHelpers/validators';

type CreateTransactionValidationSchemaType = {
  name: string;
};

export const createTransactionValidationSchema: Yup.AnySchema<CreateTransactionValidationSchemaType> =
  Yup.object().shape({
    name: REQUIRED_TEXT_FIELD_VALIDATOR,
  });
