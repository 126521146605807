import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ContentLayout } from '../../Layouts/ContentLayout/ContentLayout';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { WIRE_STATUS_DB } from '@/const/wire';
import { changeExecutorWireStatus, loadExecutorPaymentDetails, loadExecutorWire } from './api';
import { isCancelledWireStatus, isExecutedWireStatus } from '@/helpers/wireHelpers';
import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { PageColumn } from '@/components/Page/PageColumn/PageColumn';
import { WireDetails } from './ui/WireDetails';
import { PageContent } from '@/components/Page/PageContent/PageContent';
import { Box } from '@/components/Box/Box';
import { getExecutorWiresUrl } from '../urls/getExecutorWiresUrl';
import { WaterMark } from '@/bundle/shared/components/WaterMark/WaterMark';
import { getIsAllowedToExecute } from './helpers';
import { Button } from '@/components/Button/Button';
import { UserDetailsWidget } from '@/bundle/shared/components/UserDetailsWidget/UserDetailsWidget';
import { PaymentDetailsDataWidget } from '@/bundle/shared/components/PaymentDetailsDataWidget/PaymentDetailsDataWidget';

export const ExecutorWireDetailsPage = () => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isOpenExecutedModal, setIsOpenExecutedModal] = useState<boolean>(false);

  const { data: executorWireData, isPending: isPendingExecutorWire } = useQuery({
    queryKey: ['load_executor_wire', id],
    queryFn: () => loadExecutorWire(id),
  });

  const { data: executorPaymentDetailsData, isFetching: isFetchingExecutorPaymentDetails } = useQuery({
    queryKey: ['load_executor_payment_details', id],
    queryFn: () => loadExecutorPaymentDetails(id),
    enabled: !!executorWireData?.body?.is_payment_details_exist,
  });

  const { mutate, isPending } = useMutation({
    mutationKey: ['execute_wire'],
    mutationFn: () => {
      return changeExecutorWireStatus(id, WIRE_STATUS_DB.EXECUTED);
    },
    onSuccess(updatedExecutorWire) {
      if (updatedExecutorWire.error) return;

      setIsOpenExecutedModal(false);
      amplitudeService.logEvent(AMPLITUDE_EVENTS.ExecutorMarksAsExecutedSuccess);

      queryClient.setQueryData(['load_executor_wire', id], updatedExecutorWire);
    },
  });

  const executeWire = () => {
    setIsOpenExecutedModal(true);

    amplitudeService.logEvent(AMPLITUDE_EVENTS.ExecutorMarksAsExecutedRedirect);
  };

  const navigateToWires = () => {
    navigate(getExecutorWiresUrl());
  };

  const executorWire = executorWireData?.body;
  const executorPaymentDetails = executorPaymentDetailsData?.body;

  const wireStatus = executorWire?.status;
  const isExecutedWire = isExecutedWireStatus(wireStatus);
  const isCanceledWire = isCancelledWireStatus(wireStatus);
  const showWaterMark = isExecutedWire || isCanceledWire;
  const isAllowedToExecute = getIsAllowedToExecute(wireStatus) && !isPendingExecutorWire;

  return (
    <ContentLayout
      title='Wire Details'
      headerAction={
        isAllowedToExecute && (
          <Button width={160} mobileStretch onClick={executeWire}>
            Wire Executed
          </Button>
        )
      }
      backButton='Back to Transaction'
      onBack={navigateToWires}
    >
      <PageBody>
        <PageContent>
          <PageColumn>
            {executorWire && (
              <>
                <Box position='relative'>
                  <WireDetails wire={executorWire} />
                  {showWaterMark && <WaterMark wireStatus={wireStatus} />}
                </Box>
                <UserDetailsWidget header='Recipient' user={executorWire?.assigned_user} />
                <UserDetailsWidget header='Executor' user={executorWire?.assigned_executor?.user} />
              </>
            )}
          </PageColumn>
          <PageColumn>
            <PaymentDetailsDataWidget
              paymentDetails={executorPaymentDetails}
              isLoading={isFetchingExecutorPaymentDetails}
              apiError={executorPaymentDetailsData?.error}
            />
          </PageColumn>
        </PageContent>
      </PageBody>

      <ConfirmModal
        isOpen={isOpenExecutedModal}
        header='Mark as Executed'
        isLoading={isPending}
        body='You are marking this wire as successfully executed. The recipient of this wire will be notified that the wire has been sent.'
        onClose={() => setIsOpenExecutedModal(false)}
        onConfirm={mutate}
      />
    </ContentLayout>
  );
};
