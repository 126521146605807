import { ReactNode } from 'react';
import {
  DetailsBlockNotes,
  DetailsBlockNotesLabel,
  DetailsBlockNotesMessage,
  EmptyNotes,
} from '@/components/DetailsWidget/DetailsRowNotes/styles';

interface DetailsRowNotesType {
  name: string;
  children: ReactNode;
}

export const DetailsRowNotes = ({ name, children }: DetailsRowNotesType) => {
  const hasNotes = !!children;

  return (
    <DetailsBlockNotes>
      <DetailsBlockNotesLabel emptyNotes={!hasNotes}>
        {name}
        {!hasNotes && <EmptyNotes>-</EmptyNotes>}
      </DetailsBlockNotesLabel>
      {hasNotes && <DetailsBlockNotesMessage>{children}</DetailsBlockNotesMessage>}
    </DetailsBlockNotes>
  );
};
