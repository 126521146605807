import { AssignedWireType } from '@/types/wireTypes';
import { AssignRecipientForm } from './AssignRecipientForm/AssignRecipientForm';
import { isCreatedWireStatus, isRecipientProxy } from '@/helpers/wireHelpers';
import { USER_ROLE_MAP } from '@/const/user';
import { EditUserForm } from '@/bundle/shared/components/EditUserForm/EditUserForm';
import { useUser } from '@/context/userContext';

type RecipientActionsWidgetType = {
  wire: AssignedWireType;
  onRefetchWire: () => void;
  isShowEditForm: boolean;
  onCloseEditForm: () => void;
};

export const RecipientActionsWidget = ({
  wire,
  isShowEditForm,
  onRefetchWire,
  onCloseEditForm,
}: RecipientActionsWidgetType) => {
  const { user } = useUser();
  const isProxy = isRecipientProxy(wire);
  const headerLabel = isProxy ? 'Edit Recipient Proxy' : 'Edit Recipient';
  const wireUser = isProxy ? wire?.assigned_proxy_user : wire?.assigned_user;
  const isCreatedWire = isCreatedWireStatus(wire?.status);
  const allowedIdVerification = !!user?.organization_user?.organization?.is_kyc_allowed;

  const createOrAssignRecipient = () => {
    onRefetchWire();
  };

  return (
    <>
      {isCreatedWire && (
        <AssignRecipientForm
          allowedIdVerification={allowedIdVerification}
          onCreateRecipient={createOrAssignRecipient}
          onAssignRecipient={createOrAssignRecipient}
        />
      )}

      {isShowEditForm && (
        <EditUserForm
          header={headerLabel}
          role={USER_ROLE_MAP.RECIPIENT}
          user={wireUser}
          wireId={wire?.id}
          onClose={onCloseEditForm}
          onSuccess={onRefetchWire}
        />
      )}
    </>
  );
};
