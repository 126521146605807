import { authApiService } from '@/api/authApiService';
import { ApiResponseType, Maybe } from '@/types/sharedTypes';

export type CreateRecipientType = {
  recipient: {
    id: string;
  };
  user: {
    date_verified: Maybe<string>;
    email: string;
    first_name: string;
    id: string;
    language: string;
    last_name: string;
    middle_name: string;
    mobile_phone: string;
    type: 'recipient';
    username: null;
  };
};

export type CreateRecipientPayloadType = {
  email: string;
  mobile_phone: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  is_proxy: boolean;
  is_pd_provider_kyc_required?: boolean;
};

export const createRecipientApi = (
  wireId: string,
  data: CreateRecipientPayloadType
): Promise<ApiResponseType<CreateRecipientType>> => {
  const uri = `/organization/wires/${wireId}/recipient/`;

  return authApiService.POST(uri, data);
};
