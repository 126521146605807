import styled from 'styled-components';
import { bodySmall, poppinsMediumFont, poppinsRegularFont } from '@/styles/typography';
import { COLORS } from '@/styles/colors';

export const Title = styled.div`
  ${poppinsMediumFont};
  color: ${COLORS.grey[600]};
  ${bodySmall};
`;

export const UserRoleItemWrapper = styled.div<{ isActive: boolean }>`
  padding: 3px 8px;
  background: ${COLORS.grey[200]};
  color: ${COLORS.black};
  ${poppinsRegularFont};
  border-radius: 6px;

  ${({ isActive }) =>
    isActive &&
    `
      background: ${COLORS.green[200]};
      color: ${COLORS.green[500]}  
    `};
`;
