import { COLORS } from '@/styles/colors';
import { bodySmall, poppinsMediumFont } from '@/styles/typography';
import styled from 'styled-components';

export const RoleTitle = styled.div`
  ${bodySmall};
  ${poppinsMediumFont};
  color: ${COLORS.black};
  text-transform: capitalize;
`;
