import { getSearchUsersByProxyApi } from '@/api/v1/recipient/getSearchUsersByProxyApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const searchUsersByProxy = async (wireId: string, search: string) => {
  const payload = { search };

  try {
    const response = await getSearchUsersByProxyApi(wireId, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
