import { HTTP_FORBIDDEN_STATUS } from '@/api/const';

export class BlockedUserError extends Error {
  status: number;

  constructor(errorText = 'Application access is blocked') {
    super(errorText);

    this.name = 'BlockedUserError';
    this.status = HTTP_FORBIDDEN_STATUS;
  }
}
