import { useEffect } from 'react';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { ContentLayout } from '../Layouts/ContentLayout/ContentLayout';
import { NoResults } from '@/components/NoResults/NoResults';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { ReactComponent as NoTransactionsIcon } from '../../images/NoTransactionsIcon.svg';
import { Paginator } from '@/components/Paginator/Paginator';
import { useInterval } from '@/hooks/useInterval';
import { PageFooter } from '@/components/Page/PageFooter/PageFooter';
import { RecipientWiresList } from './ui/RecipientWiresList';
import { loadRecipientWires } from './api';
import { isPendingWireStatus } from '@/helpers/wireHelpers';
import { UpsellBanner } from '@/bundle/shared/components/UpsellBanner/UpsellBanner';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { useHasOrgAdminRole } from '@/bundle/shared/hooks/useHasOrgAdminRole';
import { useGetSearchParams } from '@/hooks/useGetSearchParams';

const WIRES_POLLING_INTERVAL = 30000; // 30 seconds;

export const RecipientWiresPage = () => {
  const searchParams = useGetSearchParams();
  const queryClient = useQueryClient();

  const { hasOrgAdminRole } = useHasOrgAdminRole();

  const { data, isPending } = useQuery({
    queryKey: ['load_recipient_wires', searchParams],
    queryFn: () => loadRecipientWires(searchParams),
    placeholderData: keepPreviousData,
  });

  const wires = data?.body?.results;
  const hasWires = !!wires?.length;
  const totalCount = data?.body?.count;
  const hasPaginator = totalCount > searchParams?.limit;
  const hasWireInPendingStatus = wires?.some(({ status }) => isPendingWireStatus(status));

  const sendUpsellRequest = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.UpsellRecipientDashboard);
  };

  // Polling wires list logic
  const { cancelInterval, isIntervalRunning } = useInterval(() => {
    if (hasWires && hasWireInPendingStatus) {
      queryClient.invalidateQueries({ queryKey: ['load_recipient_wires'] });
    }
  }, WIRES_POLLING_INTERVAL);

  // Cancel polling logic
  useEffect(() => {
    const shouldCancelInterval = hasWires && !hasWireInPendingStatus && isIntervalRunning;

    if (shouldCancelInterval) {
      cancelInterval();
    }
  }, [hasWires, hasWireInPendingStatus, cancelInterval, isIntervalRunning]);

  return (
    <ContentLayout
      title='Wires'
      subtitle='View the wires assigned to you. Click on the wire card to provide wire info for a particular wire.'
      banner={hasOrgAdminRole ? null : <UpsellBanner onSent={sendUpsellRequest} />}
    >
      <PageBody>
        {hasWires && <RecipientWiresList list={wires} />}
        {!hasWires && !isPending && (
          <NoResults marginTop={128} icon={<NoTransactionsIcon />} message='There is no wire yet.' />
        )}
      </PageBody>
      {hasPaginator && (
        <PageFooter>
          <Paginator page={searchParams.page} limit={searchParams.limit} total={totalCount} />
        </PageFooter>
      )}
    </ContentLayout>
  );
};
