import styled, { keyframes } from 'styled-components';
import { COLORS } from '@/styles/colors';
import { customVerticalScroll } from '@/styles/common';
import { flex, flexCenter } from '@/styles/layout';
import { media } from '@/styles/responsive';

export const LayoutWrapper = styled.div`
  height: 100vh;
  width: 100%;
  ${flexCenter};
`;

const spinnerAnimation = keyframes`
  100% {
    transform: rotate(1turn);
  }
`;

export const LayoutSpinner = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #101010 94%, #0000) top/9px 9px no-repeat,
    conic-gradient(#0000 30%, #1c1c1c);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  animation: ${spinnerAnimation} 1.2s infinite linear;
`;

export const MainLayoutContainer = styled.div`
  ${flex()};
  height: 100%;
  background-color: ${COLORS.white};

  ${media.tablet`
    flex-direction: column;
  `}
`;

export const MainLayoutContent = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  ${customVerticalScroll};

  ${media.tablet`
    padding: 0;
  `}
`;

export const MobileHeader = styled.div``;
