import { STATUS_MAP, WIRE_STATUS_TEXT_BY_USER_TYPE } from './const';
import { StatusWrapper } from './styles';
import { useUser } from '@/context/userContext';
import { UserRoleType } from '@/const/user';
import { WireStatusType } from '@/types/wireTypes';

type StatusType = {
  status: WireStatusType;
};

const getStatusText = (status: string, userRole: UserRoleType) => {
  if (!userRole) {
    return '-';
  }

  const roleWireStatus = WIRE_STATUS_TEXT_BY_USER_TYPE[userRole];

  return roleWireStatus[status];
};

export const Status = ({ status }: StatusType) => {
  const { currentRole } = useUser();
  const statusText = getStatusText(status, currentRole);

  const statusVariant = STATUS_MAP[status]?.variant;
  const statusIcon = STATUS_MAP[status]?.statusIcon;

  return (
    <StatusWrapper variant={statusVariant}>
      {statusIcon}
      {statusText}
    </StatusWrapper>
  );
};
