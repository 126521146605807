import { FormGeneratorWrapper, FieldWrapper, RadioFieldWrapper, DividerWrapper } from './styles';
import { FormikInput } from '../fields/FormikInput/FormikInput';
import { FormikRadioGroupNew } from '../fields/FormikRadioGroupNew/FormikRadioGroupNew';
import { ResponseErrorType } from '@/types/sharedTypes';
import { FormikSelect } from '../fields/FormikSelect/FormikSelect';
import { FormikDatePicker } from '../FormikDatePicker/FormikDatePicker';
import { FormikTextArea } from '../fields/FormikTextArea/FormikTextArea';
import { FormDivider } from '../FormDivider';
import { FormikPhoneInput } from '../fields/FormikPhoneInput/FormikPhoneInput';
import { FormikSelectableTabs } from '../fields/FormikSelectableTabs/FormikSelectableTabs';
import { FormikSwitchToggle } from '../fields/FormikSwitchToggle/FormikSwitchToggle';
import { FieldType } from '@/helpers/formHelpers/formHelpers';
import { ReactNode } from 'react';
import { FormikPasswordInput } from '../fields/FormikPasswordInput/FormikPasswordInput';

interface FormGeneratorType {
  config: any[];
  apiError?: ResponseErrorType;
  disableForm?: boolean;
  customField?: {
    [name: string]: ReactNode;
  };
}

const getInputTypeAdditionalProps = (type: FieldType['type']) => {
  if (type === 'confirm') {
    return {
      isCopyPasteDisabled: true,
      isConfirmTicks: true,
    };
  }

  return null;
};

export const FormGeneratorNew = ({ config, apiError, disableForm, customField }: FormGeneratorType) => {
  return (
    <FormGeneratorWrapper>
      {config.map((configItem, index) => {
        const { type, name, label, placeholder, autoComplete, isCopyPasteDisabled, options, column, disabled, meta } =
          configItem;
        const key = `${index}_${name}`;
        const isDisabledField = disableForm || disabled;
        const typeProps = getInputTypeAdditionalProps(type);

        switch (type) {
          // Type <external> renders as individual component outside of form generator
          case 'external':
            return null;

          case 'custom':
            return (
              <FieldWrapper key={key} column={column} mobileStretch={meta?.mobileStretch}>
                {customField[name]}
              </FieldWrapper>
            );

          case 'radio':
            return (
              <RadioFieldWrapper key={key} column={column} mobileStretch={meta?.mobileStretch}>
                <FormikRadioGroupNew name={name} label={label} options={options} />
              </RadioFieldWrapper>
            );

          case 'select':
            return (
              <FieldWrapper key={key} column={column} mobileStretch={meta?.mobileStretch}>
                <FormikSelect
                  name={name}
                  label={label}
                  placeholder={placeholder}
                  options={options}
                  apiError={apiError}
                  isSearchable={meta?.isSearchable}
                />
              </FieldWrapper>
            );

          case 'datepicker':
            return (
              <FieldWrapper key={key} column={column} mobileStretch={meta?.mobileStretch}>
                <FormikDatePicker
                  name={name}
                  label={label}
                  placeholder={placeholder}
                  hasErrorComponent={meta?.hasErrorComponent}
                />
              </FieldWrapper>
            );

          case 'phone_input':
            return (
              <FieldWrapper key={key} column={column} mobileStretch={meta?.mobileStretch}>
                <FormikPhoneInput
                  name={name}
                  label={label}
                  placeholder={placeholder}
                  apiError={apiError}
                  disabled={isDisabledField}
                />
              </FieldWrapper>
            );

          case 'textarea':
            return (
              <FieldWrapper key={key} column={column} mobileStretch={meta?.mobileStretch}>
                <FormikTextArea
                  name={name}
                  label={label}
                  placeholder={placeholder}
                  apiError={apiError}
                  rows={meta?.textareaRows}
                />
              </FieldWrapper>
            );

          case 'divider':
            return (
              <DividerWrapper key={key}>
                <FormDivider>{label}</FormDivider>
              </DividerWrapper>
            );

          case 'selectable_tabs':
            return (
              <FieldWrapper key={key} column={column} hasOffset mobileStretch={meta?.mobileStretch}>
                <FormikSelectableTabs
                  label={label}
                  name={name}
                  disabled={disabled}
                  options={meta?.selectableTabsOptions}
                ></FormikSelectableTabs>
              </FieldWrapper>
            );

          case 'switch_toggle':
            return (
              <FieldWrapper key={key} column={column} mobileStretch={meta?.mobileStretch}>
                <FormikSwitchToggle name={name} label={label} disabled={disabled} size='small' />
              </FieldWrapper>
            );

          case 'password':
            return (
              <FieldWrapper key={key} column={column} mobileStretch={meta?.mobileStretch}>
                <FormikPasswordInput
                  label={label}
                  name={name}
                  placeholder={placeholder}
                  apiError={apiError}
                  isCopyPasteDisabled={true}
                  autoComplete='new-password'
                  {...typeProps}
                />
              </FieldWrapper>
            );

          default: {
            return (
              <FieldWrapper key={key} column={column} mobileStretch={meta?.mobileStretch}>
                <FormikInput
                  label={label}
                  name={name}
                  disabled={isDisabledField}
                  placeholder={placeholder}
                  apiError={apiError}
                  autoComplete={autoComplete}
                  isCopyPasteDisabled={isCopyPasteDisabled}
                  iconRight={meta?.iconRight}
                  labelHint={meta?.labelSlot}
                  {...typeProps}
                />
              </FieldWrapper>
            );
          }
        }
      })}
    </FormGeneratorWrapper>
  );
};
