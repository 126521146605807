import { ReactNode } from 'react';
import {
  ContentWrapper,
  DetailsWidgetContent,
  DetailsWidgetHeader,
  DetailsWidgetHeading,
  DetailsWidgetMarker,
  DetailsWidgetWrapper,
  HeadlineSlot,
  LoaderWrapper,
} from './styles';
import { LayoutSpinner } from '@/bundle/Layouts/styles';

type DetailsWidgetType = {
  header?: ReactNode;
  action?: ReactNode;
  children?: ReactNode;
  hasHighlight?: boolean;
  headlineSlot?: ReactNode;
  headerOffset?: number;
  marker?: ReactNode;
  isLoading?: boolean;
  variant?: 'small' | 'default';
};

export const DetailsWidget = ({
  header,
  action,
  children,
  hasHighlight,
  headlineSlot,
  headerOffset,
  marker,
  isLoading,
  variant = 'default',
}: DetailsWidgetType) => {
  return (
    <DetailsWidgetWrapper hasHighlight={hasHighlight}>
      {isLoading && (
        <LoaderWrapper>
          <LayoutSpinner />
        </LoaderWrapper>
      )}
      {marker && <DetailsWidgetMarker>{marker}</DetailsWidgetMarker>}
      {headlineSlot && <HeadlineSlot>{headlineSlot}</HeadlineSlot>}
      <ContentWrapper hasSlot={!!headlineSlot} variant={variant}>
        {header && (
          <DetailsWidgetHeading hasAction={!!action} headerOffset={headerOffset}>
            <DetailsWidgetHeader>{header}</DetailsWidgetHeader>
            {action}
          </DetailsWidgetHeading>
        )}
        <DetailsWidgetContent>{children}</DetailsWidgetContent>
      </ContentWrapper>
    </DetailsWidgetWrapper>
  );
};
