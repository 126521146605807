import { useState } from 'react';
import { UserDetailsHeader } from '@/bundle/shared/components/UserDetails/UserDetailsHeader/UserDetailsHeader';
import {
  UserDetailsActionRow,
  UserDetailsCard,
  UserDetailsRow,
  UserDetailsRowTitle,
  UserDetailsRowValue,
} from '@/bundle/_Opco/shared/styles';
import { ReactComponent as LogoSmallIcon } from '../../../../../_OpcoUsersPage/Details/images/LogoSmall.svg';
import { OrganizationUserType } from '@/bundle/_Opco/types/types';
import { phoneFormatter } from '@/helpers/formatHelpers';
import { UserRole } from '@/bundle/_Opco/shared/UserRole/UserRole';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { OpcoUserStatus } from '@/bundle/_Opco/_OpcoUsersPage/ui/OpcoUserStatus/OpcoUserStatus';
import { Notification } from '@/components/Notification/Notification';
import { NotificationMessage, NotificationTitle } from '@/components/Notification/styles';
import { BlockWrapper, InviteNotification, Wrapper } from './styles';
import { ReactComponent as EditIcon } from '@/images/EditIconNew.svg';
import { Drawer } from '@/components/Drawer/Drawer';
import { EditMobilePhoneForm } from '@/bundle/_Opco/ui/OrganizationUsers/Details/ui/OrganizationUserAccountDetails/EditMobilePhoneForm';
import {
  ORGANIZATION_USER_ROLE_ICON_MAP,
  ORGANIZATION_USER_STATUS,
  ORGANIZATION_USER_ROLE_LABEL_MAP,
} from '../../../const/const';
import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { blockOrganizationUser, unblockOrganizationUser } from '@/bundle/_Opco/ui/OrganizationUsers/Details/api';
import { CheckPinCodeModal } from '@/bundle/shared/components/CheckPinCodeModal/CheckPinCodeModal';
import {
  PIN_TOKEN_EVENT_SCOPE,
  PinTokenEventScopeType,
  UPDATE_USER_MOBILE_PHONE_NOTIFICATION,
  UPDATE_USER_ROLE_NOTIFICATION,
} from '@/const/shared';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { showSnackbar } from '@/components/Snackbar/Snackbar';
import { IconButton } from '@/components/IconButton/IconButton';
import { EditUserRoleForm } from './EditUserRoleForm';
import { CANNOT_BLOCK_LAST_USER_ERROR_MESSAGE } from './const';
import { CannotModifyLastUserErrorModal } from './CannotModifyLastUserModalError';

type OrganizationUserAccountDetailsType = {
  user: OrganizationUserType;
  onSave: () => void;
};

export const OrganizationUserAccountDetails = ({ user, onSave }: OrganizationUserAccountDetailsType) => {
  const { organizationId, userId } = useParams<{ organizationId: string; userId: string }>();

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isBlockOpen, setIsBlockOpen] = useState(false);
  const [isUnblockOpen, setIsUnblockOpen] = useState(false);
  const [isPinCodeOpen, setIsPinCodeOpen] = useState(false);
  const [eventScope, setEventScope] = useState<PinTokenEventScopeType>(null);
  const [editUserRole, setEditUserRole] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);

  const { email, mobile_phone, status, role } = user;

  const { mutate: blockUserMutate, isPending: isPendingBlockUser } = useMutation({
    mutationKey: ['block_organization_user', organizationId, userId],
    mutationFn: (pinCode: string) => {
      return blockOrganizationUser(organizationId, userId, pinCode);
    },
    onSuccess: (blockedUserResponse) => {
      if (blockedUserResponse?.error) {
        const error = getResponseError(blockedUserResponse?.error);

        if (error === CANNOT_BLOCK_LAST_USER_ERROR_MESSAGE) {
          setIsOpenErrorModal(true);

          return;
        }

        showSnackbar(error, { variant: 'error' });

        return;
      }

      showSnackbar('User account has been blocked.');
      onSave();
    },
  });

  const { mutate: unblockUserMutate, isPending: isPendingUnblockUser } = useMutation({
    mutationKey: ['unblock_organization_user', organizationId, userId],
    mutationFn: (pinCode: string) => {
      return unblockOrganizationUser(organizationId, userId, pinCode);
    },
    onSuccess: (unblockUserResponse) => {
      if (unblockUserResponse?.error) {
        const error = getResponseError(unblockUserResponse?.error);

        showSnackbar(error, { variant: 'error' });

        return;
      }

      showSnackbar('User account has been unblocked.');
      onSave();
    },
  });

  const openEdit = () => setIsEditOpen(true);

  const closeEdit = () => setIsEditOpen(false);

  const openBlock = () => setIsBlockOpen(true);

  const closeBlock = () => setIsBlockOpen(false);

  const openUnblock = () => setIsUnblockOpen(true);

  const closeUnblock = () => setIsUnblockOpen(false);

  const openEditUserRole = () => setEditUserRole(true);

  const closeEditUserRole = () => setEditUserRole(false);

  const openBlockUserPinCode = () => {
    setEventScope(PIN_TOKEN_EVENT_SCOPE.BLOCK_ORG_ADMIN);
    closeBlock();
    setIsPinCodeOpen(true);
  };

  const openUnblockUserPinCode = () => {
    setEventScope(PIN_TOKEN_EVENT_SCOPE.UNBLOCK_ORG_ADMIN);
    closeUnblock();
    setIsPinCodeOpen(true);
  };

  const closePinCode = () => {
    setIsPinCodeOpen(false);
    setEventScope(null);
  };

  const onSuccessEditMobilePhone = () => {
    onSave();
    closeEdit();

    showSnackbar(UPDATE_USER_MOBILE_PHONE_NOTIFICATION, { maxWidth: '375px' });
  };

  const onSuccessEditRole = () => {
    onSave();
    closeEditUserRole();

    showSnackbar(UPDATE_USER_ROLE_NOTIFICATION, { maxWidth: '375px' });
  };

  const checkPinCodeSuccess = (pinToken: string) => {
    if (!eventScope) return;

    if (eventScope === PIN_TOKEN_EVENT_SCOPE.BLOCK_ORG_ADMIN) {
      blockUserMutate(pinToken);
      closePinCode();

      return;
    }

    unblockUserMutate(pinToken);
    closePinCode();

    return;
  };

  const formattedMobilePhone = phoneFormatter(mobile_phone);
  const formattedRole = ORGANIZATION_USER_ROLE_LABEL_MAP[role];
  const icon = ORGANIZATION_USER_ROLE_ICON_MAP[role];
  const isInvited = status === ORGANIZATION_USER_STATUS.INVITED;
  const isAvailableToModify = status === ORGANIZATION_USER_STATUS.ACTIVE || status === ORGANIZATION_USER_STATUS.INVITED;
  const isAvailableToUnblock = status === ORGANIZATION_USER_STATUS.BLOCKED;

  return (
    <Box flexDirection='row' columnGap='28px'>
      <Wrapper>
        <BlockWrapper>
          <UserDetailsHeader
            title='WireVault Account Information'
            icon={<LogoSmallIcon />}
            subTitle='You must enter your PIN code to edit these fields.'
          />
          <UserDetailsCard>
            <UserDetailsRow>
              <UserDetailsRowTitle>Email Address</UserDetailsRowTitle>
              <UserDetailsRowValue>{email}</UserDetailsRowValue>
            </UserDetailsRow>
            <UserDetailsRow>
              <Box flexDirection='row' justifyContent='space-between'>
                <Box>
                  <UserDetailsRowTitle>Mobile Phone Number</UserDetailsRowTitle>
                  <UserDetailsRowValue>{formattedMobilePhone}</UserDetailsRowValue>
                </Box>
                {isInvited && (
                  <IconButton onClick={openEdit} ml='12px'>
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
            </UserDetailsRow>
            <UserDetailsRow>
              <Box flexDirection='row' justifyContent='space-between'>
                <Box>
                  <UserDetailsRowTitle>Role</UserDetailsRowTitle>
                  <UserRole role={formattedRole} icon={icon}></UserRole>
                </Box>
                {isAvailableToModify && (
                  <IconButton onClick={openEditUserRole} ml='12px'>
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
            </UserDetailsRow>
            <UserDetailsActionRow>
              <Box>
                <UserDetailsRowTitle>Status</UserDetailsRowTitle>
                {/* TODO: refactor this to own status component */}
                <OpcoUserStatus status={status} />
              </Box>

              <Box display='flex' columnGap='12px' alignItems='center'>
                {isAvailableToModify && (
                  <Button width={71} size='medium' variant='secondary' onClick={openBlock}>
                    Block
                  </Button>
                )}

                {isAvailableToUnblock && (
                  <Button width={90} size='medium' variant='secondary' onClick={openUnblock}>
                    Unblock
                  </Button>
                )}
              </Box>
            </UserDetailsActionRow>
          </UserDetailsCard>
        </BlockWrapper>
        {isInvited && (
          <InviteNotification>
            <Notification>
              <NotificationTitle>The invitation to WireVault has not been accepted.</NotificationTitle>
              <NotificationMessage>
                Please be sure the email address and mobile phone number are correct. You can change the information for
                this account until the invitation is accepted.
              </NotificationMessage>
            </Notification>
          </InviteNotification>
        )}
      </Wrapper>

      <ConfirmModal
        isLoading={isPendingBlockUser}
        isOpen={isBlockOpen}
        header='Block User'
        body='This User will no longer be able to access WireVault. You can choose to reset their account to reenable their access later.'
        onClose={closeBlock}
        onConfirm={openBlockUserPinCode}
      />

      <ConfirmModal
        isLoading={isPendingUnblockUser}
        isOpen={isUnblockOpen}
        header='Unblock User'
        body='The User will be able to manage transactions and wires of this organization again.'
        onClose={closeUnblock}
        onConfirm={openUnblockUserPinCode}
      />

      <Drawer isOpen={isEditOpen} header='Edit Mobile Phone' subHeader='Please edit mobile phone.' onClose={closeEdit}>
        <EditMobilePhoneForm onClose={closeEdit} mobilePhone={mobile_phone} onSuccess={onSuccessEditMobilePhone} />
      </Drawer>

      <Drawer isOpen={editUserRole} header='Edit Role' subHeader='Please edit user role.' onClose={closeEditUserRole}>
        <EditUserRoleForm onClose={closeEditUserRole} userRole={role} onSuccess={onSuccessEditRole} />
      </Drawer>

      {isPinCodeOpen && (
        <CheckPinCodeModal
          isOpen={isPinCodeOpen}
          onSuccess={checkPinCodeSuccess}
          onClose={closePinCode}
          eventScope={eventScope}
        />
      )}

      <CannotModifyLastUserErrorModal
        isOpen={isOpenErrorModal}
        onClose={() => setIsOpenErrorModal(false)}
        header='User cannot be blocked'
        subHeader={
          <>
            Please assign another user of this organization <br /> as a Site Admin in order to block this user.
          </>
        }
      />
    </Box>
  );
};
