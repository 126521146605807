import { COLORS } from '@/styles/colors';
import { flexCenterColumn } from '@/styles/layout';
import { body, poppinsMediumFont, subTitle } from '@/styles/typography';
import styled from 'styled-components';

export const NotFoundLayout = styled.div`
  height: 100%;
  padding: 12px 16px;
  ${flexCenterColumn};
  ${poppinsMediumFont};
`;

export const NotFoundIconWrapper = styled.div`
  position: relative;
  left: 12px;
`;

export const NotFoundHeader = styled.h4`
  ${subTitle};
  font-weight: 500;
  margin: 32px 0 16px;
`;

export const NoutFoundSubheader = styled.p`
  ${body};
  font-weight: 500;
  color: ${COLORS.grey[600]};
  margin-bottom: 24px;
  text-align: center;
`;
