import { ReactComponent as SuccessIcon } from '@/images/circleSuccessIcon.svg';
import { ReactComponent as NeutralIcon } from '@/images/circleNeutralIcon.svg';
import { ReactComponent as RejectIcon } from '@/images/circleRejectIcon.svg';
import { CustomValidationRow, CustomValidationWrapper, MessageBlock } from './styles';
import { useBulletValidation } from '../../hooks/useBulletValidation';

export const BulletValidation = (props: any) => {
  const { value, isGrey } = props;

  const {
    hasMinMaxLength,
    hasOneLovercaseCharacter,
    hasOneUppercaseCharacter,
    hasOneDigit,
    hasSpecCharecter,
    hasNoSpaces,
    hasLatinLettersOnly,
  } = useBulletValidation(value);

  const currentIcon = (isValidRule: boolean) => {
    if (isValidRule === true) return <SuccessIcon />;

    if (!isGrey && isValidRule === false) return <RejectIcon />;

    return <NeutralIcon />;
  };

  return (
    <CustomValidationWrapper>
      <CustomValidationRow>
        {currentIcon(hasMinMaxLength)}
        <MessageBlock>From 15 to 30 characters</MessageBlock>
      </CustomValidationRow>
      <CustomValidationRow>
        {currentIcon(hasOneLovercaseCharacter)}
        <MessageBlock>One lowercase character</MessageBlock>
      </CustomValidationRow>
      <CustomValidationRow>
        {currentIcon(hasOneUppercaseCharacter)}
        <MessageBlock>One uppercase character</MessageBlock>
      </CustomValidationRow>
      <CustomValidationRow>
        {currentIcon(hasOneDigit)}
        <MessageBlock>One digit</MessageBlock>
      </CustomValidationRow>
      <CustomValidationRow>
        {currentIcon(hasSpecCharecter)}
        <MessageBlock>One special symbol</MessageBlock>
      </CustomValidationRow>
      <CustomValidationRow>
        {currentIcon(hasNoSpaces)}
        <MessageBlock>No spaces</MessageBlock>
      </CustomValidationRow>
      <CustomValidationRow>
        {currentIcon(hasLatinLettersOnly)}
        <MessageBlock>Latin letters only</MessageBlock>
      </CustomValidationRow>
    </CustomValidationWrapper>
  );
};
