import { ApiResponseType } from '@/types/sharedTypes';
import { OrganizationUserType } from '@/bundle/_Opco/types/types';
import { authApiService } from '@/api/authApiService';

export const getOrganizationUserApi = async (
  organizationId: string,
  userId: string
): Promise<ApiResponseType<OrganizationUserType>> => {
  const uri = `/opco/organizations/${organizationId}/users/${userId}/`;

  return authApiService.GET(uri);
};
