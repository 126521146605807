import { ContinueMessage, HeaderMessage, Message } from './styles';
import { Button } from '@/components/Button/Button';
import { Box } from '@/components/Box/Box';
import { Notification } from '@/components/Notification/Notification';

type ResetRequestType = {
  confirmationStep: boolean;
  error: string;
  isLoading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  onSuccess: () => void;
};

export const ResetRequest = ({
  confirmationStep,
  isLoading,
  error,
  onSuccess,
  onCancel,
  onConfirm,
}: ResetRequestType) => {
  return (
    <>
      {confirmationStep ? (
        <Box display='flex' flexDirection='column' rowGap='32px'>
          <Box display='flex' flexDirection='column'>
            <HeaderMessage>Request Account Reset</HeaderMessage>
            {error && (
              <Notification variant='error' mb='24px'>
                {error}
              </Notification>
            )}
            <Message>
              Your request will be sent to WireVault OpCo Admins. When authorized OpCo Admin submits your request, you
              will need to pass the onboarding procedure once again.
            </Message>
          </Box>
          <ContinueMessage>Do you want to continue?</ContinueMessage>
          <Box display='flex' flexDirection='row' columnGap='20px'>
            <Button variant='secondary' onClick={onCancel}>
              No
            </Button>
            <Button variant='primary' onClick={onConfirm} isLoading={isLoading}>
              Yes
            </Button>
          </Box>
        </Box>
      ) : (
        <Box display='flex' flexDirection='column'>
          <HeaderMessage>Account Request Reset is Sent</HeaderMessage>
          <Box display='flex' flexDirection='column' rowGap='32px'>
            <Message>
              Your request is sent. Once authorized OpCoAdmin submits your request, you will be informed via email
              notification.
            </Message>
            <Button onClick={onSuccess}>Ok</Button>
          </Box>
        </Box>
      )}
    </>
  );
};
