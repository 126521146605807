import { BILLING_FREQUENCY, BillingFrequencyType, PRICING_CARD_ITEMS } from './const';
import { Divider, EmptyBlock, PricingCardWrapper, TotalBlock } from './styles';
import { ReactComponent as CheckedIcon } from './images/CheckedIcon.svg';
import { ReactComponent as UnCheckedIcon } from './images/UncheckedIcon.svg';
import { useState } from 'react';
import capitalize from 'lodash/capitalize';
import { COLORS } from '@/styles/colors';
import { SwitchToggleLabel } from './ui/SwitchToggleLabel';
import { SelectedPrice } from './ui/SelectedPrice';
import { Typography } from '@/components/Typography/Typography';
import { Box } from '@/components/Box/Box';
import { SwitchToggle } from '@/components/SwitchToggle/SwitchToggle';
import { Button } from '@/components/Button/Button';
import { PaymentPlanCardRow } from './ui/PaymentPlanCardRow';
import { PreparePlanType } from '../../usePreparePaymentPlans';
import { convertCentsToDollars } from '@/helpers/formatHelpers';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { getPaymentCheckoutUrl } from '@/bundle/pages/PaymentCheckoutPage/urls/getPaymentCheckoutUrl';

type PaymentPlanCardType = {
  plan: PreparePlanType;
  billingFrequency: BillingFrequencyType;
  selectedPlanId?: string;
};

export const PaymentPlanCard = ({ plan, billingFrequency, selectedPlanId }: PaymentPlanCardType) => {
  const navigate = useNavigate();

  const [hasIdVerification, setHasIdVerification] = useState(true);

  const pricingPlan = PRICING_CARD_ITEMS[plan?.title];

  if (!pricingPlan) return null;

  const onChange = () => setHasIdVerification((value) => !value);

  const isYearlyFrequency = billingFrequency === BILLING_FREQUENCY.YEARLY;
  const title = capitalize(plan?.title);
  const addon = plan?.addon;
  const planId = plan?.plan_id[billingFrequency];
  const addonId = plan?.addon.id[billingFrequency];

  const idVerificationPrice = hasIdVerification ? addon.amount[billingFrequency] : 0;
  const planPrice = plan.amount[billingFrequency];
  const totalPrice = convertCentsToDollars(planPrice + idVerificationPrice);
  // TODO v13.0.0: not the best check, find a better solution
  const isSelectedPlan =
    plan.plan_id[BILLING_FREQUENCY.YEARLY] === selectedPlanId ||
    plan.plan_id[BILLING_FREQUENCY.MONTHLY] === selectedPlanId;

  const navigateToPaymentCheckout = () => {
    navigate({
      pathname: getPaymentCheckoutUrl(),
      search: createSearchParams({
        plan_id: planId,
        ...(hasIdVerification && { addon_id: addonId }),
      }).toString(),
    });
  };

  return (
    <PricingCardWrapper>
      <Box display='flex' flexDirection='column' rowGap={20}>
        <Box display='flex' flexDirection='column'>
          <Typography fontWeight='semibold' variant='headline4'>
            {title}
          </Typography>
          <Box display='flex' flexDirection='column' rowGap={8} mt='16px' mb='12px'>
            <PaymentPlanCardRow>{pricingPlan.depositInfoTitle}</PaymentPlanCardRow>
            <PaymentPlanCardRow>
              {pricingPlan.paymentInfoTitle}
              {pricingPlan?.entitiesCount}
            </PaymentPlanCardRow>
            <PaymentPlanCardRow>{pricingPlan.userCount}</PaymentPlanCardRow>
            <Box display='flex' alignItems='center' columnGap={8} width='100%'>
              <Box>{hasIdVerification ? <CheckedIcon /> : <UnCheckedIcon />}</Box>
              <SwitchToggle
                label={<SwitchToggleLabel isYearlyIdVerificationPrice={addon.amount[billingFrequency]} />}
                onChange={onChange}
                checked={hasIdVerification}
                name=''
                size='small'
              />
            </Box>
            {pricingPlan.overageMessage ? pricingPlan.overageMessage(hasIdVerification) : <EmptyBlock />}
          </Box>
          <Box>
            <TotalBlock>
              <Box display='flex' flexDirection='column' rowGap={4} mb='12px'>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography as='span' variant='bodySmall'>
                    Plan Price
                  </Typography>
                  <Typography as='span' variant='bodySmall' fontWeight='semibold'>
                    <SelectedPrice
                      isYearlyFrequency={isYearlyFrequency}
                      monthlyPrice={plan?.amount?.monthly}
                      yearlyPrice={plan?.amount?.yearly}
                    />
                  </Typography>
                </Box>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography as='span' variant='bodySmall'>
                    ID Verification (3FA)
                  </Typography>
                  <Typography as='span' variant='bodySmall' fontWeight='semibold'>
                    {hasIdVerification ? (
                      <SelectedPrice
                        isYearlyFrequency={isYearlyFrequency}
                        monthlyPrice={addon.amount?.monthly}
                        yearlyPrice={addon.amount?.yearly}
                      />
                    ) : (
                      '-'
                    )}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box display='flex' alignItems='center' justifyContent='space-between' mt='8px'>
                <Box display='flex' flexDirection='column'>
                  <Typography as='span' variant='bodySmall' fontWeight='semibold'>
                    Total per month
                  </Typography>
                  <Typography as='span' variant='caption' color={COLORS.grey[650]} fontWeight='medium'>
                    {isYearlyFrequency ? 'billed yearly' : 'billed monthly'}
                  </Typography>
                </Box>
                <Box>
                  <Typography as='span' variant='headline2' fontWeight='semibold'>
                    {totalPrice}
                  </Typography>
                </Box>
              </Box>
            </TotalBlock>
          </Box>
        </Box>
        <Box mt='auto'>
          <Button onClick={navigateToPaymentCheckout} disabled={isSelectedPlan}>
            {isSelectedPlan ? 'Current' : 'Select'}
          </Button>
        </Box>
      </Box>
    </PricingCardWrapper>
  );
};
