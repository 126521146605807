import { useNavigate } from 'react-router-dom';
import { getForgotPasswordEmailSentUrl } from '../ForgotPasswordEmailSentPage/urls/getForgotPasswordEmailSentUrl';
import { getForgotPasswordNextStepUrl } from '../../const/const';
import { ForgotPasswordResponseType } from '@/types/sharedTypes';
import { authTokenService } from '@/bundle/Auth/LoginFlow/LoginPage/service/authTokenService';

export const useForgotPasswordNavigateToStep = () => {
  const navigate = useNavigate();

  const navigateToStep = (body: ForgotPasswordResponseType) => {
    if (body?.is_completed) {
      authTokenService.removeTokens();

      return navigate(getForgotPasswordEmailSentUrl());
    }

    if (body?.auth_token) {
      authTokenService.setTokens({ access: body?.auth_token });

      const nextUrl = getForgotPasswordNextStepUrl(body?.auth_token);

      return navigate(nextUrl);
    }
  };

  return navigateToStep;
};
