import { MouseEvent } from 'react';
import { FakeCheckbox, Input } from './styles';

export type CheckboxClickEventType = MouseEvent<HTMLInputElement>;

type CheckboxProps = {
  checked?: boolean;
  onChange?: () => void;
  disabled?: boolean;
  id?: string;
  onClick?: (event: CheckboxClickEventType) => void;
};

export const Checkbox = ({ id, checked, onClick, onChange, disabled }: CheckboxProps) => {
  return (
    <label>
      <Input id={id} type='checkbox' checked={checked} onChange={onChange} disabled={disabled} onClick={onClick} />
      <FakeCheckbox disabled={disabled} />
    </label>
  );
};
