import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import styled from 'styled-components';

export const PaymentPlanSummaryWrapper = styled.div`
  border-radius: 20px;
  border: 1px solid ${COLORS.grey[400]};
  background-color: ${COLORS.grey[150]};
  width: 384px;
  height: min-content;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  padding: 24px 24px 12px 24px;
`;

export const MonthPriceWrapper = styled.div`
  padding: 16px 0;
  border-top: 1px solid ${COLORS.grey[400]};
  border-bottom: 1px solid ${COLORS.grey[400]};
  ${flex({ justifyContent: 'space-between' })}
`;

export const SavingPriceWrapper = styled.div`
  padding: 12px 24px;
  background: ${COLORS.green[200]};
  ${flex({ justifyContent: 'space-between', alignItems: 'center' })}
`;
