import { COLORS } from '@/styles/colors';
import { flexCenter } from '@/styles/layout';
import styled from 'styled-components';

export const ProgressBarWrapper = styled.div`
  background-color: ${COLORS.grey[300]};
  border-radius: 50px;
  position: relative;
  height: 8px;
  width: 100%;
`;

export const ProgressBarDone = styled.div<{ progressValue: number }>`
  background: ${COLORS.green[350]};
  border-radius: 20px;
  ${flexCenter};
  height: 100%;
  width: 0;
  opacity: 0;
  transition: width ease 0.3s;

  ${({ progressValue }) =>
    progressValue &&
    `
    width: ${progressValue}%;
    opacity: 1;
  `};
`;
