import { ReactComponent as PendingStatusIcon } from '../../images/BlcStatusesIcons/PendingStatusIcon.svg';
import { ReactComponent as RetryStatusIcon } from '../../images/BlcStatusesIcons/RetryStatusIcon.svg';
import { ReactComponent as FailedStatusIcon } from '../../images/BlcStatusesIcons/FailedStatusIcon.svg';
import { USER_ROLE_MAP } from '@/const/user';
import { WIRE_STATUS_DB } from '@/const/wire';

export type StatusType = {
  [key: string]: {
    variant: string;
    statusIcon?: JSX.Element;
  };
};

const STATUS_WIRE_TEXT_DB_ORG_ADMIN = {
  [WIRE_STATUS_DB.CREATED]: 'Created',
  [WIRE_STATUS_DB.RECIPIENT_ASSIGNED]: 'Recipient Assigned',
  [WIRE_STATUS_DB.RECIPIENT_PROXY_ASSIGNED]: 'Recipient Proxy Assigned',
  [WIRE_STATUS_DB.PAYMENT_DETAILS_PROVIDED]: 'Payment Details Provided',
  [WIRE_STATUS_DB.CANCELLED]: 'Cancelled',
  [WIRE_STATUS_DB.EXECUTED]: 'Executed',
  [WIRE_STATUS_DB.RECIPIENT_INVITED]: 'Recipient Invited',
  [WIRE_STATUS_DB.RECIPIENT_PROXY_INVITED]: 'Recipient Proxy Invited',
  [WIRE_STATUS_DB.EXECUTOR_INVITED]: 'Executor Invited',
  [WIRE_STATUS_DB.EXECUTOR_ASSIGNED]: 'Executor Assigned',
  [WIRE_STATUS_DB.PENDING]: 'Pending',
  [WIRE_STATUS_DB.FAILED]: 'Failed',
  [WIRE_STATUS_DB.DEPOSITOR_UNASSIGNED]: 'Depositor Unassigned',
  [WIRE_STATUS_DB.DEPOSITOR_INVITED]: 'Depositor Invited',
  [WIRE_STATUS_DB.PENDING_DEPOSIT]: 'Pending Deposit',
  [WIRE_STATUS_DB.DEPOSIT_SENT]: 'Deposit Sent',
};

const STATUS_WIRE_TEXT_DB_RECIPIENT = {
  [WIRE_STATUS_DB.CREATED]: 'Created',
  [WIRE_STATUS_DB.PROVIDE_PAYMENT_DETAILS]: 'Provide Payment Details',
  [WIRE_STATUS_DB.PAYMENT_DETAILS_PROVIDED]: 'Payment Details Provided',
  [WIRE_STATUS_DB.CANCELLED]: 'Cancelled',
  [WIRE_STATUS_DB.EXECUTED]: 'Executed',
  [WIRE_STATUS_DB.RECIPIENT_INVITED]: 'Recipient Invited',
  [WIRE_STATUS_DB.EXECUTOR_INVITED]: 'Executor Invited',
  [WIRE_STATUS_DB.EXECUTOR_ASSIGNED]: 'Executor Assigned',
  [WIRE_STATUS_DB.RECIPIENT_ASSIGNED]: 'Recipient Assigned',
  [WIRE_STATUS_DB.PENDING]: 'Pending',
  [WIRE_STATUS_DB.RETRY]: 'Retry',
  [WIRE_STATUS_DB.FAILED]: 'Failed',
};

const STATUS_WIRE_TEXT_DB_EXECUTOR = {
  [WIRE_STATUS_DB.READY_FOR_EXECUTION]: 'Ready for Execution',
  [WIRE_STATUS_DB.EXECUTED]: 'Executed',
  [WIRE_STATUS_DB.CANCELLED]: 'Cancelled',
};

const STATUS_WIRE_TEXT_DB_DEPOSITOR = {
  [WIRE_STATUS_DB.PENDING_DEPOSIT]: 'Pending Deposit',
  [WIRE_STATUS_DB.DEPOSIT_SENT]: 'Deposit Sent',
  [WIRE_STATUS_DB.CANCELLED]: 'Cancelled',
};

export const WIRE_STATUS_TEXT_BY_USER_TYPE = {
  [USER_ROLE_MAP.ORGANIZATION]: STATUS_WIRE_TEXT_DB_ORG_ADMIN,
  [USER_ROLE_MAP.RECIPIENT]: STATUS_WIRE_TEXT_DB_RECIPIENT,
  [USER_ROLE_MAP.EXECUTOR]: STATUS_WIRE_TEXT_DB_EXECUTOR,
  [USER_ROLE_MAP.DEPOSITOR]: STATUS_WIRE_TEXT_DB_DEPOSITOR,
};

export const VARIANT_HIGHLIGHT = {
  BLUE: 'blue',
  PURPLE: 'purple',
  LIGHT_PURPLE: 'light_purple',
  YELLOW: 'yellow',
  RED: 'red',
  GREEN: 'green',
  ROSE: 'rose',
  DARK_BLUE: 'dark_blue',
  LIGHT_BLUE: 'light_blue',
  DARK_ROSE: 'dark_rose',
  GREY: 'grey',
};

export const STATUS_MAP: StatusType = {
  [WIRE_STATUS_DB.CREATED]: { variant: VARIANT_HIGHLIGHT.BLUE },
  [WIRE_STATUS_DB.RECIPIENT_ASSIGNED]: { variant: VARIANT_HIGHLIGHT.PURPLE },
  [WIRE_STATUS_DB.RECIPIENT_PROXY_ASSIGNED]: { variant: VARIANT_HIGHLIGHT.PURPLE },
  [WIRE_STATUS_DB.PROVIDE_PAYMENT_DETAILS]: {
    variant: VARIANT_HIGHLIGHT.PURPLE,
  },
  [WIRE_STATUS_DB.PAYMENT_DETAILS_PROVIDED]: {
    variant: VARIANT_HIGHLIGHT.YELLOW,
  },
  [WIRE_STATUS_DB.CANCELLED]: { variant: VARIANT_HIGHLIGHT.RED },
  [WIRE_STATUS_DB.EXECUTED]: { variant: VARIANT_HIGHLIGHT.GREEN },
  [WIRE_STATUS_DB.RECIPIENT_INVITED]: { variant: VARIANT_HIGHLIGHT.ROSE },
  [WIRE_STATUS_DB.RECIPIENT_PROXY_INVITED]: { variant: VARIANT_HIGHLIGHT.ROSE },
  [WIRE_STATUS_DB.READY_FOR_EXECUTION]: {
    variant: VARIANT_HIGHLIGHT.DARK_BLUE,
  },
  [WIRE_STATUS_DB.EXECUTOR_ASSIGNED]: { variant: VARIANT_HIGHLIGHT.LIGHT_BLUE },
  [WIRE_STATUS_DB.EXECUTOR_INVITED]: { variant: VARIANT_HIGHLIGHT.DARK_ROSE },
  [WIRE_STATUS_DB.PENDING]: {
    variant: VARIANT_HIGHLIGHT.BLUE,
    statusIcon: <PendingStatusIcon />,
  },
  [WIRE_STATUS_DB.PENDING_DEPOSIT]: { variant: VARIANT_HIGHLIGHT.LIGHT_BLUE },
  [WIRE_STATUS_DB.DEPOSIT_SENT]: { variant: VARIANT_HIGHLIGHT.GREEN },
  [WIRE_STATUS_DB.RETRY]: {
    variant: VARIANT_HIGHLIGHT.YELLOW,
    statusIcon: <RetryStatusIcon />,
  },
  [WIRE_STATUS_DB.FAILED]: {
    variant: VARIANT_HIGHLIGHT.RED,
    statusIcon: <FailedStatusIcon />,
  },
  [WIRE_STATUS_DB.DEPOSITOR_UNASSIGNED]: { variant: VARIANT_HIGHLIGHT.YELLOW },
  [WIRE_STATUS_DB.DEPOSITOR_INVITED]: { variant: VARIANT_HIGHLIGHT.LIGHT_PURPLE },
};
