import { ApiResponseType, AuthResponseType } from '@/types/sharedTypes';
import { apiService } from '../../../apiService';

export const LOGIN_URI = `/users/auth/login/`;

export type LoginPayload = {
  email: string;
  password: string;
  recaptcha_response: string;
};

export const loginApi = async (data: LoginPayload): Promise<ApiResponseType<AuthResponseType>> => {
  return apiService.POST(LOGIN_URI, data);
};
