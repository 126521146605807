import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import {
  TransactionDetailsColumn,
  TransactionDetailsColumnTitle,
  TransactionDetailsColumnValue,
  TransactionDetailsDateColumn,
  TransactionDetailsFooter,
  TransactionDetailsHeading,
  TransactionDetailsWrapper,
  TransactionName,
} from './styles';
import { ReactComponent as EditIcon } from '@/images/EditIconNew.svg';
import { ReactComponent as SaveIcon } from '@/images/SaveIcon.svg';
import { ReactComponent as CloseIcon } from '@/images/CloseIconNew.svg';
import { dateFormatter } from '@/helpers/formatHelpers';
import { editTransactionValidationSchema } from './const/editTransactionValidationSchema';
import { loadTransactionDetails, updateTransactionDetails } from '../../api';
import { TransactionType } from '@/types/wireTypes';
import { Loader } from '@/components/Loader/Loader';
import { FormikDatePicker } from '@/components/form/FormikDatePicker/FormikDatePicker';
import { parseISO } from 'date-fns';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { IconButton } from '@/components/IconButton/IconButton';
import { Box } from '@/components/Box/Box';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { FormikInput } from '@/components/form/fields/FormikInput/FormikInput';

type UpdatedTransactionType = Pick<TransactionType, 'name' | 'expected_close_date'>;
type UpdateTransactionValuesType = { name: string; expected_close_date: Date };

export const TransactionDetails = () => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const [isEdit, setIsEdit] = useState(false);

  const { data } = useQuery({
    queryKey: ['load_transaction_details', id],
    queryFn: () => loadTransactionDetails(id),
  });

  const {
    mutate,
    data: updatedTransactionDetails,
    isPending,
  } = useMutation({
    mutationKey: ['update_transaction'],
    mutationFn: (values: UpdatedTransactionType) => {
      return updateTransactionDetails(id, values);
    },
    onSuccess(response) {
      if (response.error) return;

      queryClient.setQueryData(['load_transaction_details', id], response);
      setIsEdit(false);
    },
  });

  const transaction = data?.body || ({} as TransactionType);
  const { name, date_created, expected_close_date, ref_id, created_by } = transaction;

  const expectedCloseDate = expected_close_date ? parseISO(expected_close_date) : null;

  const formattedDateCreated = dateFormatter(date_created);
  const formattedExpectedCloseDate = dateFormatter(expected_close_date);

  const formik = useFormik({
    validationSchema: editTransactionValidationSchema,
    initialValues: { name, expected_close_date: expectedCloseDate },
    enableReinitialize: true,
    onSubmit: async (values: UpdateTransactionValuesType) => {
      const payload = {
        name: values.name,
        expected_close_date: values.expected_close_date ? formatDate(values.expected_close_date) : null,
      };

      await mutate(payload);
    },
  });

  const updaterHandler = (e: any) => {
    if (!isPending) {
      amplitudeService.logEvent(AMPLITUDE_EVENTS.EditTransactionSuccess);
      formik.handleSubmit(e);
    }
  };

  const closeEdit = () => {
    formik.resetForm();
    setIsEdit(false);
  };

  const isEditHandler = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.EditTransactionRedirect);
    setIsEdit(true);
  };

  return (
    <FormikForm value={formik}>
      <TransactionDetailsWrapper>
        <TransactionDetailsHeading isEdit={isEdit}>
          {isEdit ? (
            <FormikInput
              autoFocus
              name='name'
              disabled={!isEdit}
              variant='small'
              apiError={updatedTransactionDetails?.error}
            />
          ) : (
            <TransactionName>{name}</TransactionName>
          )}

          {isEdit && !isPending && (
            <Box display='flex' mt='5px' columnGap={12}>
              <IconButton onClick={updaterHandler}>
                <SaveIcon />
              </IconButton>

              <IconButton onClick={closeEdit} svgPath='stroke'>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          {isPending && <Loader width={40} background='transparent' />}
          {!isEdit && (
            <IconButton onClick={isEditHandler}>
              <EditIcon />
            </IconButton>
          )}
        </TransactionDetailsHeading>

        <TransactionDetailsFooter>
          <TransactionDetailsColumn>
            <TransactionDetailsColumnTitle>Transaction ID</TransactionDetailsColumnTitle>
            <TransactionDetailsColumnValue>{ref_id}</TransactionDetailsColumnValue>
          </TransactionDetailsColumn>

          <TransactionDetailsColumn>
            <TransactionDetailsColumnTitle>Creation Date</TransactionDetailsColumnTitle>
            <TransactionDetailsColumnValue>{formattedDateCreated}</TransactionDetailsColumnValue>
          </TransactionDetailsColumn>

          <TransactionDetailsDateColumn>
            {isEdit ? (
              <FormikDatePicker
                name='expected_close_date'
                placeholder='Select Date'
                label='Expected Closing Date'
                variant='small'
              />
            ) : (
              <>
                <TransactionDetailsColumnTitle>Expected Closing Date</TransactionDetailsColumnTitle>
                <TransactionDetailsColumnValue>{formattedExpectedCloseDate}</TransactionDetailsColumnValue>
              </>
            )}
          </TransactionDetailsDateColumn>

          <TransactionDetailsColumn>
            <TransactionDetailsColumnTitle>Created By</TransactionDetailsColumnTitle>
            <TransactionDetailsColumnValue title={created_by?.email}>{created_by?.email}</TransactionDetailsColumnValue>
          </TransactionDetailsColumn>
        </TransactionDetailsFooter>
      </TransactionDetailsWrapper>
    </FormikForm>
  );
};
