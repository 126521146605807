import { createSearchParams, useNavigate } from 'react-router-dom';
import { decodeToken } from '@/helpers/tokenHelpers';
import { AccessTokenType, AuthResponseType } from '@/types/sharedTypes';
import { authTokenService } from '@/bundle/Auth/LoginFlow/LoginPage/service/authTokenService';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { sessionStorageService } from '@/helpers/storageHelpers';
import { REDIRECT_URL_KEY } from '@/const/shared';
import { ORG_ADMIN_ROLE, USER_ROLE_MAP, UserRoleType } from '@/const/user';
import { DASHBOARD_REDIRECT_MAP } from '../LoginFlow/LoginPage/const/const';
import { setStorageCurrentRole } from '@/helpers/userHelpers';
import { useQueryClient } from '@tanstack/react-query';
import { loadSelfUserDetails } from '../LoginFlow/LoginPage/api';
import { getLoginUrl } from '../LoginFlow/LoginPage/urls/getLoginUrl';
import { getSubscriptions } from '@/bundle/pages/SettingsPage/api';
import { getSelectPaymentPlanUrl } from '@/bundle/pages/SelectPaymentPlanPage/urls/getSelectPaymentPlanUrl';

export const getLoginRedirectUrl = (role: UserRoleType) => {
  if (!role) return '';

  return DASHBOARD_REDIRECT_MAP[role];
};

export const useLogin = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const login = async (authResponse: AuthResponseType, currentRole?: UserRoleType) => {
    const { access, refresh } = authResponse;
    const { user_id, active_roles } = decodeToken<AccessTokenType>(access);
    const token = { access, refresh, user_id };
    const previousLocation = sessionStorageService.get(REDIRECT_URL_KEY);
    const role = currentRole || active_roles[0];

    authTokenService.setTokens(token);
    amplitudeService.logEvent(AMPLITUDE_EVENTS.LogIN);
    setStorageCurrentRole(role);

    // TODO v13.0.0: refactor this when BE will implement new existing_roles in access token
    // [START] Load user details to check is role is "site_admin" and then load subscription to check if user can proceed to app
    if (role === USER_ROLE_MAP.ORGANIZATION) {
      try {
        const userResponse = await queryClient.fetchQuery({
          queryKey: ['load_self_user'],
          queryFn: () => loadSelfUserDetails(),
        });

        if (userResponse.error) return;

        const isSiteAdmin = userResponse?.body?.organization_user?.role === ORG_ADMIN_ROLE.SITE_ADMIN;

        if (isSiteAdmin) {
          const subscriptionResponse = await queryClient.fetchQuery({
            queryKey: ['get_subscriptions'],
            queryFn: () => getSubscriptions(),
          });

          if (subscriptionResponse?.error) return;

          const subscriptionCount = subscriptionResponse?.body?.count;

          if (!subscriptionCount) {
            return navigate({
              pathname: getSelectPaymentPlanUrl(),
              search: createSearchParams({
                redirected_from: 'auth',
              }).toString(),
            });
          }
        }
      } catch (error) {
        return navigate(getLoginUrl());
      }
    }
    // [END]

    if (previousLocation) {
      sessionStorageService.remove(REDIRECT_URL_KEY);

      return navigate(previousLocation, { replace: true });
    }

    const dashboardUrl = getLoginRedirectUrl(role);

    return navigate(dashboardUrl, { replace: true });
  };

  return { login };
};
