import { LoadPaymentDetailsWrapper } from './styles';
import { LoadPaymentDetailsLoading } from './LoadPaymentDetailsLoading';
import { LoadPaymentDetailsBlockhainUnavailable } from './LoadPaymentDetailsBlockhainUnavailable';
import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import { Notification } from '@/components/Notification/Notification';

type LoadPaymentDetailsWidgetType = {
  isLoading?: boolean;
  hasError?: boolean;
  paymentDetails?: PaymentDetailsGeneralType;
};

export const LoadPaymentDetailsWidget = ({ isLoading, hasError, paymentDetails }: LoadPaymentDetailsWidgetType) => {
  const hasDetails = !isLoading && !hasError;
  const hasBlockchainDetails = hasDetails && paymentDetails?.is_blockchain_payment_details_data_from_bc;
  const hasDatabaseDetails = hasDetails && paymentDetails?.is_blockchain_payment_details_data_from_bc === false;

  return (
    <LoadPaymentDetailsWrapper>
      {isLoading && <LoadPaymentDetailsLoading />}
      {hasBlockchainDetails && (
        <Notification variant='success' fontVariant='medium' verticalAlign='center' mb='20px'>
          Stored in the WireVault blockchain.
        </Notification>
      )}
      {hasDatabaseDetails && <LoadPaymentDetailsBlockhainUnavailable />}
    </LoadPaymentDetailsWrapper>
  );
};
