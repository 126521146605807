import { OrganizationListItemType } from '@/bundle/_Opco/types/types';

export const getOrganizationOptions = (organizations: OrganizationListItemType[] = []) => {
  return organizations.map((organization) => {
    return {
      value: organization?.id,
      label: organization?.name,
    };
  });
};
