import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { WIRE_STATUS_DB } from '@/const/wire';
import { getRecipientWireApi } from '@/api/v1/recipient/getRecipientWireApi';
import { getRecipientPaymentDetailsApi } from '@/api/v1/recipient/getRecipientPaymentDetailsApi';
import { recipientChangeWireStatusApi } from '@/api/v1/recipient/recipientChangeWireStatusApi';
import { updateWirePaymentDetailsDomesticApi } from '@/api/v1/organization/wires/updateWirePaymentDetailsDomesticApi';
import { updateWirePaymentDetailsInternationalApi } from '@/api/v1/organization/wires/updateWirePaymentDetailsInternationalApi';
import { initRecipientDocumentVerificationApi } from '@/api/v1/recipient/initRecipientDocumentVerificationApi';
import { getRecipientTrustStampStatusApi } from '@/api/v1/recipient/getRecipientTrustStampStatusApi';
import { confirmPaymentDetailsApi } from '@/api/v1/recipient/confirmPaymentDetailsApi';

export const loadRecipientWire = async (id: string) => {
  try {
    const response = await getRecipientWireApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadRecipientPaymentDetails = async (id: string) => {
  try {
    const response = await getRecipientPaymentDetailsApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const updatePaymentDetails = async (id: string, values: any, isDomesticPaymentType: boolean) => {
  try {
    const response = isDomesticPaymentType
      ? await updateWirePaymentDetailsDomesticApi(id, values)
      : await updateWirePaymentDetailsInternationalApi(id, values);

    return prepareResponse(response as any);
  } catch (e) {
    throwResponseError(e);
  }
};

export const changeWireStatus = async (id: string, status: typeof WIRE_STATUS_DB.RECIPIENT_ASSIGNED) => {
  const body = {
    status,
  };

  try {
    const response = await recipientChangeWireStatusApi(id, body);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const initRecipientDocumentVerification = async (wireId: string) => {
  try {
    const response = await initRecipientDocumentVerificationApi(wireId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const getRecipientTrustStampStatus = async (wireId: string) => {
  try {
    const response = await getRecipientTrustStampStatusApi(wireId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const confirmPaymentDetails = async (wireId: string) => {
  try {
    const response = await confirmPaymentDetailsApi(wireId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
