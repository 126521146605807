import { Box } from '@/components/Box/Box';
import { Notification } from '@/components/Notification/Notification';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';

export const InitialSiteAdminNotification = () => {
  return (
    <Notification>
      <Box display='flex' flexDirection='column' rowGap={12}>
        <Typography variant='bodySmall' fontWeight='semibold' color={COLORS.black}>
          First user will be Site Admin by default.
        </Typography>
        <Typography variant='bodySmall' color={COLORS.black}>
          For the first user creation, the role is automatically assigned as Site Admin. Other role options will become
          available once the initial setup is complete.
        </Typography>
      </Box>
    </Notification>
  );
};
