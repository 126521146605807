import { PAYMENT_PLAN_TYPE } from '@/bundle/pages/SelectPaymentPlanPage/ui/PaymentPlanCard/const';

export const getPlanName = (name: string) => {
  if (name?.toLocaleLowerCase().includes(PAYMENT_PLAN_TYPE.FREE)) return PAYMENT_PLAN_TYPE.FREE;

  if (name?.toLocaleLowerCase().includes(PAYMENT_PLAN_TYPE.STARTER)) return PAYMENT_PLAN_TYPE.STARTER;

  if (name?.toLocaleLowerCase().includes(PAYMENT_PLAN_TYPE.PRO)) return PAYMENT_PLAN_TYPE.PRO;

  return null;
};
