import { authApiService } from '@/api/authApiService';
import { UserRoleType } from '@/const/user';
import { ApiResponseType, ListResponseType } from '@/types/sharedTypes';

export type SearchRoleType = Exclude<UserRoleType, 'opco_user' | 'organization_user'>;

export type SearchUserType = {
  id: string;
  email: string;
};

export type SearchUsersPayloadType = {
  search: string;
  role: SearchRoleType;
};

export const getSearchUsersApi = (
  data: SearchUsersPayloadType
): Promise<ApiResponseType<ListResponseType<SearchUserType>>> => {
  const uri = '/organization/users/search/';

  return authApiService.GET(uri, data);
};
