import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';
import { CreateRecipientPayloadType, CreateRecipientType } from '../organization/wires/createRecipientApi';

export const createRecipientByProxyApi = (
  wireId: string,
  data: CreateRecipientPayloadType
): Promise<ApiResponseType<CreateRecipientType>> => {
  const uri = `/recipient/wires/${wireId}/recipient/`;

  return authApiService.POST(uri, data);
};
