import { COLORS } from '@/styles/colors';
import { resetButton } from '@/styles/common';
import { bodySmall, poppinsMediumFont } from '@/styles/typography';
import styled from 'styled-components';

export const ChangeButton = styled.button`
  ${resetButton};
  color: ${COLORS.blue[500]};
  ${bodySmall};
  ${poppinsMediumFont};
  margin-left: auto;
  cursor: pointer;
`;
