import { useRef } from 'react';
import { SidebarWrapper, MenuWrapper, SidebarOverlay } from './styles';
import { SidebarUserDetails } from './ui/SidebarUserDetails/SidebarUserDetails';
import { SidebarMenu } from './ui/SidebarMenu/SidebarMenu';
import { SidebarHeader } from './ui/SidebarHeader/SidebarHeader';

type SidebarType = {
  isOpen: boolean;
  onNavigate: () => void;
  onClose: () => void;
};

export const Sidebar = ({ isOpen, onNavigate, onClose }: SidebarType) => {
  const nodeRef = useRef(null);

  return (
    <>
      <SidebarOverlay isOpen={isOpen} onClick={onClose} />
      <SidebarWrapper ref={nodeRef} isOpen={isOpen}>
        <MenuWrapper>
          <SidebarHeader />
          <SidebarMenu onNavigate={onNavigate} />
          <SidebarUserDetails onNavigate={onNavigate} />
        </MenuWrapper>
      </SidebarWrapper>
    </>
  );
};
