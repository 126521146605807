import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { Header, SubHeader } from '@/bundle/_Recipient/_Details/ui/ProxyChoosePaymentDetailsProvider/styles';
import { Box } from '@/components/Box/Box';
import { ReactComponent as WireInfo } from './images/WireInfo.svg';

export const ProxyLockedPaymentDetailsPreview = () => {
  return (
    <DetailsWidget
      header={
        <Box flexDirection='column' rowGap='8px' width='100%'>
          <Header>Wire Info</Header>
          <SubHeader>Wire info will be displayed after an Admin views it.</SubHeader>
        </Box>
      }
    >
      <Box alignItems='center' justifyContent='center' width='100%' mt='8px'>
        <WireInfo />
      </Box>
    </DetailsWidget>
  );
};
