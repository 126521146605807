import { COLORS } from '@/styles/colors';
import { bodySmall, poppinsMediumFont } from '@/styles/typography';
import styled from 'styled-components';

export const FilterListClearAll = styled.button`
  flex-shrink: 0;
  ${poppinsMediumFont}
  ${bodySmall}
  color: ${COLORS.green[500]};
  background: transparent;
  border: none;
  cursor: pointer;
`;
