import { prepareListApiParams } from '@/api/helpers/urlApiHelpers';
import { getDepositorWiresApi } from '@/api/v1/depositor/getDepositorWiresApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const loadWires = async (params) => {
  const apiParams = prepareListApiParams(params);

  try {
    const response = await getDepositorWiresApi(apiParams);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
