import { ReactNode } from 'react';
import { Link } from './styles';
import { ValueOf } from '@/types/sharedTypes';

const VariantType = {
  small: 'small',
} as const;

type ExternalLinkType = {
  children: ReactNode;
  href: string;
  target?: '_blank' | '_self';
  variant?: ValueOf<typeof VariantType>;
};

export const ExternalLink = ({ children, href, target = '_self', variant }: ExternalLinkType) => {
  return (
    <Link href={href} target={target} rel='noreferrer' variant={variant}>
      {children}
    </Link>
  );
};
