import { AssignedWireType } from '@/types/wireTypes';
import { isRecipientChainProxy, isRecipientProxy } from '@/helpers/wireHelpers';
import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import { PaymentDetailsHeaderSlot } from '@/bundle/shared/components/PaymentDetailsHeaderSlot/PaymentDetailsHeaderSlot';
import { getPaymentDetailsHeaderSlot } from './const';
import capitalize from 'lodash/capitalize';
import { PaymentDetailsCollectionType } from './styles';
import { PaymentDetailsDataWidget } from '@/bundle/shared/components/PaymentDetailsDataWidget/PaymentDetailsDataWidget';
import { ResponseErrorType } from '@/types/sharedTypes';

interface OutboundWirePaymentDetailsType {
  wire: AssignedWireType;
  paymentDetails: PaymentDetailsGeneralType;
  isLoading: boolean;
  apiError?: ResponseErrorType;
}

export const OutboundWirePaymentDetails = ({ wire, isLoading, paymentDetails }: OutboundWirePaymentDetailsType) => {
  const isProxy = isRecipientProxy(wire);
  const isChainProxy = isRecipientChainProxy(wire);
  const headerSlot = getPaymentDetailsHeaderSlot(isChainProxy, isProxy);
  const paymentDetailsCollectionType = capitalize(wire?.payment_details_collection_type);

  return (
    <PaymentDetailsDataWidget
      paymentDetails={paymentDetails}
      isLoading={isLoading}
      action={<PaymentDetailsCollectionType>{paymentDetailsCollectionType}</PaymentDetailsCollectionType>}
      headlineSlot={headerSlot && <PaymentDetailsHeaderSlot {...headerSlot} />}
    />
  );
};
