import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

export type UpdateRecipientPayloadType = {
  mobile_phone: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
};

export const updateRecipientApi = (
  wireId: string,
  userId: string,
  data: UpdateRecipientPayloadType
): Promise<ApiResponseType<any>> => {
  const uri = `/organization/wires/${wireId}/recipients/users/${userId}/`;

  return authApiService.PATCH(uri, data);
};
