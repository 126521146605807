import { ReactNode } from 'react';
import { Row, RowDirectionType, RowName, RowOffsetType, RowValue } from './styles';
import { ReactComponent as StatusHintIcon } from '../../../images/StatusHintIcon.svg';
import { Tooltip } from '@/components/Tooltip/Tooltip';

type DetailsRowType = {
  name: ReactNode;
  children?: ReactNode;
  tooltip?: any;
  hasHighlight?: boolean;
  hasBorder?: boolean;
  direction?: RowDirectionType;
  offset?: RowOffsetType;
  isHeader?: boolean;
  title?: string;
};

export const DetailsRow = ({
  name,
  children,
  tooltip,
  hasHighlight,
  hasBorder,
  direction,
  offset = 'default',
  isHeader,
  title,
}: DetailsRowType) => {
  const tooltipProps = tooltip ? { 'data-tooltip-id': name, 'data-tooltip-content': tooltip.content || children } : {};
  const tooltipOptions = tooltip ? tooltip.options : {};

  return (
    <Row hasHighlight={hasHighlight} hasBorder={hasBorder} direction={direction} offset={offset}>
      <RowName isHeader={isHeader}>
        {name}
        {tooltip && (
          <>
            <StatusHintIcon {...tooltipProps} />
            <Tooltip id={name} {...tooltipOptions} />
          </>
        )}
      </RowName>
      <RowValue hasBorder={hasBorder} direction={direction} title={title}>
        {children}
      </RowValue>
    </Row>
  );
};
