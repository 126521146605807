import { Box } from '@/components/Box/Box';
import { AuthLayout } from '../../ui/AuthLayout/AuthLayout';
import { AuthStep } from '../../ui/AuthStep/AuthStep';
import { useNavigate } from 'react-router-dom';
import { getLoginUrl } from '../../LoginFlow/LoginPage/urls/getLoginUrl';
import { Button } from '@/components/Button/Button';
import { ReactComponent as ExpiredLinkIcon } from './images/ExpiredLinkIcon.svg';
import { AuthTitle } from '../../ui/AuthTitle/AuthTitle';

export const LinkExpiredPage = () => {
  const navigate = useNavigate();

  const redirectToLogin = () => {
    return navigate(getLoginUrl());
  };

  return (
    <AuthLayout>
      <AuthStep>
        <Box alignItems='center' flexDirection='column'>
          <Box mb='28px'>
            <ExpiredLinkIcon />
          </Box>
          <AuthTitle marginBottom={36}>The link has been expired</AuthTitle>

          <Button onClick={redirectToLogin}>Return to Login</Button>
        </Box>
      </AuthStep>
    </AuthLayout>
  );
};
