import { ReactNode } from 'react';
import {
  CardColumns,
  CardHeader,
  CardHeading,
  CardMarker,
  CardMeta,
  CardWrapper,
  ContentKey,
  ContentValue,
  ContentWrapper,
} from './styles';

type CardContentType = {
  name: ReactNode;
  children: ReactNode;
  ratio?: number;
  width?: string;
};

export const CardContent = ({ name, children, ratio = 1, width }: CardContentType) => {
  return (
    <ContentWrapper ratio={ratio} width={width}>
      <ContentKey>{name}</ContentKey>
      <ContentValue title={children as string}>{children}</ContentValue>
    </ContentWrapper>
  );
};

type CardType = {
  header: ReactNode;
  title: string;
  meta?: ReactNode;
  marker?: ReactNode;
  children: ReactNode;
  onClick?: () => void;
};

export const Card = ({ header, meta, title, marker, children, onClick }: CardType) => {
  const clickHandler = onClick ? onClick : null;

  return (
    <CardWrapper onClick={clickHandler}>
      {marker && <CardMarker>{marker}</CardMarker>}
      <CardHeading>
        <CardHeader title={title}>{header}</CardHeader>
        <CardMeta>{meta}</CardMeta>
      </CardHeading>
      <CardColumns>{children}</CardColumns>
    </CardWrapper>
  );
};
