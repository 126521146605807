import { StatusWrapper } from '@/components/Status/styles';
import { OpcoUserStatusType } from '../../../types/types';
import { OPCO_USER_STATUS_MAP } from './const';

type StatusPropsType = {
  status: OpcoUserStatusType;
};

export const OpcoUserStatus = ({ status }: StatusPropsType) => {
  const statusMap = OPCO_USER_STATUS_MAP[status];

  return <StatusWrapper variant={statusMap?.variant}>{statusMap?.title}</StatusWrapper>;
};
