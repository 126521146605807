import { ReactComponent as CalendarIcon } from '@/images/CalendarIcon.svg';
import {
  AMOUNT_VALIDATOR,
  REQUIRED_OBJECT_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  STRING_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';
import { FormConfigType } from '@/helpers/formHelpers/formHelpers';

export const getInboundWireEditConfig = (currentOptions = []): FormConfigType => {
  return [
    {
      name: 'name',
      label: 'Wire Name*',
      placeholder: 'Enter Name',
      validator: REQUIRED_TEXT_FIELD_VALIDATOR,
    },
    {
      name: 'ref_id',
      label: 'Wire ID',
      placeholder: 'Enter Wire ID',
      disabled: true,
    },
    {
      name: 'type',
      label: 'Wire Type',
      disabled: true,
    },
    {
      name: 'date_created',
      label: 'Wire Creation Date',
      disabled: true,
      column: 6,
      meta: {
        iconRight: <CalendarIcon />,
        mobileStretch: true,
      },
    },
    {
      name: 'expected_payment_date',
      label: 'Expected Payment Date',
      type: 'datepicker',
      placeholder: 'Select Date',
      column: 6,
      validator: STRING_VALIDATOR,
      meta: {
        hasErrorComponent: true,
        mobileStretch: true,
      },
    },
    {
      name: 'amount',
      label: 'Wire Amount',
      placeholder: 'Enter Number',
      column: 6,
      validator: AMOUNT_VALIDATOR,
      meta: {
        mobileStretch: true,
      },
    },
    {
      name: 'currency',
      label: 'Currency',
      type: 'select',
      options: currentOptions,
      column: 6,
      validator: REQUIRED_OBJECT_VALIDATOR,
      meta: {
        mobileStretch: true,
      },
    },
    {
      name: 'notes',
      label: 'Notes',
      type: 'textarea',
      placeholder: 'Enter Notes',
      validator: TEXT_FIELD_VALIDATOR,
    },
  ];
};
