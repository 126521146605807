import styled from 'styled-components';
import { ReactComponent as NewPasswordChangedIcon } from '@/images/NewPasswordChangedIcon.svg';
import { COLORS } from '@/styles/colors';
import { headline4 } from '@/styles/typography';

export const CreatePasswordMessage = styled.div`
  margin-bottom: 32px;
  text-align: center;
  color: ${COLORS.grey[900]};
  ${headline4};
  font-weight: 600;
`;

export const CreatePasswordButtonBlock = styled.div`
  width: 100%;
`;

export const ChangedPasswordIcon = styled(NewPasswordChangedIcon)`
  margin: 0 auto;
`;
