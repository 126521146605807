import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { media } from '@/styles/responsive';

export const CreateDepositAccountWrapper = styled.div`
  display: flex;
  column-gap: 28px;
`;

export const CreateDepositAccountForm = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 28px 28px 36px;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.grey[850]};
  box-shadow: 0 4px 20px rgb(0 0 0 / 20%);
  border-radius: 20px;

  ${media.tablet`
    max-width: none;  
  `}

  ${media.mobile`
    padding: 16px;
  `}
`;

export const CreateDepositAccountFormButton = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 20px;
  justify-content: end;
`;
