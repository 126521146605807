import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import { bodySmall, poppinsMediumFont, poppinsRegularFont } from '@/styles/typography';

export const Wrapper = styled.div<{ isActive: boolean; disabled?: boolean }>`
  width: 100%;
  ${flex({ alignItems: 'center', justifyContent: 'column' })};
  row-gap: 4px;
  padding: 12px 16px;
  border-radius: 12px;
  border: 1px solid ${COLORS.grey[300]};
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    `
      background-color: ${COLORS.green[200]};
      border: 1px solid ${COLORS.green[500]}  
    `};

  ${({ disabled }) =>
    disabled &&
    `
      background-color: ${COLORS.grey[200]};
      cursor: default;
    `};
`;

export const Label = styled.span`
  ${poppinsRegularFont};
  ${bodySmall};
`;

export const MetaWrapper = styled.div`
  ${poppinsRegularFont};
  ${bodySmall};
  padding: 2px 8px;
  background-color: ${COLORS.green[200]};
  color: ${COLORS.green[500]};
  border-radius: 8px;
`;

export const Title = styled.div`
  ${poppinsMediumFont};
`;

export const Description = styled.div`
  width: 100%;
  color: ${COLORS.grey[600]};
  ${poppinsRegularFont};
  ${bodySmall};
`;
