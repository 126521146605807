import styled from 'styled-components';
import { flex } from '@/styles/layout';
import { COLORS } from '@/styles/colors';
import { bodySmall, poppinsMediumFont } from '@/styles/typography';

export const LoadPaymentDetailsWrapper = styled.div`
  width: 100%;
  ${flex({ flexDirection: 'column' })};
`;

export const LoadPaymentDetailsRow = styled.div`
  gap: 8px;
  width: 100%;
  ${flex({ alignItems: 'center' })};
  padding: 15px 16px;
  background: ${COLORS.blue[200]};
  border-radius: 10px;
  ${poppinsMediumFont};
  ${bodySmall};
`;

export const LoadPaymentDetailsLoaderErrorRow = styled(LoadPaymentDetailsRow)`
  background: #ffe7e9;
`;

export const LoadPaymentDetailsLoaderSuccessRow = styled(LoadPaymentDetailsRow)`
  background: #e4fff5;
  margin-bottom: 20px;
`;

export const LoadPaymentDetailsLoaderErrorWrapper = styled.div`
  ${flex({ flexDirection: 'column', alignItems: 'center' })};
  row-gap: 20px;
`;

export const LoadPaymentDetailsButtonRow = styled.div`
  width: 100%;
  ${flex({ justifyContent: 'start' })};
`;

export const BlockchainUnavailableHintWrapper = styled.div`
  margin-bottom: 20px;
`;
