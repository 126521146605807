import styled from 'styled-components';
import { flex } from '@/styles/layout';
import { COLORS } from '@/styles/colors';
import { bodySmall, headline4, poppinsRegularFont, poppinsSemiBoldFont } from '@/styles/typography';
import { media } from '@/styles/responsive';
import { ButtonWrapper } from '@/components/Button/styles';
import { LoaderWrapper } from '@/components/Loader/styles';

export const PinCodeWrapper = styled.div<{ hasShadow: boolean }>`
  ${flex({ flexDirection: 'column' })};
  padding: 42px;
  background-color: ${COLORS.white};
  row-gap: 24px;
  border-radius: 20px;

  ${({ hasShadow }) =>
    hasShadow &&
    `
    border-radius: 20px;
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  `};

  ${media.mobile`
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 42px 16px;
    box-shadow: none;
  `};
`;

export const Title = styled.p`
  ${poppinsSemiBoldFont};
  ${headline4};
  color: ${COLORS.grey[900]};
  margin: 0;
`;

export const Message = styled.p<{ hasError?: boolean }>`
  margin: 0;
  ${poppinsRegularFont};
  ${bodySmall};
  color: ${({ hasError }) => (hasError ? `${COLORS.red[400]}` : `${COLORS.grey[600]}`)};
`;

export const AttemptsCount = styled.p<{ hasError }>`
  margin: 0;
  ${poppinsSemiBoldFont};
  ${bodySmall};
  color: ${({ hasError }) => (hasError ? `${COLORS.red[400]}` : `${COLORS.grey[850]}`)};
`;

export const ForgotPinCodeMessage = styled.div`
  ${bodySmall};
  ${poppinsRegularFont};
  ${COLORS.black};
`;

export const RequestButton = styled.button`
  background: transparent;
  border: none;
  color: ${COLORS.green[500]};
  ${bodySmall};
  ${poppinsRegularFont};
  cursor: pointer;
`;

export const PinCodeButtonsWrapper = styled.div`
  ${flex({ justifyContent: 'space-between' })};
  column-gap: 24px;
  margin: 0 auto;
  width: 100%;

  ${ButtonWrapper}, ${LoaderWrapper} {
    flex-basis: 50%;
    flex-shrink: 1;
  }

  ${media.mobile`
    max-width: 350px;
  `}
`;
