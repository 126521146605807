import { ReactNode, useState } from 'react';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import {
  AnimatedBlock,
  ToggleButton,
} from '@/bundle/_Recipient/_Details/ui/RecipientDocumentVerification/DocumentVerificationStart/ui/styles';
import { ReactComponent as CloseArrowIcon } from './images/CloseArrowIcon.svg';
import { ReactComponent as OpenArrowIcon } from './images/OpenArrowIcon.svg';
import {
  TrustStampLink,
  TrustStampMessage,
} from '@/bundle/_Recipient/_Details/ui/RecipientDocumentVerification/TrustStampDetails/styles';
import { TrustStampDetails } from '../TrustStampDetails/TrustStampDetails';
import { ReceiveSmsStep } from './ui/ReceiveSmsStep';
import { RecordVideoStep } from './ui/RecordVideoStep';
import { TakePhotoStep } from './ui/TakePhotoStep';
import { ScanBarcodeStep } from './ui/ScanBarcodeStep';
import { formatSecondsToMinutes } from '@/helpers/dateHelpers/dateHelpers';
import { TRUST_STAMP_URL, isTrustStampInitializedStatus } from '../helpers';
import { TrustStampResponseStatusType } from '@/api/v1/recipient/getRecipientTrustStampStatusApi';
import { DetailsWidget } from '@/components/DetailsWidget/DetailsWidget';
import { ReelAnimateTime } from '../ReelAnimateTime/ReelAnimateTime';

type DocumentVerificationStartType = {
  trustStampDetails: TrustStampResponseStatusType;
  isLoading: boolean;
  seconds: number;
  userPhone: string;
  actionSlot?: ReactNode;
  onStartTrustStamp: () => void;
};

export const DocumentVerificationStart = ({
  trustStampDetails,
  isLoading,
  onStartTrustStamp,
  userPhone,
  seconds,
  actionSlot,
}: DocumentVerificationStartType) => {
  const [isShowAll, setIsShowAll] = useState(false);

  const showAll = () => {
    setIsShowAll((value) => !value);
  };

  const startNewSessionSeconds = trustStampDetails?.start_new_session_enable_after_sec;
  const status = trustStampDetails?.status;
  const isTrustStampInitialized = isTrustStampInitializedStatus(status) && startNewSessionSeconds !== 0;

  return (
    <DetailsWidget header='Identity Verification' headerOffset={12} action={actionSlot}>
      <Box>
        <TrustStampDetails>
          <TrustStampMessage>
            To proceed with your wire, you need to verify your identity using Trust Stamp. The steps are as follows. For
            more information, visit{' '}
            <TrustStampLink to={TRUST_STAMP_URL} target='_blank' variant='primary'>
              truststamp.ai
            </TrustStampLink>
            .
          </TrustStampMessage>
        </TrustStampDetails>
        <Box mt='12px' rowGap='20px' flexDirection='column'>
          <ReceiveSmsStep userPhone={userPhone} />
          <ToggleButton onClick={showAll}>
            See all steps {isShowAll ? <OpenArrowIcon /> : <CloseArrowIcon />}
          </ToggleButton>
          <AnimatedBlock isShow={isShowAll}>
            <Box flexDirection='column' rowGap='20px'>
              <RecordVideoStep />
              <TakePhotoStep />
              <ScanBarcodeStep />
            </Box>
          </AnimatedBlock>
        </Box>
        <Button onClick={onStartTrustStamp} disabled={isTrustStampInitialized} isLoading={isLoading}>
          {isTrustStampInitialized ? (
            <>
              Resend in
              <Box ml='5px'>{seconds ? <ReelAnimateTime time={formatSecondsToMinutes(seconds)} /> : '...'}</Box>
            </>
          ) : (
            'Start'
          )}
        </Button>
      </Box>
    </DetailsWidget>
  );
};
