import { IconCardWrapper, InfoCardWrapper, DefaultStatus, PaymentCardContainer } from './styles';
import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { ReactComponent as DeleteIcon } from './icons/DeleteIcon.svg';
import { PAYMENT_CARDS_ICON_MAP } from './const';
import capitalize from 'lodash/capitalize';
import { PaymentCardNumber } from '../PaymentCardNumber/PaymentCardNumber';
import { PaymentCardType } from '@/types/paymentTypes';

type PaymentCardPropsType = {
  card: PaymentCardType;
  defaultPaymentCardId?: string;
  readonly?: boolean;
  onSetDefault?: (card: PaymentCardType) => void;
  onDelete?: (card: PaymentCardType) => void;
};

export const PaymentCard = ({
  card,
  defaultPaymentCardId,
  readonly = false,
  onSetDefault,
  onDelete,
}: PaymentCardPropsType) => {
  const { id, card_type, masked_number, expiration_date } = card;
  const isDefault = defaultPaymentCardId === id;

  return (
    <PaymentCardContainer>
      <Box display='flex' columnGap={12}>
        <IconCardWrapper>{PAYMENT_CARDS_ICON_MAP[card_type]}</IconCardWrapper>
        <InfoCardWrapper>
          <Typography
            variant='bodySmall'
            fontWeight='medium'
            color={COLORS.black}
            display='flex'
            alignItems='center'
            columnGap='12px'
          >
            {capitalize(card_type)} <PaymentCardNumber cardNumber={masked_number} />
          </Typography>
          <Typography variant='bodySmall' color={COLORS.grey[650]}>
            Expiry date: {expiration_date}
          </Typography>
        </InfoCardWrapper>

        {!readonly && (
          <Box display='flex' columnGap={12} alignItems='center' height='min-content'>
            {isDefault ? (
              <DefaultStatus>Default</DefaultStatus>
            ) : (
              <Typography
                focusable
                fontWeight='medium'
                variant='bodySmall'
                color={COLORS.blue[500]}
                onClick={() => onSetDefault?.(card)}
              >
                Make default
              </Typography>
            )}

            {!isDefault && (
              <Box focusable onClick={() => onDelete(card)}>
                <DeleteIcon />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </PaymentCardContainer>
  );
};
