import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { FormikProps, useFormik } from 'formik';
import { ContentLayout } from '../../Layouts/ContentLayout/ContentLayout';
import { ContactUsHeader, ContactUsWrapper } from './styles';
import { postContactMessage } from '@/bundle/pages/ContactUsPage/api';
import { CONTACT_US_FORM_CONFIG } from '@/bundle/pages/ContactUsPage/const/const';
import { Notification } from '@/components/Notification/Notification';
import { Box } from '@/components/Box/Box';
import { FormValuesType } from './types';
import { showSnackbar } from '@/components/Snackbar/Snackbar';
import { useUser } from '@/context/userContext';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { Button } from '@/components/Button/Button';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { getMergedValues, getValidationSchema } from '@/helpers/formHelpers/formHelpers';
import { HTTP_TOO_MANY_REQUESTS_STATUS } from '@/api/const';

export const ContactUsPage = () => {
  const ref = useRef<HTMLDivElement>(null);

  const { user } = useUser();
  const currentUserData = {
    sender_email: user?.email,
    mobile_phone: user?.mobile_phone,
  };
  const initialValues = getMergedValues(CONTACT_US_FORM_CONFIG, currentUserData);

  const { mutate, isPending, data } = useMutation({
    mutationKey: ['send_message'],
    mutationFn: (formik: FormikProps<FormValuesType>) => {
      return postContactMessage(formik.values);
    },
    onSuccess: (createdMessage, formik) => {
      if (createdMessage.error && createdMessage.error?.status_code === HTTP_TOO_MANY_REQUESTS_STATUS) {
        showSnackbar(createdMessage.error?.error_content as string, { variant: 'error' });

        return;
      }

      if (createdMessage.error) return;

      formik.resetForm();
      showSnackbar('Your request was successfully submitted.', { variant: 'success' });
    },
  });

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      recipient: null,
    },
    enableReinitialize: true,
    validationSchema: getValidationSchema(CONTACT_US_FORM_CONFIG),
    onSubmit: () => {
      return mutate(formik);
    },
  });
  const apiError = data?.error;

  return (
    <ContentLayout title='Contact us'>
      <ContactUsWrapper ref={ref}>
        <ContactUsHeader>Submit a request</ContactUsHeader>
        <Notification fontVariant='medium' mb='24px'>
          Please describe your issue in the form below and we will contact you as soon as possible.
        </Notification>
        <Box justifyContent='center' flexDirection='column'>
          <FormikForm value={formik}>
            <FormGeneratorNew config={CONTACT_US_FORM_CONFIG} apiError={apiError} />
            <Box justifyContent='stars' mt='12px'>
              <Button isLoading={isPending} width={160} type='submit' mobileStretch>
                Submit
              </Button>
            </Box>
          </FormikForm>
        </Box>
      </ContactUsWrapper>
    </ContentLayout>
  );
};
