import { ReactNode } from 'react';
import { AuthStepWrapper } from './styles';

type AuthStepType = {
  width?: string;
  center?: boolean;
  children: ReactNode;
};

export const AuthStep = ({ width, children, center }: AuthStepType) => {
  return (
    <AuthStepWrapper width={width} center={center}>
      {children}
    </AuthStepWrapper>
  );
};
