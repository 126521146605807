import { AuthLayout } from '@/bundle/Auth/ui/AuthLayout/AuthLayout';
import {
  BottomDots,
  PaymentSuccessWrapper,
  LeftDot,
  Printer,
  ReceiptWrapper,
  Receipt,
  RightDot,
  ReceiptBody,
} from './styles';
import { Typography } from '@/components/Typography/Typography';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { PaymentSuccessContent } from './ui/PaymentSuccessContent';
import { useLocation, useNavigate } from 'react-router-dom';
// import { useQuery } from '@tanstack/react-query';
// import { getPaymentTransaction } from './api';
import { useEffect } from 'react';
import { getSelectPaymentPlanUrl } from '../SelectPaymentPlanPage/urls/getSelectPaymentPlanUrl';
import { DASHBOARD_REDIRECT_MAP } from '@/bundle/Auth/LoginFlow/LoginPage/const/const';

const arrayDots = Array.from(Array(18).keys());
const LEFT_OFFSET = 20;
const ADDITIONS_OFFSET = 22;

export const PaymentSuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // const transactionId = location?.state?.transactionId;

  // TODO v13.0.0 use data from BE will be ready
  // const { data } = useQuery({
  //   queryKey: ['get_payment_transaction', transactionId],
  //   queryFn: () => getPaymentTransaction(transactionId),
  //   enabled: !!transactionId,
  // });

  const navigateToDashboard = () => {
    navigate(DASHBOARD_REDIRECT_MAP.organization_user);
  };

  useEffect(() => {
    if (!location?.state) {
      navigate(getSelectPaymentPlanUrl());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state]);

  return (
    <AuthLayout>
      <PaymentSuccessWrapper>
        <Typography variant='headline4' fontWeight='semibold'>
          Your payment was successful!
        </Typography>
        <Printer />
        <ReceiptWrapper>
          <Receipt>
            <ReceiptBody>
              <PaymentSuccessContent paymentDetails={location?.state} />
              <LeftDot />
              <RightDot />
              {arrayDots.map((dot, index) => {
                const currentOffset = index * LEFT_OFFSET + ADDITIONS_OFFSET;

                return <BottomDots key={dot} offset={currentOffset} />;
              })}
            </ReceiptBody>
          </Receipt>
        </ReceiptWrapper>
        <Box display='flex' alignItems='center' columnGap={20} mt='auto' width='100%'>
          <Button onClick={navigateToDashboard}>Go to Dashboard</Button>
        </Box>
      </PaymentSuccessWrapper>
    </AuthLayout>
  );
};
