// [Documentation]: https://credivault.atlassian.net/wiki/spaces/WireVault/pages/532086787/Amplitude+Metrics

import { ValueOf } from '@/types/sharedTypes';

const DEFAULT_EVENT_PREFIX = '[Amplitude]';

export const DEFAULT_EVENTS = {
  PageViewed: `${DEFAULT_EVENT_PREFIX} Page Viewed`,
  FormStarted: `${DEFAULT_EVENT_PREFIX} Form Started`,
  FormSubmitted: `${DEFAULT_EVENT_PREFIX} Form Submitted`,
  FileDownloaded: `${DEFAULT_EVENT_PREFIX} File Downloaded`,
  StartSession: `${DEFAULT_EVENT_PREFIX} Start Session`,
  EndSession: `${DEFAULT_EVENT_PREFIX} End Session`,
};

export const DEFAULT_EVENTS_LIST = Object.values(DEFAULT_EVENTS);

export const ADDITIONAL_PROPERTIES = {
  PageDomain: `${DEFAULT_EVENT_PREFIX} Page Domain`,
  PageLocation: `${DEFAULT_EVENT_PREFIX} Page Location`,
  PagePath: `${DEFAULT_EVENT_PREFIX} Page Path`,
  PageTitle: `${DEFAULT_EVENT_PREFIX} Page Title`,
  PageUrl: `${DEFAULT_EVENT_PREFIX} Page URL`,
};

export const AMPLITUDE_EVENTS = {
  CreateOrganizationRedirect: '[ORG]:[CREATE]: Start redirect',
  CreateOrganizationSuccess: '[ORG]:[CREATE]: Success',
  OrganizationUsersListRedirect: '[ORG USERS]:[CREATE ADMIN]: Redirect to User`s list',
  CreateOrganizationUserRedirect: '[ORG USERS]:[CREATE ADMIN]: Start redirect',
  SetPasswordSuccess: '[ONBOARDING]:[SET PWD]: success',
  SetPasswordRedirectSignIn: '[ONBOARDING]:[SET PWD]: redirect to sign in',
  LogINEmailPwdCheckSuccess: '[ONBOARDING]:[LOG IN]: Email and pwd check success',
  CompleteRegistration: '[ONBOARDING]:[LOG IN]:Complete registration ',
  LogIN: '[ONBOARDING]:[LOG IN]: 2FA check success = login success',
  SetInitialPasswordRedirect: '[ONBOARDING]:[SET PWD]: start redirect',
  CreateTransactionRedirect: '[TRANSACTION]:[CREATE]: Start redirect',
  CreateTransactionSuccess: '[TRANSACTION]:[CREATE]: Success',
  TransactionViewDetails: '[TRANSACTION]: View details',
  CreateWireRedirect: '[WIRE]:[CREATE]: Start redirect',
  CreateOutboundWireSuccess: '[OUTBOUND WIRE]:[CREATE]: Success',
  CreateInboundWireSuccess: '[INBOUND WIRE]:[CREATE]: Success',
  OutboundWireViewDetails: '[WIRE]: View details',
  AssignNewRecipient: '[OUTBOUND WIRE]:[ASSIGN RECIPIENT]: New recipient',
  AssignInvitedRecipient: '[OUTBOUND WIRE]:[ASSIGN RECIPIENT]: Invited recipient',
  AssignExistingRecipient: '[OUTBOUND WIRE]:[ASSIGN RECIPIENT]: Existing recipient',
  RecipientViewsDetails: '[OUTBOUND WIRE]: Recipient views details',
  DomesticPaymentRedirect: '[OUTBOUND WIRE]:[PROVIDE WIRE INFO]: Domestic without intermediary redirect',
  DomesticPaymentSuccess: '[OUTBOUND WIRE]:[PROVIDE WIRE INFO]: Domestic without intermediary success',
  DomesticPaymentIntermediaryRedirect: '[OUTBOUND WIRE]:[PROVIDE WIRE INFO]: Domestic with intermediary redirect',
  DomesticPaymentIntermediarySuccess: '[OUTBOUND WIRE]:[PROVIDE WIRE INFO]: Domestic with intermediary success',
  InternationalPaymentRedirect: '[OUTBOUND WIRE]:[PROVIDE WIRE INFO]: International without intermediary redirect',
  InternationalPaymentSuccess: '[OUTBOUND WIRE]:[PROVIDE WIRE INFO]: International without intermediary success',
  InternationalPaymentIntermediaryRedirect:
    '[OUTBOUND WIRE]:[PROVIDE WIRE INFO]: International with intermediary redirect',
  InternationalPaymentIntermediarySuccess:
    '[OUTBOUND WIRE]:[PROVIDE WIRE INFO]: International with intermediary success',
  ViewPaymentDetails: '[OUTBOUND WIRE]:[BILLING]: View payment details',
  ExecuteWireRedirect: '[OUTBOUND WIRE]:[EXECUTE]: Mark as executed redirect',
  ExecuteWireSuccess: '[OUTBOUND WIRE]:[EXECUTE]: Mark as executed success',
  AssignExecutorRedirect: '[OUTBOUND WIRE]:[ASSIGN EXECUTOR]: Start redirect',
  AssignNewExecutor: '[OUTBOUND WIRE]:[ASSIGN EXECUTOR]: New executor',
  AssignInvatedExecutor: '[OUTBOUND WIRE]:[ASSIGN EXECUTOR]: Invited executor',
  AssignExistingExecutor: '[OUTBOUND WIRE]:[ASSIGN EXECUTOR]: Existing executor',
  ExecutorViewsDetails: '[OUTBOUND WIRE]: Executor views details',
  ExecutorMarksAsExecutedRedirect: '[OUTBOUND WIRE]:[EXECUTE]: Executor marks as executed redirect',
  ExecutorMarksAsExecutedSuccess: '[OUTBOUND WIRE]:[EXECUTE]: Executor marks as executed success',
  EditOrganizationRedirect: '[ORG]:[EDIT]: Start redirect',
  EditOrganizationSuccess: '[ORG]:[EDIT]: Success',
  EditTransactionRedirect: '[TRANSACTION]:[EDIT]: Start redirect',
  EditTransactionSuccess: '[TRANSACTION]:[EDIT]: Success',
  ClickWireFilterAll: '[WIRE]:[FILTER]: All',
  ClickWireFilterInbound: '[WIRE]:[FILTER]: Inbound',
  ClickWireFilterOutbound: '[WIRE]:[FILTER]: Outbound',
  EditRecipientInfoRedirect: '[OUTBOUND WIRE]:[EDIT]: Recipient info edit redirect',
  EditRecipientInfoSuccess: '[OUTBOUND WIRE]:[EDIT]: Recipient info edit success',
  EditGeneralInfoOutboundWireRedirect: '[OUTBOUND WIRE]:[EDIT]: General info edit redirect',
  EditGeneralInfoOutboundWireSuccess: '[OUTBOUND WIRE]:[EDIT]: General info edit success',
  EditExecutorInfoRedirect: '[OUTBOUND WIRE]:[EDIT]: Executor info edit redirect',
  EditExecutorInfoSuccess: '[OUTBOUND WIRE]:[EDIT]: Executor info edit success',
  CancelOutboundWireStartRedirect: '[OUTBOUND WIRE]:[CANCEL]: Start redirect',
  CancelOutboundWireSuccess: '[OUTBOUND WIRE]:[CANCEL]: Success',
  CreateChildOutboundWireRedirect: '[OUTBOUND WIRE]:[BILLING]: Create child wire redirect',
  CreateChildOutboundWireSuccess: '[OUTBOUND WIRE]:[BILLING]: Create child wire success',
  CreateDepositAccountStartRedirect: '[DEPOSIT ACCOUNT]:[CREATE]: Start redirect',
  ViewDetailsDepositAccount: '[DEPOSIT ACCOUNT]: View details',
  DeactivateDepositAccountStartRedirect: '[DEPOSIT ACCOUNT]:[DEACTIVATE]: Start redirect',
  DeactivateDepositAccountSuccess: '[DEPOSIT ACCOUNT]:[DEACTIVATE]: Success',
  EditDepositAccountStartRedirect: '[DEPOSIT ACCOUNT]:[EDIT]: Start redirect',
  EditDepositAccountSuccess: '[DEPOSIT ACCOUNT]:[EDIT]: Success',
  CreateNewDepositAccountRedirect: '[INBOUND WIRE]:[PAYMENT DETAILS]: Redirect to Create New Account',
  ResetPaymentDetailsInboundWireRedirect: '[INBOUND WIRE]:[RESET PAYMENT DETAILS]: Reset redirect',
  ResetPaymentDetailsInboundWireSuccess: '[INBOUND WIRE]:[RESET PAYMENT DETAILS]: Reset success',
  ReferenceDepositAccountRedirect: '[INBOUND WIRE]:[PAYMENT DETAILS]: Reference deposit account',
  EditGeneralInfoInboundWireRedirect: '[INBOUND WIRE]:[EDIT]: General info edit redirect',
  EditGeneralInfoInboundWireSuccess: '[INBOUND WIRE]:[EDIT]: General info edit success',
  EditDepositorInfoRedirect: '[INBOUND WIRE]:[EDIT]: Depositor info edit redirect',
  CancelInboundWireStartRedirect: '[INBOUND WIRE]:[CANCEL]: Start redirect',
  CancelInboundWireSuccess: '[INBOUND WIRE]:[CANCEL]: Success',
  ViewWireDetailsDepositor: '[INBOUND WIRE]: Depositor views details',
  ExecutedWireDepositorRedirect: '[INBOUND WIRE]:[EXECUTE]: Depositor marks as executed redirect',
  ExecutedWireDepositorSuccess: '[INBOUND WIRE]:[EXECUTE]: Depositor marks as executed success',
  CreateDomesticDepositAccountWithoutIntermediaryRedirect:
    '[DEPOSIT ACCOUNT]:[CREATE]: Domestic without intermediary redirect',
  CreateDomesticDepositAccountWithIntermediaryRedirect:
    '[DEPOSIT ACCOUNT]:[CREATE]: Domestic with intermediary redirect',
  CreateInternationalDepositAccountWithoutIntermediaryRedirect:
    '[DEPOSIT ACCOUNT]:[CREATE]: International without intermediary redirect',
  CreateInternationalDepositAccountWithIntermediaryRedirect:
    '[DEPOSIT ACCOUNT]:[CREATE]: International with intermediary redirect',
  CreateDomesticAccountWithoutIntermediarySuccess: '[DEPOSIT ACCOUNT]:[CREATE]: Domestic without intermediary success',
  CreateDomesticAccountWithIntermediarySuccess: '[DEPOSIT ACCOUNT]:[CREATE]: Domestic with intermediary success',
  CreateInternationalAccountWithoutIntermediarySuccess:
    '[DEPOSIT ACCOUNT]:[CREATE]: International without intermediary success',
  CreateInternationalAccountWithIntermediarySuccess:
    '[DEPOSIT ACCOUNT]:[CREATE]: International with intermediary success',
  SelectPaymentDetailsInboundWireSuccess: '[INBOUND WIRE]:[PAYMENT DETAILS]: Select payment details success',
  CreateDepositorRedirect: '[INBOUND WIRE]:[ASSIGN DEPOSITOR]: New depositor redirect',
  InvitedDepositorRedirect: '[INBOUND WIRE]:[ASSIGN DEPOSITOR]: Invited depositor redirect',
  ExistingDepositorRedirect: '[INBOUND WIRE]:[ASSIGN DEPOSITOR]: Existing depositor redirect',
  AssignUnacceptedInvitationDepositorFailed:
    '[INBOUND WIRE]:[ASSIGN DEPOSITOR]: Unaccepted invitation from any org failed',
  AssignUnacceptedInvitationFromOtherOrganizationFailed:
    '[INBOUND WIRE]:[ASSIGN DEPOSITOR]: Unaccepted invitation from other org failed',
  AssignYourSelfFailed: '[INBOUND WIRE]:[ASSIGN DEPOSITOR]: Assign to yourself failed',
  CreateNewDepositorSuccess: '[INBOUND WIRE]:[ASSIGN DEPOSITOR]: New depositor success',
  InvitedDepositorSuccess: '[INBOUND WIRE]:[ASSIGN DEPOSITOR]: Invited depositor success',
  ExistingDepositorSuccess: '[INBOUND WIRE]:[ASSIGN DEPOSITOR]: Existing depositor success',
  SwitchRoleSelectDashboardDuringLoginSuccess: '[SWITCH ROLE]: Select dashboard during login success',
  SwitchRoleStartRedirect: '[SWITCH ROLE]: Start redirect',
  SwitchRoleSelectDashboardSuccess: '[SWITCH ROLE]: Select dashboard success',
  UpsellRecipientDashboard: '[UPSELL]: Recipient dashboard',
  UpsellExecutorDashboard: '[UPSELL]: Executor dashboard',
  UpsellDepositorDashboard: '[UPSELL]: Depositor dashboard',
  UpsellRecipientWireDetails: '[UPSELL]: Wire details',
  UpsellSwitchRoleDuringLogin: '[UPSELL]: Switch role during login',
  UpsellSwitchRoleAfterLogin: '[UPSELL]: Switch role after login',
  ForgotPasswordStartRedirect: '[FORGOT PASSWORD]: Start redirect',
  ForgotPasswordSendLinkToEmail: '[FORGOT PASSWORD]: Send link',
  ForgotPasswordCheckEmailLoginRedirect: '[FORGOT PASSWORD]: Return to login in check email',
  ForgotPasswordValidateTotp: '[FORGOT PASSWORD]: Click on link in email',
  ForgotPasswordLinkExpired: '[FORGOT PASSWORD]: Return to login in link expired',
  ForgotPasswordCompleteRegistration: '[FORGOT PASSWORD]: Complete registration',
  ForgotPassword2FACode: '[FORGOT PASSWORD]: Submit 2FA code',
  ForgotPasswordEnterPinCode: '[FORGOT PASSWORD]: Submit PIN Code',
  ForgotPasswordSetPasswordSuccess: '[FORGOT PASSWORD]: Set your password success',
  ForgotPasswordSetPasswordSuccessLoginRedirect: '[FORGOT PASSWORD]: Return to login when password set',
  EditDepositorInfoSuccess: '[INBOUND WIRE]:[EDIT]: Depositor info edit success',
  AssignRecipientFromOtherOrgFailed: '[OUTBOUND WIRE]:[ASSIGN RECIPIENT]: Unaccepted invitation from other org failed',
  AssignRecipientFromAnyOrgFailed: '[OUTBOUND WIRE]:[ASSIGN RECIPIENT]: Unaccepted invitation from any org failed',
  AssignRecipientYourSelfFailed: '[OUTBOUND WIRE]:[ASSIGN RECIPIENT]: Assign to yourself failed',
  CreateOrgAdminNewRedirect: '[ORG USERS]:[CREATE ADMIN]: New user redirect',
  CreateOrgAdminExistingRedirect: '[ORG USERS]:[CREATE ADMIN]: Existing user redirect',
  CreateOrgAdminFailed: '[ORG USERS]:[CREATE ADMIN]: Unaccepted invitation failed',
  CreateOrgAdminNewSuccess: '[ORG USERS]:[CREATE ADMIN]: New user success',
  CreateOrgAdminExistingSuccess: '[ORG USERS]:[CREATE ADMIN]: Existing user success',
  AssignExecutorYourSelfFailed: '[OUTBOUND WIRE]:[ASSIGN EXECUTOR]: Assign to yourself failed',
  ExecutorFromOtherOrgFailed: '[OUTBOUND WIRE]:[ASSIGN EXECUTOR]: Unaccepted invitation from other org failed',
  ExecutorFromAnyOrgFailed: '[OUTBOUND WIRE]:[ASSIGN EXECUTOR]: Unaccepted invitation from any org failed',
  PaymentDetailsSelectRedirect: '[INBOUND WIRE]:[PAYMENT DETAILS]: Select payment details redirect',
  ProfileStartRedirect: '[PROFILE]: Start redirect',
  ProfileEditPersonalInfoRedirect: '[PROFILE]:[EDIT]: Edit personal info redirect',
  ProfileEditPersonalInfoSuccess: '[PROFILE]:[EDIT]: Edit personal info success',
  ProfileEditPersonalInfoConfirm: '[PROFILE]:[EDIT]: Edit personal info confirm',
  OutboundWirePaymentProviderInviteRecipient: '[OUTBOUND WIRE]:[REASSIGN RECIPIENT]: Start redirect',
  OutboundWirePaymentProviderProvideDetails: '[OUTBOUND WIRE]:[PROVIDE WIRE INFO]: By Proxy start redirect',
  OutboundWireProxyEditRecipientRedirect: '[OUTBOUND WIRE]:[EDIT]: Reassigned recipient info edit redirect',
  OutboundWireProxyEditRecipientSuccess: '[OUTBOUND WIRE]:[EDIT]: Reassigned recipient info edit success',
  ProxyAssignNewRecipient: '[OUTBOUND WIRE]:[REASSIGN RECIPIENT]: New recipient',
  ProxyAssignInvitedRecipient: '[OUTBOUND WIRE]:[REASSIGN RECIPIENT]: Invited recipient',
  ProxyAssignExistingRecipient: '[OUTBOUND WIRE]:[REASSIGN RECIPIENT]: Existing recipient',
  ProxyAssignNotAnotherOrganizationRegisteredRecipient:
    '[OUTBOUND WIRE]:[REASSIGN RECIPIENT]: Unaccepted invitation from other org failed',
  ProxyAssignNotRegisteredRecipient: '[OUTBOUND WIRE]:[REASSIGN RECIPIENT]: Unaccepted invitation from any org failed',
  ProxyAssignYourself: '[OUTBOUND WIRE]:[REASSIGN RECIPIENT]: Assign to yourself failed',
  ProxyAssignOrgAdminThatOriginatesWire: '[OUTBOUND WIRE]:[REASSIGN RECIPIENT]: Assign to user associated with org',
  DocumentVerificationStartSuccess: '[OUTBOUND WIRE]:[PROVIDE WIRE INFO]: ID Verification start',
  OutboundWireSourceWireConfirmRedirect: '[OUTBOUND WIRE]:[CONFIRM WIRE INFO]: Start redirect',
  OutboundWireSourceWireConfirmSuccess: '[OUTBOUND WIRE]:[CONFIRM WIRE INFO]: Success',
  OutboundWireSourceWireProvidePaymentDetailsRedirect:
    '[OUTBOUND WIRE]:[PROVIDE WIRE INFO]: Start redirect from confirm',
} as const;

export type AmplitudeEventsType = ValueOf<typeof AMPLITUDE_EVENTS>;
