import styled from 'styled-components';
import { COLORS } from '../../styles/colors';
import { body, headline4, poppinsMediumFont, poppinsRegularFont } from '../../styles/typography';
import { flex } from '../../styles/layout';
import { media } from '@/styles/responsive';

export const ConfirmModalWrapper = styled.div`
  width: 100%;
  max-width: 420px;
  text-align: center;

  ${media.mobile`
    max-width: none;
  `}
`;

export const ConfirmHeader = styled.h3`
  ${headline4};
  ${poppinsRegularFont};
  margin: 0 auto 16px;
`;

export const ConfirmBody = styled.div`
  margin-bottom: 32px;
  color: ${COLORS.grey[600]};
  ${body};
  ${poppinsRegularFont};

  ${media.mobile`
    margin-bottom: 28px;
  `}
`;

export const ConfirmMessage = styled.div`
  margin-bottom: 32px;
  color: ${COLORS.black};
  ${poppinsMediumFont};
  ${body};

  ${media.mobile`
    margin-bottom: 28px;
  `}
`;

export const ConfirmModalButtons = styled.div`
  width: 100%;
  gap: 20px;
  ${flex({ alignItems: 'center', justifyContent: 'space-between' })};

  ${media.mobile`
    ${flex({ alignItems: 'center', justifyContent: 'center', flexDirection: 'column-reverse' })};
  `}
`;

export const Hint = styled.div`
  margin-bottom: 24px;
  text-align: left;

  ${media.mobile`
    margin-bottom: 16px;
  `}

  &:last-child {
    margin-bottom: 0;
  }
`;
