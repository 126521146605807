import { AuthStep } from '@/bundle/Auth/ui/AuthStep/AuthStep';
import { ReactComponent as AlertIcon } from './images/AlertIcon.svg';
import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { Button } from '@/components/Button/Button';
import { useNavigate } from 'react-router-dom';

import { getSelectPaymentPlanUrl } from '@/bundle/pages/SelectPaymentPlanPage/urls/getSelectPaymentPlanUrl';

export const InactiveSubscription = () => {
  const navigate = useNavigate();

  const navigateToPaymentCheckout = () => {
    navigate(getSelectPaymentPlanUrl());
  };

  return (
    <Box height='100%' display='flex' alignItems='center'>
      <AuthStep width='504px'>
        <Box display='flex' alignItems='center' flexDirection='column'>
          <AlertIcon />
          <Typography mt='28px' variant='headline4' fontWeight='semibold'>
            Subscription is Inactive
          </Typography>
          <Typography mt='12px' mb='28px' display='flex' textAlign='center'>
            In order to activate the subscription please reach out to the Site Admin of your organization.
          </Typography>

          <Button onClick={navigateToPaymentCheckout}>Go To Plans</Button>
        </Box>
      </AuthStep>
    </Box>
  );
};
