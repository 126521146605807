import { COLORS } from '@/styles/colors';

export const CHECKOUT_FORM_STYLES = {
  '#tokenizer-form': {
    display: 'flex',
    'flex-direction': 'column',
    'row-gap': '10px',
    padding: '24px 30px',
    'border-radius': '16px',
    background: `${COLORS.grey[150]}`,
  },
  label: {
    'font-size': '14px',
    'line-height': '20px',
    color: COLORS.black,
    'font-weight': '400',
    padding: '0',
  },
  input: {
    display: 'block',
    width: '100%',
    color: COLORS.black,
    border: `1px solid ${COLORS.grey[400]}`,
    outline: 'none',
    'font-size': '16px',
    padding: '13px 16px',
    'border-radius': '10px',
    height: '50px',
  },
  'input.invalid': {
    border: `1px solid ${COLORS.red[600]}`,
    color: `${COLORS.black}`,
  },
  'input:hover': {
    border: `1px solid ${COLORS.grey[700]}`,
  },
  'input:focus': {
    border: `1px solid ${COLORS.green[500]}`,
    'box-shadow': '0 0 2px rgb(0 134 111 / 60%)',
  },
  select: {
    display: 'block',
    width: '100%',
    color: COLORS.black,
    border: `1px solid ${COLORS.grey[400]}`,
    outline: 'none',
    'font-size': '16px',
    padding: '13px 16px',
    'border-radius': '10px',
    height: '50px',
  },
  '.card .cc input': {
    'padding-left': '50px',
  },
  '.card .cc .cc-icon': {
    left: '16px',
    'padding-bottom': 0,
  },
  // !important used to override iframe styles selectors
  '.payment .cc': {
    'padding-bottom': '0px !important',
    'margin-bottom': '12px',
  },
  '.payment .exp': {
    'padding-right': '6px !important',
  },
  '.payment .cvv': {
    'padding-left': '6px !important',
  },
};

export const PAYMENT_CARD_VALIDATION_MAP = {
  cc: 'Credit card is required.',
  exp: 'Expiration date is required.',
  cvv: 'CVV is required.',
};

export const CHECKOUT_FORM_SETTINGS = {
  payment: {
    calculateFees: false,
    showTitle: false,
    placeholderCreditCard: '0000 0000 0000 0000',
    showExpDate: true,
    showCVV: true,
    ach: {
      sec_code: 'web', // Default web - web, ccd, ppd, tel
      secCodeList: ['web', 'ccd', 'ppd'], // Default web, ccd, ppd
      showSecCode: false, // Default false - true to show sec code dropdown
    },
    card: {
      strict_mode: true, // Set to true to allow for 19 digit cards
      requireCVV: true, // Default false - true to require cvv
    },
  },
  styles: CHECKOUT_FORM_STYLES,
};
