import { authApiService } from '../../authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

export type ResponseType = {
  id: string;
  date_created: string;
  sender_email: string;
  mobile_phone: string;
  subject: string;
  message: string;
  user_id: string;
};

export type ContactUsPayloadType = {
  sender_email: string;
  mobile_phone: string;
  subject: string;
  message: string;
};

export const contactUsApi = (data: ContactUsPayloadType): Promise<ApiResponseType<ResponseType>> => {
  const uri = '/users/contact-us/';

  return authApiService.POST(uri, data);
};
