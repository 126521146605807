import { localStorageService } from '@/helpers/storageHelpers';

const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';

export type AuthTokensType = {
  access?: string;
  auth_token?: string;
  refresh?: string;
  user_id?: string;
};

export const authTokenService = {
  setTokens(tokens) {
    localStorageService.set(ACCESS_TOKEN_KEY, tokens);
  },

  getTokens(): AuthTokensType {
    return localStorageService.get(ACCESS_TOKEN_KEY);
  },

  removeTokens() {
    localStorageService.remove(ACCESS_TOKEN_KEY);
  },
};
