import * as Yup from 'yup';
import { PASSWORD_CONFIRMATION_VALIDATOR, PASSWORD_VALIDATOR } from '@/helpers/formHelpers/validators';

type PasswordValidationSchemaType = {
  password: string;
  passwordConfirmation: string;
};

export const passwordValidationSchema: Yup.AnySchema<PasswordValidationSchemaType> = Yup.object().shape({
  password: PASSWORD_VALIDATOR,
  passwordConfirmation: PASSWORD_CONFIRMATION_VALIDATOR,
});
