import { getAuthTokenHeaders } from '@/helpers/apiHelpers/queryHelper';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { getOpcoInternalUserApi } from '@/api/v1/opco/internal/getOpcoInternalUserApi';
import { blockOpcoInternalUserApi } from '@/api/v1/opco/internal/blockOpcoInternalUserApi';
import { resetOpcoInternalUserApi } from '@/api/v1/opco/internal/resetOpcoInternalUserApi';

export const loadOpcoUser = async (userId: string) => {
  try {
    const response = await getOpcoInternalUserApi(userId);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const blockOpcoUser = async (userId: string, pinToken: string) => {
  const options = getAuthTokenHeaders(pinToken);

  try {
    const response = await blockOpcoInternalUserApi(userId, options);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const resetOpcoUser = async (userId: string, pinToken: string) => {
  const options = getAuthTokenHeaders(pinToken);

  try {
    const response = await resetOpcoInternalUserApi(userId, options);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
