import { COLORS } from '@/styles/colors';
import styled from 'styled-components';

export const CardDot = styled.span`
  display: inline-block;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: ${COLORS.grey[950]};
  margin-right: 4px;

  &:last-child {
    margin-right: 6px;
  }
`;
