import { SearchUserType } from '@/api/v1/organization/users/getSearchUsersApi';
import { DATE_TIME_FORMAT, FULL_DATE_TIME_FORMAT, DEFAULT_DATE_FORMAT } from './dateHelpers/const';
import { trimString } from './stringHelpers';
import { format, parseISO } from 'date-fns';
import { isNullOrUndefined } from './objectHelpers';

const LOCALE_DB = {
  en: 'en-US',
};

const MINIMUM_FRACTION_DIGIT = {
  en: 2,
};

type DateFormatType = typeof DATE_TIME_FORMAT | typeof FULL_DATE_TIME_FORMAT;

export const formatBlockchainId = (blockchainId: string): string => {
  if (!blockchainId) return '-';

  return trimString(`0x${blockchainId}`);
};

export const formatAmount = (amount, locale = 'en', currency = 'USD') => {
  if (!amount) return '-';

  const currentLocale = LOCALE_DB[locale];
  const currentMinimumFractionDigits = MINIMUM_FRACTION_DIGIT[locale];

  const formattedAmount = new Intl.NumberFormat(currentLocale, {
    minimumFractionDigits: currentMinimumFractionDigits,
  }).format(amount);

  return `${formattedAmount} ${currency}`;
};

export const dateFormatter = (date: string | Date, dateFormat = DEFAULT_DATE_FORMAT) => {
  if (!date) return '-';

  const value = date instanceof Date ? date : parseISO(date);

  return format(value, dateFormat);
};

export const dateTimeUTCFormatter = (date: string | Date, dateFormat: DateFormatType = DATE_TIME_FORMAT) => {
  if (!date) return '-';

  const value = date instanceof Date ? date : Date.parse(date?.replace('Z', ''));
  const result = format(value, dateFormat);

  return result;
};

export const currencyFormatter = (currency: any) =>
  currency?.map((item) => {
    return {
      value: item?.code,
      label: item?.code,
    };
  });

export const phoneFormatter = (phone: string = '') => {
  if (!phone) return '-';

  return `+${phone}`;
};

export const getAutocompleteOptions = (users: SearchUserType[] = []) => {
  return users.map((item) => {
    return { value: item.id, label: item.email };
  });
};

export const convertCentsToDollars = (cents: number) => {
  if (isNullOrUndefined(cents)) return;

  return (cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};
