import { COLORS } from '@/styles/colors';
import { ReactComponent as Logo } from './images/MobileLogo.svg';
import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';
import { THEME } from '@/styles/theme';
import styled from 'styled-components';

export const MobileLogo = styled(Logo)`
  cursor: pointer;
`;

export const MobileHeaderWrapper = styled.header`
  ${flex({ alignItems: 'center', justifyContent: 'space-between' })};
  box-shadow: 1px 0 24px rgb(25 44 61 / 10%);
  padding: 16px 32px;
  height: ${THEME.header.height};
  z-index: ${THEME.header.zIndex};
  background: ${COLORS.black};
  border-bottom: 1px solid ${COLORS.grey[850]};
  display: none;

  ${media.tablet`
    ${flex()};
  `}

  ${media.mobile`
    padding: ${THEME.offset.mobile};
  `}
`;
