import * as Yup from 'yup';
import { REQUIRED_EMAIL_VALIDATOR } from '../../../../../helpers/formHelpers/validators';

type CheckEmailValidationSchemaType = {
  email: string;
};

export const checkEmailValidationSchema: Yup.AnySchema<CheckEmailValidationSchemaType> = Yup.object().shape({
  email: REQUIRED_EMAIL_VALIDATOR,
});
