import { COLORS } from '@/styles/colors';
import { poppinsSemiBoldFont } from '@/styles/typography';
import styled from 'styled-components';
import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';

export const UserDetailsHeaderWrapper = styled.div`
  margin-bottom: 24px;
`;

export const LabelWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  border-radius: 10px;
  padding: 6px 14px 6px 8px;
  color: ${COLORS.white};
  gap: 4px;
  background-color: ${COLORS.grey[900]};
  ${poppinsSemiBoldFont};

  ${media.tablet`
    margin: 16px auto 16px 0;
  `}
`;

export const Wrapper = styled.div`
  ${flex()};

  ${media.tablet`
    ${flex({ flexDirection: 'column' })}
  `}
`;

export const WrapperHeader = styled.div`
  ${flex({ alignItems: 'center', flexDirection: 'row' })};
  column-gap: 16px;
`;
