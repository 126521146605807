import { useState } from 'react';
import { AuthStepWrapper } from '@/bundle/Auth/ui/AuthStep/styles';
import { SubTitle, Title } from '@/bundle/pages/SwitchRolePage/ui/styles';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { SwitchRoleItem } from '@/bundle/pages/SwitchRolePage/ui/SwitchRoleItem';
import { SortedActiveRoleType, SWITCH_ROLE_ITEM_MAP } from '@/bundle/pages/SwitchRolePage/const';
import { ActiveUserRolesType, UserRoleType } from '@/const/user';

interface SwitchRoleType {
  activeRoles: ActiveUserRolesType;
  backButtonText?: string;
  onSelect: (role: UserRoleType) => void;
  onBack: () => void;
}

export const getSortedActiveRoles = (activeRoles: string[]): SortedActiveRoleType[] => {
  const mappedRole = activeRoles.map((item) => SWITCH_ROLE_ITEM_MAP[item]);

  return mappedRole.sort((a, b) => {
    if (a.title < b.title) {
      return -1;
    }

    if (a.title > b.title) {
      return 1;
    }

    return 0;
  });
};

export const SwitchRole = ({ activeRoles = [], backButtonText, onSelect, onBack }: SwitchRoleType) => {
  const [selectedRole, setSelectedRole] = useState<UserRoleType>(null);

  const selectRole = (role: UserRoleType) => {
    setSelectedRole(role);
  };

  const sortedUserRoles = getSortedActiveRoles(activeRoles);

  return (
    <AuthStepWrapper>
      <Box flexDirection='column'>
        <Box flexDirection='column' rowGap='8px' mb='20px'>
          <Title>Choose Role</Title>
          <SubTitle>Please select a dashboard to view.</SubTitle>
        </Box>
        <Box flexDirection='column' rowGap='12px' mb='24px'>
          {sortedUserRoles?.map((activeRole) => {
            if (!activeRole) return null;

            const isSelected = selectedRole === activeRole.value;

            return (
              <SwitchRoleItem key={activeRole.title} role={activeRole} onChange={selectRole} isSelected={isSelected} />
            );
          })}
        </Box>
        <Box flexDirection='column' rowGap='12px'>
          <Button disabled={!selectedRole} onClick={() => onSelect(selectedRole)}>
            Select Dashboard
          </Button>
          <Button variant='secondary' onClick={onBack}>
            {backButtonText || 'Back'}
          </Button>
        </Box>
      </Box>
    </AuthStepWrapper>
  );
};
