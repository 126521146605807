import { QueryParamsStringType } from '@/api/helpers/queryHelpers';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';

export const useReplaceSearchParams = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const replaceSearchParams = (newSearchParams: QueryParamsStringType) => {
    if (!newSearchParams) return;

    try {
      const currentSearchParams = queryString.parse(searchParams.toString());
      const params = queryString.stringify(
        { ...currentSearchParams, ...newSearchParams },
        { skipEmptyString: true, skipNull: true }
      );

      setSearchParams(params);
    } catch (error) {
      console.error('Stringify query params failed with', error?.message);
    }
  };

  return {
    replaceSearchParams,
  };
};
