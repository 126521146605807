import { ORG_ADMIN_ROLE } from '@/const/user';
import {
  REQUIRED_EMAIL_VALIDATOR,
  REQUIRED_PHONE_NUMBER_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';
import * as Yup from 'yup';
import { OrganizationCreateUserFormValuesType } from '../../api';

export const USER_ROLE_OPTIONS = [
  { value: ORG_ADMIN_ROLE.ORG_ADMIN, label: 'Org Admin' },
  { value: ORG_ADMIN_ROLE.SITE_ADMIN, label: 'Site Admin' },
];

export const getCreateOrganizationUserConfig = (
  isDisabledChangeOrgAdminRole: boolean
): OrganizationCreateUserFormValuesType => {
  return {
    email: '',
    mobile_phone: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    role: isDisabledChangeOrgAdminRole ? ORG_ADMIN_ROLE.SITE_ADMIN : ORG_ADMIN_ROLE.ORG_ADMIN,
  };
};

export const CREATE_ORGANIZATION_USER_VALIDATION_SCHEMA = Yup.object().shape({
  email: REQUIRED_EMAIL_VALIDATOR,
  mobile_phone: REQUIRED_PHONE_NUMBER_VALIDATOR,
  first_name: REQUIRED_TEXT_FIELD_VALIDATOR,
  middle_name: TEXT_FIELD_VALIDATOR,
  last_name: REQUIRED_TEXT_FIELD_VALIDATOR,
});
