import { COLORS } from '@/styles/colors';
import { dashedLine } from '@/styles/common';
import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';
import { bodySmall, poppinsSemiBoldFont } from '@/styles/typography';
import styled from 'styled-components';

export const PricingCardWrapper = styled.div`
  padding: 20px;
  border-radius: 16px;
  border: 1px solid ${COLORS.grey[300]};
  width: 384px;
  background-color: ${COLORS.white};

  ${media.mobile`
    width: 100%
  `}
`;

export const TotalBlock = styled.div`
  ${flex({ flexDirection: 'column' })}
  background-color: ${COLORS.grey[200]};
  padding: 12px;
  border-radius: 10px;
`;

export const SelectedPriceCrossedOut = styled.span`
  ${poppinsSemiBoldFont};
  text-decoration: line-through;
  color: ${COLORS.grey[650]};
  ${bodySmall};
`;

export const EmptyBlock = styled.div`
  height: 32px;
`;

export const Divider = styled.div`
  ${dashedLine};
`;
