import { ReactComponent as DeleteIcon } from './images/DeleteIcon.svg';
import { ActionButton, KeyBoardWrapper, PinCodeButton } from './styles';

interface PinCodeKeyboardType {
  pinCodeKeyboard: number[];
  onAdd: (pinValue: number) => void;
  onClear: () => void;
  onDelete: () => void;
}

export const PinCodeKeyboard = ({ onAdd, onClear, onDelete, pinCodeKeyboard = [] }: PinCodeKeyboardType) => {
  const keyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <KeyBoardWrapper>
      {pinCodeKeyboard.map((value, index) => {
        const lastItem = pinCodeKeyboard.length - 1 === index;
        const order = lastItem ? 1 : null;

        return (
          <PinCodeButton key={index} onClick={() => onAdd(value)} order={order} onKeyDown={keyDown}>
            {value}
          </PinCodeButton>
        );
      })}
      <ActionButton onClick={onClear}>Clear</ActionButton>
      <ActionButton onClick={onDelete} order={2}>
        <DeleteIcon />
      </ActionButton>
    </KeyBoardWrapper>
  );
};
