import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '@/context/userContext';
import { getOpcoUserDetailsUrl } from '@/bundle/_Opco/_OpcoUsersPage/Details/url/getOpcoUserDetailsUrl';
import { USER_ROLE_MAP, UserRoleType } from '@/const/user';

export const PROFILE_ROLES: UserRoleType[] = [
  USER_ROLE_MAP.ORGANIZATION,
  USER_ROLE_MAP.EXECUTOR,
  USER_ROLE_MAP.DEPOSITOR,
  USER_ROLE_MAP.RECIPIENT,
];

export const ProfileProtectedRoute = () => {
  const { currentRole, user } = useUser();
  const opcoDetailsPage = getOpcoUserDetailsUrl(user?.id);
  const hasProfilePage = PROFILE_ROLES.includes(currentRole);

  if (hasProfilePage) {
    return <Outlet />;
  } else {
    return <Navigate to={opcoDetailsPage} />;
  }
};
