import { ApiResponseType } from '@/types/sharedTypes';
import { authApiService } from '../../authApiService';
import { AssignedWireType, WireStatusType } from '@/types/wireTypes';

export type AssignedDepositor = {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  mobile_phone: string;
  type: string;
  email: string;
  language: string;
  date_verified: string;
};

export type DepositorWireType = {
  id: string;
  date_created: string;
  date_modified: string;
  name: string;
  amount: string;
  currency: string;
  status: WireStatusType;
  ref_id: string;
  expected_payment_date: string;
  contract: {
    id: string;
    name: string;
  };
  created_by: any;
  type: string;
  assigned_depositor: {
    id: string;
    user: AssignedDepositor;
  };
};

export const getDepositorWireApi = (id: string): Promise<ApiResponseType<AssignedWireType>> => {
  const uri = `/depositor/wires/${id}/`;

  return authApiService.GET(uri);
};
