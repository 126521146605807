import { getExecutorWireApi } from '@/api/v1/executor/getExecutorWireApi';
import { WIRE_STATUS_DB } from '@/const/wire';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { executorChangeWireStatusApi } from '@/api/v1/executor/executorChangeWireStatusApi';
import { getExecutorPaymentDetailsApi } from '@/api/v1/executor/getExecutorPaymentDetailsApi';

export const loadExecutorWire = async (id: string) => {
  try {
    const response = await getExecutorWireApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadExecutorPaymentDetails = async (id: string) => {
  try {
    const response = await getExecutorPaymentDetailsApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const changeExecutorWireStatus = async (id: string, status: typeof WIRE_STATUS_DB.EXECUTED) => {
  const payload = { status };

  try {
    const response = await executorChangeWireStatusApi(id, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
