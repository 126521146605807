import styled from 'styled-components';
import { headline4, poppinsSemiBoldFont } from '../../../../styles/typography';
import { COLORS } from '../../../../styles/colors';

export const AuthTitleWrapper = styled.div<{ marginBottom?: number }>`
  ${headline4};
  ${poppinsSemiBoldFont};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '16px')};
  color: ${COLORS.grey[900]};
`;
