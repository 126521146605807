import { authApiService } from '@/api/authApiService';
import { ApiResponseType, ListResponseType } from '@/types/sharedTypes';
import { ApiParams } from '@/api/helpers/urlApiHelpers';
import { OrganizationListItemType } from '@/bundle/_Opco/types/types';

export const getOrganizationsApi = (
  params: ApiParams
): Promise<ApiResponseType<ListResponseType<OrganizationListItemType>>> => {
  const uri = '/opco/organizations/';

  return authApiService.GET(uri, params);
};
