import styled from 'styled-components';
import { bodySmall, poppinsMediumFont } from '@/styles/typography';
import { COLORS } from '@/styles/colors';

export const SwitchRoleButtonWrapper = styled.button`
  width: 100%;
  padding: 10px 8px;
  border-radius: 8px;
  background: rgb(0 178 145 / 8%);
  color: ${COLORS.mint[300]};
  ${poppinsMediumFont};
  ${bodySmall};
  border: none;
  cursor: pointer;

  &:hover {
    background: rgb(0 178 145 / 18%);
  }
`;
