import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

export type UserInfoType = {
  id: string;
  first_name?: string;
  last_name?: string;
  middle_name?: string;
  mobile_phone: string;
  type: 'op_co' | 'business';
  email: string;
  language: 'en';
  date_verified: string;
};

export const getUserInfoApi = async (userId: string): Promise<ApiResponseType<UserInfoType>> => {
  const uri = `/organization/users/${userId}/`;

  return authApiService.GET(uri);
};
