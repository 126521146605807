import { Box } from '@/components/Box/Box';
import { ReactComponent as CheckedIcon } from '../images/CheckedIcon.svg';
import { ReactComponent as UnCheckedIcon } from '../images/UncheckedIcon.svg';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { ReactNode } from 'react';

type PaymentPlanCardRowType = {
  isActive?: boolean;
  children: ReactNode;
};

export const PaymentPlanCardRow = ({ children, isActive = true }: PaymentPlanCardRowType) => {
  return (
    <Box display='flex' alignItems='center' columnGap={8}>
      {isActive ? <CheckedIcon /> : <UnCheckedIcon />}
      <Typography as='span' variant='bodySmall' color={COLORS.black}>
        {children}
      </Typography>
    </Box>
  );
};
