import { authApiService } from '../../authApiService';
import { ApiResponseType, ForgotPasswordResponseType } from '@/types/sharedTypes';

export type ForgotPasswordValidateTotpPayload = {
  user_id: string;
  token: string;
};

export const forgotPasswordValidateTotpApi = async (
  data: ForgotPasswordValidateTotpPayload
): Promise<ApiResponseType<ForgotPasswordResponseType>> => {
  const uri = '/users/forgot-password/totp/';

  return authApiService.POST(uri, data);
};
