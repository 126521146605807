import { SortIconEmpty, TableHeaderCellIcon } from './styles';
import { ReactComponent as SortDescIcon } from '../../../images/upArrow.svg';
import { ReactComponent as SortAscIcon } from '../../../images/downArrow.svg';

type TableSortingType = {
  isSort: boolean;
  isSortAsc: boolean;
  isSortDesc: boolean;
};

export const TableSorting = ({ isSort, isSortAsc, isSortDesc }: TableSortingType) => {
  return (
    <TableHeaderCellIcon>
      {isSort && (
        <>
          <SortDescIcon />
          <SortAscIcon />
        </>
      )}
      {isSortAsc && (
        <>
          <SortIconEmpty />
          <SortAscIcon />
        </>
      )}
      {isSortDesc && (
        <>
          <SortDescIcon />
          <SortIconEmpty />
        </>
      )}
    </TableHeaderCellIcon>
  );
};
