import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

type SendPaymentDetailsRegisterViewEventResponseType = {
  ok: boolean;
};

export const sendPaymentDetailsRegisterViewEventApi = (
  wireId: string
): Promise<ApiResponseType<SendPaymentDetailsRegisterViewEventResponseType>> => {
  const uri = `/organization/wires/${wireId}/payment-details/register-view-event/`;

  return authApiService.POST(uri, wireId);
};
