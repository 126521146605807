import { DepositAccountType } from '@/bundle/_OrgAdmin/pages/InboundWire/types/depositAccount';
import { authApiService } from '../../../authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

export type DepositAccountUpdateDataType = {
  name: string;
};

export const editDepositAccountApi = (
  depositAccountId: string,
  data: DepositAccountUpdateDataType
): Promise<ApiResponseType<DepositAccountType>> => {
  const uri = `/organization/deposit-accounts/${depositAccountId}/`;

  return authApiService.PATCH(uri, data);
};
