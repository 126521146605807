import { useReplaceSearchParams } from '@/hooks/useReplaceSearchParams';
import { useCallback } from 'react';

export const useTableSorting = () => {
  const { replaceSearchParams } = useReplaceSearchParams();

  const tableSorting = useCallback(
    (column, isSort, isSortAsc, isSortDesc) => {
      let sortParam;

      if (isSort) {
        sortParam = column;
      }

      if (isSortAsc) {
        sortParam = `-${column}`;
      }

      if (isSortDesc) {
        sortParam = null;
      }

      const newSearchParams = { ordering: sortParam };

      return replaceSearchParams(newSearchParams);
    },
    [replaceSearchParams]
  );

  return {
    tableSorting,
  };
};
