import { useFormikForm } from '../../../../components/form/useFormikForm/useFormikForm';
import { CREATE_PAYMENT_DETAILS_INTERNATIONAL_FORM_CONFIG } from '../const/paymentDetailstInternationalFormConfig';
import { CREATE_PAYMENT_DETAILS_DOMESTIC_FORM_CONFIG } from '../const/paymentDetailsDomesticFormConfig';

export const useChangeInitialValues = (isDomestic: boolean) => {
  const { getInitialsValues } = useFormikForm();

  const { initialDomesticPaymentFormIntermediaryFormConfig } = CREATE_PAYMENT_DETAILS_DOMESTIC_FORM_CONFIG;
  const { initialInternationalPaymentFormIntermediaryFormConfig } = CREATE_PAYMENT_DETAILS_INTERNATIONAL_FORM_CONFIG;

  const currentConfig = isDomestic
    ? initialDomesticPaymentFormIntermediaryFormConfig
    : initialInternationalPaymentFormIntermediaryFormConfig;

  const initialValues = getInitialsValues({}, currentConfig);

  return {
    initialValues,
  };
};
