import styled from 'styled-components';
import { PageFooterAlignType } from './PageFooter';
import { flex } from '@/styles/layout';

export const PageFooterWrapper = styled.div<{ align: PageFooterAlignType }>`
  width: 100%;
  ${({ align }) => flex({ justifyContent: align, alignItems: 'center' })};
  border-radius: 0 0 8px 8px;
  padding-top: 36px;
  column-gap: 20px;
  margin-top: auto;
`;
