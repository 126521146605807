export const THEME = {
  offset: {
    mobile: '16px',
  },
  header: {
    height: '73px',
    zIndex: 99,
  },
  sidebar: {
    width: '280px',
    zIndex: 101,
  },
  modal: {
    zIndex: 100,
  },
  drawer: {
    zIndex: 99,
    overlayZIndex: 2,
  },
  upsellBanner: {
    zIndex: 98,
  },
  autocomplete: {
    zIndex: 98,
  },
  select: {
    zIndex: 101,
  },
  layout: {
    scrollWidth: '7px',
  },
  utils: {
    getRawValue(value: string) {
      return parseInt(value);
    },
  },
};
