import { COLORS } from '@/styles/colors';
import { resetButton } from '@/styles/common';
import { flexCenter } from '@/styles/layout';
import { media } from '@/styles/responsive';
import { body, headline4, poppinsMediumFont } from '@/styles/typography';
import styled from 'styled-components';

export const KeyBoardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 12px;

  ${media.mobile`
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
  `}
`;

export const PinCodeButton = styled.button<{ order?: number }>`
  ${resetButton};
  ${flexCenter};
  ${poppinsMediumFont};
  ${headline4};
  width: 104px;
  height: 60px;
  background-color: ${COLORS.grey[200]};
  color: ${COLORS.grey[950]};
  border-radius: 6px;
  cursor: pointer;
  transition:
    background-color 0.2s ease,
    transform 0.2 ease;
  order: ${({ order }) => order};
  margin: 0 auto;

  &:hover {
    background-color: ${COLORS.grey[300]};
  }

  &:active {
    transform: scale(0.97);
    box-shadow:
      -1px 4px 10px 0 rgb(21 20 22 / 10%) inset,
      -4px -4px 4px 0 rgb(255 255 255 / 25%) inset;
  }
`;

export const ActionButton = styled.button<{ order?: number }>`
  ${resetButton};
  ${flexCenter};
  ${poppinsMediumFont};
  ${body};
  cursor: pointer;
  transition:
    color 0.2s ease,
    stroke 0.2s ease;
  order: ${({ order }) => order};
  color: ${COLORS.grey[950]};

  &:hover {
    color: ${COLORS.green[500]};

    path {
      stroke: ${COLORS.green[500]};
    }
  }
`;
