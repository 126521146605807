import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { body, bodySmall, poppinsMediumFont, subTitle } from '@/styles/typography';
import { ellipsis } from '@/styles/common';
import { flex } from '@/styles/layout';
import { media } from '@/styles/responsive';

export const DepositAccountDetailsWrapper = styled.div`
  max-width: 100%;
  border-radius: 16px;
  background: ${COLORS.violet[150]};
  box-shadow: 0 4px 12px 0 rgb(0 39 32 / 15%);
  margin-bottom: 28px;
`;

export const DepositAccountDetailsFirstRow = styled.div<{ isEdit: boolean }>`
  ${({ isEdit }) =>
    isEdit
      ? flex({ justifyContent: 'space-between' })
      : flex({ justifyContent: 'space-between', alignItems: 'center' })};
  column-gap: 5%;
  padding: ${({ isEdit }) => (isEdit ? '18px 24px 0 8px' : '24px 24px 24px')};
`;

export const DepositAccountDetailsSecondRow = styled.div`
  ${flex({ justifyContent: 'space-between', alignItems: 'center' })};
  padding: 0 24px 20px;

  ${media.mobile`
    ${flex({ alignItems: 'stretch', flexDirection: 'column' })};
    gap: 6px;
  `}
`;

export const DepositAccountName = styled.div`
  ${poppinsMediumFont};
  ${subTitle};
  ${ellipsis};
  color: ${COLORS.black};
  width: 90%;
  white-space: pre-wrap;
`;

export const DetailsBlockColumn = styled.div`
  ${media.mobile`
    ${flex({ alignItems: 'center', justifyContent: 'space-between' })};
  `}
`;

export const DetailsBlockTitle = styled.div`
  ${bodySmall};
  color: ${COLORS.grey[600]};
  margin-bottom: 8px;

  ${media.mobile`
    margin-bottom: 0;
  `}
`;

export const DetailsBlockData = styled.div`
  ${poppinsMediumFont};
  ${body};
  color: ${COLORS.black};

  ${media.mobile`
    ${ellipsis};  
  `}
`;
