import { Box } from '@/components/Box/Box';
import { ReactComponent as PaymentSuccessIcon } from '../image/PaymentSuccessIcon.svg';
import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { PAYMENT_CARDS_ICON_MAP } from '../../SettingsPage/ui/PaymentCard/const';
import { PaymentCardNumber } from '../../SettingsPage/ui/PaymentCardNumber/PaymentCardNumber';
import { Divider } from '../styles';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { AccountData } from '../../SettingsPage/ui/PaymentTransactionHistoryTable/ui/styles';
import { PaymentStatusBadge } from '../../SettingsPage/ui/PaymentTransactionHistoryTable/ui/PaymentStatusBadge';
import { BILLING_FREQUENCY_MAP } from '@/const/payment';
import { BillingFrequencyResponseType, PaymentCardServiceType } from '@/types/paymentTypes';

type PaymentSuccessContentType = {
  paymentDetails: {
    billingDate: string;
    billingFrequency: BillingFrequencyResponseType;
    cardNumber: string;
    cardType: PaymentCardServiceType;
    customerId: string;
    totalPrice: string;
    transactionId: string;
  };
};

export const PaymentSuccessContent = ({ paymentDetails }: PaymentSuccessContentType) => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <PaymentSuccessIcon />
      <Box display='flex' flexDirection='column' justifyContent='space-between' width='100%' mt='20px'>
        <DetailsRow name='Date' offset='small'>
          {paymentDetails?.billingDate}
        </DetailsRow>
        <DetailsRow name='Billing Frequency' offset='small'>
          {BILLING_FREQUENCY_MAP[paymentDetails?.billingFrequency]} payout
        </DetailsRow>
        <DetailsRow name='Customer ID' offset='small'>
          {paymentDetails?.customerId}
        </DetailsRow>
        <DetailsRow name='Payment Card' offset='small'>
          <AccountData>
            {PAYMENT_CARDS_ICON_MAP[paymentDetails?.cardType]}{' '}
            <PaymentCardNumber cardNumber={paymentDetails?.cardNumber} />
          </AccountData>
        </DetailsRow>
        <DetailsRow name='Status' offset='small'>
          <PaymentStatusBadge status='paid' />
        </DetailsRow>
      </Box>
      <Divider />
      <Box display='flex' mt='16px' alignItems='center' justifyContent='space-between' width='100%'>
        <Typography variant='bodySmall' fontWeight='semibold' color={COLORS.grey[650]}>
          Amount
        </Typography>
        <Typography variant='headline3' fontWeight='semibold' color={COLORS.black}>
          {paymentDetails?.totalPrice}
        </Typography>
      </Box>
    </Box>
  );
};
