import { WIRE_STATUS_DB } from '@/const/wire';
import { WireStatusType } from '@/types/wireTypes';

export const NOT_ALLOWED_TO_EXECUTE_WIRE_STATUSES: Partial<WireStatusType>[] = [
  WIRE_STATUS_DB.CANCELLED,
  WIRE_STATUS_DB.EXECUTED,
];

export const getIsAllowedToExecute = (wireStatus: WireStatusType) => {
  return !NOT_ALLOWED_TO_EXECUTE_WIRE_STATUSES.includes(wireStatus);
};
