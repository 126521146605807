import { useState } from 'react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useErrorBoundary } from './ErrorBoundary/ErrorBoundary';
import { App } from './App';
import { Snackbar } from '@/components/Snackbar/Snackbar';
import { StyleSheetManager, WebTarget } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';

const QUERY_DEFAULT_OPTIONS = {
  queries: { retry: 1 },
  mutations: { retry: 1 },
};

export const QueryClientApp = () => {
  const { setError } = useErrorBoundary();

  // Create single instance of QueryClient and setup options
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: QUERY_DEFAULT_OPTIONS,
        queryCache: new QueryCache({
          onError(error) {
            setError(error);
          },
        }),
        mutationCache: new MutationCache({
          onError: (error) => {
            setError(error);
          },
        }),
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <StyleSheetManager
        // Skip rendering custom non-native HTML props in DOM
        shouldForwardProp={(propName: string, elementToBeRendered: WebTarget) => {
          return typeof elementToBeRendered === 'string' ? isPropValid(propName) : true;
        }}
      >
        <App />
        <Snackbar />
      </StyleSheetManager>
    </QueryClientProvider>
  );
};
