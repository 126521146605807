import { useNavigate } from 'react-router-dom';
import { dateFormatter, formatAmount } from '@/helpers/formatHelpers';
import { Card, CardContent } from '@/components/Card/Card';
import { Status } from '@/components/Status/Status';
import { WireType } from '@/types/wireTypes';
import { getRecipientWireDetailsUrl } from '../_Details/urls/getRecipientWireDetailsUrl';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';

type RecipientWiresListType = {
  list: WireType[];
};

export const RecipientWiresList = ({ list }: RecipientWiresListType) => {
  const navigate = useNavigate();

  const navigateToDetails = (wire) => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.RecipientViewsDetails, { status: wire?.status });

    navigate(getRecipientWireDetailsUrl(wire.id));
  };

  return (
    <>
      {list.map((wire) => {
        const transactionName = wire?.contract?.name;
        const { status, date_created, expected_payment_date, amount, name, id } = wire;
        const formattedCreationDate = dateFormatter(date_created);
        const formattedExpectedPaymentDate = dateFormatter(expected_payment_date);
        const isDocumentVerificationRequired = wire?.is_pd_provider_kyc_required;

        return (
          <Card
            key={id}
            title={name}
            header={name}
            meta={<Status status={status} />}
            onClick={() => navigateToDetails(wire)}
            marker={isDocumentVerificationRequired && 'ID VERIFICATION'}
          >
            <CardContent name='Creation Date'>{formattedCreationDate}</CardContent>
            <CardContent name='Expected Payment Date' ratio={2}>
              {formattedExpectedPaymentDate}
            </CardContent>
            <CardContent name='Amount'>{formatAmount(amount)}</CardContent>
            <CardContent name='Transaction Name' ratio={2}>
              {transactionName}
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};
