import { getExecutorWiresApi } from '@/api/v1/executor/getExecutorWiresApi';
import { prepareListApiParams } from '../../../api/helpers/urlApiHelpers';
import { prepareResponse, throwResponseError } from '../../../helpers/apiHelpers/responseHelpers';

export const loadExecutorWires = async (queryParams) => {
  const apiParams = prepareListApiParams(queryParams);

  try {
    const response = await getExecutorWiresApi(apiParams);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
