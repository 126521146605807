import { FieldType, FormConfigType } from '@/helpers/formHelpers/formHelpers';
import {
  EIN_SSN_CODE_VALIDATOR,
  REQUIRED_OBJECT_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';

const STATUS_OPTIONS = [
  { value: 'active', label: 'Active' },
  { value: 'blocked', label: 'Blocked' },
];

type OptionType = {
  value: string;
  label: string;
};

export const getCreateOrganizationConfig = (pricingTierOptions: OptionType[]): FormConfigType => {
  return [
    {
      name: 'name',
      label: 'Name*',
      placeholder: 'Enter Name',
      validator: REQUIRED_TEXT_FIELD_VALIDATOR,
    },
    {
      name: 'ein',
      label: 'Business Tax ID*',
      placeholder: 'E.g. EIN or VAT',
      validator: EIN_SSN_CODE_VALIDATOR,
    },
    {
      name: 'pricing_tier',
      label: 'Select Pricing Tier*',
      type: 'select',
      placeholder: 'Select Pricing Tier',
      options: pricingTierOptions,
      validator: REQUIRED_OBJECT_VALIDATOR,
    },
    {
      name: 'is_kyc_allowed',
      label: 'Enable ID Verification',
      type: 'switch_toggle',
      value: false,
    },
  ];
};

export const getEditOrganizationConfig = (pricingTierOptions: OptionType[]): FormConfigType => {
  const baseConfig = getCreateOrganizationConfig(pricingTierOptions);
  const statusField: FieldType = {
    name: 'status',
    label: 'Select Status',
    type: 'select',
    placeholder: 'Select status',
    options: STATUS_OPTIONS,
  };

  // Insert second-to-last status field in edit config
  baseConfig.splice(baseConfig.length - 1, 0, statusField);

  return baseConfig;
};

const SUPPORTED_PLANS = ['FREE', 'STARTER', 'PRO', 'CUSTOM'];

// TODO v13.0.0: fix pricing plans when BE will be ready
export const pricingTierOptionsFormatter = (pricingTier: { id: string; name: string }[]) => {
  return pricingTier
    ?.filter((plan) => SUPPORTED_PLANS.includes(plan.name))
    .map((plan) => {
      return { value: plan.id, label: plan.name };
    });
};
