import { ChangeEvent } from 'react';
import {
  CustomLabel,
  FakeRadio,
  Input,
  RadioGroupBlockWrapper,
  RadioGroupTitle,
  RadioGroupWrapper,
  RadioTitle,
} from '../RadioGroup/styles';

export type RadioOptionType = {
  value: string;
  label: string;
};

export type RadioGroupNewType = {
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  options: RadioOptionType[];
  disabled?: boolean;
};

export const RadioGroupNew = ({ value, label, options, disabled, onChange }: RadioGroupNewType) => {
  return (
    <RadioGroupWrapper>
      <RadioGroupTitle>{label}</RadioGroupTitle>
      <RadioGroupBlockWrapper>
        {options.map((option, index) => {
          const key = `${index}_${option.label}`;

          return (
            <CustomLabel key={key} disabled={disabled}>
              <Input
                type='radio'
                value={option.value}
                checked={value === option.value}
                onChange={onChange}
                disabled={disabled}
              />
              <FakeRadio disabled={disabled} />
              <RadioTitle isActive>{option.label}</RadioTitle>
            </CustomLabel>
          );
        })}
      </RadioGroupBlockWrapper>
    </RadioGroupWrapper>
  );
};
