import { authApiService } from '../../authApiService';
import { ApiResponseType } from '@/types/sharedTypes';
import { ProxyAssignUserStatusCodeType } from '@/bundle/_Recipient/_Details/ui/ProxyAssignRecipientAutocomplete/const';

type AssignmentStatusType = {
  is_available_to_assign: true;
  code: ProxyAssignUserStatusCodeType;
};

export const getRecipientAssignmentStatusByProxyApi = async (
  wireId: string,
  userId: string
): Promise<ApiResponseType<AssignmentStatusType>> => {
  const uri = `/recipient/wires/${wireId}/users/${userId}/assignment-status/`;

  return authApiService.GET(uri);
};
