import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';
import { UpdateRecipientPayloadType } from '../organization/wires/updateRecipientApi';

export const updateRecipientByProxyApi = (
  wireId: string,
  userId: string,
  data: UpdateRecipientPayloadType
): Promise<ApiResponseType<any>> => {
  const uri = `/recipient/wires/${wireId}/recipients/users/${userId}/`;

  return authApiService.PATCH(uri, data);
};
