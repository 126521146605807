import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { body, poppinsRegularFont } from '@/styles/typography';

export const RequestModalMessage = styled.div`
  color: ${COLORS.grey[600]};
  text-align: center;
  ${poppinsRegularFont};
  ${body};
`;
