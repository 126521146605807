import { setAuthPinCodeApi } from '@/api/v1/users/setAuthPinCodeApi';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const setAuthPinCode = async (pinCode: string) => {
  const payload = {
    pin: pinCode,
  };

  try {
    const response = await setAuthPinCodeApi(payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
