import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { AssignExecutorFormWrapper } from '../../../styles';
import { isNullOrUndefined } from '@/helpers/objectHelpers';
import { FormMessage, FormTitle } from '../../../../../../shared/ui/styles';
import { getMergedValues, getValidationSchema } from '@/helpers/formHelpers/formHelpers';
import { Box } from '@/components/Box/Box';
import { FormGeneratorNew } from '@/components/form/FormGeneratorNew/FormGeneratorNew';
import { ERROR_ASSIGN_EXECUTOR_AMPLITUDE_EVENTS_MAP, EVENTS_EXECUTOR_ACTION_TYPE } from '../../../const/const';
import { assignExecutor, createExecutor, executorAssignmentStatus } from '../../../api/executorApi';
import { AutocompleteOptionType } from '@/components/Autocomplete/types';
import { Maybe } from '@/types/sharedTypes';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { AssignUserAutocomplete } from '@/bundle/shared/components/AssignUserAutocomplete';
import { USER_ROLE_MAP } from '@/const/user';
import { loadUserInfo } from '@/bundle/shared/api';
import { useAssignUser } from '@/bundle/shared/hooks/useAssignUser';
import { ASSIGN_USER_FORM_CONFIG } from '@/bundle/_OrgAdmin/shared/const/const';
import { FormikForm } from '@/components/form/FormikForm/FormikForm';
import { Button } from '@/components/Button/Button';

type AssignExecutorFormType = {
  onClose: () => void;
  onCreateExecutor: () => void;
  onAssignExecutor: () => void;
};

type FormValues = {
  email: string;
  mobile_phone: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
};

export const AssignExecutorForm = ({ onClose, onCreateExecutor, onAssignExecutor }: AssignExecutorFormType) => {
  const { id } = useParams<{ id: string }>();
  const [selectedExecutorOption, setSelectedExecutorOption] = useState<Maybe<AutocompleteOptionType>>(null);
  const executorId = selectedExecutorOption?.value;

  const { data: assignmentStatusData } = useQuery({
    queryKey: ['load_assignment_status', executorId],
    queryFn: () => executorAssignmentStatus(executorId),
    enabled: !!selectedExecutorOption?.isSelectedOption,
  });

  const { data: loadUserData } = useQuery({
    queryKey: ['load_user', executorId],
    queryFn: () => loadUserInfo(executorId),
    enabled: !!selectedExecutorOption?.isSelectedOption && !!assignmentStatusData?.body?.is_available_to_assign,
  });

  const {
    mutate: createExecutorMutate,
    isPending: isPendingCreateExecutor,
    data: createExecutorData,
    reset: resetCreateExecutor,
  } = useMutation({
    mutationKey: ['create_executor'],
    mutationFn: (values: FormValues) => {
      return createExecutor(id, values);
    },
    onSuccess: (createdExecutorResponse) => {
      if (createdExecutorResponse?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.AssignNewExecutor);
      onCreateExecutor();
    },
  });

  const {
    mutate: assignExecutorMutate,
    isPending: isPendingAssignExecutor,
    data: assignExecutorData,
    reset: resetAssignExecutor,
  } = useMutation({
    mutationKey: ['assign_executor'],
    mutationFn: () => {
      const userData = loadUserData?.body;

      return assignExecutor(id, userData?.id);
    },
    onSuccess: (assignedExecutorResponse) => {
      if (assignedExecutorResponse?.error) return;

      const eventType = EVENTS_EXECUTOR_ACTION_TYPE[assignmentStatusData?.body?.code];

      amplitudeService.logEvent(eventType);
      onAssignExecutor();
    },
  });

  const getUser = () => {
    return loadUserData?.body;
  };

  const isAvailableToAssign = useMemo(() => {
    const availableToAssign = assignmentStatusData?.body?.is_available_to_assign;

    if (isNullOrUndefined(availableToAssign)) return true;

    return availableToAssign === true;
  }, [assignmentStatusData]);

  const user = getUser();
  const initialValues = getMergedValues(ASSIGN_USER_FORM_CONFIG, user) as FormValues;
  const isDisabledForm = !!user || !isAvailableToAssign;
  const validationSchema = isDisabledForm ? null : getValidationSchema(ASSIGN_USER_FORM_CONFIG);

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (selectedExecutorOption?.isSelectedOption) {
        assignExecutorMutate();

        return;
      }

      createExecutorMutate(values);
    },
  });

  const onResetData = () => {
    resetCreateExecutor();
    resetAssignExecutor();
  };

  useAssignUser(
    loadUserData?.body,
    formik,
    onResetData,
    selectedExecutorOption,
    assignmentStatusData?.body?.is_available_to_assign
  );

  const assignmentStatus = assignmentStatusData?.body?.code;
  const createExecutorError = createExecutorData?.error;
  const assignExecutorError = assignExecutorData?.error;
  const apiError = createExecutorError || assignExecutorError;
  const isLoading = isPendingCreateExecutor || isPendingAssignExecutor;

  useEffect(() => {
    const assigmentStatusBody = assignmentStatusData?.body;

    if (assigmentStatusBody && !assigmentStatusBody?.is_available_to_assign) {
      amplitudeService.logEvent(ERROR_ASSIGN_EXECUTOR_AMPLITUDE_EVENTS_MAP[assigmentStatusBody?.code]);
    }
  }, [assignmentStatusData?.body]);

  return (
    <AssignExecutorFormWrapper>
      <FormTitle>Assign Executor</FormTitle>
      <FormMessage>Please indicate who will mark this wire as executed.</FormMessage>
      <FormikForm value={formik}>
        <AssignUserAutocomplete
          role={USER_ROLE_MAP.EXECUTOR}
          selectedOption={selectedExecutorOption}
          assignmentStatus={assignmentStatus}
          onSelect={setSelectedExecutorOption}
          error={formik.errors?.email}
          apiError={apiError}
        />

        <FormGeneratorNew config={ASSIGN_USER_FORM_CONFIG} apiError={apiError} disableForm={isDisabledForm} />

        <Box alignItems='center' justifyContent='flex-end' columnGap='12px' mt='12px'>
          <Button variant='secondary' width={172} size='medium' onClick={onClose}>
            Cancel
          </Button>
          <Button width={172} size='medium' type='submit' disabled={!isAvailableToAssign} isLoading={isLoading}>
            Assign
          </Button>
        </Box>
      </FormikForm>
    </AssignExecutorFormWrapper>
  );
};
