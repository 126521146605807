import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AuthLayout } from '../../ui/AuthLayout/AuthLayout';
import { TwoFAForm } from '@/bundle/Auth/LoginFlow/TwoFAPage/ui/TwoFAForm/TwoFAForm';
import { resendForgotPasswordTwoFACode, sendForgotPasswordTwoFA } from './api';
import { useForgotPasswordNavigateToStep } from '../hooks/useForgotPasswordNavigateToStep';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';

export const ForgotPasswordTwoFAPage = () => {
  const navigateToStep = useForgotPasswordNavigateToStep();
  const twoFAFormRef = useRef(null);

  const {
    mutate: sendTwoFA,
    data: sendTwoFAData,
    isPending: isSendTwoFAPending,
  } = useMutation({
    mutationKey: ['send_forgot_password_fa'],
    mutationFn: (twoFACode: string) => {
      return sendForgotPasswordTwoFA(twoFACode);
    },
    onSuccess(sendTwoFAResponse) {
      if (sendTwoFAResponse?.error) return;

      amplitudeService.logEvent(AMPLITUDE_EVENTS.ForgotPassword2FACode);

      return navigateToStep(sendTwoFAResponse?.body);
    },
    onSettled() {
      twoFAFormRef?.current?.resetCodeChanged();
    },
  });

  const {
    mutate: sendCode,
    data: sendCodeData,
    isPending: isSendCodePending,
  } = useMutation({
    mutationKey: ['send_forgot_password_code'],
    mutationFn: () => {
      return resendForgotPasswordTwoFACode();
    },
    onSuccess(sendCodeResponse) {
      const retryAfter = sendCodeResponse?.headers?.retryAfter;

      twoFAFormRef?.current?.resetTimer(retryAfter);

      if (sendCodeResponse?.error) return;
    },
  });

  const confirmTwoFA = (twoFACode: string) => {
    sendTwoFA(twoFACode);
  };

  return (
    <AuthLayout>
      <TwoFAForm
        ref={twoFAFormRef}
        isTwoFALoading={isSendTwoFAPending}
        isSendCodeLoading={isSendCodePending}
        twoFAError={sendTwoFAData?.error}
        sendCodeError={sendCodeData?.error}
        onConfirm={confirmTwoFA}
        onResend={sendCode}
      />
    </AuthLayout>
  );
};
