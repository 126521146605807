import { ClipboardEvent, ComponentPropsWithoutRef, ReactNode } from 'react';
import { Box } from '@/components/Box/Box';
import { ConfirmationTicks, CustomInput, IconWrapper, InputSizeVariant } from './styles';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { InputError } from '../InputError/InputError';

export type InputType = {
  label?: string;
  variant?: InputSizeVariant;
  labelHint?: ReactNode;
  iconRight?: ReactNode;
  hasConfirmTicks?: boolean;
  hasErrorComponent?: boolean;
  error?: string;
  apiError?: string;
  children?: ReactNode;
  hasToggleEye?: boolean;
  isCopyPasteDisabled?: boolean;
} & ComponentPropsWithoutRef<'input'>;

export const Input = ({
  label,
  variant = 'medium',
  labelHint,
  iconRight,
  hasConfirmTicks,
  hasErrorComponent = true,
  error,
  apiError,
  children,
  hasToggleEye = false,
  isCopyPasteDisabled,
  ...rest
}: InputType) => {
  const hasError = !!error || !!apiError;

  const onCopyPasteHandler = (event: ClipboardEvent<HTMLInputElement>) => {
    if (isCopyPasteDisabled) {
      event.preventDefault();
    }
  };

  return (
    <Box width='100%'>
      <Box display='flex' alignItems='center' justifyContent='space-between' columnGap='10px' mb='4px'>
        {label && (
          <Typography variant='bodySmall' color={COLORS.black}>
            {label}
          </Typography>
        )}
        {labelHint && (
          <Typography variant='caption' color={COLORS.grey[700]} fontWeight='semibold'>
            {labelHint}
          </Typography>
        )}
      </Box>
      <Box position='relative'>
        <CustomInput
          variant={variant}
          hasError={hasError}
          hasConfirmTicks={hasConfirmTicks}
          hasToggleEye={hasToggleEye}
          onPaste={onCopyPasteHandler}
          onCopy={onCopyPasteHandler}
          {...rest}
        />
        {!!iconRight && <IconWrapper>{iconRight}</IconWrapper>}
        {hasConfirmTicks && <ConfirmationTicks />}
        {children}
      </Box>
      {hasErrorComponent && (
        <Box display='flex'>
          <InputError error={error} apiError={apiError} />
        </Box>
      )}
    </Box>
  );
};
