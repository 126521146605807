import { ApiResponseType, BaseResponseType } from '@/types/sharedTypes';
import { apiService } from '@/api/apiService';

export const resetInternalRequestFromEmailApi = async (
  userId: string,
  token: string
): Promise<ApiResponseType<BaseResponseType>> => {
  const uri = `/opco/internal/users/${userId}/reset-request/${token}/`;

  return apiService.POST(uri, {});
};
