import styled from 'styled-components';

export const AppErrorWrapper = styled.div`
  width: 100%;
  height: 90vh;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;
`;

export const ErrorTitle = styled.span`
  font-size: 20px;
  line-height: 30px;
  font-family: Poppins-Semibold, sans-serif;
  font-weight: 600;
  margin-top: 25px;
`;

export const ErrorMessage = styled.span`
  font-size: 16px;
  margin-top: 5px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 6px;
`;
