import {
  CustomLabel,
  FakeRadio,
  Input,
  RadioGroupBlockWrapper,
  RadioGroupTitle,
  RadioGroupWrapper,
  RadioTitle,
} from './styles';

type FormikRadioGroupType = {
  title: string;
  isChecked?: boolean;
  radioGroupConfig: {};
  disabled?: boolean;
};

export const RadioGroup = ({ title, radioGroupConfig, disabled }: FormikRadioGroupType) => {
  return (
    <RadioGroupWrapper>
      <RadioGroupTitle>{title}</RadioGroupTitle>
      <RadioGroupBlockWrapper>
        {Object.keys(radioGroupConfig).map((key) => {
          const item = radioGroupConfig[key];

          return (
            <CustomLabel key={item.radio_title} disabled={disabled}>
              <Input type='radio' checked={item.value === item.isChecked} onChange={item.handler} disabled={disabled} />
              <FakeRadio disabled={disabled} />
              <RadioTitle isActive={item.isChecked && !disabled}>{item.radio_title}</RadioTitle>
            </CustomLabel>
          );
        })}
      </RadioGroupBlockWrapper>
    </RadioGroupWrapper>
  );
};
