import { FormikCustomTextArea, FormikTextAreaLabel, FormikTextAreaLabelWrapper, FormikTextAreaWrapper } from './styles';
import { InputError } from '../InputError/InputError';
import { useField } from 'formik';
import { ResponseErrorType } from '@/types/sharedTypes';
import { getFieldError } from '@/helpers/formHelpers/formHelpers';

type FormikTextAreaType = {
  name: string;
  label: string;
  apiError?: ResponseErrorType;
  placeholder: string;
  rows?: number;
};

export const FormikTextArea = ({ name, label, apiError, placeholder, rows = 3 }: FormikTextAreaType) => {
  const [field, meta] = useField(name);

  const { error, apiError: apiErrorMessage, hasAnyError } = getFieldError(name, meta, apiError);

  return (
    <FormikTextAreaWrapper>
      <FormikTextAreaLabelWrapper>
        <FormikTextAreaLabel hasError={hasAnyError}>{label}</FormikTextAreaLabel>
      </FormikTextAreaLabelWrapper>
      <FormikCustomTextArea {...field} rows={rows} hasError={hasAnyError} placeholder={placeholder} />
      <InputError error={error} apiError={apiErrorMessage} />
    </FormikTextAreaWrapper>
  );
};
