export const PLAN_STATUS_DB = {
  SUCCESS: 'success',
} as const;

export const PAYMENT_FREQUENCY_DB = {
  MONTHLY: 'monthly',
  ANNUAL: 'annual',
} as const;

export const PAYMENT_CARD_SERVICE_TYPE = {
  MASTER_CARD: 'mastercard',
  VISA: 'visa',
  AMEX: 'amex',
  JCB: 'jcb',
  DISCOVER: 'discover',
  DINERS: 'diners',
} as const;

export const PAYMENT_METHOD_TYPE = 'card';

export const PAYMENT_STATUS_MAP = {
  PAID: 'paid',
} as const;

export const BILLING_FREQUENCY_MAP = {
  annual: 'Yearly',
  monthly: 'Monthly',
};

export const BILLING_FREQUENCY_LABEL_MAP = {
  annual: 'year',
  monthly: 'month',
};
