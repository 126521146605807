import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { bodySmall } from '@/styles/typography';
import { media } from '@/styles/responsive';

export const DocumentVerificationContainer = styled.div`
  ${media.mobile`
    order: -1
  `}
`;

export const VerifiedLabel = styled.div`
  background-color: ${COLORS.green[300]};
  color: ${COLORS.green[400]};
  padding: 4px 8px;
  border-radius: 10px;
  ${bodySmall};
`;
