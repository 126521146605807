import { ApiParams } from '../../helpers/urlApiHelpers';
import { authApiService } from '../../authApiService';
import { ApiResponseType, ForgotPasswordResponseType } from '@/types/sharedTypes';

export const forgotPasswordCompleteRegistrationApi = async (
  data,
  params?: ApiParams
): Promise<ApiResponseType<ForgotPasswordResponseType>> => {
  const uri = '/users/forgot-password/complete-registration/';

  return authApiService.POST(uri, data, params);
};
