import { COLORS } from '@/styles/colors';
import { body, bodySmall } from '@/styles/typography';
import styled from 'styled-components';

export const EmailLinkWrapper = styled.a`
  display: inline-block;
  color: ${COLORS.blue[500]};
  ${bodySmall};
  cursor: pointer;
`;

export const EmailLinkWrapperBig = styled(EmailLinkWrapper)`
  ${body};
`;
