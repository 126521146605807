import { body, poppinsRegularFont } from '@/styles/typography';
import { styled } from 'styled-components';

export const PepperPayContainer = styled.div`
  max-width: 800px;
`;

export const PaymentCardErrorList = styled.ul`
  padding: 0 !important;
  margin: 0 0 0 20px;
`;

export const PaymentCardError = styled.li`
  ${poppinsRegularFont};
  ${body};
`;
