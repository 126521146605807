import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { AutocompleteOptionType } from '@/components/Autocomplete/types';
import { Box } from '@/components/Box/Box';
import { ResponseErrorType } from '@/types/sharedTypes';
import { searchUsers } from '@/bundle/shared/api';
import { UserAssignmentStatusCodeType } from '@/const/shared';
import { USER_ASSIGNMENT_NOTIFICATIONS_MAP, NewUserNotification } from './UserAssignmentNotifications';
import { getAutocompleteOptions } from '@/helpers/formatHelpers';
import { SearchRoleType } from '@/api/v1/organization/users/getSearchUsersApi';
import { EMAIL } from '@/const/regExpPatterns';

type AssignUserAutocompleteType = {
  role: SearchRoleType;
  selectedOption: AutocompleteOptionType;
  assignmentStatus: UserAssignmentStatusCodeType;
  onSelect: (selectedUser: AutocompleteOptionType) => void;
  disabled?: boolean;
  error?: string;
  apiError?: ResponseErrorType;
};

export const EMAIL_ALLOW_SEARCH_LENGTH = 3;

export const AssignUserAutocomplete = ({
  role,
  selectedOption,
  assignmentStatus,
  onSelect,
  disabled,
  error,
  apiError,
}: AssignUserAutocompleteType) => {
  const queryClient = useQueryClient();
  const searchEmail = selectedOption?.value;
  const shouldLoadUsers = searchEmail?.length >= EMAIL_ALLOW_SEARCH_LENGTH && !selectedOption.isSelectedOption;

  const { data, isFetching } = useQuery({
    queryKey: ['search_users', searchEmail],
    queryFn: () => {
      return searchUsers(searchEmail, role);
    },
    enabled: shouldLoadUsers,
  });

  useEffect(() => {
    if (searchEmail?.length < EMAIL_ALLOW_SEARCH_LENGTH) {
      queryClient.resetQueries({ queryKey: ['search_users'], exact: false });
    }
  }, [searchEmail, queryClient]);

  const getAssignNotification = (statusCode: UserAssignmentStatusCodeType) => {
    if (!selectedOption) return;

    const isNewUser = !selectedOption.isSelectedOption && EMAIL.test(searchEmail);

    if (isNewUser) {
      return <NewUserNotification />;
    }

    return USER_ASSIGNMENT_NOTIFICATIONS_MAP[statusCode];
  };

  const options = getAutocompleteOptions(data?.body?.results);
  const showNotifications = !error && !apiError && !disabled;
  const assignNotification = getAssignNotification(assignmentStatus);

  return (
    <>
      <Autocomplete
        name='email'
        label='Email Address*'
        placeholder='Enter or Select Email Address'
        options={options}
        isLoading={isFetching}
        disabled={disabled}
        onSelect={onSelect}
        apiError={apiError}
        hideError
      />
      {showNotifications && <Box>{assignNotification}</Box>}
    </>
  );
};
