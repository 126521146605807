import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import { bodySmall, poppinsMediumFont } from '@/styles/typography';
import styled, { keyframes } from 'styled-components';
import { media } from '@/styles/responsive';
import { THEME } from '@/styles/theme';

const hoverAnimation = keyframes`
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(30%);
  }
`;

export const UpsellBannerWrapper = styled.div`
  display: flex;
  position: relative;
  padding: 12px 36px;
  background: linear-gradient(89.99deg, #aaf4eb 0.01%, #c9d5ff 99.99%);
  z-index: ${THEME.upsellBanner.zIndex};

  ${media.tablet`
    padding: 8px 16px;
  `}
`;

export const GetStartedStep = styled.div<{ isVisible: boolean }>`
  width: 100%;
  ${flex({ justifyContent: 'center' })};
  column-gap: 12px;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
`;

export const UpsellBannerText = styled.div`
  color: ${COLORS.black};
  ${bodySmall};
  ${poppinsMediumFont};
`;

export const CloseUpsellBannerWrapper = styled.div`
  cursor: pointer;
  margin-left: auto;
`;

export const AdvertisingMessageBlock = styled.div`
  color: ${COLORS.black};
  ${bodySmall};
  ${poppinsMediumFont};
`;

export const AnimateFadeInUpsellBanner = styled.div`
  width: 100%;

  &.fade-enter {
    opacity: 0;
    transform: translateY(-8px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition:
      opacity 0.4s ease-out,
      transform 0.4s ease-out;
  }
`;

export const SuccessBlockWrapper = styled.div<{ isVisible: boolean }>`
  width: 100%;
  column-gap: 10px;
  display: ${({ isVisible }) => (isVisible ? 'none' : 'flex')};
  justify-content: center;
`;

export const GetStartedBlock = styled.span`
  margin-left: 12px;
  text-decoration: underline;
  text-underline-offset: 2px;
  font-weight: 600;
  transition: transform 0.3s linear;
  cursor: pointer;

  & > svg {
    display: inline;
    vertical-align: middle;
    margin-left: 4px;
  }

  &:hover > svg {
    animation: ${hoverAnimation} 1s ease-in-out infinite;
  }
`;

export const IconWrapper = styled.div``;
