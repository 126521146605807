import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getTransactionDetailsUrl } from '../../Transactions/_Details/urls/getTransactionDetailsUrl';
import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { CreateReplacementWireForm } from './ui/CreateReplacementWireForm/CreateReplacementWireForm';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { useInterval } from '@/hooks/useInterval';
import { WIRE_STATUS_DB } from '@/const/wire';
import {
  isPendingWireStatus,
  isRecipientAssignedWireStatus,
  isRecipientChainProxy,
  isRecipientProxy,
} from '@/helpers/wireHelpers';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  changeWireStatus,
  ChangeWireStatusType,
  createReplacementWire,
  loadOutboundWireDetails,
  loadPaymentDetails,
  registerBillingEvent,
} from '@/bundle/_OrgAdmin/pages/OutboundWire/_Edit/api';
import { loadCurrency } from '@/bundle/_OrgAdmin/pages/Transactions/_Details/api';
import { currencyFormatter } from '@/helpers/formatHelpers';
import { getOutboundWireDetailsUrl } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/urls/getOutboundWireDetailsUrl';
import { PageContent } from '@/components/Page/PageContent/PageContent';
import { PageColumn } from '@/components/Page/PageColumn/PageColumn';
import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { Drawer } from '@/components/Drawer/Drawer';
import { OutboundWirePaymentDetails } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/ui/OutboundWirePaymentDetails/OutboundWirePaymentDetails';
import { OutboundWireGeneralDetails } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/ui/OutboundWireGeneralDetails/OutboundWireGeneralDetails';
import { getHeaderActionMessage } from './const/const';
import { ReplacementWireDataType } from '@/api/v1/organization/wires/createReplacementWireApi';
import { getAmplitudePaymentDetailsProvider } from '@/services/amplitudeService/amplitudeHelpers';
import { OutboundWireHeaderActions } from './ui/OutboundWireHeaderActions/OutboundWireHeaderActions';
import { SourceWirePaymentDetails } from '@/bundle/shared/components/SourceWirePaymentDetails/SourceWirePaymentDetails';
import { ExecutorActionsWidget } from './ui/ExecutorActionsWidget/ExecutorActionsWidget';
import { RecipientActionsWidget } from './ui/RecipientActionsWidget/RecipientActionsWidget';
import { ViewPaymentDetails } from './ui/ViewPaymentDetails/ViewPaymentDetails';
import { OutboundWireUserDetails } from './ui/OutboundWireUserDetails/OutboundWireUserDetails';
import { RedirectButton } from '@/components/RedirectButton/RedirectButton';
import { getShowPaymentDetails } from '@/helpers/paymentDetailsHelpers';

export const OutboundWireDetailsPage = () => {
  const queryClient = useQueryClient();
  const { transactionId, id } = useParams<{ transactionId: string; id: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const [isEdit, setIsEdit] = useState(false);
  const [isShowRecipientEditForm, setIsShowRecipientEditForm] = useState(false);
  const [isShowExecutorAssignForm, setIsShowExecutorAssignForm] = useState(false);
  const [isShowExecutorEditForm, setIsShowExecutorEditForm] = useState(false);
  const [isOpenCancelWireModal, setIsOpenCancelWireModal] = useState(false);
  const [isOpenExecuteWireModal, setIsOpenExecuteWireModal] = useState(false);
  const [isOpenCreateWireForm, setIsOpenCreateWireForm] = useState(false);

  const { data: outboundWireData, isPending: isPendingOutboundWire } = useQuery({
    queryKey: ['load_outbound_wire', id],
    queryFn: () => loadOutboundWireDetails(id),
  });

  const refetchOutboundWire = () => {
    queryClient.invalidateQueries({ queryKey: ['load_outbound_wire', id] });
  };

  const outboundWire = outboundWireData?.body;
  const wireStatus = outboundWire?.status;
  const isRecipientAssignStatus = isRecipientAssignedWireStatus(wireStatus);

  const isPaymentDetailsExist = !!outboundWire?.is_payment_details_exist;
  const isBillingEvent = !!outboundWire?.view_payment_details_event_register;
  const hasSourceWirePaymentDetails = !!(outboundWire?.payment_details_source_wire?.id && isRecipientAssignStatus);
  const isProvidedPaymentDetails = isPaymentDetailsExist && isBillingEvent;
  const wireId = isProvidedPaymentDetails ? id : outboundWire?.payment_details_source_wire?.id;
  const hasRedirectButton = location?.state?.transactionId && location?.state?.id;

  const { data: paymentDetailsData, isFetching: isFetchingPaymentDetails } = useQuery({
    queryKey: ['load_payment_details', wireId],
    queryFn: () => loadPaymentDetails(wireId),
    enabled: isProvidedPaymentDetails || hasSourceWirePaymentDetails,
  });

  const {
    mutate: mutateRegisterBillingEvent,
    data: billingEventData,
    isPending: isPendingBillingEvent,
  } = useMutation({
    mutationKey: ['register_billing_event'],
    mutationFn: () => {
      return registerBillingEvent(id);
    },
    onSuccess: (response) => {
      if (response?.error) return;

      refetchOutboundWire();
    },
  });

  const { mutate: mutateChangeWireStatus, isPending: isPendingChangeWireStatus } = useMutation({
    mutationKey: ['change_wire_status'],
    mutationFn: (status: ChangeWireStatusType) => {
      return changeWireStatus(id, status);
    },
    onSuccess: (response) => {
      if (response?.error) return;

      queryClient.setQueryData(['load_outbound_wire', id], response);
    },
  });

  const isShowPaymentDetails = getShowPaymentDetails(
    !!paymentDetailsData?.body,
    outboundWire,
    !!billingEventData?.body
  );

  const registerShowPaymentDetailsEvent = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.ViewPaymentDetails);
    mutateRegisterBillingEvent();
  };

  const {
    mutate: mutateCreateChildWire,
    isPending: isPendingCreateChildWire,
    reset: resetCreateChildWire,
  } = useMutation({
    mutationKey: ['create_child_wire'],
    mutationFn: (payload: ReplacementWireDataType) => createReplacementWire(id, payload),
    onSuccess: (response) => {
      if (response?.error) return;

      const isProxy = isRecipientProxy(outboundWire);
      const isChainProxy = isRecipientChainProxy(outboundWire);
      const provider = getAmplitudePaymentDetailsProvider(isChainProxy, isProxy);

      amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateChildOutboundWireSuccess, { provider });

      setIsOpenCreateWireForm(false);
      navigate(getOutboundWireDetailsUrl(transactionId, response?.body?.id));
      refetchOutboundWire();
    },
  });

  const { data: currency } = useQuery({
    queryKey: ['load_currency'],
    queryFn: () => loadCurrency(),
    enabled: !!(isEdit || isOpenCreateWireForm),
  });

  const currencyOptions = currencyFormatter(currency?.body?.results);
  const headerActionMessage = getHeaderActionMessage(outboundWire);
  const isPendingStatus = isPendingWireStatus(wireStatus);

  // polling wire details logic
  const { cancelInterval, isIntervalRunning } = useInterval(() => {
    if (isPendingStatus) {
      refetchOutboundWire();
    }
  });

  //handlers block
  const navigateToTransactionDetails = () => {
    navigate(getTransactionDetailsUrl(transactionId));
  };

  const openEditMode = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.EditGeneralInfoOutboundWireRedirect);
    setIsEdit(true);
  };

  const confirmCancelWire = () => {
    mutateChangeWireStatus(WIRE_STATUS_DB.CANCELLED);
    setIsOpenCancelWireModal(false);

    if (isShowExecutorAssignForm) {
      setIsShowExecutorAssignForm(false);
    }

    amplitudeService.logEvent(AMPLITUDE_EVENTS.CancelOutboundWireSuccess);
  };

  const confirmExecuteWire = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.ExecuteWireSuccess);
    setIsOpenExecuteWireModal(false);

    mutateChangeWireStatus(WIRE_STATUS_DB.EXECUTED);
  };

  const redirectToWire = () => {
    navigate(getOutboundWireDetailsUrl(location.state?.transactionId, location.state?.id));
  };

  // Reset edit mode when switching between details pages
  useEffect(() => setIsEdit(false), [id, transactionId]);

  // Cancel polling logic
  useEffect(() => {
    const shouldCancelInterval = !isPendingStatus && isIntervalRunning;

    if (shouldCancelInterval) {
      cancelInterval();
    }
  }, [wireStatus, cancelInterval, isIntervalRunning, isPendingStatus]);

  const closeReplacementWireModal = () => {
    resetCreateChildWire();
    setIsOpenCreateWireForm(false);
  };

  const openEditRecipientForm = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.EditRecipientInfoRedirect);
    setIsShowRecipientEditForm(true);
  };

  const openEditExecutorForm = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.EditExecutorInfoRedirect);
    setIsShowExecutorEditForm(true);
  };

  const onUpdateWireDetails = () => {
    setIsEdit(false);
  };

  const openCancelWireDialog = () => {
    setIsOpenCancelWireModal(true);
    amplitudeService.logEvent(AMPLITUDE_EVENTS.CancelOutboundWireStartRedirect);
  };

  const openExecuteWireDialog = () => {
    setIsOpenExecuteWireModal(true);
    amplitudeService.logEvent(AMPLITUDE_EVENTS.ExecuteWireRedirect);
  };

  const openAssignExecutorForm = () => {
    setIsShowExecutorAssignForm(true);
    amplitudeService.logEvent(AMPLITUDE_EVENTS.AssignExecutorRedirect);
  };

  const openCreateReplacementWireForm = () => {
    setIsOpenCreateWireForm(true);
    amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateChildOutboundWireRedirect);
  };

  const paymentDetails = paymentDetailsData?.body;

  return (
    <ContentLayout
      title='Wire details'
      headerAction={
        <OutboundWireHeaderActions
          wire={outboundWire}
          isLoadingWire={isPendingOutboundWire}
          viewPaymentDetails={isShowPaymentDetails}
          isShowExecutorForm={isShowExecutorAssignForm}
          onOpenCancelWireDialog={openCancelWireDialog}
          onOpenExecuteWireDialog={openExecuteWireDialog}
          onAssignExecutorRedirect={openAssignExecutorForm}
          onOpenCreateReplacementWireForm={openCreateReplacementWireForm}
        />
      }
      headerActionMessage={headerActionMessage}
      backButton='Back to Transaction'
      onBack={navigateToTransactionDetails}
    >
      <PageBody>
        <PageContent>
          <PageColumn>
            <OutboundWireGeneralDetails
              wire={outboundWire}
              wireApiError={outboundWireData?.error}
              currencyOptions={currencyOptions}
              isEdit={isEdit}
              onUpdateWireDetails={onUpdateWireDetails}
              onEditWireDetails={openEditMode}
            />

            <OutboundWireUserDetails
              wire={outboundWire}
              isShowExecutorForm={isShowExecutorAssignForm}
              isShowEditExecutorForm={isShowExecutorEditForm}
              isShowEditRecipientForm={isShowRecipientEditForm}
              onEditRecipientForm={openEditRecipientForm}
              onEditExecutorForm={openEditExecutorForm}
            />
          </PageColumn>
          <PageColumn>
            <ExecutorActionsWidget
              wire={outboundWire}
              onRefetchWire={refetchOutboundWire}
              isShowAssignForm={isShowExecutorAssignForm}
              isShowEditForm={isShowExecutorEditForm}
              onCloseAssignForm={() => setIsShowExecutorAssignForm(false)}
              onCloseEditForm={() => setIsShowExecutorEditForm(false)}
            />

            <RecipientActionsWidget
              wire={outboundWire}
              onRefetchWire={refetchOutboundWire}
              isShowEditForm={isShowRecipientEditForm}
              onCloseEditForm={() => setIsShowRecipientEditForm(false)}
            />

            {hasSourceWirePaymentDetails && paymentDetails && (
              <SourceWirePaymentDetails
                paymentDetails={paymentDetails}
                notification='Waiting for the Recipient to confirm their wire info.'
                wire={outboundWire}
              />
            )}

            {isPaymentDetailsExist && isShowPaymentDetails && (
              <OutboundWirePaymentDetails
                wire={outboundWire}
                paymentDetails={paymentDetails}
                isLoading={isFetchingPaymentDetails}
                apiError={paymentDetailsData?.error}
              />
            )}

            {isPaymentDetailsExist && !isBillingEvent && (
              <ViewPaymentDetails
                wire={outboundWire}
                isShowPaymentDetails={isShowPaymentDetails}
                billingEventApiError={billingEventData?.error}
                isLoadingBillingEvent={isPendingBillingEvent}
                onViewPaymentDetails={registerShowPaymentDetailsEvent}
              />
            )}
          </PageColumn>
        </PageContent>
      </PageBody>

      <ConfirmModal
        isOpen={isOpenCancelWireModal}
        isLoading={isPendingChangeWireStatus}
        header='Cancel Wire'
        body='You will not be able to view this wire once you cancel.'
        onClose={() => setIsOpenCancelWireModal(false)}
        onConfirm={confirmCancelWire}
      />

      <ConfirmModal
        isOpen={isOpenExecuteWireModal}
        isLoading={isPendingChangeWireStatus}
        header='Mark as Executed'
        body='You are marking this wire as successfully executed. The recipient of this wire will be notified that the wire has been sent.'
        onClose={() => setIsOpenExecuteWireModal(false)}
        onConfirm={confirmExecuteWire}
      />
      <Drawer
        isOpen={isOpenCreateWireForm}
        header='Create Replacement Wire'
        subHeader='You are creating a replacement wire. This will allow you to update the wire info for the assigned recipient. This collection will not be billed and you cannot change the recipient of this wire.'
        onClose={closeReplacementWireModal}
      >
        <CreateReplacementWireForm
          wire={outboundWire}
          currencyOptions={currencyOptions}
          onCreate={mutateCreateChildWire}
          isLoading={isPendingCreateChildWire}
          onClose={closeReplacementWireModal}
        />
      </Drawer>
      {hasRedirectButton && <RedirectButton onNavigate={redirectToWire} />}
    </ContentLayout>
  );
};
