import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import { bodySmall, poppinsMediumFont } from '@/styles/typography';

export const LoaderWrapper = styled.div`
  gap: 8px;
  width: 100%;
  ${flex({ alignItems: 'center' })};
  padding: 15px 16px;
  background: ${COLORS.blue[200]};
  border-radius: 10px;
  ${poppinsMediumFont};
  ${bodySmall};
`;
