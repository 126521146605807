import styled from 'styled-components';
import { bodySmall, poppinsRegularFont } from '@/styles/typography';
import { COLORS } from '@/styles/colors';
import { flexCenter } from '@/styles/layout';

export const MetaWrapper = styled.div`
  ${poppinsRegularFont};
  ${bodySmall};
  padding: 2px 8px;
  background-color: ${COLORS.green[200]};
  color: ${COLORS.green[500]};
  border-radius: 8px;
`;

export const ALertIconWrapper = styled.div`
  padding: 4px;
  background-color: ${COLORS.orange[100]};
  border-radius: 100%;
  ${flexCenter};
`;

export const PaymentDetailsCollectionType = styled.div`
  padding: 4px 8px;
  background: ${COLORS.grey[200]};
  color: ${COLORS.grey[700]};
  border-radius: 10px;
  ${bodySmall};
`;
