import { useNavigate } from 'react-router-dom';
import { getDashboardRedirectUrl } from '@/bundle/Auth/LoginFlow/LoginPage/const/const';
import { LogoWrapper, MenuHeader, OrganizationDetailsWrapper, OrganizationIcon, OrganizationName } from './styles';
import { useUser } from '@/context/userContext';
import { getOrganizationName } from '@/helpers/userHelpers';
import { USER_ROLE_MAP } from '@/const/user';

export const SidebarHeader = () => {
  const navigate = useNavigate();
  const { user, currentRole } = useUser();
  const organizationName = getOrganizationName(user);
  const isOrgAdmin = currentRole === USER_ROLE_MAP.ORGANIZATION;
  const showOrganizationName = isOrgAdmin && !!organizationName;

  const redirectToDashboard = () => {
    const url = getDashboardRedirectUrl(currentRole);

    navigate(url);
  };

  return (
    <>
      <MenuHeader>
        <LogoWrapper onClick={redirectToDashboard}></LogoWrapper>
      </MenuHeader>
      {showOrganizationName && (
        <OrganizationDetailsWrapper>
          <OrganizationIcon />
          <OrganizationName>{organizationName}</OrganizationName>
        </OrganizationDetailsWrapper>
      )}
    </>
  );
};
