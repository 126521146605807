import { trimString } from '@/helpers/stringHelpers';
import { Chip } from '../Chip/Chip';
import { Box } from '@/components/Box/Box';
import { BilledEventsFiltersType } from '../../../hooks/useBilledEventsFilters/useBilledEventsFilters';
import { useEffect, useState } from 'react';
import { FiltersChipType, getChipsFromFilters } from './helpers';

type ChipsListType = {
  filters: BilledEventsFiltersType;
  onRemoveChip: (chip: FiltersChipType) => void;
};

export const ChipsList = ({ filters, onRemoveChip }: ChipsListType) => {
  const [chips, setChips] = useState<FiltersChipType[]>([]);

  useEffect(() => {
    const chipFromFilters = getChipsFromFilters(filters);

    setChips(chipFromFilters);
  }, [filters]);

  const removeChip = (chip: FiltersChipType) => {
    if (!chip.removable) return;

    onRemoveChip(chip);
  };

  return (
    <Box display='flex' flexWrap='wrap'>
      {chips.map((chip) => {
        const trimmedLabel = trimString(chip.label, 50);

        return (
          <Chip key={chip.id} title={trimmedLabel} onClick={() => removeChip(chip)} isRemovable={chip.removable} />
        );
      })}
    </Box>
  );
};
