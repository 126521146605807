import { useEffect, useState } from 'react';
import { ReelAnimatedValueContainer, ReelAnimatedValueGroup, ReelAnimatedValueWrapper } from './styles';

type ReelAnimateValueType = {
  value: number | string;
};

const MOVE_DELAY_INTERVAL = 900;

export const ReelAnimateValue = ({ value }: ReelAnimateValueType) => {
  const [previousValue, setPreviousValue] = useState(value);
  const [isMove, setIsMove] = useState(false);

  useEffect(() => {
    setIsMove(previousValue !== value);

    const moveTimeout = setTimeout(() => {
      setPreviousValue(value);
      setIsMove(false);
    }, MOVE_DELAY_INTERVAL);

    return () => {
      clearTimeout(moveTimeout);
    };
  }, [previousValue, value]);

  return (
    <ReelAnimatedValueContainer>
      <ReelAnimatedValueGroup move={isMove}>
        <ReelAnimatedValueWrapper>{previousValue}</ReelAnimatedValueWrapper>
        <ReelAnimatedValueWrapper>{value}</ReelAnimatedValueWrapper>
      </ReelAnimatedValueGroup>
    </ReelAnimatedValueContainer>
  );
};
