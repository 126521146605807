import { dateFormatter } from '../../../../../../../helpers/formatHelpers';
import { Status } from '../../../../../../../components/Status/Status';
import { WireEditInfoItem, WireEditInfoLabel, WireEditInfoValue, WireEditInfoWrapper } from './styles';
import { WireStatusType } from '@/types/wireTypes';

type EditWireDetailsType = {
  status: WireStatusType;
  dateUpdate: string;
};

export const EditWireDetails = ({ status, dateUpdate }: EditWireDetailsType) => (
  <WireEditInfoWrapper>
    <WireEditInfoItem>
      <WireEditInfoLabel>Status</WireEditInfoLabel>
      <Status status={status} />
    </WireEditInfoItem>
    <WireEditInfoItem>
      <WireEditInfoLabel>Last Update</WireEditInfoLabel>
      <WireEditInfoValue>{dateFormatter(dateUpdate)}</WireEditInfoValue>
    </WireEditInfoItem>
  </WireEditInfoWrapper>
);
