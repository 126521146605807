import { COLORS } from '@/styles/colors';
import { resetButton } from '@/styles/common';
import { flex, flexCenter } from '@/styles/layout';
import { body, poppinsMediumFont } from '@/styles/typography';
import styled from 'styled-components';

export const RoundedButtonIcon = styled.div`
  ${flexCenter};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 2px 10px rgb(63 77 90 / 35%);
  cursor: pointer;
`;

export const RoundedButtonContainer = styled.button`
  ${flex({ alignItems: 'center' })};
  ${resetButton};
  cursor: pointer;
  column-gap: 1rem;

  &:hover ${RoundedButtonIcon} {
    box-shadow: 0 2px 20px rgb(63 77 90 / 35%);
  }
`;

export const RoundedButtonContent = styled.div`
  color: ${COLORS.black};
  ${poppinsMediumFont};
  ${body};
  white-space: nowrap;
`;
