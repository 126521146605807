import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import styled from 'styled-components';

export const PaymentSettingsCardWrapper = styled.div<{ variant?: 'primary' | 'dark' }>`
  width: 458px;
  border: 1px solid ${COLORS.grey[400]};
  border-radius: 16px;
  ${flex({ flexDirection: 'column' })};
  padding: 20px;

  ${({ variant }) =>
    variant === 'dark' &&
    `
      background-color: ${COLORS.grey[150]};    
  `};
`;
