import { COLORS } from '@/styles/colors';
import { ellipsis } from '@/styles/common';
import { flex, flexCenter } from '@/styles/layout';
import { bodySmall, poppinsMediumFont, poppinsSemiBoldFont, subTitleSmall } from '@/styles/typography';
import styled from 'styled-components';

export const UserDetailsWrapper = styled.div`
  width: 100%;
`;

export const UserDetailsIcon = styled.div`
  ${flexCenter};
  background-color: ${COLORS.grey[200]};
  border-radius: 8px;
  width: 42px;
  height: 42px;
`;

export const UserDetailsCard = styled.div`
  border-radius: 12px;
  border: 1px solid ${COLORS.grey[300]};
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 15%);
`;

export const UserDetailsTitle = styled.div`
  color: ${COLORS.grey[950]};
  ${subTitleSmall};
  ${poppinsSemiBoldFont};
`;

export const UserDetailsSubtitle = styled.div`
  color: ${COLORS.grey[600]};
  ${bodySmall};
  margin-top: 8px;
`;

export const UserDetailsRow = styled.div<{ isFlex?: boolean }>`
  padding: 20px;
  border-bottom: 1px solid ${COLORS.grey[300]};

  ${({ isFlex }) => isFlex && flex()};

  &:last-child {
    border-bottom: none;
  }
`;

export const UserDetailsRowTitle = styled.div`
  ${bodySmall};
  color: ${COLORS.grey[600]};
  margin-bottom: 4px;
`;

export const UserDetailsRowValue = styled.div`
  ${poppinsMediumFont};
  color: ${COLORS.grey[950]};
  ${ellipsis};
`;

export const UserDetailsActionRow = styled(UserDetailsRow)`
  ${flex({ justifyContent: 'space-between' })};
`;
