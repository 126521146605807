import { BILLING_FREQUENCY, BillingFrequencyType } from '../PaymentPlanCard/const';
import { DiscountBadge, FrequencyButton, FrequencyToggleWrapper, ToggleWrapper, CoveringBlock } from './styles';

type BillingFrequencyToggleType = {
  frequency: BillingFrequencyType;
  onChange: (frequency: BillingFrequencyType) => void;
};

export const BillingFrequencyToggle = ({ frequency, onChange }: BillingFrequencyToggleType) => {
  const setYearlyFrequency = () => onChange(BILLING_FREQUENCY.YEARLY);
  const setMonthlyFrequency = () => onChange(BILLING_FREQUENCY.MONTHLY);

  const isYearlyFrequency = frequency === BILLING_FREQUENCY.YEARLY;
  const isMonthlyFrequency = frequency === BILLING_FREQUENCY.MONTHLY;

  return (
    <FrequencyToggleWrapper>
      <ToggleWrapper>
        <CoveringBlock frequency={frequency} />
        <FrequencyButton onClick={setYearlyFrequency} active={isYearlyFrequency}>
          Yearly
          <DiscountBadge>Save 10%</DiscountBadge>
        </FrequencyButton>
        <FrequencyButton onClick={setMonthlyFrequency} active={isMonthlyFrequency}>
          Monthly
        </FrequencyButton>
      </ToggleWrapper>
    </FrequencyToggleWrapper>
  );
};
