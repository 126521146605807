import { authApiService } from '@/api/authApiService';
import { DefaultPaymentMethodType } from '@/types/paymentTypes';
import { ApiResponseType } from '@/types/sharedTypes';

// TODO: add types later
export const deletePaymentMethodApi = (payload: DefaultPaymentMethodType): Promise<ApiResponseType<any>> => {
  const uri = '/payment/customer-vault/payment-method/';

  return authApiService.DELETE(uri, payload);
};
