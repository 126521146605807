import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';
import { SelfUserDetailsType } from '@/api/v1/users/getSelfUserDetailsApi';

export type UpdateUserDetailsType = {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
};

export const updateSelfUserDetailsApi = (
  payload: UpdateUserDetailsType
): Promise<ApiResponseType<SelfUserDetailsType>> => {
  const uri = '/users/me/';

  return authApiService.PATCH(uri, payload);
};
