import { ValueOf } from '@/types/sharedTypes';
import { ReactNode } from 'react';
import { COLORS } from '@/styles/colors';
import { Typography } from '@/components/Typography/Typography';

export const BILLING_FREQUENCY = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
} as const;

export const PAYMENT_PLAN_TYPE = {
  FREE: 'free',
  STARTER: 'starter',
  PRO: 'pro',
} as const;

export type BillingFrequencyType = ValueOf<typeof BILLING_FREQUENCY>;
export type PaymentPlansType = ValueOf<typeof PAYMENT_PLAN_TYPE>;

export type PricingCard = {
  title: PaymentPlansType;
  overageMessage?: (hasIdVerification: boolean) => ReactNode;
  depositInfoTitle: ReactNode;
  paymentInfoTitle: ReactNode;
  entitiesCount: ReactNode;
  userCount: ReactNode;
};

export const PRICING_CARD_ITEMS: { [key in PaymentPlansType]: PricingCard } = {
  [PAYMENT_PLAN_TYPE.FREE]: {
    title: 'free',
    depositInfoTitle: (
      <>
        <strong>Share unlimited</strong> deposit info
      </>
    ),
    paymentInfoTitle: (
      <>
        <strong>Collect</strong> or <strong>confirm</strong> wire info:
      </>
    ),
    entitiesCount: (
      <>
        <strong> 1x</strong>
        <Typography as='span' variant='bodySmall' color={COLORS.grey[650]}>
          /month
        </Typography>
      </>
    ),
    userCount: (
      <>
        <strong>1</strong> user
      </>
    ),
  },
  [PAYMENT_PLAN_TYPE.STARTER]: {
    title: 'starter',
    depositInfoTitle: (
      <>
        <strong>Share unlimited</strong> deposit info
      </>
    ),
    paymentInfoTitle: (
      <>
        <strong>Collect</strong> or <strong>confirm</strong> wire info:
      </>
    ),
    entitiesCount: (
      <>
        <strong> 3x</strong>
        <Typography as='span' variant='bodySmall' color={COLORS.grey[650]}>
          /month
        </Typography>
      </>
    ),
    userCount: (
      <>
        <strong>1</strong> user
      </>
    ),
  },
  [PAYMENT_PLAN_TYPE.PRO]: {
    title: 'pro',
    overageMessage: (hasIdVerification) => (
      <>
        <Typography color={COLORS.grey[650]} variant='caption'>
          Overage rate — <strong>${hasIdVerification ? 30 : 20}</strong> per collected or confirmed payment info
        </Typography>
      </>
    ),
    depositInfoTitle: (
      <>
        <strong>Share unlimited</strong> deposit info
      </>
    ),
    paymentInfoTitle: (
      <>
        <strong>Collect</strong> or <strong>confirm</strong> wire info:
      </>
    ),
    entitiesCount: (
      <>
        <strong> 10x</strong>
        <Typography as='span' variant='bodySmall' color={COLORS.grey[650]}>
          /month
        </Typography>
      </>
    ),
    userCount: (
      <>
        <strong>Unlimited</strong> users
      </>
    ),
  },
} as const;
