import { authApiService } from '@/api/authApiService';
import { PaymentPlanResponseType } from '@/types/paymentTypes';
import { ApiResponseType, ListResponseType } from '@/types/sharedTypes';

export const getPaymentPlanByIdApi = (
  planId: string
): Promise<ApiResponseType<ListResponseType<PaymentPlanResponseType>>> => {
  const uri = `/payment/plans/${planId}/`;

  return authApiService.GET(uri);
};
