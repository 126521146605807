import { authApiService } from '../../authApiService';
import { ApiResponseType, BaseResponseType } from '@/types/sharedTypes';

export type ForgotPasswordSetPasswordPayload = {
  password: string;
};

export const forgotPasswordSetPasswordApi = async (
  data: ForgotPasswordSetPasswordPayload
): Promise<ApiResponseType<BaseResponseType>> => {
  const uri = '/users/forgot-password/password/';

  return authApiService.POST(uri, data);
};
