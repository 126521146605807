import { COLORS } from '@/styles/colors';
import { poppinsMediumFont } from '@/styles/typography';
import styled, { css } from 'styled-components';

const GeneralItemStyle = css<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${poppinsMediumFont};
  color: ${COLORS.black};
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      background-color: ${COLORS.grey[300]};
      border-radius: 4px;
    `}
`;

export const WireTypeFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const FilterItem = styled.div``;

export const ItemWrapperWithOutIcon = styled.div<{ active?: boolean }>`
  ${GeneralItemStyle};
  padding: 6px 16px;
`;

export const ItemWrapperWithIcon = styled.div<{ active?: boolean }>`
  ${GeneralItemStyle};
  padding: 6px 16px 6px 8px;
  column-gap: 8px;
`;
