import styled from 'styled-components';
import { bodySmall, poppinsRegularFont } from '@/styles/typography';

export const ProgressMessage = styled.div`
  margin-bottom: 24px;
  ${poppinsRegularFont};
  ${bodySmall};
`;

export const ProgressImage = styled.img`
  align-self: center;
  margin-bottom: 24px;
`;
