import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import { DetailsDividerRow } from '@/components/DetailsWidget/DetailsDividerRow/DetailsDividerRow';
import { DetailsRow } from '@/components/DetailsWidget/DetailsRow/DetailsRow';
import { Box } from '@/components/Box/Box';
import { getPaymentDetailsData } from '../helpers';

type IntermediaryPaymentDetailsType = {
  paymentDetails: PaymentDetailsGeneralType;
};

export const IntermediaryPaymentDetails = ({ paymentDetails }: IntermediaryPaymentDetailsType) => {
  const {
    bankCodeLabel,
    isDataFromBlockchain,
    intermediaryBankName,
    intermediaryAccountName,
    intermediaryBankAddress,
    intermediaryBankPhoneNumber,
    intermediaryBankCodeValue,
  } = getPaymentDetailsData(paymentDetails);

  return (
    <Box>
      <DetailsDividerRow />
      <DetailsRow name='Intermediary Bank' isHeader />
      <DetailsRow name={bankCodeLabel} hasHighlight={isDataFromBlockchain}>
        {intermediaryBankCodeValue}
      </DetailsRow>
      <DetailsRow name='Bank Name'>{intermediaryBankName}</DetailsRow>
      <DetailsRow name='Account Number' hasHighlight={isDataFromBlockchain}>
        {intermediaryAccountName}
      </DetailsRow>
      <DetailsRow name='Bank Address'>{intermediaryBankAddress}</DetailsRow>
      <DetailsRow name='Bank Phone Number'>{intermediaryBankPhoneNumber}</DetailsRow>
    </Box>
  );
};
