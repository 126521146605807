import { ApiResponseType } from '@/types/sharedTypes';
import { authApiService } from '../../../authApiService';

export interface DepositAccountDomesticType {
  name: string;
  bank_aba_number: string;
  bank_phone_number: string;
  intermediary_bank_phone_number: string;
  recipient_name: string;
  account_name: string;
  bank_name: string;
  bank_account_number: string;
  bank_address: string;
  intermediary_bank_aba_number: string;
  intermediary_bank_name: string;
  intermediary_bank_account_number: string;
  intermediary_bank_address: string;
}

export type ResponseType = {
  created_by: {
    id: string;
    email: string;
  };
  date_created: string;
  date_modified: string;
  id: string;
  name: string;
  ref_id: string;
  status: string;
};

export const createDomesticDepositAccountApi = (
  data: DepositAccountDomesticType
): Promise<ApiResponseType<ResponseType>> => {
  const uri = '/organization/deposit-accounts/domestic/';

  return authApiService.POST(uri, data);
};
