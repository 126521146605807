import { PaymentPlanResponseType } from '@/types/paymentTypes';
import { PAYMENT_PLAN_TYPE } from './ui/PaymentPlanCard/const';
import { PAYMENT_FREQUENCY_DB } from '@/const/payment';

const PAYMENT_PLANS_IDS = [
  'csgbq7n0i475k6jhf4ig',
  'csgbrp70i475k6jhf4jg',
  'csgbton0i475k6jhf64g',
  'csgc08n0i475k6jhf93g',
  'csgc2s70i475k6jhfeag',
  'csgc3q70i475k6jhfebg',
];

export type PreparePlanType = {
  title: string;
  plan_id: {
    monthly: string;
    yearly: string;
  };
  description: {
    monthly: string[];
    yearly: string[];
  };
  amount: {
    monthly: number;
    yearly: number;
  };
  addon: {
    description: string;
    amount: {
      monthly: number;
      yearly: number;
    };
    id: {
      monthly: string;
      yearly: string;
    };
  };
};

const getPlan = (actualPlans: PaymentPlanResponseType[], planName: string) => {
  if (!actualPlans) return;

  return actualPlans.filter((actualPlan) => actualPlan.name.toLocaleLowerCase().includes(planName));
};

const preparePlan = (plans: PaymentPlanResponseType[], title: string): PreparePlanType => {
  if (!plans) return;

  const annualPlan = plans?.find((plan) => plan.billing_frequency === PAYMENT_FREQUENCY_DB.ANNUAL);
  const monthlyPlan = plans?.find((plan) => plan.billing_frequency === PAYMENT_FREQUENCY_DB.MONTHLY);

  return {
    title,
    description: {
      yearly: annualPlan?.description?.split('\n'),
      monthly: monthlyPlan?.description?.split('\n'),
    },
    plan_id: {
      yearly: annualPlan?.id,
      monthly: monthlyPlan?.id,
    },
    amount: {
      yearly: annualPlan?.amount,
      monthly: monthlyPlan?.amount,
    },
    addon: {
      description: annualPlan?.add_ons[0]?.description,
      amount: {
        yearly: annualPlan?.add_ons[0]?.amount,
        monthly: monthlyPlan?.add_ons[0]?.amount,
      },
      id: {
        yearly: annualPlan?.add_ons[0]?.id,
        monthly: monthlyPlan?.add_ons[0]?.id,
      },
    },
  };
};

export const usePreparePaymentPlans = (paymentPlans: PaymentPlanResponseType[]) => {
  if (!paymentPlans) return;

  const supportedPlans = paymentPlans?.filter((paymentPlan) => PAYMENT_PLANS_IDS.includes(paymentPlan?.id));

  const freePlans = getPlan(supportedPlans, PAYMENT_PLAN_TYPE.FREE);
  const startedPlans = getPlan(supportedPlans, PAYMENT_PLAN_TYPE.STARTER);
  const proPlans = getPlan(supportedPlans, PAYMENT_PLAN_TYPE.PRO);

  const freePlan = preparePlan(freePlans, PAYMENT_PLAN_TYPE.FREE);
  const starterPlan = preparePlan(startedPlans, PAYMENT_PLAN_TYPE.STARTER);
  const proPlan = preparePlan(proPlans, PAYMENT_PLAN_TYPE.PRO);

  return {
    [PAYMENT_PLAN_TYPE.FREE]: freePlan,
    [PAYMENT_PLAN_TYPE.STARTER]: starterPlan,
    [PAYMENT_PLAN_TYPE.PRO]: proPlan,
  };
};
