import { ApiParams, prepareListApiParams } from '@/api/helpers/urlApiHelpers';
import { createOpcoInternalUserApi, CreateOpcoUserType } from '@/api/v1/opco/internal/createOpcoInternalUserApi';
import { getAuthTokenHeaders } from '@/helpers/apiHelpers/queryHelper';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { getOpcoInternalUsersApi } from '@/api/v1/opco/internal/getOpcoInternalUsersApi';

export const loadOpcoUsers = async (params: ApiParams) => {
  const apiParams = prepareListApiParams(params);

  try {
    const response = await getOpcoInternalUsersApi(apiParams);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const createOpcoUser = async (payload: CreateOpcoUserType, pinToken: string) => {
  const options = getAuthTokenHeaders(pinToken);

  try {
    const response = await createOpcoInternalUserApi(payload, options);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
