import {
  SwitchRoleDescription,
  SwitchRoleIcon,
  SwitchRoleItemWrapper,
  SwitchRoleTitle,
} from '@/bundle/pages/SwitchRolePage/ui/styles';
import { Box } from '@/components/Box/Box';
import { ReactNode } from 'react';

interface SwitchRoleItemType {
  role: {
    title: string;
    value: string;
    description: string;
    icon: ReactNode;
  };
  isSelected: boolean;
  onChange: (string) => void;
}

export const SwitchRoleItem = ({ role, onChange, isSelected }: SwitchRoleItemType) => {
  const { title, value, description, icon } = role;

  return (
    <SwitchRoleItemWrapper onClick={() => onChange(value)} isSelected={isSelected}>
      <Box flexDirection='row'>
        <SwitchRoleIcon isSelected={isSelected}>{icon}</SwitchRoleIcon>
        <Box flexDirection='column' justifyContent='space-between'>
          <SwitchRoleTitle>{title}</SwitchRoleTitle>
          <SwitchRoleDescription>{description}</SwitchRoleDescription>
        </Box>
      </Box>
    </SwitchRoleItemWrapper>
  );
};
