import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { COLORS } from '@/styles/colors';

export const EditLinkWrapper = styled(NavLink)`
  display: inline-block;

  &:hover {
    path {
      fill: ${COLORS.green[500]};
    }
  }
`;
