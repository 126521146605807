import styled from 'styled-components';
import { COLORS } from '../../styles/colors';
import { flex } from '../../styles/layout';
import { media } from '@/styles/responsive';
import { THEME } from '@/styles/theme';
import { overlayBackground } from '@/styles/common';

export const SidebarWrapper = styled.aside<{ isOpen: boolean }>`
  ${flex()};
  flex-basis: ${THEME.sidebar.width};
  flex-shrink: 0;
  min-width: 0;
  background-color: ${COLORS.black};

  ${media.tablet`
    position: fixed;
    left: 0;
    bottom: 0;
    top: ${THEME.header.height};
    width: 100%;
    border-radius: 0;
    z-index: ${THEME.sidebar.zIndex};;
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.2s ease-in, transform 0.25s ease-in;

    ${({ isOpen }) =>
      isOpen &&
      `
      opacity: 1;
      transform: translateX(0);
    `};
  `}

  ${media.tabletOnly`
    width: ${THEME.sidebar.width};
  `}
`;

export const MenuWrapper = styled.div`
  ${flex({ flexDirection: 'column' })};
  flex-grow: 1;
  overflow: auto;
`;

export const SidebarOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: ${THEME.header.height};
  left: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  ${overlayBackground};
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.2s ease,
    visibility 0.2s ease;

  ${media.tabletOnly`
    ${({ isOpen }) =>
      isOpen &&
      `
      opacity: 1;
      visibility: visible;
    `};
  `}
`;
