import { MouseEvent } from 'react';
import { ReactComponent as DatePickerIcon } from '../../../../images/CalendarIcon.svg';
import { ReactComponent as CrossIcon } from '../images/CloseIcon.svg';
import {
  CrossIconWrapper,
  DatePickerIconWrapper,
  DatePickerInputWrapper,
} from '@/components/form/FormikDatePicker/ui/styles';
import { Input, InputType } from '../../fields/Input/Input';

type DatePickerInputType = {
  value: string;
  onOpen: (event: MouseEvent<HTMLDivElement>) => void;
  onClear: () => void;
  isClearable?: boolean;
  error: string;
  apiError: string;
} & InputType;

export const DatePickerInput = ({
  label,
  placeholder,
  value,
  onOpen,
  onClear,
  variant = 'medium',
  isClearable = true,
  hasErrorComponent,
  error,
  apiError,
  ...rest
}: DatePickerInputType) => {
  const clearDate = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    onClear();
  };

  const hasClearIcon = value && isClearable;

  return (
    <>
      <DatePickerInputWrapper onClick={onOpen}>
        <Input
          placeholder={placeholder}
          value={value}
          readOnly
          hasErrorComponent={hasErrorComponent}
          error={error}
          apiError={apiError}
          label={label}
          variant={variant}
          {...rest}
        >
          {hasClearIcon && (
            <CrossIconWrapper onClick={clearDate} variant={variant}>
              <CrossIcon />
            </CrossIconWrapper>
          )}
          <DatePickerIconWrapper variant={variant}>
            <DatePickerIcon />
          </DatePickerIconWrapper>
        </Input>
      </DatePickerInputWrapper>
    </>
  );
};
