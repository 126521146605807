import { useNavigate } from 'react-router-dom';
import { getDepositAccountDetailsUrl } from '../../../DepositAccounts/_Details/urls/getDepositAccountDetailsUrl';
import { ReactComponent as RejectIcon } from '../../../../../../images/circleRejectIcon.svg';
import { ReactComponent as SuccessIcon } from '../../../../../../images/circleSuccessIcon.svg';
import { DepositAccountLinkWrapper, DepositAccountTitle, StatusBlock, Title } from './styles';
import { DEPOSIT_ACCOUNT_STATUS } from '@/const/shared';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';

type DepositAccountLinkType = {
  depositAccount?: {
    id: string;
    name: string;
    status: string;
  };
};

export const DepositAccountLink = ({ depositAccount }: DepositAccountLinkType) => {
  const navigate = useNavigate();

  const navigateToDepositAccount = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.ReferenceDepositAccountRedirect);

    navigate(getDepositAccountDetailsUrl(depositAccount?.id));
  };

  const currentStatusIcon = depositAccount.status === DEPOSIT_ACCOUNT_STATUS.ACTIVE ? <SuccessIcon /> : <RejectIcon />;

  return (
    <DepositAccountLinkWrapper>
      <StatusBlock>
        {currentStatusIcon}
        <Title>Reference Deposit Account</Title>
      </StatusBlock>
      <DepositAccountTitle title={depositAccount.name} onClick={navigateToDepositAccount}>
        {depositAccount.name}
      </DepositAccountTitle>
    </DepositAccountLinkWrapper>
  );
};
