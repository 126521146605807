import styled from 'styled-components';
import { COLORS } from '@/styles/colors';
import { body, bodySmall, headline4, poppinsRegularFont, poppinsSemiBoldFont } from '@/styles/typography';

export const Title = styled.div`
  text-align: start;
  ${poppinsSemiBoldFont};
  color: ${COLORS.grey[900]};
  ${headline4};
`;

export const SubTitle = styled.div`
  ${poppinsRegularFont};
  ${body};
  ${COLORS.black};
`;

export const SwitchRoleItemWrapper = styled.div<{ isSelected: boolean }>`
  border-radius: 12px;
  border: 1px solid ${COLORS.grey[300]};
  background-color: ${COLORS.white};
  padding: 13px 16px;
  cursor: pointer;

  &:hover {
    border-color: ${COLORS.grey[700]};
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    border-color: ${COLORS.green[500]};
    background-color: ${COLORS.green[200]};

    &:hover {
      border-color: ${COLORS.green[500]};
    }
  `};
`;

export const SwitchRoleTitle = styled.div`
  color: ${COLORS.black};
  ${poppinsSemiBoldFont};
  ${body};
`;

export const SwitchRoleDescription = styled.div`
  color: ${COLORS.black};
  ${poppinsRegularFont};
  ${bodySmall};
`;

export const SwitchRoleIcon = styled.div<{ isSelected: boolean }>`
  margin-right: 16px;

  ${({ isSelected }) =>
    isSelected &&
    `
    rect {
      fill: none;
    }
  `};
`;
