import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import styled, { css } from 'styled-components';

const baseStyles = css`
  ${flex({ alignItems: 'center' })}
  padding: 6px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
`;

export const InboundWrapper = styled.div`
  ${baseStyles};
  background-color: ${COLORS.light_ocean};
`;

export const OutboundWrapper = styled.div`
  ${baseStyles};
  background-color: ${COLORS.purple[100]};
`;
