import { authApiService } from '@/api/authApiService';
import { ApiResponseType, Maybe, ValueOf } from '@/types/sharedTypes';

export const TRUST_STAMP_STATUS = {
  NOT_INITIALIZED: 'not_initialized',
  INITIALIZED: 'initialized',
  IN_PROGRESS: 'in_progress',
  SUCCEED: 'succeed',
  FAILED: 'failed',
} as const;

export type TrustStampStatusType = ValueOf<typeof TRUST_STAMP_STATUS>;

export type TrustStampResponseStatusType = {
  date_initialized: string;
  provide_pd_disable_after_sec: number;
  session_id?: string;
  start_new_session_enable_after_sec: number;
  status: TrustStampStatusType;
  meta: Maybe<{ first_name: string; last_name: string; middle_name: string }>;
};

export const getRecipientTrustStampStatusApi = (
  wireId: string
): Promise<ApiResponseType<TrustStampResponseStatusType>> => {
  const uri = `/recipient/wires/${wireId}/trust-stamp/status/`;

  return authApiService.GET(uri);
};
