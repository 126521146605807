import { authApiService } from '../../authApiService';
import { ApiResponseType } from '@/types/sharedTypes';
import { DepositorWireType } from '@/api/v1/depositor/getDepositorWireApi';

export type ChangeStatusDataType = {
  status: string;
};

export const depositorChangeWireStatusApi = async (
  id: string,
  data: ChangeStatusDataType
): Promise<ApiResponseType<DepositorWireType>> => {
  const uri = `/depositor/wires/${id}/status/`;

  return authApiService.PATCH(uri, data);
};
