import { BREAKPOINTS } from '@/styles/responsive';
import { useMediaQuery } from './useMediaQuery';

export const useResponsiveDevice = () => {
  const isMobile = useMediaQuery(`only screen and (max-width: ${BREAKPOINTS.md}px)`);
  const isTablet = useMediaQuery(
    `only screen and (min-width: ${BREAKPOINTS.md}px) and (max-width: ${BREAKPOINTS.xl}px)`
  );
  const isDesktop = useMediaQuery(`only screen and (min-width: ${BREAKPOINTS.xl}px)`);

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};
