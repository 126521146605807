import { useNavigate } from 'react-router-dom';
import { MobileHeaderWrapper, MobileLogo } from './styles';
import { getDashboardRedirectUrl } from '@/bundle/Auth/LoginFlow/LoginPage/const/const';
import { HamburgerButton } from '../HamburgerButton/HamburgerButton';
import { useUser } from '@/context/userContext';

type MobileHeaderType = {
  isOpen: boolean;
  onSidebarToggle: () => void;
};

export const MobileHeader = ({ isOpen, onSidebarToggle }: MobileHeaderType) => {
  const navigate = useNavigate();
  const { currentRole } = useUser();

  const redirectToDashboard = () => {
    const url = getDashboardRedirectUrl(currentRole);

    navigate(url);
  };

  return (
    <MobileHeaderWrapper>
      <MobileLogo onClick={redirectToDashboard}></MobileLogo>
      <HamburgerButton onToggle={onSidebarToggle} isOpen={isOpen}></HamburgerButton>
    </MobileHeaderWrapper>
  );
};
