import {
  TRUST_STAMP_STATUS,
  TrustStampResponseStatusType,
  TrustStampStatusType,
} from '@/api/v1/recipient/getRecipientTrustStampStatusApi';
import { WIRE_STATUS_DB } from '@/const/wire';
import { AssignedWireType } from '@/types/wireTypes';

export const TRUST_STAMP_URL = 'https://truststamp.ai';

export const isTrustStampNotInitializedStatus = (status: TrustStampStatusType) => {
  return status === TRUST_STAMP_STATUS.NOT_INITIALIZED;
};

export const isTrustStampInitializedStatus = (status: TrustStampStatusType) => {
  return status === TRUST_STAMP_STATUS.INITIALIZED;
};

export const isTrustStampInProgressStatus = (status: TrustStampStatusType) => {
  return status === TRUST_STAMP_STATUS.IN_PROGRESS;
};

export const isTrustStampSucceedStatus = (status: TrustStampStatusType) => {
  return status === TRUST_STAMP_STATUS.SUCCEED;
};

export const isTrustStampFailedStatus = (status: TrustStampStatusType) => {
  return status === TRUST_STAMP_STATUS.FAILED;
};

export const shouldShowDocumentVerification = (
  trustStampDetails: TrustStampResponseStatusType,
  isPaymentDetailsExists: boolean
) => {
  if (!trustStampDetails || isPaymentDetailsExists) return false;

  const { status, provide_pd_disable_after_sec } = trustStampDetails;

  // When user successfully passed document verification and payment details timer was expired ->
  // redirect user to start verification process again
  if (isTrustStampSucceedStatus(status) && provide_pd_disable_after_sec === 0) {
    return true;
  }

  return (
    isTrustStampNotInitializedStatus(status) ||
    isTrustStampInitializedStatus(status) ||
    isTrustStampFailedStatus(status)
  );
};

export const shouldShowDocumentVerificationPassedDetails = (
  wire: AssignedWireType,
  trustStampDetails: TrustStampResponseStatusType
) => {
  if (!wire || !trustStampDetails) return false;

  return (
    (wire?.is_pd_provider_kyc_required && wire?.is_payment_details_exist && wire.is_payment_details_allowed_to_view) ||
    (isTrustStampSucceedStatus(trustStampDetails?.status) && trustStampDetails.start_new_session_enable_after_sec > 0)
  );
};

export const isTrustStampVerified = (trustStampDetails: TrustStampResponseStatusType) => {
  if (!trustStampDetails) return false;

  return isTrustStampSucceedStatus(trustStampDetails?.status) && trustStampDetails.provide_pd_disable_after_sec > 0;
};

export const getDocumentVerifiedRecipientFullName = (trustStampDetails: TrustStampResponseStatusType) => {
  if (!trustStampDetails) return '';

  const { first_name, middle_name, last_name } = trustStampDetails.meta || {};

  return `${first_name || ''} ${middle_name || ''} ${last_name || ''}`.trim();
};

export const getIsEnabledTrustStampDetailsRequest = (wire: AssignedWireType) => {
  if (!wire || !wire?.is_pd_provider_kyc_required) return false;

  if (!!wire.is_payment_details_exist && !wire.is_payment_details_allowed_to_view) return false;

  return wire.status !== WIRE_STATUS_DB.RECIPIENT_ASSIGNED;
};
