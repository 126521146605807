import { Box } from '@/components/Box/Box';
import { Typography } from '@/components/Typography/Typography';
import { convertCentsToDollars } from '@/helpers/formatHelpers';
import { COLORS } from '@/styles/colors';

type SwitchToggleLabelType = {
  isYearlyIdVerificationPrice: number;
};

export const SwitchToggleLabel = ({ isYearlyIdVerificationPrice }: SwitchToggleLabelType) => {
  const convertedVerificationPrice = convertCentsToDollars(isYearlyIdVerificationPrice);

  return (
    <Box display='flex' alignItems='center' flexDirection='row' columnGap={2}>
      <Typography variant='bodySmall' color={COLORS.black}>
        ID Verification (3FA) —
      </Typography>
      <Typography variant='bodySmall' fontWeight='semibold'>
        {convertedVerificationPrice ?? 0}
      </Typography>
    </Box>
  );
};
